import i18n from '@app/utils/i18n';
import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ROOM_TYPE } from "@app/dataset";
import DigitsColorWrapper from '@app/components/DigitsColorWrapper';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const LoadingModal = styled.div`
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: none;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 30px;
`

const ProfitTypeToString = (profitType: number) => {
    switch (profitType) {
        case 1:
            return i18n.t("베팅 요율")
        case 3:
            return i18n.t("이익금")
        case 4:
            return i18n.t("토너먼트 바이인")
        default:
            return ""
    }
}

type Data = {
    blind: number[],
    profitId: number,
    groupId: number,
    roomId: number,
    userId: number,
    game: number,
    profitType: number,
    originAmount: number,
    amount: number,
    agencyId: number,
    agentCode:string,
    hqAmount: number,
    subhqAmount: number,
    agencyAmount: number,
    fiatAmountAgency: number,
    currency: string,
    addedAt: string,
    agency_sent: number,
    userNickname: string,
    agencyName: string,
    agencyEgg:number,
    subHQEgg:number,
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const AgencyRakeLogList = ({ isAgency, title }: { isAgency: boolean, title:string }) => {
    const {t} = useTranslation();
    const tableRef = useRef(null);
    const { token } = useAppSelector(state => state.auth);
    const [dataList, setDataList] = useState<Data[]>([])
    const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);
    const [dataTotalCount, setDataTotalCount] = useState(0)
    const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);

    const [inputAgencyName, setInputAgencyName] = useState("")
    const [inputUserNickname, setInputUserNickname] = useState("")
    const [inputUserId, setInputUserId] = useState("")
    const [inputAgentCode, setInputAgentCode] = useState("")
    const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000)))
    const [inputEndDate, setInputEndDate] = useState(new Date((Date.now() - 0)))
    const [inputSubHQName, setInputSubHQName] = useState("")

    const [searchAgencyName, setSearchAgencyName] = useState("")
    const [searchUserNickname, setSearchUserNickname] = useState("")
    const [searchUserId, setSearchUserId] = useState("")
    const [searchAgentCode, setSearchAgentCode] = useState("")
    const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT))
    const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT))
    const [searchSubHQName, setSearchSubHQName] = useState("")
    const [totalSum, setTotalSum] = useState<{
        betSum:{[key:number]:number},
        rakeAmount:{[key:number]:number},
        agencyAmount:number,
        // subhqAmount:number,
        fiatAmountAgency:{[key:string]:{[key:number]:number}},
    }>({
        betSum:{},
        rakeAmount:{},
        agencyAmount:0,
        // subhqAmount:0,
        fiatAmountAgency:{},
    })

    const [paginationData, setPaginationData] = useState({
        currentPage: 0,
        maxPage: 0,
        paginationMin: 0,
        paginationMax: 0,
    })
    const [currentPage, setCurrentPage] = useState(0);
    const [rowCount, setRowCount] = useState(15)
    const [onlyFiatRakeView, setOnlyFiatRakeView] = useState<boolean>(false);
    const [showLoading, setShowLoading] = useState<boolean>(false);

    const updateLog = async (innerPageId: number, innerRowCount: number) => {
        console.log(searchAgencyName)
        const result = await AdminAPI.agencyRakeLogs(token, innerPageId, innerRowCount, searchStartDate, searchEndDate, searchAgencyName, searchUserNickname, searchUserId, searchAgentCode, searchSubHQName);
        
        if (result.success) {
            const agencyId = result.agencyId;
            if(agencyId == 336) setOnlyFiatRakeView(true);
            result.rows = result.rows.map((item: any, index:number) => {
                const blind = result.blinds.find((w:any)=>w.groupId == item.groupId)
                const subHQAgencyIds = (item.subHQAgencyIds || "").split(",")
                const subhqAmounts = (item.subhqAmounts || "").split(",")
                const subHQNames = (item.subHQNames || "").split(",")

                const subAgencies = subHQAgencyIds.map((agencyId:number,i:number)=>{
                    return {
                        agencyId:agencyId,
                        agencyName:subHQNames[i],
                        amount:Number(subhqAmounts[i])
                    }
                });

                const mySub = subAgencies.find((v:any)=>v.agencyId == agencyId)
                return {
                    ...item,
                    blind:blind ? blind.blind : [0,0],
                    subhqAmount:mySub ? mySub.amount : 0,
                    subAgencies:subAgencies
                }
            });
            setDataList(result.rows)
            setDataTotalCount(result.totalCnt)
            setTotalSum({
                betSum:result.sum.originAmount || {},
                agencyAmount:result.sum.agencyAmount || 0,
                // subhqAmount:result.sum.subhqAmount || 0,
                rakeAmount:result.sum.rakeAmount || {},
                fiatAmountAgency:result.sum.fiatAmountAgency || {},
            })

            const maxPage = Math.ceil(result.totalCnt / innerRowCount)
            const paginationMin = Math.floor(innerPageId / 10) * 10
            const paginationMax = Math.min(paginationMin + 10, maxPage)
            setPaginationData({
                currentPage: innerPageId,
                maxPage: maxPage,
                paginationMin: paginationMin,
                paginationMax: paginationMax,
            })
        }
    }

    useEffect(() => {
        updateLog(currentPage, rowCount);
    }, [
        currentPage, 
        rowCount, 
        searchAgencyName, 
        searchUserNickname, 
        searchUserId, 
        searchStartDate, 
        searchEndDate, 
        searchAgentCode,
        searchSubHQName
    ]);

    const onClickSearch = useCallback(function () {
        setSearchAgencyName(inputAgencyName);
        setSearchUserNickname(inputUserNickname);
        setSearchUserId(inputUserId);
        setSearchAgentCode(inputAgentCode);
        setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
        setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
        setSearchSubHQName(inputSubHQName)
    }, [inputAgencyName, inputUserNickname, inputUserId, inputAgentCode, inputStartDate, inputEndDate, inputSubHQName]);

    const onClickCancelSearch = useCallback(function () {
        setSearchAgencyName("");
        setSearchUserNickname("");
        setSearchUserId("");
        setSearchStartDate("");
        setSearchEndDate("");
        setSearchAgentCode("");
        setSearchSubHQName("");
        setInputAgencyName("");
        setInputUserNickname("");
        setInputUserId("");
        setInputStartDate(new Date('2021-01-01 00:00:00'));
        setInputEndDate(new Date('2099-01-01 23:59:59'));
    }, [])

    const onClickExcelDownload = useCallback(async ()=>{
        setShowLoading(true);
        await updateLog(0, dataTotalCount);
        await new Promise(r=> setTimeout(r, 5000));
        document.getElementById("excel-download-button")?.click();
        await new Promise(r=> setTimeout(r, 1000));
        updateLog(paginationData.currentPage, rowCount)
        setShowLoading(false);
    },[dataTotalCount, searchStartDate, searchEndDate, searchAgencyName, searchUserNickname, searchUserId, searchAgentCode])

    return <div>
        {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={() => setAgencyDetailPopup(null)} /> : <></>}
        {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={() => setUserDetailPopup(null)} /> : <></>}
        {showLoading ? <LoadingModal>{t("처리중")}...</LoadingModal> : <></>}
        <ContentHeader title={title || t("요율 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row mb-2'>
                                    {isAgency == false ? 
                                        <div className="input-group col-4">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text">{t("소속 에이전트")}</span>
                                            </div>
                                            <input type="text" className="form-control" onChange={(v) => { setInputAgencyName(v.target.value) }} value={inputAgencyName} />
                                        </div> : <></>
                                    }
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("유저 닉네임")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => setInputUserNickname(v.target.value)} value={inputUserNickname} />
                                    </div>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("유저 아이디")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => setInputUserId(v.target.value)} value={inputUserId} />
                                    </div>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("총판 코드")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => setInputAgentCode(v.target.value)} value={inputAgentCode} />
                                    </div>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("부본사 명")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => setInputSubHQName(v.target.value)} value={inputSubHQName} />
                                    </div>
                                </div>
                                <div className='row'>

                                    <div className="input-group col-8">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("기간")}</span>
                                        </div>
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                                            onChange={(e) => {
                                                setInputStartDate(new Date(e.target.value));
                                            }}
                                        />
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                                            onChange={(e) => {
                                                setInputEndDate(new Date(e.target.value));
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                                let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("어제")}</button>
                                        </div>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                                let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("오늘")}</button>
                                        </div>
                                    </div>
                                    <div className='col-4'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" ref={tableRef} style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>{t("블라인드")}</th>
                                            <th>{t("지급 시각")}</th>
                                            <th>{t("방 번호")}</th>
                                            <th>{t("게임 번호")}</th>
                                            <th>{t("소속 사이트")}</th>
                                            <th>{t("총판 코드")}</th>
                                            <th>{t("유저 닉네임")}</th>
                                            <th>{t("수익 타입")}</th>
                                            <th>{t("배팅 금액")}</th>
                                            
                                            {!onlyFiatRakeView && <th>{t("레이크")}</th>}
                                            {isAgency ? <>
                                                {!onlyFiatRakeView && <th>{t("요율 지급액")}</th>}
                                                <th>{t("요율 지급액(화폐)")}</th>
                                            </> : <>
                                                <th>{t("에이전시 요율 지급액")}</th>
                                                <th>{t("부본사 요율 지급액")}</th>
                                            </>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <b>{t("합계")} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(Object.values(totalSum.betSum).reduce((a,b)=>a+Number(b),0))*10))/10)} /></b>
                                                <div style={{fontSize:"11px"}}>
                                                {Object.entries(totalSum.betSum).map((item:any,index:any)=>{
                                                    if(item[1] <= 0) return null;
                                                    return <div key={index}>
                                                        {ProfitTypeToString(Number(item[0]))} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Number(item[1])} />
                                                    </div>
                                                })}
                                                </div>
                                            </td>
                                            {!onlyFiatRakeView && <td>
                                                <b>{t("합계")} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(Object.values(totalSum.rakeAmount).reduce((a,b)=>a+Number(b),0))*10))/10)}/></b>
                                                <div style={{fontSize:"11px"}}>
                                                {Object.entries(totalSum.rakeAmount).map((item:any,index:any)=>{
                                                    if(item[1] <= 0) return null;
                                                    return <div key={index}>
                                                        {ProfitTypeToString(Number(item[0]))} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Number(item[1])} />
                                                    </div>
                                                })}
                                                </div>
                                            </td>}
                                            {isAgency ? <>
                                                {!onlyFiatRakeView && <td><b>{t("합계")} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(totalSum.agencyAmount)*10))/10)}/></b></td>}
                                                <td>
                                                    {totalSum.fiatAmountAgency ? Object.entries(totalSum.fiatAmountAgency).map((item:any,index:any)=>{
                                                        return <div key={index}><b>{item[0]} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(Object.values(item[1]).reduce((a:any,b)=>a+Number(b),0))*10))/10)}/></b></div>
                                                    }) : null}
                                                    <div style={{fontSize:"11px"}}>
                                                    {totalSum.fiatAmountAgency ? Object.entries(totalSum.fiatAmountAgency).map((item:any,index:any)=>{
                                                        console.log(item[1])
                                                        return Object.keys(item[1]).map((key:any,index2:any)=>{
                                                            console.log(key);
                                                            return <div key={index + "::" + key}>{ProfitTypeToString(Number(key))} ({item[0]}) : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(item[1][key])*10))/10)}/></div>
                                                        });
                                                    }) : null}
                                                    </div>
                                                </td>
                                                {/* <td><b>합계 : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(Object.values(totalSum.fiatAmountAgency).reduce((a,b)=>a+Number(b),0))*10))/10)}/></b></td> */}
                                            </> : <>
                                                <td><b>{t("합계")} : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(totalSum.agencyAmount)*10))/10)}/></b></td>
                                                {/*<td><b>합계 : <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={(Math.abs(Math.floor(Number(totalSum.subhqAmount)*10))/10)}/></b></td>*/}
                                            </> }
                                        </tr>
                                        {dataList.length == 0 ? <tr>
                                            <td colSpan={999}> {t("검색 결과가 없습니다")}.</td>
                                        </tr> : null}
                                        {
                                            dataList.map((item: Data, index) => {
                                                return <tr key={index} style={{
                                                    backgroundColor: index % 2 == 0 ? "#f9f9f9" : "white"
                                                }}>
                                                    <td>{(item.blind||[]).join("-")}</td>
                                                    <td>{moment(item.addedAt).local().format(DATE_FORMAT)}</td>
                                                    <td>{item.roomId}</td>
                                                    <td>{item.game}</td>
                                                    <td><button className="btn btn-link" onClick={() => setAgencyDetailPopup(item.agencyId)}>{item.agencyName}</button></td>
                                                    <td>{item.agentCode}</td>
                                                    <td><button className="btn btn-link" onClick={() => setUserDetailPopup(item.userId)}>{item.userNickname}</button></td>
                                                    <td>{ProfitTypeToString(item.profitType)}</td>
                                                    <td>
                                                        {!showLoading && item.profitType == 3 ? <div style={{fontSize:"11px"}}>{t("바이아웃 금액")}</div> : ""}
                                                        {!showLoading && item.profitType == 4 ? <div style={{fontSize:"11px"}}>{t("토너먼트 바이인 금액")}</div> : ""}
                                                        <DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Math.abs(Number(item.originAmount))}/>
                                                    </td>
                                                    {!onlyFiatRakeView && <td><DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Math.abs(Math.floor(Number(item.amount)*10)/10)}/></td>}
                                                    {isAgency ? <>
                                                        {!onlyFiatRakeView && <td><DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Math.abs(Math.floor(Number(item.agencyAmount)*10)/10)}/></td>}
                                                        <td><DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Math.abs(Math.floor(Number(item.fiatAmountAgency)*10)/10)}/>{item.currency}</td>
                                                    </> : <>
                                                        <td><DigitsColorWrapper nonColor={showLoading} fontSize={13} number={Math.abs(Math.floor(Number(item.agencyAmount)*10)/10)}/></td>
                                                        <td><DigitsColorWrapper nonColor={showLoading} fontSize={13} number={item.subhqAmount ? Math.abs(Math.floor(Number(item.subhqAmount)*10)/10) : 0}/></td>
                                                    </>}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                                {/* <table className="table table-head-fixed text-nowrap" ref={tableRef} style={{display:"none"}}>
                                    <thead>
                                        <tr>
                                            <th>{t("블라인드")}</th>
                                            <th>{t("지급 시각")}</th>
                                            <th>{t("방 번호")}</th>
                                            <th>{t("게임 번호")}</th>
                                            <th>{t("소속 사이트")}</th>
                                            <th>{t("총판 코드")}</th>
                                            <th>{t("유저 닉네임")}</th>
                                            <th>{t("수익 타입")}</th>
                                            <th>{t("배팅 금액")}</th>
                                            <th>{t("레이크")}</th>
                                            {isAgency ? <>
                                                <th>{t("요율 지급액")}</th>
                                            </> : <>
                                                <th>{t("에이전시 요율 지급액")}</th>
                                                <th>{t("부본사 요율 지급액")}</th>
                                            </>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataList.length == 0 ? <tr>
                                            <td colSpan={999}> {t("검색 결과가 없습니다")}.</td>
                                        </tr> : null}
                                        {
                                            dataList.map((item: Data, index) => {
                                                return <tr key={index} style={{
                                                    backgroundColor: index % 2 == 0 ? "#f9f9f9" : "white"
                                                }}>
                                                    <td>{(item.blind||[]).join("-")}</td>
                                                    <td>{moment(item.addedAt).local().format(DATE_FORMAT)}</td>
                                                    <td>{item.roomId}</td>
                                                    <td>{item.game}</td>
                                                    <td><button className="btn btn-link" onClick={() => setAgencyDetailPopup(item.agencyId)}>{item.agencyName}</button></td>
                                                    <td>{item.agentCode}</td>
                                                    <td><button className="btn btn-link" onClick={() => setUserDetailPopup(item.userId)}>{item.userNickname}</button></td>
                                                    <td>{ProfitTypeToString(item.profitType)}</td>
                                                    <td>
                                                        {Math.abs(Number(item.originAmount))}
                                                    </td>
                                                    <td>{Math.abs(Math.floor(Number(item.amount)*10)/10)}</td>
                                                    {isAgency ? <>
                                                        <td>{Math.abs(Math.floor(Number(item.agencyAmount)*10)/10)}</td>
                                                    </> : <>
                                                        <td>{Math.abs(Math.floor(Number(item.agencyAmount)*10)/10)}</td>
                                                        <td>{item.subhqAmount ? Math.abs(Math.floor(Number(item.subhqAmount)*10)/10) : 0}</td>
                                                    </>}
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table> */}
                            </div>
                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-left">
                                    <li className="page-item">
                                        <div className="page-link no-hover">
                                            {t("표시")}
                                        </div>
                                    </li>
                                    {
                                        [30, 50, 200].map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                                                    cursor: 'pointer'
                                                }} onClick={() => setRowCount(item)}>{item}</div>
                                            </li>
                                        })
                                    }
                                    <li className='page-item'>
                                        <div className='page-link' style={{cursor:'pointer'}} onClick={onClickExcelDownload}>{t("엑셀 다운로드")} </div>
                                    </li>
                                </ul>
                                <ul className="pagination pagination-sm m-0 float-right">
                                    <li className="page-item">
                                        <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                                            onClick={() => {
                                                if (paginationData.paginationMin < 10) {
                                                    return;
                                                } else {
                                                    setCurrentPage(paginationData.paginationMin - 1)
                                                }
                                            }}>
                                            <i className="fas fa-chevron-left"></i>
                                        </div>
                                    </li>
                                    {
                                        Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <div
                                                    className={"page-link " + (index + paginationData.paginationMin === currentPage ? 'selected' : '')}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setCurrentPage(index + paginationData.paginationMin)}>{index + paginationData.paginationMin + 1}</div>
                                            </li>
                                        })
                                    }
                                    <li className="page-item">
                                        <div
                                            className={"page-link " + (paginationData.paginationMax * rowCount > dataTotalCount ? 'disabled' : '')}
                                            onClick={() => {
                                                if (paginationData.paginationMax * rowCount > dataTotalCount) {
                                                    return;
                                                } else {
                                                    setCurrentPage(paginationData.paginationMax)
                                                }
                                            }}>
                                            <i className="fas fa-chevron-right"></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <DownloadTableExcel
                filename={`${t("유저 요율 상세내역")}_${moment(searchStartDate).format("YYYY-MM-DD")}_${moment(searchEndDate).format("YYYY-MM-DD")}`}
                sheet={t("유저 요율 상세내역")}
                currentTableRef={tableRef.current}
            >
                <div id="excel-download-button" style={{display:"none"}}></div>
            </DownloadTableExcel>
        </section>
    </div>
}

export default AgencyRakeLogList;
