import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import UserInGameMoneyPopup from "@app/components/popup/UserInGameMoneyPopup";
import {useTranslation} from "react-i18next";

let FromNow = styled.div`
  font-size:12px;
  font-weight: bold;
`

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const UserAccessList = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [duplicates, setDuplicates] = useState<{
    ip:string,
    userIds:string[],
    nicknames:string[],
    uids:string[],
    agencyNames:string[],
    agencyIds:string[],
  }[]>();

  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))

  const [userInGameMoneyPopup, setUserInGameMoneyPopup] = useState<number | null>(null);

  const OnLoad = useCallback(function(){
    setDuplicates(undefined);
    AdminAPI.userDuplicateIpAccess(token, searchStartDate, searchEndDate ).then(v=>{
        setDuplicates(v.rows)
    })
  },[
    searchStartDate,
    searchEndDate,
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchStartDate,
    searchEndDate,
  ])

  const onClickSearch = useCallback(function(){
    if(inputEndDate.getTime() - inputStartDate.getTime() > 7*24*60*60*1000){
      alert(t("검색 기간은 {{day}}일 이내로 설정해주세요.", { day : "3" }))
      return;
    }

    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  },[
    inputStartDate,
    inputEndDate,
  ])

  const onClickCancelSearch = useCallback(function(){
    setSearchStartDate("");
    setSearchEndDate("");

    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));
  },[])

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}
    {userInGameMoneyPopup !== null ? <UserInGameMoneyPopup onClose={()=>setUserInGameMoneyPopup(null)} /> : <></>}

    <ContentHeader title={`IP ${t("중복자 조회")}`}/>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className='row'>
                            <div className="input-group col-8">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{t("기간")}</span>
                                </div>
                                <input type="datetime-local" className="form-control"
                                        value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                                        onChange={(e) => {
                                        setInputStartDate(new Date(e.target.value));
                                        }}
                                />
                                <input type="datetime-local" className="form-control"
                                        value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                                        onChange={(e) => {
                                        setInputEndDate(new Date(e.target.value));
                                        }}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().startOf('isoWeek').format(DATE_FORMAT)
                                        let r2 = moment().endOf('isoWeek').format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("이번주")}</button>
                                </div>
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("어제")}</button>
                                </div>
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("오늘")}</button>
                                </div>
                            </div>
                        <div className='col-4'>
                        <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                        <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                    </div>
                </div>
            </div>



              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th style={{width:"200px"}}>IP</th>
                    <th>{t("접속자")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    duplicates ? duplicates.map((item, index) => {
                      return <tr key={index}>
                        <td>{item.ip}</td>
                        <td>
                            {item.userIds.map((userId, index) => {
                                return <div key={index} style={{backgroundColor:"#eee", display:"inline-block", margin:"0 10px", borderRadius:"5px", padding:"2px 15px"}}>
                                    <span>
                                        {item.nicknames[index]}({item.uids[index]})
                                        <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setUserDetailPopup(parseInt(userId)) }}/>
                                    </span>
                                    <span style={{marginLeft:"10px"}}>
                                        {item.agencyNames[index]}
                                        <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setAgencyDetailPopup(parseInt(item.agencyIds[index])) }}/>
                                    </span>
                                </div>
                            })}
                        </td>
                      </tr>
                    }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
}

export default UserAccessList;
