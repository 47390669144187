import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate, Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';

import {logoutUser} from '@store/reducers/auth';
import styled from 'styled-components';
import {PfDropdown, PfImage} from '@profabric/react-components';
import { useTranslation } from 'react-i18next';

const StyledSmallUserImage = styled(PfImage)`
  margin-top: 3px;
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;

const StyledBigUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
  --pf-border: 3px solid #fff3;
`;

const UserHeader = styled.li`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 195px;
  padding: 10px;
  text-align: center;
  img {
    z-index: 5;
    height: 90px;
    width: 90px;
    border: 3px solid;
    border-color: transparent;
    border-color: rgba(255, 255, 255, 0.2);
  }
  p {
    z-index: 5;
    font-size: 17px;
    margin-top: 10px;
    small {
      display: block;
      font-size: 12px;
    }
  }
`;

const UserBody = styled.li`
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom: 1px solid #495057;
  border-top: 1px solid #dee2e6;
  padding: 15px;
  &::after {
    display: block;
    clear: both;
    content: '';
  }

  @media (min-width: 576px) {
    a {
      background: #ffffff !important;
      color: #495057 !important;
    }
  }
`;

const UserFooter = styled.li`
  background-color: #f8f9fa;
  padding: 10px;
  &::after {
    display: block;
    clear: both;
    content: '';
  }
  .btn-default {
    color: #6c757d;
  }

  @media (min-width: 576px) {
    .btn-default:hover {
      background-color: #f8f9fa;
    }
  }
`;

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 280px;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

const SelectLanguage = styled.div`
  display: flex;
  width: 100%;
  /* padding:10px 0; */
  >span{
    flex:1;
  }
`;

const Lang = styled.span<{
  checked: boolean;
}>`
  cursor: pointer;
  color: ${props => props.checked ? "#fff" : "#fff"};
  font-weight: ${props => props.checked ? "bold" : "normal"};
  &:hover{
    color: red;
  }
`

const ChatWithAdmin = styled.div`
  width:100%;
  padding : 10px 0;
  display:flex;
  justify-content:center;
  align-items:center;
  a {
    display:flex;
    justify-content:center;
    align-items:center;
    color: #fff;
    background-color: #2BA2D6;
    border-color: #2BA2D6;
    padding: 5px 10px;
    border-radius: 5px;
    img{
      margin-left:10px;
      width:20px;
    }
  }
`;

const UserDropdown = () => {
  const {
    t,
    i18n
  } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state: any) => state.auth.currentUser);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [currentLang, setCurrentLang] = useState('');

  const logOut = (event: any) => {
    event.preventDefault();
    setDropdownOpen(false);
    dispatch(logoutUser());
    navigate('/login');
  };

  const navigateToProfile = (event: any) => {
    event.preventDefault();
    setDropdownOpen(false);
    navigate('/profile');
  };

  useEffect(() => {
    setCurrentLang(localStorage.getItem('i18nextLng') || 'en-US');
  }, []);

  const setLang = useCallback(async (lang: string) => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
    setCurrentLang(lang);
  }, []);

  return (
    <StyledDropdown isOpen={dropdownOpen} hideArrow>
      <StyledSmallUserImage
        slot="button"
        src={user.picture}
        fallbackSrc="/img/default-profile.png"
        alt="User"
        width={25}
        height={25}
        rounded
      />
      <div slot="menu">
        <UserHeader className=" bg-primary">
          <StyledBigUserImage
            src={user.picture}
            fallbackSrc="/img/default-profile.png"
            alt="User"
            width={90}
            height={90}
            rounded
          />
          <p>
            {user.email}
            <small>
              <span>{user.uid}</span>
              {/* <span>
                {DateTime.fromISO(user.createdAt).toFormat('dd LLL yyyy')}
              </span> */}
            </small>
          </p>
          <SelectLanguage>
            <Lang checked={currentLang === "ko-KR"} onClick={()=>setLang("ko-KR")}>KR</Lang>
            <Lang checked={currentLang === "en-US"} onClick={()=>setLang("en-US")}>EN</Lang>
            <Lang checked={currentLang === "th-TH"} onClick={()=>setLang("th-TH")}>TH</Lang>
            <Lang checked={currentLang === "jp-JP"} onClick={()=>setLang("jp-JP")}>JP</Lang>
            <Lang checked={currentLang === "ct-CT"} onClick={()=>setLang("ct-CT")}>CT</Lang>
            <Lang checked={currentLang === "cm-CM"} onClick={()=>setLang("cm-CM")}>CM</Lang>
            <Lang checked={currentLang === "zh-CN"} onClick={()=>setLang("zh-CN")}>CN</Lang>
            <Lang checked={currentLang === "zh-TW"} onClick={()=>setLang("zh-TW")}>TW</Lang>
            <Lang checked={currentLang === "pt-PT"} onClick={()=>setLang("pt-PT")}>PT</Lang>
            <Lang checked={currentLang === "vi-VN"} onClick={()=>setLang("vi-VN")}>VN</Lang>
          </SelectLanguage>
        </UserHeader>
        <ChatWithAdmin>
          <a href="https://t.me/esgame24" target="_blank">{t("관리자에게 문의하기")}
            <img src="/img/ic_telegram.svg"/>
          </a>
        </ChatWithAdmin>
        <UserBody>
          {/* <div className="row">
            <div className="col-4 text-center">
              <Link to="/">{t("팔로워")}</Link>
            </div>
            <div className="col-4 text-center">
              <Link to="/">{t("세일즈")}</Link>
            </div>
            <div className="col-4 text-center">
              <Link to="/">{t("친구")}</Link>
            </div>
          </div> */}
        </UserBody>
        <UserFooter>
          {/* <button
            type="button"
            className="btn btn-default btn-flat"
            onClick={navigateToProfile}
          >
            {t("프로필")}
          </button> */}
          <button
            type="button"
            className="btn btn-default btn-flat float-right"
            onClick={logOut}
          >
            {t("로그아웃")}
          </button>
        </UserFooter>
      </div>
    </StyledDropdown>
  );
};

export default UserDropdown;
