import PopupContainer from "@app/components/popup/PopupContainer";
import {useTranslation} from "react-i18next";
import { API_VERSION } from "@app/pages/apidocument/ApiViewer";
interface AlertNewApiPopupProps {
  onClose: () => void;
}

const AlertNewApiPopup = ({onClose}: AlertNewApiPopupProps) => {
  const {t} = useTranslation();

  const onClickNotToday = () => {
    onClose();
    localStorage.setItem("NEW_API_ALERT_VERSION", API_VERSION);
  }

  return <PopupContainer onClickBackdrop={onClose} maxWidth="720px">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("알림")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-10 col-form-label white-space-pre-line">{t("신규 개발 문서({{version}})가 업데이트되었습니다. 개발팀은 해당 문서를 확인하여 문제 없도록 참고 부탁드립니다. 개발문의는 텔레그램으로 부탁드립니다.", {version: API_VERSION})}</label>
          </div>
          <div className="float-right">
            <button onClick={onClickNotToday} className="btn btn-sm btn-secondary">{t("확인했습니다")}</button>
          </div>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AlertNewApiPopup;
