import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { sha256 } from "js-sha256";
import api from "../../api/admin"
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "@app/store/reducers/auth";

interface AgencyDetailPopupProps {
    onClose: () => void;
}

const AgencyWalletToPointPopup = ({ onClose }: AgencyDetailPopupProps) => {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.currentUser);

    const { token } = useAppSelector(state => state.auth);
    const [myWalletAmount, setMyWalletAmount] = useState(0);
    const [requestEggAmount, setRequestEggAmount] = useState(0);

    const onUpdateRequestedAmount = useCallback(()=>{
        AdminAPI.walletLogs(token, 0, 0, "2024-01-01 00:00:00", "2024-01-01 00:00:00").then(v => {
            setMyWalletAmount(v.amount);
        })
    },[])

    useEffect(function () {
        onUpdateRequestedAmount();  
    }, [])

    const onClickRequest = async () => {
        if(myWalletAmount < requestEggAmount){
            alert("보유 포인트가 부족합니다.");
            return;
        }
        if(requestEggAmount <= 0 ){
            return alert("포인트를 입력해주세요.");
        }

        const result = await AdminAPI.walletToPoint(token, requestEggAmount);
        if(result.success){
            alert("포인트 전환이 완료되었습니다.");
            onClose();
        }else{
            alert("포인트 전환에 실패하였습니다. 에러코드 : " + JSON.stringify(result.err));
        }
    }

    const onChanageMoney = (v: number) => {
        if( v > Number(myWalletAmount) ){
            setRequestEggAmount(Number(myWalletAmount))
            return;
        }
        setRequestEggAmount(Number(v))
    }

    return <PopupContainer onClickBackdrop={onClose}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">포인트로 전환하기</h4>
                    <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">전환 포인트</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" value={requestEggAmount} onChange={v=>onChanageMoney(Number(v.target.value))} />
                            <div style={{fontSize:"11px"}}>
                                (보유 중인 포인트: {Number(myWalletAmount||0).toLocaleString()}포인트)
                            </div>
                            <div style={{fontSize:"11px"}}>
                                전환하기 버튼을 누르면 즉시 전환이 되며 취소가 불가능합니다.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
                    <button type="button" className="btn btn-primary" onClick={onClickRequest}>전환하기</button>
                </div>
            </div>
        </div>
    </PopupContainer>
}
export default AgencyWalletToPointPopup;
