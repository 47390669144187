import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import {useTranslation} from "react-i18next";

interface StandaloneAdjustMoneyPopupProps {
  onClose: () => void;
}

const StandaloneAdjustMoneyPopup = ({onClose}: StandaloneAdjustMoneyPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [searchName, setSearchName] = useState("")
  const [users , setUsers] = useState<{
    userId:string,
    nickname:string,
    agencyName:string,
    uid:string,
    money:number
  }[] | null>(null);

  const [inputUserId, setInputUserId] = useState(0);
  const [inputEgg, setInputEgg] = useState("");
  const [inputMemo, setInputMemo] = useState("");

  const onClickSearch = async function(){
    api.getUserlistForStandaloneAdjust(token, searchName).then(v=>{
        setUsers(v.rows)
    })
  }

  const onClickSend = async function(){
    if(inputUserId == 0)  return alert(t("유저를 선택하세요"));

    let numberEgg = Number(inputEgg);
    if(isNaN(numberEgg)) return alert(t("지급량은 숫자로 입력해주세요"));

    api.sendStandaloneAdjustmentMoney(token, inputUserId, numberEgg, inputMemo).then(v=>{
        if(v.result == false){
            alert(t("지급에 실패하였습니다. 개발팀에게 문의해주세요."))
        }else{
            alert(t("변경되었습니다"))
        }
        onClose();
    })
  }


  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("유저 보유금 변경")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("유저 검색")}</label>
            <div className="col-sm-9">
                <input type="text" className="form-control" value={searchName} onChange={v=>setSearchName(v.target.value)}/>
                <button type="button" className="btn btn-primary" onClick={onClickSearch}>{t("검색")}</button>
            </div>
          </div>

        {users == null ? <>{t("유저를 검색해주세요")}</>:<>
          {users.length == 0 ? `${t("검색된 유저가 없습니다")}` : <>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("유저 선택")}</label>
                <div className="col-sm-9">
                <select className="form-control" onChange={v=>setInputUserId(Number(v.target.value))} >
                    <option value={0}>{t("선택하세요")}</option>
                    {users.map(v=><option value={v.userId}>{t("아이디")} : {v.uid} / ${t("닉네임")} : {v.nickname} / ${t("에이전시")} : {v.agencyName} / ${t("보유금")} : {v.money}</option>)}
                </select>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("지급량")}<br/><span className="small">({t("숫자앞에 마이너스를 붙이면 차감됩니다.")})</span></label>
                <div className="col-sm-9">
                <input type="text" className="form-control" value={inputEgg} onChange={v=>setInputEgg(v.target.value)}/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("메모")}</label>
                <div className="col-sm-9">
                <input type="text" className="form-control" value={inputMemo} onChange={v=>setInputMemo(v.target.value)}/>
                </div>
            </div>
            </>}
        </>}
        </div>

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
          <button type="button" className="btn btn-primary" onClick={onClickSend}>{t("지급하기")}</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}

export default StandaloneAdjustMoneyPopup;