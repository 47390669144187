import React, {useState, useEffect, useCallback} from 'react';
import {Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import moment from 'moment';
import {loadUser, logoutUser} from '@store/reducers/auth';
import {toggleSidebarMenu} from '@app/store/reducers/ui';
import {addWindowClass, removeWindowClass, sleep} from '@app/utils/helpers';
import ControlSidebar from '@app/modules/main/control-sidebar/ControlSidebar';
import Header from '@app/modules/main/header/Header';
import MenuSidebar from '@app/modules/main/menu-sidebar/MenuSidebar';
import Footer from '@app/modules/main/footer/Footer';
import {PfImage} from '@profabric/react-components';
import AdminAPI from '@app/api/admin';
import { useAppSelector } from '@app/store/hooks';
import AlertFewEggPopup from '@app/components/popup/AlertFewEggPopup';
import AlertNewApiPopup from '@app/components/popup/AlertNewApiPopup';
import AlertNewStandaloneRequestPopup from '@app/components/popup/AlertNewStandaloneRequestPopup';
import { API_VERSION } from '@app/pages/apidocument/ApiViewer';

const Main = () => {
  const alertAudio = new Audio('/sound/egg_alert.mp4');
  const dispatch = useDispatch();
  const {token} = useAppSelector(state => state.auth);
  const user = useSelector((state: any) => state.auth.currentUser);
  const menuSidebarCollapsed = useSelector(
    (state: any) => state.ui.menuSidebarCollapsed
  );
  const controlSidebarCollapsed = useSelector(
    (state: any) => state.ui.controlSidebarCollapsed
  );
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const [isAppLoaded, setIsAppLoaded] = useState(false);
  const [showAlertFewEggPopup, setShowAlertFewEggPopup] = useState(false);
  const [showAlertNewApiPopup, setShowAlertNewApiPopup] = useState(false);
  const [showRequestStandalonePopup, setShowRequestStandalonePopup] = useState(false);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const fetchProfile = async () => {
    try {
      if(token){
        const response = await AdminAPI.getInfo({session:token});
        dispatch(loadUser(response.payload));
      }

      await sleep(1000);
      setIsAppLoaded(true);
    } catch (error) {
      dispatch(logoutUser());
      await sleep(1000);
      setIsAppLoaded(true);
    }
  };

  const checkApiDocument = useCallback(() => {
    const alertVersion = localStorage.getItem("NEW_API_ALERT_VERSION");
    if(alertVersion != API_VERSION){
      setShowAlertNewApiPopup(true);
    }
  }, []);

  const autoEggAlert = useCallback(async ()=>{
    const notToday = localStorage.getItem("FEW_EGG_ALERT_TODAY");
    if(notToday === moment().format("YYYY-MM-DD")){
      return;
    }
    const response = await AdminAPI.getInfo({session:token});
    if(user && response.payload.egg < 1000000){
      // 강제 재생을 위한 설정
      alertAudio.play().then(() => {
      }).catch(err => console.log('오디오 재생 실패:', err));
      setShowAlertFewEggPopup(true);
    }
    if(response.payload.egg != user.egg){
      dispatch(loadUser(response.payload));
    }
  }, [token, user]);

  // 5분마다 한번씩 onClickLoadEgg 호출
  useEffect(() => {
    if(user && user.agencyId){
      autoEggAlert();
      checkApiDocument();
      const interval = setInterval(autoEggAlert, 1000 * 60 * 5);
      return () => clearInterval(interval);
    }
  }, [user]);

  const getStandaloneRequest = useCallback(async () => {
    if(!token){
      return;
    }
    const response = await AdminAPI.getStandaloneNewRequest({session:token});
    let latestRequestId = Number(localStorage.getItem("STANDALONE_REQUEST_ID")) || 0;
    console.log("response", response);
    if(response.result > latestRequestId){
      setShowRequestStandalonePopup(true);
      localStorage.setItem("STANDALONE_REQUEST_ID", response.result.toString());
    }
  }, [token]);

  useEffect(() => {
    console.log("getStandaloneRequest", user);
    if(user && user.standalone){
      getStandaloneRequest();
      const interval = setInterval(getStandaloneRequest, 1000 * 60);
      return () => clearInterval(interval);
    }
  }, [user]);

  useEffect(() => {
    removeWindowClass('register-page');
    removeWindowClass('login-page');
    removeWindowClass('hold-transition');

    addWindowClass('sidebar-mini');

    fetchProfile();
    return () => {
      removeWindowClass('sidebar-mini');
    };
  }, [ token ]);

  useEffect(() => {
    removeWindowClass('sidebar-closed');
    removeWindowClass('sidebar-collapse');
    removeWindowClass('sidebar-open');
    if (menuSidebarCollapsed && screenSize === 'lg') {
      addWindowClass('sidebar-collapse');
    } else if (menuSidebarCollapsed && screenSize === 'xs') {
      addWindowClass('sidebar-open');
    } else if (!menuSidebarCollapsed && screenSize !== 'lg') {
      addWindowClass('sidebar-closed');
      addWindowClass('sidebar-collapse');
    }
  }, [screenSize, menuSidebarCollapsed]);

  useEffect(() => {
    if (controlSidebarCollapsed) {
      removeWindowClass('control-sidebar-slide-open');
    } else {
      addWindowClass('control-sidebar-slide-open');
    }
  }, [screenSize, controlSidebarCollapsed]);

  const getAppTemplate = useCallback(() => {
    if (!isAppLoaded) {
      return (
        <div className="preloader flex-column justify-content-center align-items-center">
          <PfImage
            className="animation__shake"
            src="/img/logo.png"
            alt="AdminLTELogo"
            height={60}
            width={60}
          />
        </div>
      );
    }

    return (
      <>
        <Header />

        <MenuSidebar />

        <div className="content-wrapper">
          <div className="pt-3" />
          <section className="content">
            <Outlet />
          </section>
        </div>
        <Footer />
        <ControlSidebar />
        <div
          id="sidebar-overlay"
          role="presentation"
          onClick={handleToggleMenuSidebar}
          onKeyDown={() => {}}
        />
      </>
    );
  }, [isAppLoaded]);

  return <div className="wrapper">
    {getAppTemplate()}
    {showAlertFewEggPopup && <AlertFewEggPopup onClose={() => { setShowAlertFewEggPopup(false); }} amount={1000000}/> }
    {showAlertNewApiPopup && <AlertNewApiPopup onClose={() => { setShowAlertNewApiPopup(false); }}/> }
    {showRequestStandalonePopup && <AlertNewStandaloneRequestPopup onClose={() => { setShowRequestStandalonePopup(false); }}/> }
  </div>;
};

export default Main;
