import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import moment from "moment";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { useTranslation } from "react-i18next";
import { PrintCurrency } from "./UserAdjustMoney";

const months: string[] = [];
for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
    months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
}

const AgencyEggProfit = ({
    user
}:{user:{uid:string, agencyId:number}}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [currentMonth, setCurrentMonth] = React.useState(moment().utc().format('YYYY-MM'));
    const { token } = useAppSelector(state => state.auth);
    const [list, setList] = React.useState<{
        day: string,
        _1: number,
        _3: number,
        _4: number,
        _1_fiat: number, // fiat
        _3_fiat: number, // fiat
        _4_fiat: number, // fiat
        currency: string
    }[] | null>(null);

    const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentMonth(e.target.value);
    }

    useEffect(function () {
        AdminAPI.agencyRakeProfits(token, moment(currentMonth).endOf("month").format("YYYY-MM-DD HH:mm:ss"), user.agencyId).then((v) => {
            const list = Object.values(v.rows.reduce((acc: any, item: any) => {
                const day = item.day.split("T")[0];
                acc[day] = acc[day] || {
                    day,
                    _1:0,
                    _3:0,
                    _4:0,
                    _1_fiat:0,
                    _3_fiat:0,
                    _4_fiat:0,
                    currency: item.currency
                }
                acc[day]["_"+item.profitType] += Number(item.amount);
                acc[day]["_"+item.profitType+"_fiat"] += Number(item.fiatAmount);
                return acc;
            }, {}));

            setList(list as any)
        })
    }, [currentMonth]);


    let accum = 0;
    return <div>
        <ContentHeader title={t("레이크 수익 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row mb-2'>
                                    <div className="offset-8 input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("월별 레이크 수익")}</span>
                                        </div>
                                        <select className="form-control" onChange={onChangeMonth}>
                                            {
                                                months.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body table-responsive p-0" style={{ height: 790 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>{t("날짜")}</th>
                                            <th>{t("레이크 수익")}</th>
                                            <th>{t("정산 수수료 수익")}</th>
                                            <th>{t("토너먼트 바이인 수익")}</th>
                                            <th>{t("월 누적")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list ? <tr style={{fontWeight:"bold"}}>
                                            <td>{t("합계")}</td>
                                            <td><DigitsColorWrapper number={list.reduce((acc, item) => acc + Number(item._1), 0)}/>HP</td>
                                            <td><DigitsColorWrapper number={list.reduce((acc, item) => acc + Number(item._3), 0)}/>HP</td>
                                            <td><DigitsColorWrapper number={list.reduce((acc, item) => acc + Number(item._4), 0)}/>HP</td>
                                            <td><DigitsColorWrapper number={(
                                                list.reduce((acc, item) => acc + Number(item._1), 0)+
                                                list.reduce((acc, item) => acc + Number(item._3), 0)+
                                                list.reduce((acc, item) => acc + Number(item._4), 0)
                                            )}/>HP</td>
                                        </tr> : null}
                                    { new Array(moment(currentMonth).daysInMonth()).fill(0).map((_,i) => {
                                        const date = moment(currentMonth).date(i+1).format('YYYY-MM-DD');
                                        const item = list?.find((item) => item.day === date);
                                        
                                        let item1 = 0;
                                        let item3 = 0;
                                        let item4 = 0;
                                        let item1_fiat = 0;
                                        let item3_fiat = 0;
                                        let item4_fiat = 0;
                                        let currency = "HP";

                                        if(item){
                                            item1 = (Math.floor(Number(item._1)*10)/10) || 0;
                                            item3 = (Math.floor(Number(item._3)*10)/10) || 0;
                                            item4 = (Math.floor(Number(item._4)*10)/10) || 0;

                                            item1_fiat = (Math.floor(Number(item._1_fiat)*10)/10) || 0;
                                            item3_fiat = (Math.floor(Number(item._3_fiat)*10)/10) || 0;
                                            item4_fiat = (Math.floor(Number(item._4_fiat)*10)/10) || 0;

                                            accum += item1 + item3 + item4;
                                            currency = item.currency;
                                        }

                                        return <tr key={date}>
                                            <td>{date}</td>
                                            <td>
                                                <DigitsColorWrapper number={(item1)}/>HP<br/>
                                                ({PrintCurrency(currency, item1_fiat)})
                                            </td>
                                            <td>
                                                <DigitsColorWrapper number={(item3)}/>HP<br/>
                                                ({PrintCurrency(currency, item3_fiat)})
                                            </td>
                                            <td>
                                                <DigitsColorWrapper number={(item4)}/>HP<br/>
                                                ({PrintCurrency(currency, item4_fiat)})
                                            </td>
                                            <td><DigitsColorWrapper number={(accum)}/>HP</td>
                                        </tr>
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default AgencyEggProfit;
