import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import styled from "styled-components";
import HistoryPlayer from "../game-detail/HistoryPlayer";
import {useTranslation} from "react-i18next";

interface PopupProps {
  onClose: () => void;
  roomId: number;
  gameNumber: number;
  communityCards: number[];
}

const DefaultView = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 346px;
  background: #101010;

  .table {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    >.table-img {
      width: 100%;
      object-fit: contain;
    }

    > .seats {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > .dealer {
      position: absolute;
      width: 16px;
      left: -100%;
      top: -100%;
      z-index: 2;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.40));
    }

    > .pot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      img {
        width: 48px;
      }

      .sidepot {
        width: 69px;
        padding: 2px 8px;
        font-size: 10px;
        transform: scale(0.9);
        font-weight: 600;
        border-radius: 15px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
      }

      .box {
        width: 95px;
        padding: 4px;
        font-size: 14px;
        font-weight: 500;
        border-radius: 4px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;

        > div:first-child {
          display: none;
        }

        > div:last-child {
          color: #FFF;
          font-weight: 700;
        }
      }
    }
  }
`;

const DetailView = styled.div`
  width: 100%;
  background: gray;
  color: #FFF;
  padding: 12px 4px;

  > .round-item {
    font-size: 10px;

    > .community {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 0;

      > div {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      > .round-name {
        width: 60px;
        font-size: 14px;
        font-weight: 500;
      }

      > .round-cards {

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 4px;

        > img {
          width: 30px;
          object-fit: contain;
        }
      }

      > .count {
        font-size: 14px;
        font-weight: 700;
        color: rgba(255, 255, 255, 0.7);

        > img {
          width: 12px;
          height: 12px;
          margin-right: 2px;
          margin-top: 2px;
        }


        > &:last-child {
          opacity: 0.7;
        }
      }
    }

    > .bets {
      background: rgb(35, 37, 39);
      display: flex;
      flex-direction: column;
      padding: 8px 12px;
      gap: 8px;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 4px;
        font-size: 11px;

        > .group {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;

          &:first-child {
            width: 120px;
          }

          &:nth-child(2) {
            width: 70px;
          }

          &:last-child {
            flex: 1;
          }
        }

        .position {
          font-style: normal;
          width: 90px;
          max-width: 90px;
          padding: 2px 4px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
          border: solid 1px rgba(255, 255, 255, 0.2);
          overflow: hidden;
          background: #2F99FB;

        }

        .nickname {
          width: 100%;
          text-align: center;
          font-size: 14px;
        }

        .total {
          font-size: 16px;
          width: 100%;
          text-align: right;
        }

        .action {
          font-style: normal;
          min-width: 50px;
          padding: 2px 4px;
          color: white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 700;
          text-align: center;
          background: #17171B;
          border: solid 1px rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
`;
const GameDetailPopup = ({roomId, gameNumber, onClose, communityCards}: PopupProps) => {
  const {t} = useTranslation()

  const GameCards = styled.div`
    width: 100%;
    height: 48px;
    border-radius: 8px;

    display: flex;
    align-items: center;
    padding: 8px 12px;
    background: transparent;
    margin-top: 10px;

    > .title {
      font-size: 10px;
      font-weight: 500;
      margin-right: 20px;
    }

    > .cards {
      flex: 1;
      display: flex;
      gap: 6px;

      > img {
        width: 42px;
        height: 56px;
      }
    }
  `;
  const {token} = useAppSelector(state => state.auth);
  const [gameInfo, setGameInfo] = useState<any[]>();
  const seatsRef = useRef<HTMLDivElement>(null);
  const dealerButtonRef = useRef<HTMLImageElement>(null);
  const [lastGameData, setLastGameData] = useState<any>();
  const [handHistory, setHandHistory] = useState<any[]>([]);
  useEffect(() => {
    if (handHistory.length === 0) {
      return;
    }

    setLastGameData(handHistory.slice(-1)[0].lastGameData);
  }, [handHistory]);
  useEffect(function () {
    api.getGameHistory(token, roomId, gameNumber).then((v: any) => {
      const result = v.result;

      const sorted = result.sort((a: any, b: any) => a.betId - b.betId);
      setHandHistory(sorted);

      const playersPosition: { [userId: number]: string } = {};
      const arr: { round: number, cards: number[], players: number[], pot: number, bets: any[] }[] = [];
      const usersPosition: any = {}
      for (let row of result) {
        const round = row.round;

        if (round === 0) {
          let position = row.position
          if (position === 'dealer') {
            position = 'BT';
          }
          usersPosition[row.userId] = position.toUpperCase()
        }

        if (!arr[round]) {
          arr[round] = {
            round: round,
            cards: communityCards.slice(0, round + 2),
            players: [],
            pot: 0,
            bets: [],
          };
        }

        if (arr[round].players.indexOf(row.userId) === -1) {
          arr[round].players.push(row.userId);
        }

        if (!playersPosition[row.userId]) {
          let position = row.position;
          if (position === 'dealer') {
            position = 'BT';
          }
          playersPosition[row.userId] = position.toUpperCase();
        }

        arr[round].pot += row.bet;
        arr[round].bets.push({
          betId: row.betId,
          userId: row.userId,
          nickname: row.nickname,
          bet: row.bet,
          position: usersPosition[row.userId],
          action: ['', 'CHECK', 'CALL', 'FOLD', 'RAISE', 'ALLIN', 'BET', 'SB', 'BB', 'STR'][row.type]
        });

      }
      setGameInfo(arr)
    })
  }, [])

  const players = useMemo(() => {
    const arr: {
      userId: number;
      seat: number;
      nickname: string;
      profileImg: string;
      isFold: boolean;
    }[] = [];

    for (let row of handHistory) {
      const player = arr.find(x => x.userId === row.userId);
      if (player) {
        player.isFold = row.fold;
      } else {
        arr.push({
          userId: row.userId,
          seat: row.seat,
          nickname: row.nickname,
          profileImg: row.profileImg,
          isFold: row.fold
        });
      }
    }

    return arr;
  }, [handHistory]);


  const dealerSeat = useMemo<number>(() => {
    let player;
    player = handHistory.find(x => x.position === 'dealer');
    if (!player) {
      // 딜러 포지션이 없으면 1:1 상황으로 판단하고 bb를 딜러로 설정한다.
      player = handHistory.find(x => x.position === 'bb');
    }

    return !player ? -1 : player.seat;
  }, [handHistory]);

  const totalPot = useMemo(() => {
    if (!lastGameData) {
      return 0;
    }

    return lastGameData.pots.reduce((a: any, v: any) => a + v.amount, 0);
  }, [lastGameData]);

  const arrangeLayout = useCallback(() => {
    if (!seatsRef.current) {
      return;
    }

    function position(arr: any, idx: number, left: number, top: number) {
      try {
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const isPortrait = false
    const seatRects = isPortrait ? [
      [0.5, 0.95],
      [0.03, 0.77],
      [0.03, 0.53],
      [0.03, 0.3],
      [0.32, 0.09],
      [0.68, 0.09],
      [0.96, 0.3],
      [0.96, 0.53],
      [0.96, 0.77]
    ] : [
      [0.28, 0.81],
      [0.11, 0.62],
      [0.11, 0.36],
      [0.28, 0.2],
      [0.5, 0.2],
      [0.72, 0.2],
      [0.89, 0.36],
      [0.89, 0.62],
      [0.69, 0.81]
    ];

    const buttonRects = isPortrait ? [
      [0.5, 0.95],
      [0.2, 0.77],
      [0.2, 0.53],
      [0.2, 0.3],
      [0.32, 0.09],
      [0.68, 0.09],
      [0.8, 0.3],
      [0.8, 0.53],
      [0.8, 0.77]
    ] : [
      [0.28, 0.63],
      [0.23, 0.58],
      [0.22, 0.43],
      [0.31, 0.34],
      [0.45, 0.34],
      [0.69, 0.34],
      [0.78, 0.43],
      [0.78, 0.58],
      [0.69, 0.63]
    ];

    let deleteSeatIndices: number[] = [];

    for (let seatIndex of deleteSeatIndices) {
      seatRects.splice(seatIndex, 1);
      buttonRects.splice(seatIndex, 1);
    }

    const width = seatsRef.current.offsetWidth;
    const height = seatsRef.current.offsetHeight;
    const left = seatsRef.current.offsetLeft;
    const top = seatsRef.current.offsetTop;

    const seats = seatsRef.current.querySelectorAll('.history-player');
    for (let i = 0; i < 9; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1]);
    }

    if (dealerButtonRef.current && dealerSeat !== -1) {
      const dealerButton = dealerButtonRef.current;
      position([dealerButton], 0, left + width * buttonRects[dealerSeat][0], top + height * buttonRects[dealerSeat][1]);
    }
  }, [players, dealerSeat]);

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog" style={{
      maxWidth: 700
    }}>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("게임 디테일 정보")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        {gameInfo ?
          <div className="modal-body" style={{
            maxHeight: 'calc(100vh - 210px)',
            overflowY: 'scroll'
          }}>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
              <DefaultView>
                <div className="table">
                  <img className='table-img' onLoad={arrangeLayout}
                       src={`/img/history_landscape_blue.svg`}/>
                  <div ref={seatsRef} className="seats">
                    {
                      Array.from({length: 9}).map((_, i) => {
                        const player = players.find(x => x.seat == i);
                        if (!player) {
                          return <div key={i} className="history-player" data-seat={i}/>;
                        }
                        const playerGameData = (lastGameData?.changeStackSizeList ?? []).find((x: any) => x.userId === player.userId);
                        const stackSize = playerGameData?.stackSize || 0;
                        const prevStackSize = playerGameData?.prevStackSize || 0;
                        const winner = lastGameData?.winners.find((x: any) => x.userId === player.userId);
                        const isWinner = winner !== undefined;
                        const prize = isWinner ? winner.amount : 0;
                        const cards = lastGameData?.cards.find((x: any) => x.userId === player.userId);

                        return <HistoryPlayer
                          key={i}
                          userId={player.userId}
                          seat={player.seat}
                          nickname={player.nickname}
                          profileImg={player.profileImg}
                          folded={player.isFold}
                          winner={isWinner}
                          prize={prize}
                          cards={cards?.cards || []}
                          changeStackSize={stackSize - prevStackSize}
                        />;
                      })
                    }
                  </div>
                  <img ref={dealerButtonRef} className="dealer" src="/img/dealer_button.svg"/>
                  {
                    (lastGameData && lastGameData.pots) && (
                      <div className="pot">
                        <img src="/img/game_pot_chips.svg"/>
                        <div className="box">
                          <div>POT</div>
                          <div>{totalPot.toLocaleString()}</div>
                        </div>
                        {
                          (
                            <GameCards>
                              <div className="cards">
                                {
                                  (lastGameData?.communityCards ?? []).map((card: any, i: number) => (
                                    <img key={i} src={`/img/cards/${card}.svg`}/>
                                  ))
                                }
                              </div>
                            </GameCards>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </DefaultView>
              <DetailView>
                {
                  gameInfo.map((x: any, i: number) => (
                    <div key={i} className="round-item">
                      <div className="community">
                        <div className="round-name">{[t('프리플랍'), t('플랍'), t('턴'), t('리버')][x.round]}</div>
                        <div className="round-cards">
                          {
                            x.round === 0 ? <>
                              <img src={`/img/cards/card_back.svg`}/>
                              <img src={`/img/cards/card_back.svg`}/>
                            </> : <>
                              {
                                x.cards.map((card: number, i: number) => (
                                  <img key={i} src={`/img/cards/${card}.svg`}/>
                                ))
                              }
                            </>
                          }
                        </div>
                        <div className="count">
                          <div>{t("플레이어 수")} : {x.players.length.toLocaleString()}</div>
                        </div>
                        <div className="count">
                          <div>{t("팟")} : {x.pot.toLocaleString()}</div>
                        </div>
                      </div>
                      <div className="bets">
                        <div style={{
                          background: 'green'
                        }}>
                          <div className='group'>
                            <div className="position">{t("포지션")}</div>
                            <div className="nickname">{t("닉네임")}</div>
                          </div>
                          <div className='group'>
                            <div className="action">{t("액션")}</div>
                            <div className="total">TOTAL</div>
                          </div>
                          {/*<div className='group'>
                                  <div className='total'>
                                    {x.bets.slice(0, betIdx + 1).map(_ => _.bet).reduce((a, v) => a + v, 0).toLocaleString()}
                                  </div>
                                </div>*/}
                        </div>
                        {
                          x.bets.filter((_: any) => _.action !== undefined).map((bet: any, betIdx: number) => {
                            return (
                              <div key={bet.betId}>
                                <div className='group'>
                                  <div className="position">{bet.position}</div>
                                  <div className="nickname">{bet.nickname}</div>
                                </div>
                                <div className='group'>
                                  <div className="action" data-action={bet.action}>{bet.action}</div>
                                  <div className="total">{bet.bet.toLocaleString()}</div>
                                </div>
                                {/*<div className='group'>
                                  <div className='total'>
                                    {x.bets.slice(0, betIdx + 1).map(_ => _.bet).reduce((a, v) => a + v, 0).toLocaleString()}
                                  </div>
                                </div>*/}
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  ))
                }
              </DetailView>
            </div>
          </div>
          : <>Loading..</>}
        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default GameDetailPopup;
