/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, {useEffect, useState} from 'react';
import {NavLink, useNavigate, useLocation, Location} from 'react-router-dom';

import {IMenuItem} from '@app/modules/main/menu-sidebar/MenuSidebar';
import { useSelector } from 'react-redux';

const MenuItem = ({menuItem}: {menuItem: IMenuItem}) => {
  const user = useSelector((state: any) => state.auth.currentUser);
  const [isMenuExtended, setIsMenuExtended] = useState(false);
  const [isExpandable, setIsExpandable] = useState(false);
  const [isMainActive, setIsMainActive] = useState(false);
  const [isInvisible, setIsInvisible] = useState(false);
  const [isOneOfChildrenActive, setIsOneOfChildrenActive] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuExtended(!isMenuExtended);
  };

  const handleMainMenuAction = () => {
    if (isExpandable) {
      toggleMenu();
      return;
    }
    navigate(menuItem.path ? menuItem.path : '/');
  };

  const calculateIsActive = (url: Location) => {
    setIsMainActive(false);
    setIsOneOfChildrenActive(false);
    if (isExpandable && menuItem && menuItem.children) {
      menuItem.children.forEach((item) => {
        if (item.path === url.pathname) {
          setIsOneOfChildrenActive(true);
          setIsMenuExtended(true);
        }
      });
    } else if (menuItem.path === url.pathname) {
      setIsMainActive(true);
    }
  };

  useEffect(() => {
    if (location) {
      calculateIsActive(location);
    }
  }, [location, isExpandable, menuItem]);

  useEffect(() => {
    if (!isMainActive && !isOneOfChildrenActive) {
      setIsMenuExtended(false);
    }
  }, [isMainActive, isOneOfChildrenActive]);

  useEffect(() => {
    if(menuItem.standalone){
      setIsInvisible(!user.standalone);
    } else if(menuItem.showAll){
      setIsInvisible(false);
    }else if(user.isSubHQ == 1){
      setIsInvisible(!menuItem.subHQOnly);
    }else if(menuItem.subHQOnly){
      setIsInvisible(true);
    }else if(menuItem.agencyOnly){
      setIsInvisible(!user.agencyId);
    } else{
      if(menuItem.children && user.privileges){
        let invisible = menuItem.children.filter(v=> user.privileges.find((w:string)=>w==v.priviliege||"") ).length == 0;
        setIsInvisible(invisible);
      }else{
        setIsInvisible(true);
      }

      if(menuItem.priviliege && user.privileges){
        let invisible = !user.privileges.find((v:string)=>v == menuItem.priviliege);
        setIsInvisible(invisible);
      }else{
        setIsInvisible(true);
      }

      if(user.privileges.find((v:string)=>v=="Super")){
        setIsInvisible(false);
      }
    }

    setIsExpandable(
      Boolean(menuItem && menuItem.children && menuItem.children.length > 0)
    );
  }, [menuItem, user]);

  if(isInvisible){
    return <></>
  }

  return (
    <li className={`nav-item${isMenuExtended ? ' menu-open' : ''}`}>
      <a
        className={`nav-link${
          isMainActive || isOneOfChildrenActive ? ' active' : ''
        }`}
        role="link"
        onClick={handleMainMenuAction}
        style={{cursor: 'pointer'}}
      >
        <i style={{
          width: 18,
          objectFit: 'contain',
          marginRight: 10
        }} className={`${menuItem.icon}`} />
        <p>{menuItem.name}</p>
        {isExpandable ? <i className="right fas fa-angle-left" /> : null}
      </a>

      {isExpandable &&
        menuItem &&
        menuItem.children &&
        menuItem.children.map((item) => (
          <ul key={item.name} className="nav nav-treeview">
            <li className="nav-item" style={{paddingLeft:"20px"}}>
              <NavLink className="nav-link" to={`${item.path}`}>
                <i style={{
                  width: 18,
                  objectFit: 'contain',
                  marginRight: 10
                }} className={`${item.icon}`} />
                <p>{item.name}</p>
              </NavLink>
            </li>
          </ul>
        ))}
    </li>
  );
};

export default MenuItem;
