import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import { RakePercentage } from "@app/dataset";
import {  } from "./CreateAgencyPopup";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface AgencyDetailPopupProps {
  onClose: () => void;
  agencyId:number;
}

const AgencyDetailPopup = ({onClose, agencyId}: AgencyDetailPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const user = useSelector((state: any) => state.auth.currentUser);
  const [info , setInfo] = useState<{
    agencyId:string,
    name:string,
    agentName:string,
    agentCallNumber:string,
    egg:string,
    rakePercentage:RakePercentage,
    memo:string,
    addedAt:string,
    apiKey:string,
    uid:string,
  }>();

  useEffect(function(){
    api.getAgencyInfo(token, agencyId).then(v=>{
        if(v.agency){
            setInfo({
                agencyId:v.agency.agencyId.toString(),
                name:v.agency.name.toString(),
                agentName:v.agency.agentName.toString(),
                agentCallNumber:v.agency.agentCallNumber.toString(),
                egg:v.agency.egg.toString(),
                rakePercentage:v.agency.rakePercentage,
                memo:(v.agency.memo||"").toString(),
                addedAt:v.agency.addedAt.toString(),
                apiKey:v.agency.apiKey.toString(),
                uid:v.agency.uid.toString(),
            })
        }
    })
  },[])
  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("에이전시 정보")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        
        {info == null ? <>Loading..</>:<>

        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("소속 에이전트")}</label>
            <div className="col-sm-9">
              <input disabled type="text" className="form-control" value={info?.name}/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("이름")}</label>
            <div className="col-sm-9">
              <input disabled type="text" className="form-control" value={info?.agentName}/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("아이디")}</label>
            <div className="col-sm-9">
              <input disabled type="text" className="form-control" value={info?.uid}/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("전화번호")}</label>
            <div className="col-sm-9">
              <input disabled type="text" className="form-control" value={info?.agentCallNumber}/>
            </div>
          </div>
          
          {/* <SimpleRakePrint values={info?.rakePercentage}/>
          <SimpleRakePrint head={true} subHQList={subHQList} values={info?.rakePercentage} /> */}

          <div className={`form-group row ${user.privilege == 1 ? "" : "d-none"}`}>
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("메모")}</label>
            <div className="col-sm-9">
                {info?.memo}
            </div>
          </div>
        </div>
        
        </>}

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AgencyDetailPopup;
