import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useEffect, useRef, useState } from "react";
import { sha256 } from "js-sha256";
import api from "../../api/admin"
import {useTranslation} from "react-i18next";

interface AdminLogPopupProps {
  onClose: () => void;

}

const THUMB_SIZE_W = 1344;
const THUMB_SIZE_H = 768;

const IMAGE_SIZE_W = 1200;
const IMAGE_SIZE_H = 600;

async function imgSize(file: File): Promise<{ width: number, height: number }> {
  var _URL = window.URL || window.webkitURL;
  return new Promise((resolve, reject) => {
    var img = new Image();
    var objectUrl = _URL.createObjectURL(file);
    img.onload = function () {
      var img = this as any;
      _URL.revokeObjectURL(objectUrl);

      return resolve({
        width: img.width,
        height: img.height
      })
    };
    img.src = objectUrl;
  })
}

const LanguageName: { [key: string]: string } = {
  "KR":"한국어",
  "JP":"일본어",
  "THAI":"태국어",
  "PT":"포르투갈어",
  "TL":"타이어",
  "VI":"베트남어",
  "ZH-CN":"중국어",
  "ZH-TW":"중국어(대만)",
  "ES-ES":"스페인어",
  "ES-LA":"라틴아메리카",
  "EN":"영어"
}

const NoticeAddPopup = ({ onClose }: AdminLogPopupProps) => {
  const {t} = useTranslation();
  const { token } = useAppSelector(state => state.auth);

  const Language = {
    ["KR"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["EN"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["JP"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["THAI"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["PT"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["TL"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["VI"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["ZH-CN"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["ZH-TW"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["ES-ES"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
    ["ES-LA"]: {
      titleRef: useRef<HTMLInputElement>(null),
      thumbRef: useRef<HTMLInputElement>(null),
      imageRef: useRef<HTMLInputElement>(null),
      textRef: useRef<HTMLTextAreaElement>(null),
    },
  }

  const [uploading, setUploading] = useState(false);

  const handleCreateNotice = async () => {
    setUploading(true);

    let data:{
      [key:string]:{
        thumb:string,
        img:string,
        title:string,
        text:string,
      }
    } = {}
    for(let v of Object.entries(Language)){
      let key = v[0];
      let refs = v[1];
      if (!refs.thumbRef.current!.files![0])
        return alert(`[${key}]`+ t("썸네일을 선택해주세요."));
      if (!refs.imageRef.current!.files![0])
        return alert(`[${key}]`+ t("이미지를 선택해주세요."));
      if (!refs.titleRef.current!.value)
        return alert(`[${key}]`+`${t("제목을 입력해주세요")}.`);
      if (!refs.textRef.current!.value)
        return alert(`[${key}]`+`${t("텍스트를 입력해주세요")}.`);

      let thumb_size = await imgSize(refs.thumbRef.current!.files![0]);
      let img_size = await imgSize(refs.imageRef.current!.files![0]);

      if (thumb_size.width != THUMB_SIZE_W || thumb_size.height != THUMB_SIZE_H) {
        return alert(`[${key}]`+`${t("규격에 맞는 이미지를 업로드해야합니다")}.`)
      }

      if (img_size.width != IMAGE_SIZE_W || img_size.height < IMAGE_SIZE_H) {
        return alert(`[${key}]`+`${t("규격에 맞는 이미지를 업로드해야합니다")}.`)
      }

      let thumb = await api.noticeImgUpload(token, refs.thumbRef.current!.files![0]);
      let img = await api.noticeImgUpload(token, refs.imageRef.current!.files![0]);

      data[key] = {
        thumb: thumb.data,
        img: img.data,
        title: refs.titleRef.current!.value,
        text: refs.textRef.current!.value,
      }
    }
    const result = await api.noticeAdd(
      token,
      data
    )
    if (result.success) {
      onClose();
    }
  };

  useEffect(function () {
  }, [])

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("공지사항 등록")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body" style={{
          "maxHeight":"70vh",
          "overflow":"scroll",
        }}>
          {Object.entries(Language).map(([KEY, refs]) => {
            return <>
              <h3>{LanguageName[KEY]}</h3>
              <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("제목")}[{KEY}]</label>
                <div className="col-sm-9">
                  <input ref={refs.titleRef} type="text" className="form-control" placeholder={t("제목")}/>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("썸네일")}[{KEY}]</label>
                <div className="col-sm-9">
                  <input ref={refs.thumbRef} type="file" className="form-control" placeholder={t("썸네일")} />
                  <div className="form-group row">
                    <div className="offset-sm-3 col-sm-9">
                      <span style={{
                        fontSize: '12px',
                      }}>({t("{{THUMB_SIZE_W}}px * {{THUMB_SIZE_H}}px 사이즈 규격, jpg/png만 업로드 가능", { THUMB_SIZE_W: THUMB_SIZE_W, THUMB_SIZE_H : THUMB_SIZE_H })})</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("이미지")}[{KEY}]</label>
                <div className="col-sm-9">
                  <input ref={refs.imageRef} type="file" className="form-control" placeholder={t("이미지")} />
                  <div className="form-group row">
                    <div className="offset-sm-3 col-sm-9">
                      <span style={{
                        fontSize: '12px',
                      }}>({t("{{IMAGE_SIZE_W}}px * {{IMAGE_SIZE_H}}px 이상 사이즈 규격, jpg/png만 업로드 가능", { IMAGE_SIZE_W : IMAGE_SIZE_W, IMAGE_SIZE_H : IMAGE_SIZE_H })})</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("텍스트")}[{KEY}]</label>
                <div className="col-sm-9">
                  <textarea ref={refs.textRef} className="form-control" placeholder={t("내용")} />
                </div>
              </div>
            </>
          })}
        </div>

        {uploading ? <div>{t("업로드 중")}...</div> :
          <div className="modal-footer justify-content-between">
            <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
            <button type="button" className="btn btn-primary" onClick={handleCreateNotice}>{t("작성하기")}</button>
          </div>}

      </div>
    </div>
  </PopupContainer>
}
export default NoticeAddPopup;
