import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import { useTranslation } from "react-i18next";

interface AgencyDetailPopupProps {
  onClose: () => void;
}

const AgencySendEggPopup = ({onClose}: AgencyDetailPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [agencys , setAgencys] = useState<{
    agencyId:string,
    name:string,
    egg:number
  }[]>();

  const [inputAgencyId, setInputAgencyId] = useState(0);
  const [inputEgg, setInputEgg] = useState("");
  const [inputMemo, setInputMemo] = useState("");

  useEffect(function(){
    api.agencyNameList(token).then(v=>{
        setAgencys(v.rows);
    })
  },[]);

  const onClickSend = async function(){
    if(inputAgencyId == 0)  return alert(t("소속 에이전트를 선택하세요"));

    let numberEgg = Number(inputEgg);
    if(isNaN(numberEgg)) return alert(t("지급량은 숫자로 입력해주세요"));

    api.sendEgg(token, inputAgencyId, numberEgg, inputMemo).then(v=>{
        onClose();
    })
  }


  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("수동 알 지급")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        
        {agencys == null ? <>Loading..</>:<>

        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("소속 에이전트")}</label>
            <div className="col-sm-9">
              <select className="form-control" onChange={v=>setInputAgencyId(Number(v.target.value))} >
                <option value={0}>{t("선택하세요")}</option>
                {agencys.map(v=><option value={v.agencyId}>{v.name} ({t("보유알")}:{Number(v.egg).toLocaleString()})</option>)}
              </select>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("지급량")}</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" value={inputEgg} onChange={v=>setInputEgg(v.target.value)}/>
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("메모")}</label>
            <div className="col-sm-9">
              <input type="text" className="form-control" value={inputMemo} onChange={v=>setInputMemo(v.target.value)}/>
            </div>
          </div>
        </div>
        </>}

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
          <button type="button" className="btn btn-primary" onClick={onClickSend}>{t("지급하기")}</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AgencySendEggPopup;
