import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import AgencyExchangeRequestPopup from "@app/components/popup/AgencyExchangeRequestPopup";
import { ExchangeStatusType, getExchangeStatusString } from "./AgencyExchangeList";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CurrencyMark } from "@app/conversionChip";

const months: string[] = [];
for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
    months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
}

const ExchangeInfo = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { token } = useAppSelector(state => state.auth);
    const user = useSelector((state: any) => state.auth.currentUser);
    const [currentMonth, setCurrentMonth] = React.useState(moment().utc().format('YYYY-MM'));
    const [list, setList] = React.useState<{
        day: string,
        addedAt: string,
        data:{
            USD: number,
            KRW: number,
            JPY: number,
            PHP: number,
            THB: number,
            SGD: number,
            CNY: number,
            BRL: number,
            TWD: number,
            VND: number,
            MXN: number,
            TRY: number,
            day: string
        }
    }[]>([]);

    useEffect(()=>{
        AdminAPI.getFiatExchangeRatioList(token, currentMonth).then((v) => {
            setList(v.list)
        });
    },[currentMonth]);

    const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentMonth(e.target.value);
    }

    return <div>
        <ContentHeader title={t("환율 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row mb-2'>
                                    <div className="offset-8 input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("월별 조회")}</span>
                                        </div>
                                        <select className="form-control" onChange={onChangeMonth}>
                                            {
                                                months.map((item, index) => {
                                                    return <option value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>{t("날짜")}</th>
                                            <th>USD({CurrencyMark("USD")})</th>
                                            <th>KRW({CurrencyMark("KRW")})</th>
                                            <th>JPY({CurrencyMark("JPY")})</th>
                                            <th>PHP({CurrencyMark("PHP")})</th>
                                            <th>THB({CurrencyMark("THB")})</th>
                                            <th>SGD({CurrencyMark("SGD")})</th>
                                            <th>CNY({CurrencyMark("CNY")})</th>
                                            <th>BRL({CurrencyMark("BRL")})</th>
                                            <th>TWD({CurrencyMark("TWD")})</th>
                                            <th>VND({CurrencyMark("VND")})</th>
                                            <th>MXN({CurrencyMark("MXN")})</th>
                                            <th>TRY({CurrencyMark("TRY")})</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{item.data.day}</td>
                                                    <td>{item.data.USD}</td>
                                                    <td>{item.data.KRW}</td>
                                                    <td>{item.data.JPY}</td>
                                                    <td>{item.data.PHP}</td>
                                                    <td>{item.data.THB}</td>
                                                    <td>{item.data.SGD}</td>
                                                    <td>{item.data.CNY}</td>
                                                    <td>{item.data.BRL}</td>
                                                    <td>{item.data.TWD}</td>
                                                    <td>{item.data.VND}</td>
                                                    <td>{item.data.MXN}</td>
                                                    <td>{item.data.TRY}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default ExchangeInfo;
