import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const SortBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
`;
const SortArrow = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    &:hover {
        color: #007bff;
    }
    &.active {
        color: #007bff;
    }
`;

const SortArrowIcon = styled.i`
    font-size: 1.1rem;
`;

export interface ISortBox {
    sortKey: number;
    sortType: string;
    nowSortKey: number;
    onSort: (sortKey: number, sortType: string) => void;
}

const SortBox = ({ sortKey, sortType, nowSortKey, onSort }: ISortBox) => {
    return (
        <SortBoxContainer>
            <SortArrow
                className={`${
                    nowSortKey == sortKey && sortType == "asc" ? "active" : ""
                }`}
                onClick={() => onSort(sortKey, "asc")}
                style={{marginBottom:"-2px"}}
            >
                <SortArrowIcon className="fas fa-sort-up" />
            </SortArrow>
            <SortArrow
                className={`${
                    nowSortKey == sortKey && sortType == "desc" ? "active" : ""
                }`}
                onClick={() => onSort(sortKey, "desc")}
                style={{marginTop:"-2px"}}
            >
                <SortArrowIcon className="fas fa-sort-down" />
            </SortArrow>
        </SortBoxContainer>
    );
};

export default SortBox;
