import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BET_TYPE } from "@app/dataset";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { currencyToChip } from "@app/conversionChip";


const GrayTh = styled.th`
  background-color: #eee !important;
`

const WhiteGrayTh = styled.th`
  background-color: #ddd !important;
`

const GrayTd = styled.td`
  background-color: #eee;
  font-size: 15px;

  span{
    font-size: 13px;
    &.zero {
        display: none;
    }
    &.green{
        color: green;
    }
    &.red{
        color: red;
    }
  }
`

const WhiteGrayTd = styled.td`
  background-color: #ddd;
  font-size: 15px;
  
  span{
    font-size: 13px;
    &.zero {
        display: none;
    }
    &.green{
        color: green;
    }
    &.red{
        color: red;
    }
  }
`

function act(ss:BET_TYPE){
  switch(ss){
      case BET_TYPE.FOLD:
          return 'FOLD';
      case BET_TYPE.CALL:
          return 'CALL';
      case BET_TYPE.CHECK:
          return 'CHECK';
      case BET_TYPE.RAISE:
          return 'RAISE';
      case BET_TYPE.ALLIN:
          return 'ALL IN';
      case BET_TYPE.BET:
          return 'BET';
      case BET_TYPE.SB:
          return 'SB';
      case BET_TYPE.BB:
          return 'BB';
      case BET_TYPE.STR:
          return 'STR';
      case BET_TYPE.ANTE:
          return 'ANTE';
  }
}
function toNum(v:any){
    if(isNaN(v))
        return 0;
    if(v == Infinity)
        return 0;
    return v;
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const GameProfitTournament = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [dataList, setDataList] = useState([])
  const [total, setTotal] = useState<{
    totalBuyin: number,
    totalRebuyin: number,
    totalWin: number,
  }>({
    totalBuyin: 0,
    totalRebuyin: 0,
    totalWin: 0,
  });

  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))

  const getBettingHistory = async () => {
    const result = await AdminAPI.totalProfitTournament(token, searchStartDate);
    if( result.success ){
        result.data.sort((a:any, b:any) => {
            return new Date(a.day).getTime() - new Date(b.day).getTime()
        });

        let totalBuyin = 0;
        let totalRebuyin = 0;
        let totalWin = 0;

        for(let i in result.data){
            let idx = Number(i);
            let v = result.data[i];

            v.buyin = Number(v.buyin);
            v.rebuyin = Number(v.rebuyin);
            v.win = currencyToChip("USD", Number(v.win));

            totalBuyin += v.buyin;
            totalRebuyin += v.rebuyin;
            totalWin += v.win;

            v.accumBuyin = totalBuyin;
            v.accumRebuyin = totalRebuyin;
            v.accumWin = totalWin;
        }
        setDataList(result.data)

        for(let v of result.data){
        }
        setTotal({
          totalBuyin: totalBuyin,
          totalRebuyin: totalRebuyin,
          totalWin: totalWin,
        })
    }
  }

  useEffect(() => {
    getBettingHistory()
  }, [searchStartDate])

  const onClickSearch = function(){
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
  }

  const onClickCancelSearch = useCallback(function(){
    let r1 = moment().startOf('month').format(DATE_FORMAT)
    setInputStartDate(new Date(r1));

    setSearchStartDate(r1);
  },[])

  return <div>
    <ContentHeader title={t("토너먼트 수익표")}/>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row'>
                  <div className="input-group col-8">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().subtract(3, 'months').startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("세달전")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().subtract(2, 'months').startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("두달전")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("지난달")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("이번달")}</button>
                    </div>
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-3">
                            <div className="info-box">
                            <span className="info-box-icon bg-info"><i className="fas fa-sign-out-alt" /></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{t("총 바이인")}</span>
                                <span className="info-box-number"><DigitsColorWrapper number={total.totalBuyin}/>{t("포인트")}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="info-box">
                            <span className="info-box-icon bg-warning"><i className="fas fa-sign-out-alt" /></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{t("총 리바인")}</span>
                                <span className="info-box-number"><DigitsColorWrapper number={total.totalRebuyin}/>{t("포인트")}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="info-box">
                            <span className="info-box-icon bg-success"><i className="fas fas fa-cookie-bite" /></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{t("총 상금")}</span>
                                <span className="info-box-number"><DigitsColorWrapper number={(total.totalWin)}/>{t("포인트")}</span>
                            </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="info-box">
                            <span className="info-box-icon bg-success"><i className="fas fa-hand-holding-usd" /></span>
                            <div className="info-box-content">
                                <span className="info-box-text">{t("총 순익")}</span>
                                <span className="info-box-number"><DigitsColorWrapper number={(total.totalBuyin+total.totalRebuyin-total.totalWin)}/>{t("포인트")}</span>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap">
                  <thead>
                  <tr>
                    <th>{t("일자")}</th>
                    <GrayTh>{t("바이인")}</GrayTh>
                    <GrayTh>{t("리바인")}</GrayTh>
                    <GrayTh>{t("상금지출")}</GrayTh>
                    <GrayTh>{t("순수익")}</GrayTh>
                    <WhiteGrayTh>{t("바이인(누적)")}</WhiteGrayTh>
                    <WhiteGrayTh>{t("리바인(누적)")}</WhiteGrayTh>
                    <WhiteGrayTh>{t("상금지출(누적)")}</WhiteGrayTh>
                    <WhiteGrayTh>{t("순수익(누적)")}</WhiteGrayTh>
                  </tr>
                  </thead>
                  <tbody>
                  {dataList.length == 0 ? <tr>
                    <td colSpan={9}> {t("검색 결과가 없습니다")}.</td>
                  </tr> : null}
                  {
                    dataList.map((item: any, index) => {
                      return <tr key={index}>
                        <td>{item.day}</td>

                        <GrayTd>
                            <b><DigitsColorWrapper number={Number(item.buyin)}/></b>{t("포인트")}
                        </GrayTd>
                        <GrayTd>
                            <b><DigitsColorWrapper number={Number(item.rebuyin)}/></b>{t("포인트")}
                        </GrayTd>
                        <GrayTd>
                            <b><DigitsColorWrapper number={Number(item.win)}/></b>{t("포인트")}
                        </GrayTd>
                        <GrayTd>
                            <b><DigitsColorWrapper number={Number(item.buyin+item.rebuyin-item.win)}/></b>{t("포인트")}
                        </GrayTd>

                        <WhiteGrayTd>
                            <b><DigitsColorWrapper number={Number(item.accumBuyin)}/></b>{t("포인트")}
                        </WhiteGrayTd>
                        <WhiteGrayTd>
                            <b><DigitsColorWrapper number={Number(item.accumRebuyin)}/></b>{t("포인트")}
                        </WhiteGrayTd>
                        <WhiteGrayTd>
                            <b><DigitsColorWrapper number={Number(item.accumWin)}/></b>{t("포인트")}
                        </WhiteGrayTd>
                        <WhiteGrayTd>
                            <b><DigitsColorWrapper number={Number(item.accumBuyin+item.accumRebuyin-item.accumWin)}/></b>{t("포인트")}
                        </WhiteGrayTd>
                      </tr>

                    })
                  }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
}


export default GameProfitTournament;
