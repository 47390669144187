import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import moment from "moment";
import Chart, {ChartItem} from 'chart.js/auto';
import styled from "styled-components";
import AdminAPI from '@app/api/admin';
import { useAppSelector } from '@app/store/hooks';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AgencyServerStatus from '../AgencyServerStatus';
import {useTranslation} from "react-i18next";
import DigitsColorWrapper from '@app/components/DigitsColorWrapper';


const TableItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  > .profile {
    width: 1rem;
    height: 1rem;
    object-fit: cover;
  }
`

const Dashboard = () => {
  const {t} = useTranslation()
  const {token} = useAppSelector(state => state.auth);
  const [todayEndGame, setTodayEndGame] = useState(0);
  const [todayTotalBet, setTodayTotalBet] = useState(0);
  const [todayAcceptedEgg, setTodayAcceptedEgg] = useState(0);
  const [curWeek, setCurWeek] = useState(0);
  const [chart, setChart] = useState<{bet:number, dayOfWeek:number}[]>([]);
  const today = moment().utc().add(9, 'hours').format('YYYY-MM-DD');
  const user = useSelector((state: any) => state.auth.currentUser);
  const [totalPoint, setTotalPoint] = useState(0);
  const navigate = useNavigate();

  useEffect(function(){
    if(user.isSubHQ){
      navigate('/subhq/profits');
    }else if(user.agencyId){
      navigate('/egg/request');
    }else{
      AdminAPI.dashboard(token).then(v=>{
        setTodayEndGame(v.todayEndGame);
        setTodayTotalBet(v.todayTotalBet);
        setTodayAcceptedEgg(v.todayAcceptedEgg);
        setCurWeek(v.curWeek);
        setChart(v.chart);
      })

      setTotalPoint(0);
      AdminAPI.totalSupplyPoint(token).then((v)=>{
        setTotalPoint(v.point);
      })
    }
  },[])

  useEffect(() => {
    var container = document.getElementById('acquisitions') as HTMLDivElement;
    container.innerHTML = "<canvas></canvas>";
    console.log(container)

    var barChartCanvas = document.querySelector('#acquisitions canvas') as HTMLCanvasElement
    var barChartData = {
      labels: [t('월요일'), t('화요일'), t('수요일'), t('목요일'), t('금요일'), t('토요일'), t('일요일')],
      datasets: [
        {
          label: t('저번주'),
          backgroundColor: 'rgba(60,141,188,0.9)',
          borderColor: 'rgba(60,141,188,0.8)',
          pointRadius: false,
          pointColor: '#3b8bba',
          pointStrokeColor: 'rgba(60,141,188,1)',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(60,141,188,1)',
          data: [1,2,3,4,5,6,7].map(v=>{
            let f = chart.find(w=>w.dayOfWeek == v);
            return f ? f.bet : 0;
          })
        },
        {
          label: t('이번주'),
          backgroundColor: 'rgba(210, 214, 222, 1)',
          borderColor: 'rgba(210, 214, 222, 1)',
          pointRadius: false,
          pointColor: 'rgba(210, 214, 222, 1)',
          pointStrokeColor: '#c1c7d1',
          pointHighlightFill: '#fff',
          pointHighlightStroke: 'rgba(220,220,220,1)',
          data: [8,9,10,11,12,13,14].map(v=>{
            let f = chart.find(w=>w.dayOfWeek == v);
            return f ? f.bet : 0;
          })
        },
      ]
    }

    console.log(barChartData, chart)

    var barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      datasetFill: false
    }

    new Chart(barChartCanvas, {
      type: 'bar',
      data: barChartData,
      options: barChartOptions
    })
  }, [chart])

  return (
    <div>
      <ContentHeader title={today + " " + t("히스토리")}/>
      <section className="content">
        <div className="container-fluid">

          <div className="row">
            <div className="col-12">
              <div className="card">
                  <div className='card-body'>
                    {t("현재 유통량")} : <DigitsColorWrapper number={ totalPoint } />{t("포인트")}
                  </div>
              </div>
            </div>
          </div>

          <div className="row">
            
            <div className="col-lg-4 col-12">
              <div className="small-box bg-gradient-info">
                <div className="inner">
                  <p>{t("금일 진행된 방 수")}</p>
                  <h3>{Number(todayEndGame).toLocaleString()}</h3>
                </div>
                <div className="icon">
                  <i className="ion ion-bag"/>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="small-box bg-success">
                <div className="inner">
                  <p>{t("금일 베팅 총액")}</p>
                  <h3>
                    {Number(todayTotalBet).toLocaleString()}<sup style={{fontSize: '20px'}}>{t("알")}</sup>
                  </h3>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars"/>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-12">
              <div className="small-box bg-warning">
                <div className="inner">
                  <p>{t("알 지급 내역")}</p>
                  <h3>
                    {Number(todayAcceptedEgg).toLocaleString()}<sup style={{fontSize: '20px'}}>{t("알")}</sup>
                  </h3>
                </div>
                <div className="icon">
                  <i className="ion ion-person-add"/>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="card">
                  <div className='card-body'>
                    {user && !user.agencyId ? <AgencyServerStatus /> : <></>}
                  </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className="col-12">
              <div className="card">
                <div className='card-header bg-danger'>
                </div>
                <div className='card-body'>
                  <div className="col-lg-12">
                    <div className="small-box">
                      <div className="inner">
                        <p>{t("최근 1주일 배팅 히스토리")}</p>
                        <h3>{chart.filter(v=>v.dayOfWeek > 7).reduce((a,b)=>{
                          return a + Number(b.bet);
                        },0).toLocaleString()}{t("알")}</h3>
                      </div>
                      <div className="icon">
                        <i className="fas fa-shopping-cart"></i>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12" style={{
                    height: 400
                  }}>
                    <div id="acquisitions" style={{height:"300px"}}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
