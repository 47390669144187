import React, {useEffect} from 'react';
import {ContentHeader} from '@components';
import {CreateRingRoomInterface, CreateTournamentRoomInterface, RAKE_TYPE, ROOM_TYPE} from "@app/dataset";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const CreateRoom = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);

  const [roomData, setRoomData] = React.useState<CreateRingRoomInterface>({
    type: ROOM_TYPE.RING,
    blind: [
      100,200
    ],
    ante: 0,
    bigBlind: 0,
    smallBlind: 0,
    buyIn: 0,
    endDate:moment.utc().add(3, "hours").format(DATE_FORMAT),
    isAnte: false,
    isStraddle: false,
    minBuyin: 10000,
    maxBuyin: 80000,
    maxTableMember: 9,
    rake: 100,
    rakeType: RAKE_TYPE.HAND_RAKE,
  })
  const handleCreateTournamentRoom = async () => {
    try{
      roomData.smallBlind = roomData.blind[0];
      roomData.bigBlind = roomData.blind[1];
      roomData.endDate = moment(roomData.endDate).utc().format(DATE_FORMAT);
      const result = await AdminAPI.createRoom(token, roomData);
      if(result.success) {
        alert(t("방이 생성되었습니다"))
      }
    }catch(e){
      alert(t("방 만들기에 실패하였습니다"))
    }
  }


  return (
    <div>
      <ContentHeader title={t("방 만들기")}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row" style={{
            maxWidth: 900
          }}>
            <div className='col-12'>
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">{t("링 만들기")}</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("블라인드")}</label>
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <th style={{
                          width: 10
                        }}>#</th>
                        <th>{t("블라인드")}</th>
                        <th style={{width:70}}><button type="button" className="btn btn-block btn-primary btn-sm" onClick={()=>{
                          const _blind = [...roomData.blind];
                          _blind.push(0);
                          setRoomData({
                            ...roomData,
                            blind: _blind
                          })
                        }}>{t("추가")}</button></th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        roomData.blind.map((blind, index) => {
                          return <tr key={index}>
                            <td>{index+1}</td>
                            <td key={index}><input style={{width: '100%'}} type='number' value={blind} onChange={(event)=>{
                              const _blind = [...roomData.blind];
                              _blind[index] = Number(event.target.value);
                              setRoomData({
                                ...roomData,
                                blind: _blind
                              })
                            }}/></td>
                            <td><button type="button" className="btn btn-block btn-danger btn-sm" onClick={()=>{
                              const _blind = [...roomData.blind];
                              _blind.splice(index, 1);
                              setRoomData({
                                ...roomData,
                                blind: _blind
                              })
                            }}>{t("삭제")}</button></td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("종료 시간 (한국시간)")}</label>
                    <input type="datetime-local" className="form-control" defaultValue={roomData.endDate}
                           onChange={(e) => {
                             setRoomData({
                               ...roomData,
                               endDate: e.target.value
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최소 바이 인")}</label>
                    <input type="number" className="form-control" defaultValue={roomData.minBuyin}
                           onChange={(e) => {
                              setRoomData({
                                ...roomData,
                                minBuyin: Number(e.target.value)
                              })
                           }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최대 바이 인")}</label>
                    <input type="number" className="form-control" defaultValue={roomData.maxBuyin}
                           onChange={(e) => {
                             setRoomData({
                               ...roomData,
                               maxBuyin: Number(e.target.value)
                             })
                           }}
                    />
                  </div>


                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최대 테이블 참여자 수")}</label>
                    {
                      [5,6,8,9].map((item, index) => {
                        return <div className="form-check">
                          <input className="form-check-input" type="radio" name="maxTableMember" defaultChecked={roomData.maxTableMember === item}
                                 onClick={() => {
                                   setRoomData({
                                     ...roomData,
                                     maxTableMember: item
                                   })
                                 }}/>
                          <label className="form-check-label">{item}</label>
                        </div>
                      })
                    }
                  </div>


                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("앤티설정")}</label>
                    <div className="form-group">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="isAnte" defaultChecked={roomData.isAnte}
                               onClick={() => {
                                 setRoomData({
                                   ...roomData,
                                   isAnte: true
                                 })
                               }}/>
                        <label className="form-check-label">{t("있음")}</label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="isAnte" defaultChecked={!roomData.isAnte}
                               onClick={() => {
                                 setRoomData({
                                   ...roomData,
                                   isAnte: false
                                 })
                               }}/>
                        <label className="form-check-label">{t("없음")}</label>
                      </div>
                    </div>
                  </div>

                  {
                    roomData.isAnte && <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("앤티")}</label>
                      <input type="number" className="form-control" defaultValue={roomData.ante}
                             onChange={(e) => {
                               setRoomData({
                                 ...roomData,
                                 ante: Number(e.target.value)
                               })
                             }}
                      />
                    </div>
                  }

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("스트래들")}</label>
                    <div className="form-group">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="isStraddle"
                               defaultChecked={roomData.isStraddle} onClick={() => {
                          setRoomData({
                            ...roomData,
                            isStraddle: true
                          })
                        }}/>
                        <label className="form-check-label">{t("있음")}</label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="isStraddle"
                               defaultChecked={!roomData.isStraddle} onClick={() => {
                          setRoomData({
                            ...roomData,
                            isStraddle: false
                          })
                        }}/>
                        <label className="form-check-label">{t("없음")}</label>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("레이크 타입")}</label>
                    <div className="form-group">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="rakeType"
                               defaultChecked={roomData.rakeType === RAKE_TYPE.NONE} onClick={() => {
                          setRoomData({
                            ...roomData,
                            rakeType: RAKE_TYPE.NONE
                          })
                        }}/>
                        <label className="form-check-label">{t("없음")}</label>

                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="rakeType"
                               defaultChecked={roomData.rakeType === RAKE_TYPE.POT_RAKE} onClick={() => {
                          setRoomData({
                            ...roomData,
                            rakeType: RAKE_TYPE.POT_RAKE
                          })
                        }}/>
                        <label className="form-check-label">{t("팟 레이크")}</label>

                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="rakeType"
                               defaultChecked={roomData.rakeType === RAKE_TYPE.HAND_RAKE} onClick={() => {
                          setRoomData({
                            ...roomData,
                            rakeType: RAKE_TYPE.HAND_RAKE
                          })
                        }}/>
                        <label className="form-check-label">{t("핸드 레이크")}</label>

                      </div>
                    </div>
                  </div>
                  {
                    roomData.rakeType !== RAKE_TYPE.NONE && <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("레이크")}</label>
                      <input type="number" className="form-control" defaultValue={roomData.rake}
                             onChange={(e) => {
                               setRoomData({
                                 ...roomData,
                                 rake: Number(e.target.value)
                               })
                             }}
                      />
                    </div>
                  }
                </div>

                <div
                  className="card-footer"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12
                  }}>
                  <button className="btn btn-warning"
                          onClick={() => handleCreateTournamentRoom()}>{t("링 만들기")}
                  </button>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );
};

export default CreateRoom;
