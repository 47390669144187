import React, { useCallback, useEffect, useState } from 'react';
import { ContentHeader } from '@components';
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const WalletToPointList = () => {
  const { t } = useTranslation();
  const [totAccepted, setTotAccepted] = useState(0);
  const { token } = useAppSelector(state => state.auth);

  const [reqPageNum, setReqPageNum] = useState(15);
  const [accPageNum, setAccPageNum] = useState(15);

  const [reqPage, setReqPage] = useState(0);
  const [accPage, setAccPage] = useState(0);

  const [inputAgencyName, setInputAgencyName] = useState("");
  const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000 * 30)))
  const [inputEndDate, setInputEndDate] = useState(new Date((Date.now() - 0)))

  const [searchAgencyName, setSearchAgencyName] = useState("");
  const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT));
  const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT));

  const [ list, setList ]= useState<any[]>([]);


  const OnLoadAcceptedList = useCallback(function () {
    setList([]);
    AdminAPI.walletSwapList(token, accPageNum * accPage, accPageNum, false, searchAgencyName, searchStartDate, searchEndDate).then(v => {
      setList(v.rows);
      setTotAccepted(v.totalCnt)
    });

  }, [token, accPageNum, accPage, searchAgencyName, searchStartDate, searchEndDate])

  const onClickSearch = useCallback(function () {
    setSearchAgencyName(inputAgencyName)
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  }, [
    inputAgencyName, inputStartDate, inputEndDate
  ])

  useEffect(function () {
    OnLoadAcceptedList()
  }, [accPageNum, accPage, searchAgencyName, searchStartDate, searchEndDate])


  const onClickCancelSearch = useCallback(function () {
    setSearchAgencyName("");
    setSearchStartDate("");
    setSearchEndDate("");

    setInputAgencyName("");
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));
  }, [])

  return <>
  <h4 className="mb-2 mt-1">{t("요율 지갑 포인트 환전 내역")}</h4>
  <div className="row">
    <div className="col-12">
      <div className="card">
        <div className="card-header">
          <div className='row'>
            <div className="input-group col-3">
              <div className="input-group-prepend">
                <span className="input-group-text">{t("소속 에이전트")}</span>
              </div>
              <input type="text" className="form-control" value={inputAgencyName} onChange={v => setInputAgencyName(v.target.value)} />
            </div>
            <div className="input-group col-6">
              <div className="input-group-prepend">
                <span className="input-group-text">{t("기간")}</span>
              </div>
              <input type="datetime-local" className="form-control"
                value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                onChange={(e) => {
                  setInputStartDate(new Date(e.target.value));
                }}
              />
              <input type="datetime-local" className="form-control"
                value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                onChange={(e) => {
                  setInputEndDate(new Date(e.target.value));
                }}
              />
              <div className="input-group-append">
                <button type="button" className="btn btn-info" onClick={() => {
                  let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                  let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                  setInputStartDate(new Date(r1));
                  setInputEndDate(new Date(r2));
                }}>{t("어제")}</button>
              </div>
              <div className="input-group-append">
                <button type="button" className="btn btn-info" onClick={() => {
                  let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                  let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                  setInputStartDate(new Date(r1));
                  setInputEndDate(new Date(r2));
                }}>{t("오늘")}</button>
              </div>
            </div>
            <div className='offset-1 col-2'>
              <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
              <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
            </div>
          </div>
        </div>

        <div className="card-body table-responsive p-0" style={{ height: 890 }}>
          <table className="table table-head-fixed text-nowrap" id="accepted-table">
            <thead>
              <tr>
                <th>{t("충전 요청")} ID</th>
                <th>{t("요청 에이전트")} ID</th>
                <th>{t("요청 에이전트 이름")}</th>
                <th>{t("소속 에이전시")}</th>
                <th>{t("충전된 포인트")}</th>
                <th>{t("현재 보유 포인트")}</th>
                <th>{t("처리 시각")}</th>
                
              </tr>
            </thead>
            <tbody>
              {
                list.map((item, index) => {
                  return <tr key={index}>
                      <td>{item.reqId}</td>
                      <td>@{item.agencyId}</td>
                      <td>{item.uid}</td>
                      <td>{item.agencyName}</td>
                      <td>{Number(item.amount).toLocaleString()}</td>
                      <td>{Number(item.curEgg).toLocaleString()}</td>
                      <td>{moment(item.acceptedAt).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                  </tr>
                })
              }
            </tbody>
          </table>
        </div>
        <div className="card-footer clearfix">
          <ul className="pagination pagination-sm m-0 float-left">
            <li className="page-item">
              <div className="page-link">
                {t("표시")}
              </div>
            </li>
            {
              [15, 30, 50].map((item, index) => {
                return <li className="page-item">
                  <div className="page-link" style={
                    item === accPageNum ? {
                      backgroundColor: 'lightgrey',
                      color: 'black'
                    } : {}
                  } onClick={() => { setAccPageNum(item); setAccPage(0) }}>{item}</div>
                </li>
              })
            }
          </ul>
          <ul className="pagination pagination-sm m-0 float-right">
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fas fa-chevron-left"></i>
              </a>
            </li>
            {
              Array(Math.ceil(totAccepted / accPageNum)).fill(0).map((item, index) => {
                return <li className="page-item"><a className="page-link" href="#accepted-table" style={{ backgroundColor: index == accPage ? "#ddd" : "#fff" }} onClick={() => setAccPage(index)}>{index + 1}</a></li>
              })
            }
            <li className="page-item">
              <a className="page-link" href="#">
                <i className="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
  </>
}

const ManagementEgg = () => {
  const { t } = useTranslation();
  const { token } = useAppSelector(state => state.auth);
  const [summary, setSummary] = useState<[string, string]>(["", ""]);
  const today = moment().utc().add(9, 'hours').format('YYYY-MM-DD');
  const [totRequest, setTotRequest] = useState(0);
  const [totAccepted, setTotAccepted] = useState(0);
  const [reqPageNum, setReqPageNum] = useState(15);
  const [accPageNum, setAccPageNum] = useState(15);

  const [reqPage, setReqPage] = useState(0);
  const [accPage, setAccPage] = useState(0);

  const [inputAgencyName, setInputAgencyName] = useState("");
  const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000 * 30)))
  const [inputEndDate, setInputEndDate] = useState(new Date((Date.now() - 0)))

  const [searchAgencyName, setSearchAgencyName] = useState("");
  const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT));
  const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT));
  
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const [requestEggs, setRequestEggs] = useState<{
    reqId: number,
    agencyId: number,
    amount: number,
    uid: string,
    acceptedAdminId: number,
    canceledAdminId: number,
    requestedAt: string,
    agencyName: string,
    curEgg: number
  }[]>();
  const [acceptedEggs, setAcceptedEggs] = useState<{
    reqId: number,
    agencyId: number,
    amount: number,
    acceptedAdminId: number,
    canceledAdminId: number,
    uid: string,
    requestedAt: string,
    acceptedAt: string,
    canceledAt: string,
    canceledAdminUid: string,
    agencyName: string,
    acceptedAdminUid: string,
    curEgg: number
  }[]>();

  const OnLoadAcceptedList = useCallback(function () {
    setAcceptedEggs(undefined);
    AdminAPI.userEggRequestList(token, accPageNum * accPage, accPageNum, false, searchAgencyName, searchStartDate, searchEndDate).then(v => {
      setAcceptedEggs(v.rows);
      setTotAccepted(v.totalCnt)
    });
  }, [token, accPageNum, accPage, searchAgencyName, searchStartDate, searchEndDate])

  const OnLoadRequestList = useCallback(function () {
    setRequestEggs(undefined);
    AdminAPI.userEggRequestList(token, reqPageNum * reqPage, reqPageNum, true).then(v => {
      setRequestEggs(v.rows);
      setTotRequest(v.totalCnt)
    });
  }, [token, reqPageNum, reqPage])

  const OnLoadSummary = useCallback(function () {
    AdminAPI.monthlySummaryEgg(token).then(v => {
      setSummary([Number(v.request).toLocaleString(), Number(v.accept).toLocaleString()]);
    })
  }, [])

  useEffect(function () {
    OnLoadAcceptedList();
    OnLoadRequestList();
    OnLoadSummary();
  }, [])

  useEffect(function () {
    OnLoadRequestList();
  }, [reqPageNum, reqPage])

  useEffect(function () {
    OnLoadAcceptedList()
  }, [accPageNum, accPage, searchAgencyName, searchStartDate, searchEndDate])

  const onClickAccept = useCallback(function (reqId: number) {
    if (confirm(`${t("정말로 해당 요청을 처리하겠습니까")}?`)) {
      AdminAPI.acceptAgencyEgg(token, reqId).then(v => {
        OnLoadSummary();
        OnLoadAcceptedList();
        OnLoadRequestList();
      })
    }
  }, [])

  const onClickCancel = useCallback(function (reqId: number) {
    if (confirm(`${t("정말로 해당 요청을 처리 취소하시겠습니까")}?`)) {
      AdminAPI.cancelAgencyEgg(token, reqId).then(v => {
        OnLoadSummary();
        OnLoadAcceptedList();
        OnLoadRequestList();
      })
    }
  }, [])

  const onClickSearch = useCallback(function () {
    setSearchAgencyName(inputAgencyName)
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  }, [
    inputAgencyName, inputStartDate, inputEndDate
  ])

  const onClickCancelSearch = useCallback(function () {
    setSearchAgencyName("");
    setSearchStartDate("");
    setSearchEndDate("");

    setInputAgencyName("");
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));
  }, [])

  const onClickDelete = useCallback(function (reqId: number) {
    if (confirm(`${t("정말로 해당 요청을 거절하시겠습니까")}?`)) {
      AdminAPI.deleteRequestAgencyEgg(token, reqId).then(v => {
        OnLoadSummary();
        OnLoadAcceptedList();
        OnLoadRequestList();
      })
    }
  }, []);


  return (
    <div>
      {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={() => setAgencyDetailPopup(null)} /> : <></>}
      <ContentHeader title={`${t("홀덤 포인트 관리")}`} />
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <p>{t("금월 충전 금액")}</p>
                  <h3>
                    {summary[0]}<sup style={{ fontSize: '20px' }}>{t("포인트")}</sup>
                  </h3>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="small-box bg-gradient-success">
                <div className="inner">
                  <p>{t("금월 지급 금액")}</p>
                  <h3>
                    {summary[1]}<sup style={{ fontSize: '20px' }}>{t("포인트")}</sup>
                  </h3>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
              </div>
            </div>
          </div>

          <h4 className="mb-2 mt-1">{t("충전 요청")} ({requestEggs ? totRequest : 0})</h4>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body table-responsive p-0" style={{ height: 890 }}>
                  <table className="table table-head-fixed text-nowrap" id="request-table">
                    <thead>
                      <tr>
                        <th>{t("충전 요청")} ID</th>
                        <th>{t("요청 에이전트")} ID</th>
                        <th>{t("요청 에이전트 이름")}</th>
                        <th>{t("소속 에이전시")}</th>
                        <th>{t("충전 요청 포인트")}</th>
                        <th>{t("현재 보유 포인트")}</th>
                        <th>{t("충전 후 예상 보유 포인트")}</th>
                        <th>{t("신청시각")}</th>
                        <th>{t("상태")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        requestEggs ? requestEggs.map((item, index) => {
                          return <tr key={index}>
                            <td>{item.reqId}</td>
                            <td>@{item.agencyId}</td>
                            <td>{item.uid}</td>
                            <td>{item.agencyName} <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setAgencyDetailPopup(item.agencyId) }} /></td>
                            <td>{Number(item.amount).toLocaleString()}</td>
                            <td>{Number(item.curEgg).toLocaleString()}</td>
                            <td>{(Number(item.amount) + Number(item.curEgg)).toLocaleString()}</td>
                            <td>{moment(item.requestedAt).local().format('MM-DD HH:mm')}</td>
                            <td>
                              <span className="badge bg-info">{t("요청중")}</span>
                            </td>
                            <td>
                              <button type="button" onClick={() => onClickAccept(item.reqId)} className="btn btn-primary btn-sm">{t("처리 완료하기")}</button>
                              <button type="button" onClick={() => onClickDelete(item.reqId)} className="btn btn-danger btn-sm">{t("거절하기")}</button>
                            </td>
                          </tr>
                        }) : <><tr><td colSpan={9}> {t("로딩 중")} .. </td></tr></>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer clearfix">
                  <ul className="pagination pagination-sm m-0 float-left">
                    <li className="page-item">
                      <div className="page-link">
                        {t("표시")}
                      </div>
                    </li>
                    {
                      [15, 30, 50].map((item, index) => {
                        return <li className="page-item">
                          <div className="page-link" style={
                            item === reqPageNum ? {
                              backgroundColor: 'lightgrey',
                              color: 'black'
                            } : {}
                          } onClick={() => { setReqPageNum(item); setReqPage(0) }}>{item}</div>
                        </li>
                      })
                    }
                  </ul>
                  <ul className="pagination pagination-sm m-0 float-right">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-chevron-left"></i>
                      </a>
                    </li>
                    {
                      Array(Math.ceil(totRequest / reqPageNum)).fill(0).map((item, index) => {
                        return <li className="page-item"><a className="page-link" href="#request-table" style={{ backgroundColor: index == reqPage ? "#ddd" : "#fff" }} onClick={() => setReqPage(index)}>{index + 1}</a></li>
                      })
                    }
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          <h4 className="mb-2 mt-1">{t("처리 완료 내역")}({acceptedEggs ? totAccepted : 0})</h4>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <div className='row'>
                    <div className="input-group col-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t("소속 에이전트")}</span>
                      </div>
                      <input type="text" className="form-control" value={inputAgencyName} onChange={v => setInputAgencyName(v.target.value)} />
                    </div>
                    <div className="input-group col-6">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t("기간")}</span>
                      </div>
                      <input type="datetime-local" className="form-control"
                        value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                        onChange={(e) => {
                          setInputStartDate(new Date(e.target.value));
                        }}
                      />
                      <input type="datetime-local" className="form-control"
                        value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                        onChange={(e) => {
                          setInputEndDate(new Date(e.target.value));
                        }}
                      />
                      <div className="input-group-append">
                        <button type="button" className="btn btn-info" onClick={() => {
                          let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                          let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                          setInputStartDate(new Date(r1));
                          setInputEndDate(new Date(r2));
                        }}>{t("어제")}</button>
                      </div>
                      <div className="input-group-append">
                        <button type="button" className="btn btn-info" onClick={() => {
                          let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                          let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                          setInputStartDate(new Date(r1));
                          setInputEndDate(new Date(r2));
                        }}>{t("오늘")}</button>
                      </div>
                    </div>
                    <div className='offset-1 col-2'>
                      <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                      <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
                    </div>
                  </div>
                </div>

                <div className="card-body table-responsive p-0" style={{ height: 890 }}>
                  <table className="table table-head-fixed text-nowrap" id="accepted-table">
                    <thead>
                      <tr>
                        <th>{t("충전 요청")} ID</th>
                        <th>{t("요청 에이전트")} ID</th>
                        <th>{t("요청 에이전트 이름")}</th>
                        <th>{t("소속 에이전시")}</th>
                        <th>{t("충전된 포인트")}</th>
                        <th>{t("현재 보유 포인트")}</th>
                        <th>{t("처리 취소 시 보유 포인트")}</th>
                        <th>{t("처리 시각")}</th>
                        <th>{t("처리자")}</th>
                        <th>{t("신청시각")}</th>
                        <th>{t("상태")}</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        acceptedEggs ? acceptedEggs.map((item, index) => {
                          return <tr key={index}>
                            <td>{item.reqId}</td>
                            <td>@{item.agencyId}</td>
                            <td>{item.uid}</td>
                            <td>{item.agencyName} <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setAgencyDetailPopup(item.agencyId) }} /></td>
                            {item.canceledAdminId ? <>
                              <td>{Number(item.amount).toLocaleString()}</td>
                              <td>-</td>
                              <td>-</td>
                              <td>{moment(item.canceledAt).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>{item.canceledAdminUid}</td>
                            </> : <>
                              <td>{Number(item.amount).toLocaleString()}</td>
                              <td>{Number(item.curEgg).toLocaleString()}</td>
                              <td>{Number(item.curEgg - item.amount).toLocaleString()}</td>
                              <td>{moment(item.acceptedAt).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>-</td>
                            </>}
                            <td>{moment(item.requestedAt).format('MM-DD HH:mm')}</td>
                            <td>
                              {item.canceledAdminId ?
                                <span className="badge bg-gradient-success">{t("처리 취소됨")}</span> :
                                <span className="badge bg-gradient-success">{t("완료")}</span>
                              }
                            </td>
                            <td>
                              {item.canceledAdminId ? <></> :
                                <button type="button" className="btn btn-danger btn-sm" onClick={() => onClickCancel(item.reqId)}>{t("처리 취소하기")}</button>
                              }
                            </td>
                          </tr>

                        }) : <><tr><td colSpan={9}> {t("로딩 중")} .. </td></tr></>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer clearfix">
                  <ul className="pagination pagination-sm m-0 float-left">
                    <li className="page-item">
                      <div className="page-link">
                        {t("표시")}
                      </div>
                    </li>
                    {
                      [15, 30, 50].map((item, index) => {
                        return <li className="page-item">
                          <div className="page-link" style={
                            item === accPageNum ? {
                              backgroundColor: 'lightgrey',
                              color: 'black'
                            } : {}
                          } onClick={() => { setAccPageNum(item); setAccPage(0) }}>{item}</div>
                        </li>
                      })
                    }
                  </ul>
                  <ul className="pagination pagination-sm m-0 float-right">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-chevron-left"></i>
                      </a>
                    </li>
                    {
                      Array(Math.ceil(totAccepted / accPageNum)).fill(0).map((item, index) => {
                        return <li className="page-item"><a className="page-link" href="#accepted-table" style={{ backgroundColor: index == accPage ? "#ddd" : "#fff" }} onClick={() => setAccPage(index)}>{index + 1}</a></li>
                      })
                    }
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>


          <WalletToPointList />


        </div>
      </section>
    </div>
  );
};

export default ManagementEgg;
