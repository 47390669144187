import axios from "axios";
import {AddRoomInterface, RAKE_TYPE, ROOM_TYPE, RakePercentage, AgencyEggHistoryType} from "@app/dataset";
import moment from "moment";
import {t} from "i18next";
import UserWallet from "@app/pages/UserWallet";

const API_HOST = process.env.REACT_APP_API_HOST as string;
const ENDPOINT = API_HOST + '/admin';

const GET = async function(url:string, opt:any){
  let res = await axios.get(url, opt).then(res => res.data);
  if(res.code == -5){
    alert(`${t("권한이 없는 계정입니다")}.`)
    window.location.href='/'
    return
  }
  if(res.code == -2){
    alert(`${t("로그인이 필요합니다")}.`)
    window.location.href='/'
    return
  }
  return res;
}

const FILE_POST = async function(endpoint:string, session:string, file:any){
  let formData = new FormData();
  formData.append("file", file)

  let res = await axios.post(endpoint, formData, {
    headers: {
      session: session,
      "Content-Type": "multipart/form-data",
    }
  }).then(res => res.data);

  if(res.code == -5){
    alert(`${t("권한이 없는 계정입니다")}.`)
    window.location.href='/'
    return
  }
  if(res.code == -2){
    alert(`${t("로그인이 필요합니다")}.`)
    window.location.href='/'
    return
  }
  return res;
}
const POST = async function(endpoint:string, params:any){
  let res = await axios.post(endpoint, params, {
    headers: {
      session: params.session
    }
  }).then(res => res.data);
  if(res.code == -5){
    alert(`${t("권한이 없는 계정입니다")}.`)
    window.location.href='/'
    return
  }
  if(res.code == -2){
    alert(`${t("로그인이 필요합니다")}.`)
    window.location.href='/'
    return
  }
  return res;
}

const AdminAPI = {
  createRoom(session: string, params: AddRoomInterface) {
    return POST(ENDPOINT + '/createRoom', {
      session,
      ...params
    })
  },
  login(params: {id: string, password: string}) {
    return POST(ENDPOINT + '/login', params)
  },
  getInfo(params: {session: string}) {
    return GET(ENDPOINT + '/info', {
      headers: {
        session: params.session
      }
    })
  },

  getSubHQList(session:string){
    return POST(ENDPOINT + "/subHQ/list", {
      session:session
    })
  },

  getSubHQMyList(session:string){
    return POST(ENDPOINT + "/subHQ/myList", {
      session:session
    })
  },

  getAgencyInfo(session:string, agencyId:number){
    return POST(ENDPOINT + "/agency/info", {
      agencyId:agencyId,
      session:session
    })
  },

  signup(params: {session: string, id: string, password: string}) {
    return POST(ENDPOINT + '/signUpPriorityManager', params)
  },
  getTournamentList(params: {session: string}) {
    return GET(ENDPOINT + '/list/tournament', {
      headers: {
        session: params.session
      }
    })
  },
  getRingList(params: {session: string}) {
    return GET(ENDPOINT + '/list/ring', {
      headers: {
        session: params.session
      }
    })
  },

  getStandaloneNewRequest(params: {session: string}){
    return GET(ENDPOINT + '/standalone/newRequest',{
      headers: {
        session: params.session,
      }
    })
  },

  setInvisibleTournament(params: {session: string, groupId: number}) {
    return POST(ENDPOINT + '/tournament/invisible', {
      session: params.session,
      groupId: params.groupId
    })
  },

  deleteTournament(params: {session: string, groupId: number}) {
    return POST(ENDPOINT + '/tournament/delete', {
      session: params.session,
      groupId: params.groupId
    })
  },

  noticeImgUpload(session:string, file:any) {
    return FILE_POST(ENDPOINT + '/notice/imgUpload', session,file);
  },

  noticeList(session:string, offset:number, limit:number) {
    return POST(ENDPOINT + '/notice/list', {
      session: session,
      offset,
      limit
    })
  },

  noticeAdd(session:string, data:{[key:string]:{thumb:string, img:string, title:string, text:string}}) {
    return POST(ENDPOINT + '/notice/add', {
      session: session,
      data
    })
  },

  noticeDelete(session:string, noticeId:number) {
    return POST(ENDPOINT + '/notice/delete', {
      session: session,
      noticeId
    })
  },

  noticeOrderUpdate(session:string, noticeId:number, order:number) {
    return POST(ENDPOINT + '/notice/update/order', {
      session: session,
      noticeId,
      order
    })
  },

  getSubHQDetailList(params: {session: string, pageId: number, rowCount: number, searchAgencyName:string}) {
    return POST(ENDPOINT + '/agency/getSubHQList', {
      session: params.session,
      pageId: params.pageId,
      rowCount: params.rowCount,
      searchAgencyName: params.searchAgencyName
    })
  },

  getAgencyWalletList(session:string, searchAgencyName:string) {
    return POST(ENDPOINT + '/agency/getAgencyWalletList', {
      session: session,
      searchAgencyName: searchAgencyName
    })
  },

  getAgencyList(params: {session: string, pageId: number, rowCount: number, searchAgencyName:string}) {
    return POST(ENDPOINT + '/agency/getAgencyList', {
      session: params.session,
      pageId: params.pageId,
      rowCount: params.rowCount,
      searchAgencyName: params.searchAgencyName
    })
  },

  agencyRakeProfits(session:string, searchMonth:string, agencyId:number){
    return POST(ENDPOINT + '/agency/profits',{
      session:session,
      searchMonth:searchMonth,
      agencyId:agencyId
    })
  },

  subhqProfits(session:string, searchMonth:string){
    return POST(ENDPOINT + '/subhq/profits',{
      session:session,
      searchMonth:searchMonth
    })
  },

  createAgency(params: {
    session: string,
    agencyId:number,
    agencyName: string,
    agentName: string,
    uid: string,
    password: string,
    rakePercentage: RakePercentage,
    memo: string,
    phoneNumber: string,
    endpoint: string,
    isAgency:boolean,
    topAgencyId:number,
    pointCharge:number,
    standaloneOffline:number,
    standaloneInGame:number,
    enableCreateSub:number,
    bankInfo:{
      bank:string,
      bankAccount:string,
      bankAccountHolder:string,
      usdtNetwork:string,
      usdtAddress:string
    }
  }) {
    return POST(ENDPOINT + '/agency/createAgency', {
      agencyName: params.agencyName,
      agentName: params.agentName,
      uid: params.uid,
      phoneNumber: params.phoneNumber,
      password: params.password,
      rakePercentage: params.rakePercentage,
      memo: params.memo,
      session: params.session,
      agencyId: params.agencyId,
      endpoint: params.endpoint,
      isAgency: params.isAgency,
      topAgencyId:params.topAgencyId,
      pointCharge:params.pointCharge,
      standaloneOffline:params.standaloneOffline,
      standaloneInGame:params.standaloneInGame,
      enableCreateSub:params.enableCreateSub,
      bankInfo:params.bankInfo
    })
  },
  
  createAgencyFromSubHQ(params: {session: string, agencyId:number, agencyName: string, agentName: string, uid: string, password: string, rakePercentage: RakePercentage, memo: string, phoneNumber: string, endpoint: string, topAgencyId:number}) {
    return POST(ENDPOINT + '/subhq/createAgency', params)
  },

  getAdminList(session: string) {
    return GET(ENDPOINT + '/adminlist', {
      headers: {
        session: session
      }
    })
  },

  setAdminPrilvilege(session: string, adminId: number, privilegeName: string, rem:boolean) {
    return POST(ENDPOINT + '/admin/modPrilvilege', {
      session: session,
      adminId: adminId,
      privilegeName: privilegeName,
      rem: rem,
    })
  },

  getUserInfo(userId:number, session:string){
    return GET(ENDPOINT + "/userInfo?userId="+userId, {
      headers: {
        session: session
      }
    })
  },

  getUserInfoForAgency(userId:number, session:string){
    return GET(ENDPOINT + "/agency/userInfo?userId="+userId, {
      headers: {
        session: session
      }
    })
  },

  getUserAccessIP(userId:number, session:string){
    return GET(ENDPOINT + "/getUserAccessIP?userId="+userId, {
      headers: {
        session: session
      }
    })
  },



  getForceAdjustLog(session:string, offset:number, limit:number,searchAgencyName:string, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/user/forceAdjust/log',{
      session:session,
      offset,
      limit,
      searchAgencyName,
      searchStartDate:searchStartDate ? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate ? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  getRestoreLog(session:string, offset:number, limit:number,searchAgencyName:string, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/user/restore/log',{
      session:session,
      offset,
      limit,
      searchAgencyName,
      searchStartDate:searchStartDate ? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate ? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },
  getSendEggLog(session:string, offset:number, limit:number,searchAgencyName:string, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/agency/egg/sendLog',{
      session:session,
      offset,
      limit,
      searchAgencyName,
      searchStartDate:searchStartDate ? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate ? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  sendEgg(session:string, agencyId:number, egg:number, memo:string){
    return POST(ENDPOINT + '/agency/egg/send',{
      session:session,
      agencyId,
      egg,
      memo
    })
  },

  agencyNameList(session:string){
    return POST(ENDPOINT + '/agencyNameList',{
      session: session
    })
  },

  forceAdjustUser(session:string, userId:number, memo:string){
    return POST(ENDPOINT + '/user/forceAdjust',{
      session:session,
      userId,
      memo
    })
  },

  restoreSiteMoney(session:string, userId:number, egg:number, memo:string){
    return POST(ENDPOINT + '/user/restoreSiteMoney',{
      session:session,
      userId,
      egg,
      memo
    })
  },

  userListForViewMoney(session:string, nicknameOrUid:string ){
    return POST(ENDPOINT + '/userListForViewMoney',{
      session: session,
      nicknameOrUid
    })
  },
  userListForRestore(session:string, nicknameOrUid:string ){
    return POST(ENDPOINT + '/userListForRestore',{
      session: session,
      nicknameOrUid
    })
  },

  viewUserMoney(session:string, userId:number){
    return POST(ENDPOINT + '/viewUserMoney',{
      session: session,
      userId
    })
  },

  getBettingHistory(params: {
    session: string,
    pageId: number,
    rowCount: number,
    searchAgencyName:string,
    searchUserNickname:string,
    searchUserId:string,
    searchStartDate:string,
    searchEndDate:string,
    roomType:ROOM_TYPE
  }) {
    return POST(ENDPOINT + '/agency/bettingHistory', {
      session: params.session,
      pageId: params.pageId,
      rowCount: params.rowCount,
      searchAgencyName: params.searchAgencyName,
      searchUserNickname: params.searchUserNickname,
      searchUserId: params.searchUserId,

      searchStartDate:params.searchStartDate ? moment(params.searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:params.searchEndDate ? moment(params.searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",

      roomType:params.roomType
    })
  },


  adminLogs(session:string, adminId:number, offset:number, limit:number){
    return POST(ENDPOINT + '/admin/logs',{
      session:session,
      adminId,
      offset,
      limit,
    })
  },


  acceptAgencyEgg(session:string, reqId:number){
    return POST(ENDPOINT + '/agency/egg/accept',{
      session:session,
      reqId
    })
  },

  cancelAgencyEgg(session:string, reqId:number){
    return POST(ENDPOINT + '/agency/egg/cancel',{
      session:session,
      reqId
    })
  },

  deleteRequestAgencyEgg(session:string, reqId:number){
    return POST(ENDPOINT + '/agency/egg/delete',{
      session:session,
      reqId
    })
  },

  monthlySummaryEgg(session:string){
    return POST(ENDPOINT + '/agency/summary/egg',{
      session:session,
    })
  },

  userBan(session:string, userId:number, memo:string){
    return POST(ENDPOINT + '/users/ban',{
      session:session,
      userId,
      memo
    })
  },
  userReleaseBan(session:string, userId:number){
    return POST(ENDPOINT + '/users/releaseBan',{
      session:session,
      userId
    })
  },

  userBanForAgency(session:string, userId:number, memo:string){
    return POST(ENDPOINT + '/users/banForAgency',{
      session:session,
      userId,
      memo
    })
  },
  userReleaseBanForAgency(session:string, userId:number){
    return POST(ENDPOINT + '/users/releaseBanForAgency',{
      session:session,
      userId
    })
  },

  userListForAgency(session:string, offset:number, limit:number, searchUsernickname:string="", searchUserId:string="", onlyBan:boolean = false, orderBy: "rakebackAmount"|"money"|null = null, orderType: "asc"|"desc" = "desc"){
    return POST(ENDPOINT + '/agency/users/list',{
      session:session,
      offset,
      limit,
      searchUsernickname,
      searchUserId,
      onlyBan,
      orderBy,
      orderType
    })
  },

  userList(session:string, offset:number, limit:number, searchAgencyName:string="", searchUsernickname:string="", searchUserId:string="", onlyBan:boolean = false, orderBy: "rakebackAmount"|"money"|null = null, orderType: "asc"|"desc" = "desc"){
    return POST(ENDPOINT + '/users/list',{
      session:session,
      offset,
      limit,
      searchAgencyName,
      searchUsernickname,
      searchUserId,
      onlyBan,
      orderBy,
      orderType
    })
  },

  agencyServerConnectionErrorLast12Hour(session:string,){
    return POST(ENDPOINT + '/agencyServerConnectionErrorLast12Hour',{
      session
    });
  },

  agencyServerConnectionErrorLast20Min(session:string,){
    return POST(ENDPOINT + '/agencyServerConnectionErrorLast20Min',{
      session
    });
  },

  buyinoutErrors(session:string,){
    return POST(ENDPOINT + '/buyinoutErrors',{
      session
    });
  },

  buyoutErrorRetry(session:string, enterId:number){
    return POST(ENDPOINT + '/buyoutErrorRetry',{
      session,
      enterId
    });
  },

  buyoutErrorPass(session:string, enterId:number){
    return POST(ENDPOINT + '/buyoutErrorPass',{
      session,
      enterId
    });
  },

  userDuplicateIpAccess(session:string, searchStartDate:string="", searchEndDate:string=""){
    return POST(ENDPOINT + '/users/duplicateIPAccess',{
      session:session,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  userAccessList(session:string, offset:number, limit:number, searchStartDate:string="", searchEndDate:string=""){
    return POST(ENDPOINT + '/users/accesslist',{
      session:session,
      offset,
      limit,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  walletLogs(session:string, offset:number, limit:number, searchStartDate:string="", searchEndDate:string="", searchViewType:number=0){
    return POST(ENDPOINT + '/agency/walletLogs',{
      session:session,
      offset,
      limit,
      searchViewType,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  requestEgg(session:string, amount:number){
    return POST(ENDPOINT + '/agency/my/request/egg/add',{
      session:session,
      amount
    })
  },
  myEggRequestList(session:string, offset:number, limit:number){
    return POST(ENDPOINT + '/agency/my/request/egg',{
      session:session,
      offset,
      limit
    })
  },

  walletSwapList(session:string, offset:number, limit:number, isRequest:boolean, searchAgencyName:string="", searchStartDate:string="", searchEndDate:string=""){
    return POST(ENDPOINT + '/agency/walletSwapList',{
      session:session,
      offset,
      limit,
      isRequest,
      searchAgencyName,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  userEggRequestList(session:string, offset:number, limit:number, isRequest:boolean, searchAgencyName:string="", searchStartDate:string="", searchEndDate:string=""){
    return POST(ENDPOINT + '/agency/request/egg',{
      session:session,
      offset,
      limit,
      isRequest,
      searchAgencyName,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  adminDel(session:string, adminId:number){
    return POST(ENDPOINT + '/admin/del',{
      session:session,
      adminId,
    })
  },

  top10Week(session:string){
    return POST(ENDPOINT + '/lastWeekUsers',{ orderBy:true, session:session})
  },

  worst10Week(session:string){
    return POST(ENDPOINT + '/lastWeekUsers',{ orderBy:false, session:session})
  },

  headInfo(session:string){
    return GET(ENDPOINT + '/headinfo',{
      headers: {
        session: session
      }
    })
  },

  dashboard(session:string){
    return GET(ENDPOINT + '/dashboard',{
      headers: {
        session: session
      }
    })
  },

  agencyStatus(session:string, agencyId:number, start:string|null, end:string|null){
    return POST(ENDPOINT + '/agency/status',{
      agencyId: agencyId,
      session: session,
      start,
      end
    })
  },

  agencyStatistics_1(session:string, month:string, agencyId:number){
    return POST(ENDPOINT + '/agency/statistics/1',{
      session: session,
      month,
      agencyId
    })
  },

  agencyStatistics_2(session:string, month:string, agencyId:number){
    return POST(ENDPOINT + '/agency/statistics/2',{
      session: session,
      month:month,
      agencyId
    })
  },

  agencyStatistics_3(token:string, searchUserNickname:string, searchUserId:string, searchStartDate:string, searchEndDate:string, searchAgentCode:string, orderBy:string, orderASC:boolean, agencyId:number){
    return POST(ENDPOINT + '/agency/statistics/3',{
      session: token,
      searchUserNickname,
      searchUserId,
      searchStartDate:searchStartDate ? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate ? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchAgentCode,
      orderBy,
      orderASC,
      agencyId
    })
  },

  tournamentNameList(session:string){
    return POST(ENDPOINT + '/tournamentNameList',{
      session: session
    })
  },

  gameRecords(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchStartDate:string, searchEndDate:string, searchRoomType:number|undefined, searchGroupId:string){
    return POST(ENDPOINT + '/gameRecords',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchRoomType,
      searchGroupId,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  getGameHistory(token:string, roomId:number, gameNumber:number){
    return POST(ENDPOINT + '/gameHistoryInfo',{
      session: token,
      roomId,
      gameNumber
    })
  },
  getRooms(session:string, offset:number, limit:number){
    return POST(ENDPOINT + '/roomlist',{
      session: session,
      offset,
      limit
    })
  },

  stopGameServer(session:string){
    return POST(ENDPOINT + '/stopGameServer',{
      session: session,
    })
  },
  runGameServer(session:string){
    return POST(ENDPOINT + '/runGameServer',{
      session: session,
    })
  },
  statusGameServer(session:string){
    return POST(ENDPOINT + '/statusGameServer',{
      session: session,
    })
  },

  changePlayerCount(token:string, roomId:number, playerCount:number){
    return POST(ENDPOINT + '/changePlayerCount',{
      session: token,
      roomId,
      playerCount
    })
  },

  setAllRoomNotice(session:string, notice:{
    KR:string,
    JP:string,
    EN:string,
    THAI:string,
  }){
    return POST(ENDPOINT + '/setRoomNotice',{
      session: session,
      notice
    })
  },
  setRoomNotice(session:string, roomId:number, notice:{
    KR:string,
    JP:string,
    EN:string,
    THAI:string,
  }){
    return POST(ENDPOINT + '/setRoomNotice',{
      session: session,
      roomId,
      notice
    })
  },

  userNextBuyoutLog(token:string, enterId:number, userId:number){
    return POST(ENDPOINT + '/userNextBuyoutLog',{
      session: token,
      enterId,
      userId
    })
  },

  userMoneyTracker(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchGameType:number, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/userMoneyTracker',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchGameType,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  userBuyinoutLogs(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchGameType:number, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/userBuyinoutLogs',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchGameType,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  userAdjustMoneyList(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchGameType:number, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/userAdjustMoneyList',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchGameType,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  totalProfitTournament(token:string, searchStartDate:string){
    return POST(ENDPOINT + '/game/tournament/total/profit',{
      session: token,
      searchStartDate:searchStartDate? moment.utc(searchStartDate).format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  totalProfit(token:string, searchStartDate:string, krTimezone:number){
    return POST(ENDPOINT + '/game/total/profit',{
      session: token,
      searchStartDate:searchStartDate? moment.utc(searchStartDate).format("YYYY-MM-DD HH:mm:ss") : "",
      timezone:krTimezone
    })
  },

  tournamentMember(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchGroupId:number){
    return POST(ENDPOINT + '/tournament/member',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchGroupId
    })
  },


  getTournamentPresets(session:string){
    return POST(ENDPOINT + '/getTournamentPresets',{
      session: session
    })
  },

  upsertTournamentPreset(session:string, presetName:string, presetObj: any){
    return POST(ENDPOINT + '/upsertTournamentPreset',{
      session: session,
      presetName,
      presetObj,
    })
  },

  deleteTournamentPreset(session:string, presetName:string){
    return POST(ENDPOINT + '/deleteTournamentPreset',{
      session: session,
      presetName,
    })
  },

  tournamentBuyinInfo(session:string, groupId:number){
    return POST(ENDPOINT + '/tournament/buyinInfo',{
      session: session,
      groupId,
    })
  },

  agencyExchangeRequest(session:string, amount:number, account_name:string, account_address:string, account_bankName:string){
    return POST(ENDPOINT + '/agency/exchange/request',{
      session: session,
      amount,
      account_name,
      account_address,
      account_bankName
    })
  },

  walletToPoint(session:string, amount:number){
    return POST(ENDPOINT + '/walletToPoint',{
      session: session,
      amount
    })
  },

  agencyExchangeRequestList(session:string, offset:number, limit:number){
    return POST(ENDPOINT + '/agency/exchange/list',{
      session: session,
      offset,
      limit
    })
  },

  agencyExchangeAccept(session:string, exId:number, statusCode:number, statusMemo:string, password:string){
    return POST(ENDPOINT + '/agency/exchange/accept',{
      session: session,
      exId,
      statusCode,
      statusMemo,
      password
    })
  },

  agencyExchangeAllPendingList(session:string, offset:number, limit:number){
    return POST(ENDPOINT + '/agency/exchange/allPendingList',{
      session: session,
      offset,
      limit
    })
  },

  agencyExchangeAllNoPendingList(session:string, offset:number, limit:number){
    return POST(ENDPOINT + '/agency/exchange/allNoPendingList',{
      session: session,
      offset,
      limit
    })
  },

  agencyExchangePendingAmount(session:string){
    return POST(ENDPOINT + '/agency/exchange/pendingAmount',{
      session: session,
    })
  },

  agencyRakeLogs(session:string, offset:number, limit:number, searchStartDate:string, searchEndDate:string, agencyName:string, userNickname:string, uid:string, searchAgentCode:string, searchSubHQName:string){
    return POST(ENDPOINT + '/agency/rake/logs',{
      session: session,
      offset,
      limit,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      agencyName,
      userNickname,
      uid,
      searchAgentCode,
      searchSubHQName
    })
  },

  getAgencyListForExchange(session:string, searchAgencyName:string){
    return POST(ENDPOINT + "/agency/list/exchange",{
      session: session,
      searchAgencyName,
    })
  },

  getAgencyRequestExchangeList(session:string, agencyId:number){
    return POST(ENDPOINT + "/agency/request/list/exchange",{
      session: session,
      agencyId,
    })
  },

  getHierarchySubHQ(session:string){
    return POST(ENDPOINT + "/getHierarchySubHQ",{
      session: session,
    })
  },
  
  getFiatExchangeRate(session:string){
    return POST(ENDPOINT + "/fiat/exchangeRate",{
      session: session,
    })
  },

  getFiatExchangeRatioList(session:string, currentMonth:string){
    return POST(ENDPOINT + "/fiat/exchangeRatioList",{
      session: session,
      currentMonth: currentMonth
    })
  },

  getUsdtHistory(session:string, startDate:string, endDate:string){
    return POST(ENDPOINT + "/usdt/history",{
      session: session,
      startDate,
      endDate
    })
  },
  
  getUsdtInfo(session:string){
    return POST(ENDPOINT + "/usdt/info",{
      session: session,
    })
  },

  setUsdtInfo(session:string, ethAddress:string, trxAddress:string){
    return POST(ENDPOINT + "/usdt/set",{
      session: session,
      ethAddress,
      trxAddress
    })
  },

  getTopLevelSubHQ(session:string, page:number, rowCount:number, agencyName:string){
    return POST(ENDPOINT + "/topLevelSubHQ",{
      session: session,
      page,
      rowCount,
      agencyName
    })
  },

  getSubLevelAgency(session:string, topLevelAgencyId:number){
    return POST(ENDPOINT + "/subLevelAgency",{
      session: session,
      topLevelAgencyId
    })
  },

  deleteRingGame(session:string, roomId:number){
    return POST(ENDPOINT + "/deleteRingGame",{
      session: session,
      roomId
    })
  },
  reloadGameRecords(token:string, minBetId:number, maxBetId:number){
    return POST(ENDPOINT + '/reloadGameRecords',{
      session: token,
      minBetId,
      maxBetId
    })
  },

  getAgencyProfitList(token:string, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/agency/profitList',{
      session: token,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  totalSupplyPoint(token:string){
    return POST(ENDPOINT + '/totalSupplyPoint',{
      session: token,
    })
  },

  agencyUserListForViewMoney(token:string, agencyId:number){
    return POST(ENDPOINT + '/agency/viewUserMoney',{
      session: token,
      agencyId
    })
  },

  getInquiryList(token:string, status:number){
    return POST(ENDPOINT + '/inquiry/list',{
      session: token,
      status
    })
  },

  writeInquiryAnswer(token:string, inquiryId:number, answer:string){
    return POST(ENDPOINT + '/inquiry/answer',{
      session: token,
      inquiryId,
      answer
    })
  },

  submitInquiryAnswer(token:string, inquiryId:number, answer:string){
    return POST(ENDPOINT + '/inquiry/answer',{
      session: token,
      inquiryId,
      answer
    })
  },

  userWalletList(token:string, type:number){
    return POST(ENDPOINT + '/userWallet/list',{
      session: token,
      type
    })
  },

  userWalletAccept(token:string, historyId:number){
    return POST(ENDPOINT + '/userWallet/accept',{
      session: token,
      historyId
    })
  },

  userWalletReject(token:string, historyId:number){
    return POST(ENDPOINT + '/userWallet/reject',{
      session: token,
      historyId
    })
  },

  getPointHistory(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchStartDate:string, searchEndDate:string, type:number=0){
    return POST(ENDPOINT + '/point/history',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      startDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      endDate:searchEndDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      type
    })
  },

  getAgencyPointHistory(token:string, page:number, pageNum:number, searchAgencyName:string, searchUserNickname:string, searchStartDate:string, searchEndDate:string, type:number=0){
    return POST(ENDPOINT + '/agency/point/history',{
      session: token,
      page,
      pageNum,
      searchAgencyName,
      searchUserNickname,
      startDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      endDate:searchEndDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      type
    })
  },

  rakebackUserList(token:string, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/rakeback/userList',{
      session: token,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  rakebackListForAgency(token:string, searchUserNickname:string, searchUserId:string, searchStartDate:string, searchEndDate:string, page:number, pageNum:number, searchType:number=0){
    return POST(ENDPOINT + '/agency/rakeback/list',{
      session: token,
      searchUserNickname,
      searchUserId,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      page,
      pageNum,
      searchType
    })
  },

  rakebackList(token:string, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchStartDate:string, searchEndDate:string, page:number, pageNum:number, searchType:number=0){
    return POST(ENDPOINT + '/rakeback/list',{
      session: token,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      page,
      pageNum,
      searchType
    })
  },

  getRakebackUser(token:string, searchUserId:number, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/rakeback/get',{
      session: token,
      searchUserId,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchStartDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  getStandaloneRequests(session:string, searchStartDate:string, searchEndDate:string, searchStatus:string, searchAgencyName:string, searchUserNickname:string, searchType:string, page:number, pageNum:number){
    return POST(ENDPOINT + '/standalone/requests',{
      session: session,
      searchStartDate : searchStartDate ? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate : searchEndDate ? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchStatus,
      searchAgencyName,
      searchUserNickname,
      searchType,
      page,
      pageNum
    })
  },

  processStandaloneRequest(session:string, requestId:number, status:string, memo:string){
    return POST(ENDPOINT + '/standalone/request/process',{
      session: session,
      requestId,
      status,
      memo
    })
  },

  editStandaloneBankInfo(session:string, bank:string, bankAccount:string, bankAccountHolder:string, usdtAddress:string, usdtNetwork:string){
    return POST(ENDPOINT + '/standalone/bank/edit',{
      session: session,
      bank,
      bankAccount,
      bankAccountHolder,
      usdtAddress,
      usdtNetwork
    })
  },

  cancelStandalonePayment(session:string, requestId:number){
    return POST(ENDPOINT + '/standalone/payment/cancel',{
      session: session,
      requestId
    })
  },

  changeUserPassword(session:string, userId:number, password:string){
    return POST(ENDPOINT + '/admin/user/password',{
      session: session,
      userId,
      password
    })
  },

  forceLogin(session:string, agencyId:number){
    return POST(ENDPOINT + '/agency/login/link',{
      session: session,
      agencyId
    })
  },

  getStandaloneAdjustmentHistory(session:string, page:number, pageNum:number, searchStartDate:string, searchEndDate:string){
    return POST(ENDPOINT + '/standalone/adjustmoney/history',{
      session: session,
      page,
      pageNum,
      searchStartDate:searchStartDate? moment(searchStartDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
      searchEndDate:searchEndDate? moment(searchEndDate).utc().format("YYYY-MM-DD HH:mm:ss") : "",
    })
  },

  sendStandaloneAdjustmentMoney(session:string, userId:number, money:number, memo:string){
    return POST(ENDPOINT + '/standalone/user/adjustmoney',{
      session: session,
      userId,
      money,
      memo
    })
  },

  getUserlistForStandaloneAdjust(session:string, nicknameOrUid:string){
    return POST(ENDPOINT + '/standalone/user/list',{
      session: session,
      nicknameOrUid,
    })
  }
};

export default AdminAPI;
