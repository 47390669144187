import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ContentHeader } from "@components";
import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { RakePercentage } from "@app/dataset";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { sha256 } from "js-sha256";
import CreateAgencyPopupSubHQ from "@app/components/popup/CreateAgencyPopupSubHQ";
import { useSelector } from "react-redux";

type Info = {
    agencyId: number,
    name: string,
    egg: number,
    isSubHQ: boolean,
    userDisplayMemo: string,
}
type Hierarchy = {
    info: Info,
    children: { [key: string]: Hierarchy }
}

const Tr = styled.tr<{ depth: number }>`
    background-color: ${p => `rgba(0,0,0,${p.depth / 20});`};
`;

const PrintHierarcy = ({
    hierarchyInfo,
    nohead,
    depth,
    setShowUpdateAgency
}: {
    hierarchyInfo: Hierarchy,
    nohead?: boolean,
    depth: number,
    setShowUpdateAgency: (agencyId: number) => void
}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const padding = 200 - depth * 20
    return <div style={{ flex: 1 }}>
        <table className="table" style={{ flex: 1 }}>
            {nohead ? <></> :
                <thead>
                    <tr>
                        <th style={{ border: 0 }}>ID</th>
                        <th style={{ border: 0 }}>{t("이름")}</th>
                        <th style={{ border: 0 }}>{t("현재 보유 포인트")}</th>
                        <th style={{ border: 0 }}>{t("비고")}</th>
                        <th style={{ border: 0 }}>{t("통계")}</th>
                    </tr>
                </thead>
            }
            <tbody>
                {hierarchyInfo && hierarchyInfo.children &&
                    Object.entries(hierarchyInfo.children).map(([agencyId, _item]) => <>
                        <Tr key={agencyId} depth={depth}>
                            <td style={{ width: `${padding}px` }}>@{_item.info.agencyId}</td>
                            <td style={{ width: `150px` }}>{_item.info.name}</td>
                            <td style={{ width: `300px` }}>{Number(_item.info.egg).toLocaleString()}</td>
                            <td>{_item.info.userDisplayMemo}</td>
                            <td>{!_item.info.isSubHQ ? <>
                                <i style={{ cursor: 'pointer' }} className='fas fa-chart-area' onClick={()=>{ navigate("/agency/status?agencyId="+_item.info.agencyId) }}></i>
                            </>:<></> }</td>
                            {/* <td>{_item.info.isSubHQ ?
                                <i style={{ cursor: 'pointer' }} className='fas fa-edit' onClick={()=>{ setShowUpdateAgency(_item.info.agencyId) }}></i>
                            :<></>}</td> */}
                        </Tr>
                        {_item && Object.keys(_item.children).length > 0 && <tr>
                            <td style={{ padding: "0 0 0 0", }} colSpan={4}>
                                <div style={{ display: "flex" }}>
                                    <span><img style={{ width: "20px" }} src="/img/hirarchy_arrow.png" /></span>
                                    <PrintHierarcy hierarchyInfo={_item} nohead={true} depth={depth + 1} setShowUpdateAgency={setShowUpdateAgency} />
                                </div>
                            </td>
                        </tr>}
                    </>
                    )
                }
                {!hierarchyInfo || !hierarchyInfo.children || Object.entries(hierarchyInfo.children).length == 0 ? <tr>
                    <td colSpan={4}>{t("하부 계층이 없습니다.")}</td>
                </tr> : <></>}
            </tbody>
        </table>
    </div>
}

const TopInfoWrapper = styled.div`
    display: flex;
    padding: 10px;
    font-size: 16px;
    gap: 10px;
    border-bottom: 1px solid #ccc;
    flex-direction: row;
    .info{
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
`;

const HierarchyList = () => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [hierarchyInfo, setHierarchyInfo] = useState<Hierarchy>({ info: {}, children: {} } as Hierarchy)
    const [showCreatePopup, setShowCreatePopup] = useState(false);
    const [updateAgencyId, setUpdateAgencyId] = useState(0);
    const user = useSelector((state: any) => state.auth.currentUser);

    const getAgencyList = async () => {
        const result = await AdminAPI.getHierarchySubHQ(token)
        if (result.success) {
            setHierarchyInfo(result.hierarchy[result.agencyId])
        }
    }

    const setShowUpdateAgency = useCallback((agencyId: number) => {
        setUpdateAgencyId(agencyId);
        setShowCreatePopup(true);
    }, [])

    const onClickAddSubHQ = useCallback(() => {
        setUpdateAgencyId(0);
        setShowCreatePopup(true);
    }, [])

    useEffect(() => {
        getAgencyList()
    }, [])

    return <div>
        <ContentHeader title={t("소속 하부 계층")} />

        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            {hierarchyInfo && hierarchyInfo.info && <TopInfoWrapper>
                                <div className="info">
                                    <div><b>{hierarchyInfo.info.name}</b></div>
                                    <div>{t("보유 홀덤 포인트 : ")}{Number(hierarchyInfo.info.egg).toLocaleString()}</div>
                                    <div>{t("비고 : ")}{hierarchyInfo.info.userDisplayMemo}</div>
                                </div>
                                {user.enableCreateSub && <button type="button" className="btn btn-primary btn-sm" onClick={onClickAddSubHQ}>{t("하부 생성")}</button>}
                            </TopInfoWrapper>}

                            <div className="card-body table-responsive p-0" style={{ height: 790 }}>
                                <PrintHierarcy hierarchyInfo={hierarchyInfo} depth={0} setShowUpdateAgency={setShowUpdateAgency} />
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
        {showCreatePopup && <CreateAgencyPopupSubHQ onClose={() => setShowCreatePopup(false)} onSuccess={() => {
            getAgencyList()
        }} updateAgencyId={updateAgencyId} />}
    </div>
}

export default HierarchyList;
