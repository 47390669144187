import { useEffect } from "react";
import Page from "./Page"
import { useParams, useSearchParams } from "react-router-dom";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';

const SalesStatus = ({
  isAdmin
}:{isAdmin:boolean}) => {
  const {token} = useAppSelector(state => state.auth);
  const [searchParams, setSearchParams]  = useSearchParams();
  const agencyId = searchParams.get("agencyId");

  useEffect(function(){
    console.log(isAdmin , agencyId)
    if(isAdmin && agencyId){
      AdminAPI.agencyStatus(token, parseInt(agencyId), null, null)
    }
  },[isAdmin, agencyId])

  return <div>
    <Page />
  </div>
}

export default SalesStatus;
