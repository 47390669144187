import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import { useTranslation } from "react-i18next";

interface AdminLogPopupProps {
  onClose: () => void;
}

const RequestEggPopup = ({onClose}: AdminLogPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const eggAmountRef = useRef<HTMLInputElement>(null);

  const [uploading, setUploading] = useState(false);

  const handleCreateNotice = async () => {

    let eggAmount = Number(eggAmountRef.current!.value || 0)
    if(eggAmount > 0){
        setUploading(true);
        let v = await api.requestEgg(token, eggAmount);
        if(v.success){
            alert(t("충전 요청이 완료되었습니다."))
            onClose();
        }
    }else{
        alert(t("요청하는 알의 갯수는 0보다 커야합니다."))
    }
  };

  useEffect(function(){
  },[])

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("홀덤포인트 요청하기")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("요청 홀덤포인트 갯수")}</label>
            <div className="col-sm-9">
                <input ref={eggAmountRef} type="number" className="form-control" placeholder="0"/>
                <div className="form-group row">
                    <div className="offset-sm-3 col-sm-9">
                        <span style={{
                        fontSize: '12px',
                        }}>({t("홀덤포인트를 요청한 뒤 관리자에게 문의하세요")}.)</span>
                    </div>
                </div> 
            </div>
          </div>
        </div>

        {uploading ? <div>{t("업로드 중")}...</div> : 
        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
          <button type="button" className="btn btn-primary" onClick={handleCreateNotice}>{t("요청하기")}</button>
        </div> }

      </div>
    </div>
  </PopupContainer>
}
export default RequestEggPopup;
