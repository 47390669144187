import i18n from '@app/utils/i18n';
import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import moment from "moment";
import { useAppSelector } from "@store/hooks";
import "react-datepicker/dist/react-datepicker.css";
import DigitsColorWrapper from '@app/components/DigitsColorWrapper';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import AdminAPI from '@app/api/admin';
import { CurrencyMark } from '@app/conversionChip';

const UserCard = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover{
        background-color: #f9f9f9;
    }

    .name{
        font-size: 16px;
        font-weight: bold;
    }
    .agency{
        font-size: 12px;
        color: #666;
    }
    .userId{
        font-size: 10px;
        color: #aaa;
    }
`

const UserLists = styled.div`
    width: 100%;
    overflow-x: scroll;
    white-space: nowrap;
`

type Data = {
    type:number,
    userId: number,
    userNickname: string,
    agencyId: number,
    agencyName: string,
    groupId: number,
    roomId: number,
    games: number,
    betAmount: number,
    rake: string,
    addedAt: string,
    blind: number[],
    beforeAmount: number,
    afterAmount: number,
    currency: string,
    rakeFiat: number
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const UserRakebackLogList = ({isAgency}:{isAgency:boolean}) => {
    const { t } = useTranslation();
    const tableRef = useRef(null);
    const { token } = useAppSelector(state => state.auth);
    const [dataList, setDataList] = useState<Data[]>([])

    const [inputAgencyName, setInputAgencyName] = useState("")
    const [inputUserNickname, setInputUserNickname] = useState("")
    const [inputUserId, setInputUserId] = useState("")
    const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000)))
    const [inputEndDate, setInputEndDate] = useState(new Date((Date.now() - 0)))
    const [inputType, setInputType] = useState(0)

    const [searchAgencyName, setSearchAgencyName] = useState("")
    const [searchUserNickname, setSearchUserNickname] = useState("")
    const [searchUserId, setSearchUserId] = useState("")
    const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT))
    const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT))
    const [searchType, setSearchType] = useState(0)

    const [sum, setSum] = useState<{
        rake: number,
        rakeFiat: number,
        changeRake: number,
        changeFiat: number,
        betAmount: number
    }>({
        rake: 0,
        rakeFiat: 0,
        changeRake: 0,
        changeFiat: 0,
        betAmount: 0
    })

    const [duplicateUserList, setDuplicateUserList] = useState<{
        userId: number,
        userNickname: string,
        agencyId: number,
        agencyName: string
    }[]>([])

    const [page, setPage] = useState(0);
    const [pageNum, setPageNum] = useState(50);
    const [totData, setTotData] = useState(0);

    const fetchData = useCallback(async (token:string, searchAgencyName:string, searchUserNickname:string, searchUserId:string, searchStartDate:string, searchEndDate:string, page:number, pageNum:number, searchType:number) => {
        const res = isAgency ? 
            await AdminAPI.rakebackListForAgency(token, searchUserNickname, searchUserId, searchStartDate, searchEndDate, page, pageNum, searchType) : 
            await AdminAPI.rakebackList(token, searchAgencyName, searchUserNickname, searchUserId, searchStartDate, searchEndDate, page, pageNum, searchType);
        if (res.success) {
            setDataList(res.rows)
            setTotData(res.totalCount)
            setSum(res.sum)

            // Start Generation Here
            const nicknames: any = {};
            res.rows.forEach((item: any) => {
                nicknames[item.userNickname] = nicknames[item.userNickname] || {};
                nicknames[item.userNickname][item.userId] = true;
            });
            const duplicateNickname = Object.entries(nicknames).filter((v: any) => Object.keys(v[1]).length > 1);
            const duplicateUsers = duplicateNickname.map((item: any) =>
                res.rows.filter((v: any) => v.userNickname === item[0])
            );

            const uniqueUsers = duplicateUsers
                .flat()
                .filter((user: any, index: number, self: any[]) => 
                    index === self.findIndex((u) => u.userId === user.userId)
                );

            setDuplicateUserList(uniqueUsers);
        }
    }, [])

    useEffect(() => {
        fetchData(token, searchAgencyName, searchUserNickname, searchUserId, searchStartDate, searchEndDate, page, pageNum, searchType);    
    }, [fetchData, token, searchAgencyName, searchUserNickname, searchUserId, searchStartDate, searchEndDate, page, pageNum, searchType]);

    const onClickSearch = useCallback(function () {
        setSearchAgencyName(inputAgencyName)
        setSearchUserNickname(inputUserNickname)
        setSearchUserId(inputUserId)
        setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT))
        setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT))
        setSearchType(inputType)
    }, [inputAgencyName, inputUserNickname, inputUserId, inputStartDate, inputEndDate, inputType]);

    const onClickCancelSearch = useCallback(function () {
        setSearchAgencyName("");
        setSearchUserNickname("");
        setSearchUserId("");
        setSearchStartDate("");
        setSearchEndDate("");
        setInputAgencyName("");
        setInputUserNickname("");
        setInputUserId("");
        setInputType(0);
        setInputStartDate(new Date('2021-01-01 00:00:00'));
        setInputEndDate(new Date('2099-01-01 23:59:59'));
    }, [])

    return <div>
        <ContentHeader title={t("레이크백 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row mb-2'>
                                    {isAgency ? <></> : <>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("소속 에이전트")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => { setInputAgencyName(v.target.value) }} value={inputAgencyName} />
                                    </div>
                                    </>}
                                    
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("유저 닉네임")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => setInputUserNickname(v.target.value)} value={inputUserNickname} />
                                    </div>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("유저 아이디")}</span>
                                        </div>
                                        <input type="text" className="form-control" onChange={(v) => setInputUserId(v.target.value)} value={inputUserId} />
                                    </div>

                                </div>
                                <div className='row mb-2'>
                                    <div className="input-group col-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("타입")}</span>
                                        </div>
                                        <select className="form-control" onChange={(e) => setInputType(Number(e.target.value))} value={inputType}>
                                            <option value={0}>{t("All")}</option>
                                            <option value={1}>{t("적립")}</option>
                                            <option value={2}>{t("전환")}</option>
                                        </select>
                                    </div>
                                    <div className="input-group col-8">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("기간")}</span>
                                        </div>
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                                            onChange={(e) => {
                                                setInputStartDate(new Date(e.target.value));
                                            }}
                                        />
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                                            onChange={(e) => {
                                                setInputEndDate(new Date(e.target.value));
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                                let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("어제")}</button>
                                        </div>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                                let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("오늘")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-2'>
                                    <div className='col-12'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                                    </div>
                                </div>
                            </div>

                            <UserLists className='card-body'>
                                {duplicateUserList.map((user, index) => {
                                    return <UserCard key={index} onClick={() => {
                                        setInputAgencyName(user.agencyName)
                                        setInputUserNickname(user.userNickname)

                                        fetchData(token, user.agencyName, user.userNickname, searchUserId, searchStartDate, searchEndDate, page, pageNum, searchType)
                                    }}>
                                        <div className='name'>{user.userNickname}</div>
                                        <div className='agency'>{user.agencyName}</div>
                                        <div className='userId'>({user.userId})</div>
                                    </UserCard>
                                })}
                            </UserLists>

                            <div className="card-body table-responsive p-0" ref={tableRef} style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>{t("타입")}</th>
                                            <th>{t("블라인드")}</th>
                                            <th>{t("유저 아이디")}</th>
                                            <th>{t("유저 닉네임")}</th>
                                            <th>{t("소속 에이전트")}</th>
                                            <th>{t("방번호")}</th>
                                            <th>{t("핸드")}</th>
                                            <th>{t("배팅 금액")}</th>
                                            <th>{t("이전 레이크백")}</th>
                                            <th>{t("레이크백")}</th>
                                            <th>{t("전환")}</th>
                                            <th>{t("이후 레이크백")}</th>
                                            <th>{t("시각")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{t("합계")} : {Number(sum.betAmount || 0).toLocaleString()}</td>
                                            <td></td>
                                            <td>{t("합계")} : {Number(sum.rake || 0).toLocaleString()}</td>
                                            <td>{t("합계")} : {Number(sum.changeRake || 0).toLocaleString()}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {dataList.length === 0 ? <tr>
                                            <td colSpan={999}> {t("검색 결과가 없습니다")}.</td>
                                        </tr> : null}
                                        {
                                            dataList.map((item: Data, index) => {
                                                return <tr key={index} style={{
                                                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "white"
                                                }}>
                                                    <td>{item.type === 1 ? "적립" : "전환"}</td>
                                                    <td>{(item.blind || []).map((blind: number) => blind.toLocaleString()).join("-")}</td>
                                                    <td>{item.userId}</td>
                                                    <td>{item.userNickname}</td>
                                                    <td>{item.agencyName}</td>
                                                    <td>{item.roomId}</td>
                                                    <td>{item.games}</td>
                                                    <td>{Number(item.betAmount).toLocaleString()}</td>
                                                    <td>{Number(item.beforeAmount).toLocaleString()}</td>
                                                    <td>
                                                        {item.type === 1 ? Number(item.rake).toLocaleString() : 0}
                                                    </td>
                                                    <td>
                                                        {item.type === 2 ? <>
                                                            <span>{Number(item.rake||0).toLocaleString()}</span>
                                                            <span style={{color: "red", fontSize: "12px"}}>({CurrencyMark(item.currency)}{Number(item.rakeFiat).toLocaleString()})</span>
                                                        </>
                                                        : <>0</>}
                                                    </td>
                                                    <td>{Number(item.afterAmount).toLocaleString()}</td>
                                                    <td>{moment(item.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-left">
                                    <li className="page-item">
                                        <div className="page-link">
                                            {t("표시")}
                                        </div>
                                    </li>
                                    {
                                        [50, 100, 200].map((item, index) => {
                                            return <li key={index} className="page-item">
                                                <div className="page-link" style={
                                                    item === pageNum ? {
                                                        backgroundColor: 'lightgrey',
                                                        color: 'black'
                                                    } : {}
                                                } onClick={() => setPageNum(item)}>{item}</div>
                                            </li>
                                        })
                                    }
                                </ul>
                                <ul className="pagination pagination-sm m-0 float-right">
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            <i className="fas fa-chevron-left"></i>
                                        </a>
                                    </li>
                                    {
                                        Array(Math.ceil(totData / pageNum)).fill(0).map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <a style={{ backgroundColor: page == index ? "#ddd" : "#fff" }} className="page-link" href="#user-table" onClick={() => setPage(index)}>{index + 1}</a>
                                            </li>
                                        })
                                    }
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            <i className="fas fa-chevron-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default UserRakebackLogList;
