import React, {useEffect} from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import Main from '@modules/main/Main';
import Login from '@modules/login/Login';
import Register from '@modules/register/Register';
import ForgetPassword from '@modules/forgot-password/ForgotPassword';
import RecoverPassword from '@modules/recover-password/RecoverPassword';
import {useWindowSize} from '@app/hooks/useWindowSize';
import {calculateWindowSize} from '@app/utils/helpers';
import {useDispatch, useSelector} from 'react-redux';
import {setWindowSize} from '@app/store/reducers/ui';

import Dashboard from '@pages/Dashboard/Dashboard';
import Blank from '@pages/Blank';
import SubMenu from '@pages/SubMenu';
import Profile from '@pages/profile/Profile';

import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/PrivateRoute';
import CreateTournament from "@pages/CreateTournament";
import SignUp from "@pages/SignUp";
import TournamentList from "@pages/TournamentList";
import CreateRing from "@pages/CreateRing";
import RingList from "@pages/RingList";
import Agency from "@pages/Agency";
import BetHistory from "@pages/BetHistory";
import ManagementEgg from "@pages/ManagementEgg";
import UserList from "@pages/UserList";
import Notice from "@pages/Notice";
import SalesStatus from "@pages/SalesStatus";
import DashboardDeduction from "@pages/Dashboard/Deduction";
import DashboardEarn from "@pages/Dashboard/Earn";
import RequestEgg from '@pages/RequestEgg';
import GameRecord from './pages/GameRecord';
import GameRecordForAgency from './pages/GameRecordForAgency';
import RunningGames from './pages/RunningGames';
import UserAdjustMoney from './pages/UserAdjustMoney';
import UserAccessList from './pages/UserAccessList'
import BuyInOut from "./pages/BuyInOut";
import GameProfit from './pages/GameProfit';
import BuyinoutErrorLogs from './pages/BuyinoutErrorLogs';
import TournamentMember from "./pages/TournamentMember";
import GameProfitTournament from "./pages/GameProfitTournament";
import SendEgg from "./pages/SendEgg"
import SendSiteMoney from "./pages/SendSiteMoney"
import DuplicateIPAccess from "./pages/DuplicateIPAccess"
import AgencyRakeLogList from './pages/AgencyRakeLogList';
import GameRecordForSubHQ from './pages/GameRecordForSubHQ';

import SubHQ_Profits from './pages/subhq/Profits';
import SubHQ_Hierarchy from './pages/subhq/Hierarchy';

import AgencyWallet from './pages/AgencyWallet';

import AgencyExchangeList from "./pages/AgencyExchangeList"
import AgencyProfitList from './pages/AgencyProfitList';
import UserListForAgency from "./pages/UserListForAgency"

import { ROOM_TYPE } from './dataset';
import SubHQs from './pages/SubHQs';
import EggExchange from './pages/EggExchange';
import ForceAdjustList from './pages/ForceAdjustList';
import {useTranslation} from "react-i18next";
import i18next from "i18next";
import AdminAPI from './api/admin';
import { useAppSelector } from './store/hooks';
import { setExchangeRate } from './conversionChip';
import ExchangeInfo from './pages/ExchangeInfo';
import UserMoneyTracker from "./pages/UserMoneyTracker"
import AgencyWalletList from "./pages/AgencyWalletList"

import UserWallet from './pages/UserWallet';
import InquiryList from './pages/InquiryList';

import GameDetail from './pages/GameDetail';
import PointHistory from './pages/PointHistory';
import USDTHistory from './pages/UsdtHistory';
import ApiViewer from './pages/apidocument/ApiViewer';
import UserRakebackLogList from './pages/UserRakebackLogList';
import StandaloneExchange from './pages/StandaloneExchange';
import StandaloneAdjustmentMoney from './pages/StandaloneAdjustmentMoney';
// import ForceLogin from './pages/ForceLogin';

const App = () => {
  const {t} = useTranslation();
  const windowSize = useWindowSize();
  const screenSize = useSelector((state: any) => state.ui.screenSize);
  const dispatch = useDispatch();
  const {token} = useAppSelector(state => state.auth);

  useEffect(() => {
    const size = calculateWindowSize(windowSize.width);
    if (screenSize !== size) {
      dispatch(setWindowSize(size));
    }
  }, [windowSize]);

  useEffect(()=>{
    AdminAPI.getFiatExchangeRate(token).then((result)=>{
      setExchangeRate(1000, result.rate);
    });
  },[token])

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/game/detail/:roomId/:gameNumber">
          <Route path="/game/detail/:roomId/:gameNumber" element={<GameDetail />} />
        </Route>
        <Route path="/login" element={<PublicRoute/>}>
          <Route path="/login" element={<Login/>}/>
        </Route>
        <Route path="/register" element={<PublicRoute/>}>
          <Route path="/register" element={<Register/>}/>
        </Route>
        <Route path="/forgot-password" element={<PublicRoute/>}>
          <Route path="/forgot-password" element={<ForgetPassword/>}/>
        </Route>
        <Route path="/recover-password" element={<PublicRoute/>}>
          <Route path="/recover-password" element={<RecoverPassword/>}/>
        </Route>
        <Route path="/" element={<PrivateRoute/>}>
          <Route path="/" element={<Main/>}>
            <Route path="/create/room/tournament" element={<CreateTournament/>}/>
            <Route path="/create/room/ring" element={<CreateRing/>}/>
            <Route path="/signup" element={<SignUp/>}/>
            <Route path="/list/tournament" element={<TournamentList/>}/>
            <Route path="/list/ring" element={<RingList/>}/>
            <Route path="/sub-menu-2" element={<Blank/>}/>
            <Route path="/sub-menu-1" element={<SubMenu/>}/>
            <Route path="/blank" element={<Blank/>}/>
            <Route path="/profile" element={<Profile/>}/>
            <Route path="/agency" element={<Agency/>}/>
            <Route path="/subHQs" element={<SubHQs/>}/>

            <Route path="/betting/history" element={<BetHistory roomType={ROOM_TYPE.RING}/>}/>
            <Route path="/egg/management" element={<ManagementEgg/>}/>
            <Route path="/egg/request" element={<RequestEgg/>}/>
            <Route path="/egg/send" element={<SendEgg/>}/>
            <Route path="/sendsitemoney" element={<SendSiteMoney/>}/>
            <Route path="/forceAdjustment" element={<ForceAdjustList/>}/>
            <Route path="/user/list" element={<UserList/>}/>
            <Route path="/user/access" element={<UserAccessList isAgency={false}/>}/>
            <Route path="/agency/user/list" element={<UserListForAgency/>}/>
            <Route path='/agency/userMoneyTracker' element={<UserMoneyTracker isAgency={true} /> } />
            
            <Route path="/agency/user/access" element={<UserAccessList isAgency={true}/>}/>
            <Route path="/agencyWalletList" element={<AgencyWalletList/>}/>

            <Route path="/userWallet" element={<UserWallet/>}/>
            <Route path="/inquiry" element={<InquiryList/>}/>

            <Route path="/agency/standaloneExchange" element={<StandaloneExchange/>}/>
            <Route path="/agency/standaloneAdjustMoney" element={<StandaloneAdjustmentMoney/>}/>
            
            <Route path="/sales/status" element={<SalesStatus isAdmin={false}/>}/>
            <Route path="/agency/status" element={<SalesStatus isAdmin={true}/>}/>
            <Route path="/runningGames" element={<RunningGames/>}/>
            <Route path="/setting" element={<Blank/>}/>
            <Route path="/notice" element={<Notice/>}/>
            <Route path="/dashboard/recent/deduction" element={<DashboardDeduction/>}/>
            <Route path="/dashboard/recent/earn" element={<DashboardEarn/>}/>
            <Route path="/game/record" element={<GameRecord isAgency={false} roomType={ROOM_TYPE.RING} />}/>
            <Route path="/agency/game/record" element={<GameRecordForAgency isAgency={true} />}/>
            <Route path="/userAdjustment" element={<UserAdjustMoney/>}/>
            <Route path="/userMoneyTracker" element={<UserMoneyTracker isAgency={false}/>}/>
            
            <Route path="/tournament/gameProfit" element={<GameProfitTournament/>}/>
            <Route path="/BuyInOutLog" element={<BuyInOut/>}/>
            <Route path="/BuyinoutErrorLogs" element={<BuyinoutErrorLogs/>}/>
            <Route path="/gameProfit" element={<GameProfit/>}/>
            <Route path="/rakeList" element={<AgencyRakeLogList isAgency={false} title={t("유저 요율 상세내역")}/>}/>
            <Route path="/egg/exchange" element={<EggExchange/>}/>
            <Route path="/agencyProfitList" element={<AgencyProfitList />}/>

            <Route path="/rakebackList" element={<UserRakebackLogList isAgency={false}/>}/>
            <Route path="/agency/rakebackList" element={<UserRakebackLogList isAgency={true}/>}/>

            <Route path="/tournament/betting/history" element={<BetHistory roomType={ROOM_TYPE.TOURNAMENT}/>}/>
            <Route path="/tournament/game/record" element={<GameRecord isAgency={false} roomType={ROOM_TYPE.TOURNAMENT} />}/>

            <Route path="/tournament/member" element={<TournamentMember />}/>
            <Route path="/agency/tournament/member" element={<TournamentMember isAgency={true} />}/>

            <Route path="/user/duplicate/ip" element={<DuplicateIPAccess />}/>

            <Route path="/subhq/profits" element={<SubHQ_Profits />}/>
            <Route path="/subhq/hierarchy" element={<SubHQ_Hierarchy />}/>
            
            <Route path="/subhq/rakeList" element={<AgencyRakeLogList isAgency={false} title={""}/>}/>
            <Route path="/subhq/game/record" element={<GameRecordForSubHQ />}/>
            <Route path="/subhq/userAdjustment" element={<UserAdjustMoney/>}/>

            <Route path="/subhq/requestExchange" element={<AgencyExchangeList />}/>
            <Route path="/agency/requestExchange" element={<AgencyExchangeList />}/>
            <Route path="/agency/rakeList" element={<AgencyRakeLogList isAgency={true} title={""} />}/>
            <Route path="/exchangeInfo" element={<ExchangeInfo />}/>
            <Route path="/usdtHistory" element={<USDTHistory />}/>

            <Route path='/agency/wallet' element={<AgencyWallet/> }/>
            <Route path="/pointHistory" element={<PointHistory isAgency={false}/>}/>
            <Route path="/agency/pointHistory" element={<PointHistory isAgency={true}/>}/>
            <Route path="/apidocument/kr" element={<ApiViewer lang="kr" />} />
            <Route path="/apidocument/en" element={<ApiViewer lang="en" />} />
            <Route path="/" element={<Dashboard/>}/>
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        autoClose={3000}
        draggable={false}
        position="top-right"
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnHover
      />
    </BrowserRouter>
  );
};

export default App;
