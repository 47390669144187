import React, {useCallback, useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import RequestEggPopup from '@app/components/popup/RequestEggPopup';
import {useTranslation} from "react-i18next";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const RequestEgg = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [ summary, setSummary ] = useState<[string, string]>(["", ""]);
  const today = moment().utc().add(9, 'hours').format('YYYY-MM-DD');
  const [totRequest, setTotRequest] = useState(0);
  const [totAccepted, setTotAccepted] = useState(0);
  const [reqPageNum,setReqPageNum] = useState(15);

  const [showPopup, setShowPopup] = useState(false);

  const [reqPage,setReqPage] = useState(0);

  const [requestEggs,setRequestEggs] = useState<{
    reqId:number,
    agencyId:number,
    amount:number,
    uid:string,
    acceptedAdminId:number,
    canceledAdminId:number,
    requestedAt:string,
    agencyName:string,
    curEgg:number,
    memo:string
  }[]>();
  const [acceptedEggs,setAcceptedEggs] = useState<{
    reqId:number,
    agencyId:number,
    amount:number,
    acceptedAdminId:number,
    canceledAdminId:number,
    uid:string,
    requestedAt:string,
    acceptedAt:string,
    canceledAt:string,
    canceledAdminUid:string,
    agencyName:string,
    acceptedAdminUid:string,
    curEgg:number
  }[]>();

  const OnLoadRequestList = useCallback(function(){
    setRequestEggs(undefined);
    AdminAPI.myEggRequestList(token, reqPageNum*reqPage, reqPageNum).then(v=>{
      setRequestEggs(v.rows);
      setTotRequest(v.totalCnt)
    });
  },[token, reqPageNum, reqPage])

  const OnLoadSummary  = useCallback(function(){
    // AdminAPI.monthlySummaryEgg(token).then(v=>{
    //   setSummary([Number(v.request).toLocaleString() , Number(v.accept).toLocaleString()]);
    // })
  },[])

  useEffect(function(){
    OnLoadRequestList();
    OnLoadSummary();
  },[])

  useEffect(function(){
    OnLoadRequestList();
  },[reqPageNum, reqPage])

  return (
    <div>
        {showPopup && <RequestEggPopup onClose={() => { setShowPopup(false); OnLoadRequestList(); }}/> }

      <ContentHeader title={`${t("홀덤포인트 관리")}`}/>
      <section className="content">
        <div className="container-fluid">
          <div style={{display:"flex", justifyContent:"space-between"}}>
              <h4 className="mb-2 mt-1">
                  {t("충전 요청")} ({requestEggs ? totRequest : 0})
              </h4>
              <div>
                  <button type="button" className="btn btn-primary" onClick={()=>setShowPopup(true)}>{t("홀덤포인트 요청하기")}</button>
              </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body table-responsive p-0" style={{height: 890}}>
                  <table className="table table-head-fixed text-nowrap" id="request-table">
                    <thead>
                    <tr>
                      <th>{t("충전 요청")} ID</th>
                      <th>{t("신청시각")}</th>
                      <th>{t("소속 에이전시")}</th>
                      <th>{t("충전 요청 포인트")}</th>
                      <th>{t("현재 보유 포인트")}</th>
                      <th>{t("충전 후 예상 보유 포인트")}</th>
                      <th>{t("상태")}</th>
                      <th>{t("비고")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      requestEggs ? requestEggs.map((item, index) => {
                        return <tr key={index}>
                          <td>{item.reqId}</td>
                          <td>{moment(item.requestedAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                          <td>{item.agencyName}</td>
                          <td>{Number(item.amount).toLocaleString()}</td>
                          <td>{Number(item.curEgg).toLocaleString()}</td>
                          <td>{item.acceptedAdminId ? "-" : (Number(item.amount)+Number(item.curEgg)).toLocaleString()}</td>
                          <td>
                            {item.canceledAdminId ? <span className="badge bg-danger">{t("거절")}</span> : (!item.acceptedAdminId ? <span className="badge bg-info">{t("요청중")}</span> : <span className="badge bg-success">{t("승인됨")}</span>)}
                          </td>
                          <td>{item.memo}</td>
                        </tr>
                      }) : <><tr><td colSpan={9}> {t("로딩 중")} .. </td></tr></>
                    }
                    </tbody>
                  </table>
                </div>
                <div className="card-footer clearfix">
                  <ul className="pagination pagination-sm m-0 float-left">
                    <li className="page-item">
                      <div className="page-link">
                        {t("표시")}
                      </div>
                    </li>
                    {
                      [15, 30, 50].map((item, index) => {
                        return <li className="page-item">
                          <div className="page-link" style={
                            item === reqPageNum ? {
                              backgroundColor: 'lightgrey',
                              color: 'black'
                            } : {}
                          } onClick={()=>{setReqPageNum(item); setReqPage(0)}}>{item}</div>
                        </li>
                      })
                    }
                  </ul>
                  <ul className="pagination pagination-sm m-0 float-right">
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-chevron-left"></i>
                      </a>
                    </li>
                    {
                      Array(Math.ceil(totRequest/reqPageNum)).fill(0).map((item, index) => {
                        return <li className="page-item"><a className="page-link" href="#request-table" style={{backgroundColor:index==reqPage ? "#ddd" : "#fff"}}  onClick={()=>setReqPage(index)}>{index + 1}</a></li>
                      })
                    }
                    <li className="page-item">
                      <a className="page-link" href="#">
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

        </div>
      </section>
    </div>
  );
};

export default RequestEgg;
