function DigitsColorWrapper({
  number,
  color,
  fontSize,
  nonColor
}: {
  number: number,
  color?:string,
  fontSize?:number,
  nonColor?:boolean
}) {
    if(isNaN(number)) return <>{number}</>;

    const str = number.toLocaleString();
    const split = str.split(".");
    if(split.length == 1) return <>{str}</>;

    return <span style={{display:"inline-flex", "alignItems":"flex-end"}}>
        {!nonColor ? <>
          {split[0]} <span style={{color:color||"orange", fontSize:fontSize ? `${fontSize}px`: "" }}>.{split[1]}</span>
        </> : <>{split[0]}.{split[1]}</>}
    </span>
}

export default DigitsColorWrapper;