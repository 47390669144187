import i18n from '@app/utils/i18n';
import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useEffect, useState, useRef, useCallback } from "react";
import { sha256 } from "js-sha256";
import { RakePercentage } from "@app/dataset";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const RakeInputDiv = styled.div`
  display: inline-block;
  flex:1;
  margin-right: 5px;

  >div{
    font-size: 13px;
  }
`

const SimpleRakeDiv = styled.div`
  display: flex;
  gap: 10px;

  >div{
    flex:1;
    font-size: 11px;
  }
`

const RakeForm = styled.div`
  background-color: #f2f2f2;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  overflow-x: scroll;
  padding: 10px 0;
  margin: 10px 0;
`

const SimpleRakeTitle = styled.div`
  font-weight: bold;
  margin-top: 5px;
  font-size: 12px;
`

const RakeTypes = [
  "BET_RAKE", "HANDS_PENALTY", "TOURNAMENT_BUYIN",
]

function TypeKeyToString(key: string) {
  switch (key) {
    case "BET_RAKE": return i18n.t("베팅 요율")
    case "HANDS_PENALTY": return i18n.t("핸드 패널티")
    case "TOURNAMENT_BUYIN": return i18n.t("토너먼트 바이인")
  }
  return key;

}

interface CreateAgencyPopupProps {
  onClose: () => void;
  onSuccess: () => void;
  updateAgencyId: number | undefined;
}

export const _SimpleRakePrint = ({
  values,
  subHQList,
  head
}: {
  values: RakePercentage,
  subHQList: {
    agencyId: number,
    name: string,
  }[],
  head: boolean
}) => {
  const { t } = useTranslation();
  return <div style={{ textAlign: "left" }}>
    {RakeTypes.map((Key: any) => {
      const key = Key as keyof RakePercentage;
      let hh = values[key].hq;
      if (hh == null) {
        let tot = (values[key].subhq || []).reduce((a, b) => {
          a = a + b.percentage;
          return a;
        }, 0);

        hh = 100 - values[key].agency - tot;
      }

      return <>
        <SimpleRakeTitle>{TypeKeyToString(key)} {t("요율")}</SimpleRakeTitle>
        <SimpleRakeDiv key={key}>
          <div>A : <b>{values[key].agency}%</b></div>
          <div>H : <b>{hh}%</b></div>
          {(values[key].subhq || []).map(v => {
            const name = subHQList.find(vv => vv.agencyId == v.agencyId)?.name;
            return <div key={v.agencyId}><b>{name}</b> : <b>{v.percentage}%</b></div>
          })}
        </SimpleRakeDiv>
      </>
    })}
  </div>
}

export const SimpleRakeInput = ({
  betRake,
  lossPer,
  tournamentBuyinPer,
  parent,
  onChange
}: {
  betRake: number,
  lossPer: number,
  tournamentBuyinPer: number,
  parent: SubHQ | null | undefined,
  onChange: (r1: number, r2: number, r3: number) => boolean,
}) => {
  const { t } = useTranslation();

  const [i1, setI1] = useState(betRake.toString());
  const [i2, setI2] = useState(lossPer.toString());
  const [i3, setI3] = useState(tournamentBuyinPer.toString());

  useEffect(function () {
    setI1(betRake.toString())
    setI2(lossPer.toString())
    setI3(tournamentBuyinPer.toString())
  }, [betRake, lossPer, tournamentBuyinPer])

  return <div>
    {parent && <>
      <div style={{ color: "red", fontSize: "12px" }}><b>{t("상부 요율")} [{parent.name}]</b></div>
      <div style={{ fontSize: "12px", display: "flex", gap: '20px', marginBottom: "10px" }}>
        <div>{t("레이크 요율")} : {parent.rakePercentage.BET_RAKE.my || 0}</div>
        <div>{t("핸드 패널티 백분율")} : {parent.rakePercentage.HANDS_PENALTY.my || 0}</div>
        <div>{t("토너먼트 바이인 백분율")} : {parent.rakePercentage.TOURNAMENT_BUYIN.my || 0}</div>
      </div>
    </>}
    <div className="form-group row">
      <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("레이크 요율")}</label>
      <div className="col-sm-9">
        <input type="text" className="form-control"
          value={i1}
          onChange={(e) => setI1(e.target.value)}
          onBlur={(e) => {
            const t = onChange(Number(e.target.value), lossPer, tournamentBuyinPer);
            if (!t) setI1("0");
          }}
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("핸드 패널티 백분율")}</label>
      <div className="col-sm-9">
        <input type="text" className="form-control"
          value={i2}
          onChange={(e) => setI2(e.target.value)}
          onBlur={(e) => {
            const t = onChange(betRake, Number(e.target.value), tournamentBuyinPer);
            if (!t) setI2("0");
          }}
        />
      </div>
    </div>

    <div className="form-group row">
      <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("토너먼트 바이인 백분율")}</label>
      <div className="col-sm-9">
        <input type="text" className="form-control"
          value={i3}
          onChange={(e) => setI3(e.target.value)}
          onBlur={(e) => {
            const t = onChange(betRake, lossPer, Number(e.target.value));
            if (!t) setI3("0");
          }}
        />
      </div>
    </div>
  </div>
}

export const AdvancedRakeInput = ({
  values,
  subHQList,
  onChange,
}: {
  values: RakePercentage,
  subHQList?: {
    agencyId: number,
    name: string,
  }[],
  onChange: (v: RakePercentage) => void,
}) => {
  const { t } = useTranslation();
  return <>
    {RakeTypes.map((Key: any) => {
      const key = Key as keyof RakePercentage;
      return <div className="form-group row" key={key}>
        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{TypeKeyToString(key)} {t("요율")}</label>
        <div className="col-sm-9">
          <div className="input-group">
            <RakeInputDiv>
              <div>{t("에이전시")}(%)</div>
              <input type="number" className="form-control" value={values[key].agency} onChange={v => onChange({
                ...values,
                [key]: {
                  ...values[key],
                  agency: Math.floor(Number(v.target.value))
                }
              })} />
            </RakeInputDiv>

            <RakeInputDiv>
              <div>{t("본사")}(%)</div>
              <input type="number" className="form-control" value={values[key].hq} onChange={v => onChange({
                ...values,
                [key]: {
                  ...values[key],
                  hq: Math.floor(Number(v.target.value))
                }
              })} />
            </RakeInputDiv>

            {(values[key].subhq || []).map((item, index) => {
              const subhq = subHQList?.find(v => v.agencyId == item.agencyId);
              return <RakeInputDiv key={index}>
                <div><b>{subhq?.name}</b>(%)</div>
                <input type="number" className="form-control" value={item.percentage} onChange={v => {
                  const subhq = values[key].subhq;
                  subhq[index].percentage = Math.floor(Number(v.target.value));
                  onChange({
                    ...values,
                    [key]: {
                      ...values[key],
                      subhq: subhq
                    }
                  })
                }} />
              </RakeInputDiv>
            })}
          </div>
        </div>
      </div>
    })}
  </>
}

const CheckRakePercentage = (rakePercentage: RakePercentage) => {
  // 각각의 총합이 1이 되어야함.
  for (const Key in rakePercentage) {
    const key = Key as keyof RakePercentage;
    const subhq = rakePercentage[key].subhq.reduce((a, b) => {
      return a + b.percentage;
    }, 0)
    const sum = rakePercentage[key].agency + subhq + rakePercentage[key].hq;
    if (sum != 100) {
      alert(TypeKeyToString(key) + " " + i18n.t("요율의 합은 100이 되어야 합니다."));
      return false;
    }
  }
  return true;
}

type SubHQ = {
  agencyId: number,
  name: string,
  rakePercentage: RakePercentage
}

const CreateAgencyPopup = ({ onClose, onSuccess, updateAgencyId }: CreateAgencyPopupProps) => {
  const { t } = useTranslation();
  const { token } = useAppSelector(state => state.auth);
  const agencyNameRef = useRef<HTMLInputElement>(null);
  const agentNameRef = useRef<HTMLInputElement>(null);
  const uidRef = useRef<HTMLInputElement>(null);
  const phoneNumberRef = useRef<HTMLInputElement>(null);
  const callbackUrlRef = useRef<HTMLInputElement>(null);
  const apiKeyRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const memoRef = useRef<HTMLTextAreaElement>(null);
  const pointChargeRef = useRef<HTMLInputElement>(null);
  const agencyRadioRef = useRef<HTMLInputElement>(null);
  const subHQRadioRef = useRef<HTMLInputElement>(null);
  const standaloneOfflineRef = useRef<HTMLInputElement>(null);
  const bankNameRef = useRef<HTMLInputElement>(null);
  const accountNumberRef = useRef<HTMLInputElement>(null);
  const accountOwnerRef = useRef<HTMLInputElement>(null);
  const usdtNetworkRef = useRef<HTMLSelectElement>(null);
  const usdtAddressRef = useRef<HTMLInputElement>(null);
  const standaloneInGameRef = useRef<HTMLInputElement>(null);
  const enableCreateSubRef = useRef<HTMLInputElement>(null);
  // const subHQSelectRef = useRef<HTMLSelectElement>(null);
  const [renderUpdate, setRenderUpdate] = useState(0)
  const [subHQList, setSubHQList] = useState<SubHQ[]>([]);
  const [topAgencyId, setTopAgencyId] = useState(0);
  const [addSubHQ, setAddSubHQ] = useState(0);
  const [rakePercentage, setRakePercentage] = useState<RakePercentage>({
    "BET_RAKE": {
      subhq: [],
      hq: 0,
      agency: 0,
      my: 0,
    },
    "HANDS_PENALTY": {
      subhq: [],
      hq: 0,
      agency: 0,
      my: 0,
    },
    "TOURNAMENT_BUYIN": {
      subhq: [],
      hq: 0,
      agency: 0,
      my: 0,
    }
  });

  useEffect(function () {
    AdminAPI.getSubHQList(token).then(v => {
      setSubHQList(v.payload)
    })
  }, [])

  useEffect(function () {
    if (updateAgencyId) {
      AdminAPI.getAgencyInfo(token, updateAgencyId).then(v => {
        if (v.agency) {
          const agencyId = v.agency.agencyId.toString();
          const name = v.agency.name.toString();
          const agentName = v.agency.agentName.toString();
          const agentCallNumber = v.agency.agentCallNumber.toString();
          const egg = v.agency.egg.toString();
          const rakePercentage = v.agency.rakePercentage as RakePercentage;
          const memo = (v.agency.memo || "").toString();
          const addedAt = v.agency.addedAt.toString();
          const apiKey = v.agency.apiKey.toString();
          const uid = v.agency.uid.toString();
          const endpoint = (v.agency.endpoint || "").toString();
          const pointCharge = v.agency.standaloneAgency.toString();
          const bankInfo = v.agency.standaloneBankInfo || {};
          const standaloneOffline = v.agency.standaloneOffline.toString();
          const standaloneInGame = v.agency.standaloneInGame.toString();
          const enableCreateSub = (v.agency.enableCreateSub||0).toString();
          setTopAgencyId(v.agency.topAgencyId || 0);

          agencyNameRef.current!.value = name;
          agentNameRef.current!.value = agentName
          uidRef.current!.value = uid
          phoneNumberRef.current!.value = agentCallNumber
          passwordRef.current!.value = ""
          memoRef.current!.value = memo
          apiKeyRef.current!.value = apiKey
          pointChargeRef.current!.checked = pointCharge == "1"
          bankNameRef.current!.value = bankInfo.bank || ""
          accountNumberRef.current!.value = bankInfo.bankAccount || ""
          accountOwnerRef.current!.value = bankInfo.bankAccountHolder || ""
          usdtNetworkRef.current!.value = bankInfo.usdtNetwork || ""
          usdtAddressRef.current!.value = bankInfo.usdtAddress || ""
          standaloneOfflineRef.current!.checked = standaloneOffline == "1"
          standaloneInGameRef.current!.checked = standaloneInGame == "1"
          enableCreateSubRef.current!.checked = enableCreateSub == "1"
          setRakePercentage(rakePercentage)

          callbackUrlRef.current!.value = endpoint
          if (v.agency.isSubHQ == 1) {
            subHQRadioRef.current!.checked = true;
          } else {
            agencyRadioRef.current!.checked = true;
          }

          setRenderUpdate(Date.now())
        }
      })
    } else {
      agencyRadioRef.current!.checked = true;
    }
  }, [updateAgencyId])

  const onClickAddSubHQ = function () {
    if (addSubHQ == 0) return;
    const subhq = rakePercentage.BET_RAKE.subhq || [];
    const find = subhq.find(v => v.agencyId == addSubHQ);
    if (find) {
      alert(t("이미 추가된 부본사입니다."))
      return;
    }
    rakePercentage.BET_RAKE.subhq = rakePercentage.BET_RAKE.subhq || []
    rakePercentage.HANDS_PENALTY.subhq = rakePercentage.HANDS_PENALTY.subhq || []
    rakePercentage.TOURNAMENT_BUYIN.subhq = rakePercentage.TOURNAMENT_BUYIN.subhq || []

    rakePercentage.BET_RAKE.subhq.push({
      agencyId: addSubHQ,
      percentage: 0
    })
    rakePercentage.HANDS_PENALTY.subhq.push({
      agencyId: addSubHQ,
      percentage: 0
    })
    rakePercentage.TOURNAMENT_BUYIN.subhq.push({
      agencyId: addSubHQ,
      percentage: 0
    })
    setRakePercentage({ ...rakePercentage })
    setAddSubHQ(0);
  }

  const handleCreateAgency = async () => {
    if (!updateAgencyId) {
      if (uidRef.current!.value.length == 0)
        return alert(t("아이디를 입력해주세요."))
      if (passwordRef.current!.value.length == 0)
        return alert(t("비밀번호를 입력해주세요."))
    }

    if (subHQRadioRef.current!.checked == false && agencyRadioRef.current!.checked == false) {
      return alert(`${t("에이전시 구분을 선택해주세요")}.`)
    }

    const _isAgency = subHQRadioRef.current!.checked == false && agencyRadioRef.current!.checked == true;
    // if (_isAgency) {
    //   const checkRake = CheckRakePercentage(rakePercentage);
    //   if (checkRake == false) return;
    // }

    if (topAgencyId != 0) {
      let topAgency = subHQList.find(v => v.agencyId == topAgencyId);
      if (topAgency == null) {
        return alert(t("에이전시 선택이 잘못되어습니다. 다시 선택해주세요."));
      }
    }
    let cbUrl = callbackUrlRef.current!.value;
    cbUrl = cbUrl.trim();
    const result = await AdminAPI.createAgency({
      session: token,
      agencyName: agencyNameRef.current!.value,
      agentName: agentNameRef.current!.value,
      uid: uidRef.current!.value,
      phoneNumber: phoneNumberRef.current!.value,
      password: passwordRef.current!.value == "" ? "" : sha256(passwordRef.current!.value),
      rakePercentage: rakePercentage,
      memo: memoRef.current!.value,
      agencyId: updateAgencyId ? Number(updateAgencyId) : 0,
      endpoint: cbUrl,
      isAgency: _isAgency,
      topAgencyId: topAgencyId,
      pointCharge: pointChargeRef.current!.checked ? 1 : 0,
      standaloneOffline: standaloneOfflineRef.current!.checked ? 1 : 0,
      standaloneInGame: standaloneInGameRef.current!.checked ? 1 : 0,
      enableCreateSub: subHQRadioRef.current!.checked ? (enableCreateSubRef.current!.checked ? 1 : 0) : 0,
      bankInfo: {
        bank: bankNameRef.current!.value,
        bankAccount: accountNumberRef.current!.value,
        bankAccountHolder: accountOwnerRef.current!.value,
        usdtNetwork: usdtNetworkRef.current!.value,
        usdtAddress: usdtAddressRef.current!.value,
      }
    })

    if (result.success) {
      alert(updateAgencyId ? t('데이터가 수정되었습니다') : t('에이전시가 생성되었습니다'))
      onSuccess();
      onClose();
    } else {
      if (result.message == "BOTTOM_AGENCY_MORE_RAKE") {
        alert(t("변경하려는 상부보다 레이크가 높은 하부가 있습니다."))
        return;
      }
      alert(`${t("에이전시 생성에 실패하였습니다")}.\n` + JSON.stringify(result.payload))
    }
  }

  const handleChangeMySimpleRake = useCallback((r1: number, r2: number, r3: number) => {
    if (topAgencyId != 0) {
      let topAgency = subHQList.find(v => v.agencyId == topAgencyId);
      if (topAgency == null) {
        alert(t("에이전시 선택이 잘못되어습니다. 다시 선택해주세요."));
        return false;
      }

      if ((topAgency.rakePercentage.BET_RAKE.my || 0) < r1) {
        alert(t("베팅 요율은 상부보다 높을 수 없습니다."))
        return false;
      }
      if ((topAgency.rakePercentage.HANDS_PENALTY.my || 0) < r2) {
        alert(t("핸드 패널티는 상부보다 높을 수 없습니다."))
        return false;
      }
      if ((topAgency.rakePercentage.TOURNAMENT_BUYIN.my || 0) < r3) {
        alert(t("토너먼트 바이인은 상부보다 높을 수 없습니다."))
        return false;
      }
    } else {
      if (r1 > 4.5) {
        alert(t("베팅 요율은 4.5를 넘을 수 없습니다."))
        return false;
      }
      if (r2 > 100) {
        alert(t("핸드 패널티는 100을 넘을 수 없습니다."))
        return false;
      }
      if (r3 > 100) {
        alert(t("토너먼트 바이인은 100을 넘을 수 없습니다."))
        return false;
      }
    }

    setRakePercentage({
      "BET_RAKE": {
        subhq: [],
        hq: 0,
        agency: 0,
        my: r1,
      },
      "HANDS_PENALTY": {
        subhq: [],
        hq: 0,
        agency: 0,
        my: r2,
      },
      "TOURNAMENT_BUYIN": {
        subhq: [],
        hq: 0,
        agency: 0,
        my: r3,
      }
    })

    return true;
  }, [topAgencyId, subHQList])

  const handleChangeTopAgency = useCallback((agencyId: number) => {
    if (topAgencyId != agencyId) {
      if (!window.confirm(t("상부를 변경할 경우 수익 요율이 초기화됩니다. 다시 설정이 필요합니다. 상부를 변경하시겠습니까?"))) {
        return
      }
    }

    setTopAgencyId(agencyId);
    setRakePercentage({
      "BET_RAKE": {
        subhq: [],
        hq: 0,
        agency: 0,
        my: 0,
      },
      "HANDS_PENALTY": {
        subhq: [],
        hq: 0,
        agency: 0,
        my: 0,
      },
      "TOURNAMENT_BUYIN": {
        subhq: [],
        hq: 0,
        agency: 0,
        my: 0,
      }
    })
  }, [topAgencyId])

  const onChangeStandaloneOffline = () => {
    setTimeout(() => {
      if (standaloneInGameRef.current!.checked && standaloneOfflineRef.current!.checked) {
        standaloneInGameRef.current!.checked = false;
      }
      setRenderUpdate(Date.now())
    }, 50)
  }

  const onChangeStandaloneInGame = () => {
    setTimeout(() => {
      if (standaloneOfflineRef.current!.checked && standaloneInGameRef.current!.checked) {
        standaloneOfflineRef.current!.checked = false;
      }
      setRenderUpdate(Date.now())
    }, 50)
  }

  const isAgency = subHQRadioRef.current && agencyRadioRef.current && subHQRadioRef.current!.checked == false && agencyRadioRef.current!.checked == true;

  return <PopupContainer onClickBackdrop={onClose} maxWidth="700px">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{updateAgencyId ? t("에이전시 수정") : t("에이전시 생성")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body" style={{
          "maxHeight": "70vh",
          "overflow": "scroll",
        }}>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("구분")}</label>
            <div className="col-sm-9" style={{ display: "flex" }}>
              <div className="form-check form-check-inline">
                <input ref={agencyRadioRef} className="form-check-input" type="radio" name="inlineRadioOptions" id="agencyType" value="option1" onChange={v => setRenderUpdate(Date.now())} />
                <label className="form-check-label" htmlFor="agencyType">{t("에이전시")}</label>
              </div>

              <div className="form-check form-check-inline">
                <input ref={subHQRadioRef} className="form-check-input" type="radio" name="inlineRadioOptions" id="subHQ" value="option1" onChange={v => setRenderUpdate(Date.now())} />
                <label className="form-check-label" htmlFor="subHQ">{t("부본사")}</label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("상부")}</label>
            <div className="col-sm-9">
              <select className="form-control" value={topAgencyId} onChange={(e) => handleChangeTopAgency(Number(e.target.value))}>
                <option value={0}>{t("선택안함")}</option>
                {(subHQList || []).filter(v => v.agencyId != updateAgencyId).map((item, index) => {
                  return <option key={index} value={item.agencyId}>{item.name}</option>
                })}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{isAgency ? t("소속 에이전트") : t("부본사 명")}</label>
            <div className="col-sm-9">
              <input ref={agencyNameRef} type="text" className="form-control" placeholder={isAgency ? t("소속 에이전트") : t("부본사 명")} />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("이름")}</label>
            <div className="col-sm-9">
              <input ref={agentNameRef} type="text" className="form-control" placeholder={t("이름")} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("아이디")}</label>
            <div className="col-sm-9">
              <input ref={uidRef} type="text" className="form-control" placeholder={t("아이디")} disabled={!!updateAgencyId} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("전화번호")}</label>
            <div className="col-sm-9">
              <input ref={phoneNumberRef} type="text" className="form-control" placeholder={t("전화번호")} />
            </div>
          </div>

          <div className="form-group row" style={isAgency == false ? { display: "none" } : {}}>
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">api key</label>
            <div className="col-sm-9">
              <input ref={apiKeyRef} type="text" className="form-control" placeholder={t("자동발급")} disabled={true} />
            </div>
          </div>

          <div className="form-group row" style={isAgency == false ? { display: "none" } : {}}>
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("콜백")}URL</label>
            <div className="col-sm-9">
              <input ref={callbackUrlRef} type="text" className="form-control" placeholder={t("콜백") + "URL"} />
            </div>
          </div>


          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("비밀번호")}</label>
            <div className="col-sm-9">
              <input ref={passwordRef} type="password" className="form-control" placeholder={t("비밀번호")} />
            </div>
          </div>

          {/*isAgency ? <RakeForm>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">부본사 추가</label>
              <div className="col-sm-9">
                <select className="form-control" value={addSubHQ} onChange={(e) => setAddSubHQ(Number(e.target.value))}>
                  <option value={0}>선택안함</option>
                  {subHQList?.map((item, index) => {
                    return <option key={index} value={item.agencyId}>{item.name}</option>
                  })}
                </select>
                <button onClick={() => onClickAddSubHQ()}>추가</button>
              </div>
            </div>

            <AdvancedRakeInput values={rakePercentage} subHQList={subHQList} onChange={setRakePercentage} />
          </RakeForm> : */ <RakeForm>
              <SimpleRakeInput
                betRake={rakePercentage.BET_RAKE.my || 0}
                lossPer={rakePercentage.HANDS_PENALTY.my || 0}
                tournamentBuyinPer={rakePercentage.TOURNAMENT_BUYIN.my || 0}
                parent={topAgencyId > 0 ? subHQList.find(v => v.agencyId == topAgencyId) : null}
                onChange={handleChangeMySimpleRake} />
            </RakeForm>}

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("메모")}</label>
            <div className="col-sm-9">
              <textarea ref={memoRef} className="form-control" rows={7} placeholder="Enter ..." style={{
                height: '180px',
                resize: 'none'
              }}></textarea>
            </div>
          </div>

          <div className="form-group row" style={{ display: subHQRadioRef.current?.checked ? "flex" : "none" }}>
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("하부 생성 권한")}</label>
            <div className="col-sm-9">
              <input ref={enableCreateSubRef} type="checkbox" className="form-check-input" />
              <span style={{ fontSize: "12px" }}>
                {t("하부 에이전시를 생성할 수 있는 권한을 부여합니다.")}
              </span>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전 허용]")}</label>
            <div className="col-sm-9">
              <input ref={pointChargeRef} type="checkbox" className="form-check-input" onChange={() => {
                alert("포인트 충전 허용 기능은 내장 기능입니다. 해당 설정을 키게 되면 에이전시가 게임 내에서 직접 포인트 충환전을 진행 할 수 있게 됩니다.")
                setTimeout(() => {
                  setRenderUpdate(Date.now())
                }, 50)
              }} />
              <span style={{ fontSize: "12px" }}>
                {t("설정 시 사이트운용사가 아닌 내부 포인트를 충환전하는 에이전시로 전환됩니다.")}
              </span>
            </div>
          </div>

          <div style={{ display: pointChargeRef.current && pointChargeRef.current.checked ? "block" : "none" }}>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] 오프라인")}</label>
              <div className="col-sm-9">
                <input ref={standaloneOfflineRef} onChange={onChangeStandaloneOffline} type="checkbox" className="form-check-input" />
                <span style={{ fontSize: "12px" }}>
                  {t("충환전시 온라인 충전이 아닌, 현장에서 직접 결제하게 하는 기능으로 은행 정보등이 노출되지 않습니다.")}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] 인게임")}</label>
              <div className="col-sm-9">
                <input ref={standaloneInGameRef} onChange={onChangeStandaloneInGame} type="checkbox" className="form-check-input" />
                <span style={{ fontSize: "12px" }}>
                  {t("게임 내에 충전 버튼을 노출시킵니다. 별도의 충환전 사이트를 두고 운용할 때 사용됩니다. ")}
                </span>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] 은행명")}</label>
              <div className="col-sm-9">
                <input ref={bankNameRef} type="text" className="form-control" placeholder={t("은행명")} />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] 계좌번호")}</label>
              <div className="col-sm-9">
                <input ref={accountNumberRef} type="text" className="form-control" placeholder={t("계좌번호")} />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] 예금주")}</label>
              <div className="col-sm-9">
                <input ref={accountOwnerRef} type="text" className="form-control" placeholder={t("예금주")} />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] USDT 네트워크")}</label>
              <div className="col-sm-9">
                {/* <input ref={usdtNetworkRef} type="text" className="form-control" placeholder={t("USDT 네트워크")} /> */}
                <select ref={usdtNetworkRef} className="form-control">
                  <option value="TRC20">TRC20</option>
                  <option value="ERC20">ERC20</option>
                  <option value="BEP20">BEP20</option>
                  <option value="SOLANA">SOLANA</option>
                </select>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("[포인트 충전] USDT 주소")}</label>
              <div className="col-sm-9">
                <input ref={usdtAddressRef} type="text" className="form-control" placeholder={t("USDT 주소")} />
              </div>
            </div>




          </div>
        </div>

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
          <button type="button" className="btn btn-primary" onClick={handleCreateAgency}>{updateAgencyId ? t("수정하기") : t("생성하기")}</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}


export default CreateAgencyPopup;
