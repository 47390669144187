function DiffDigitColorTxt({
  prev,
  after,
  color,
}: {
  prev: string,
  after: string,
  color?:string,
}) {
  console.log(prev, after);
  let isRed = false;
  return (after.split("").map((v,i)=>{
    const diff = Number(v) - Number(prev[i] ? prev[i] : "0");
    if(!isRed && (diff == 0 || isNaN(diff))) return <>{v}</>;
    isRed = true;
    return <span style={{color: color||"red"}}>{v}</span>;
  }))
}

export default DiffDigitColorTxt;