import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { sha256 } from "js-sha256";
import api from "../../api/admin"
import { useDispatch, useSelector } from "react-redux";
import { loadUser } from "@app/store/reducers/auth";
import {useTranslation} from "react-i18next"

interface AgencyDetailPopupProps {
    onClose: () => void;
}

const AgencyDetailPopup = ({ onClose }: AgencyDetailPopupProps) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.auth.currentUser);

    const { token } = useAppSelector(state => state.auth);
    const [requestEggAmount, setRequestEggAmount] = useState(0);
    const [bankName, setBankName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [accountHolder, setAccountHolder] = useState("");

    const [nowRequestAmount, setNowRequestAmount] = useState(0);

    const onUpdateRequestedAmount = useCallback(()=>{
        api.agencyExchangePendingAmount(token).then(v=>{
            setNowRequestAmount(v.amount);
        })
    },[])

    useEffect(function () {
        onUpdateRequestedAmount();  
    }, [])

    const onSetMillionAmount = ()=>{
        setRequestEggAmount(Math.floor(requestEggAmount/1)*1);
    }

    const onClickRequest = async () => {
        if(user.egg < requestEggAmount){
            alert(t("보유 포인트가 부족합니다."));
            return;
        }
        if(requestEggAmount <= 0 ){
            return alert(t("환전 신청 포인트를 입력해주세요."));
        }

        const amount = Math.floor(requestEggAmount/1)*1;
        const result = await AdminAPI.agencyExchangeRequest(
            token,
            amount,
            accountHolder,
            accountNumber,
            bankName,
        );

        const response = await AdminAPI.getInfo({session:token});
        dispatch(loadUser(response.payload));
        onUpdateRequestedAmount();

        if (result.success) {
            alert(t("환전 신청이 완료되었습니다."))
            onClose();
        } else {
            if(result.err == -1){
                return alert(t("보유 포인트가 부족합니다."))
            }
            alert(t("환전 신청에 실패하였습니다. 에러코드 : ") + JSON.stringify(result.err));
        }
    }

    return <PopupContainer onClickBackdrop={onClose}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{t("환전 신청")}</h4>
                    <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("환전 신청 포인트")}</label>
                        <div className="col-sm-9">
                            <input type="number" className="form-control" value={requestEggAmount} onChange={v=>setRequestEggAmount(Number(v.target.value))} onBlur={onSetMillionAmount} />
                            <div style={{fontSize:"11px"}}>
                                {(t("1포인트 단위 환전 가능 (보유 중인 포인트: {{egg}}포인트)", {egg:Number(user.egg).toLocaleString()}))}
                            </div>
                            <div style={{fontSize:"11px"}}>
                                {t("현재 환전 요청 중인 포인트: {{nowRequestAmount}}포인트", {nowRequestAmount: Number(nowRequestAmount).toLocaleString()})}
                            </div>
                            <div style={{fontSize:"11px"}}>
                                <a target="_blank" href='https://xe.com'>{t("환율 정보 확인하기")}</a>{t("(환율은 처리 시점이 아닌, 신청 시점의 환율이 적용됩니다.)")}
                            </div>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("은행명")}</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" value={bankName} onChange={v=>setBankName(v.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("계좌번호")}</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" value={accountNumber} onChange={v=>setAccountNumber(v.target.value)} />
                        </div>
                    </div>
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("예금주")}</label>
                        <div className="col-sm-9">
                            <input type="text" className="form-control" value={accountHolder} onChange={v=>setAccountHolder(v.target.value)} />
                        </div>
                    </div>
                </div>

                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
                    <button type="button" className="btn btn-primary" onClick={onClickRequest}>{t("요청하기")}</button>
                </div>
            </div>
        </div>
    </PopupContainer>
}
export default AgencyDetailPopup;
