import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import {CreateTournamentRoomInterface, RAKE_TYPE, ROOM_TYPE} from "@app/dataset";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";

const GAME_API_HOST = process.env.REACT_APP_GAME_API_SERVER_HOST as string;
const API_KEY = process.env.REACT_APP_TEST_AGENCY_API_KEY as string;
const TEST_USERID = Number(process.env.REACT_APP_TEST_USERID as string);

async function POST(endpoint:string,args:any){
  const headers = {
      'apikey': API_KEY,
      "Content-Type": "application/json",
  };
  let r = await fetch(GAME_API_HOST + endpoint, {
      method:"POST",
      body: JSON.stringify(args),
      headers: headers
  });

  return await r.json();
}
async function api_game_link(userId:number){
  return await POST("/game/link",{
      userId
  })
}
async function getGameLink(userid:number, roomId:number){
  let result = await api_game_link(userid);
  window.open(result.payload+"&roomId="+roomId, "_blank")
}


const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const RunningGames = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [allRoomNotice, setAllRoomNotice] = useState<{
    KR:string,
    JP:string,
    EN:string,
    THAI:string,
  }>({
    KR:"",
    JP:"",
    EN:"",
    THAI:"",
  });
  const [roomList, setRoomList] = useState<{
    roomId:number,
    groupId:number,
    data:any,
    groupData:any,
    playerCount:number,
    playerCountInputed:number,
    addedAt:string,
    noticeText:{
      KR:string,
      JP:string,
      EN:string,
      THAI:string,
    },
  }[]>([]);
  const [curTable, setCurTable] = useState<number>(0);
  const [cur, setCur] = useState("");

  useEffect(() => {
    AdminAPI.getRooms(token, 0, 1000).then((res) => {
        setRoomList(res.rows)
    })
  }, [])

  useEffect(() => {
    AdminAPI.statusGameServer(token).then((res) => {
        setCur(res.isLive)
        setCurTable(res.curTable);
    })
  }, [])

  const onClickChangePlayerCount = (roomId:number) => {
    let playerCount = (document.getElementById('player-count-input-'+roomId) as HTMLInputElement).value;

    let _playerCount = playerCount == "" ? Number(0) : Number(playerCount);
    if(_playerCount < 0 || isNaN(_playerCount)) {
        alert(t("플레이어 수는 0 이상의 숫자로 입력해주세요."));
        return;
    }

    AdminAPI.changePlayerCount(token, roomId, _playerCount).then((res) => {
        AdminAPI.getRooms(token, 0, 1000).then((res) => {
            setRoomList(res.rows)
        })
    })
  };

  const onClickSetAllRoomNotice = () => {
    if(!confirm(`${t("방 전체 공지를 변경하시겠습니까")}? \n`+allRoomNotice)) return;
    AdminAPI.setAllRoomNotice(token, allRoomNotice).then((res) => {
        AdminAPI.getRooms(token, 0, 1000).then((res) => {
          setRoomList([])
          AdminAPI.getRooms(token, 0, 1000).then((res) => {
              setRoomList(res.rows)
          })
          alert(`${t("방 전체 공지가 변경 및 전송되었습니다")}.`)
          setAllRoomNotice({
            KR:"",
            JP:"",
            THAI:"",
            EN:""
          })
        })
    })
  }

  // const onClickRoomNotice = (roomId:number) => {
  //   let notice = (document.getElementById('room-notice'+roomId) as HTMLInputElement).value;
  //   if(!confirm(`${t("방 공지를 변경하시겠습니까")}?\n`+notice)) return;
  //   AdminAPI.setRoomNotice(token, roomId, notice).then((res) => {
  //       AdminAPI.getRooms(token, 0, 1000).then((res) => {
  //         AdminAPI.getRooms(token, 0, 1000).then((res) => {
  //           setRoomList(res.rows)
  //         })
  //         alert(`${t("방 공지가 변경 및 전송되었습니다")}.`)
  //       })
  //   })
  // }

  return (
    <div>
      <ContentHeader title={t("진행 중인 게임")}/>
      <div className="card-header">
        <div className="row">
          {cur == "0" ? <button className='btn btn-primary' style={{margin:"0 20px 20px 10px"}} onClick={() => {
              if(!window.confirm(`${t("게임서버를 시작하시겠습니까")}?`)) return;
              let password = prompt(`${t("비밀번호를 입력해주세요")}.`);
              if(password != "rgmaing") {
                  alert(`${t("비밀번호가 틀렸습니다")}.`);
                  return;
              }

              setCur("");
              AdminAPI.runGameServer(token).then((res) => {
                  AdminAPI.statusGameServer(token).then((res) => {
                      setCur(res.isLive)
                      setCurTable(res.curTable);
                  })
              })
          }}>{t("게임서버 시작")}</button> : cur == "1" ? <button className='btn btn-danger' style={{margin:"0 20px 20px 10px"}}  onClick={() => {
              if(!window.confirm(`${t("게임서버를 강제 종료하시겠습니까")}?`)) return;

              let password = prompt(`${t("비밀번호를 입력해주세요")}.`);
              if(password != "rgmaing") {
                  alert(`${t("비밀번호가 틀렸습니다")}.`);
                  return;
              }

              setCur("");
              AdminAPI.stopGameServer(token).then((res) => {
                  AdminAPI.statusGameServer(token).then((res) => {
                      setCur(res.isLive)
                      setCurTable(res.curTable);
                  })
              })
          }}>{t("모든 방 다음판부터 중단")}</button> : <>{t("커맨드 입력 중")}..</>}
          <span>{t("현재 진행 중인 테이블 수")} : {curTable}</span>
        </div>
        <div className='row mb-2'>
          <div className="input-group col-10">
            <div className="input-group-prepend">
              <span className="input-group-text">{t("모든 방에 공지 날리기")}</span>
            </div>
            KR : <input type="text" className="form-control" onChange={(v)=>{setAllRoomNotice({
              ...allRoomNotice,
              KR:v.target.value
            })}} value={allRoomNotice.KR}/>
            JP : <input type="text" className="form-control" onChange={(v)=>{setAllRoomNotice({
              ...allRoomNotice,
              JP:v.target.value
            })}} value={allRoomNotice.JP}/>
            EN : <input type="text" className="form-control" onChange={(v)=>{setAllRoomNotice({
              ...allRoomNotice,
              EN:v.target.value
            })}} value={allRoomNotice.EN}/>
            THAI : <input type="text" className="form-control" onChange={(v)=>{setAllRoomNotice({
              ...allRoomNotice,
              THAI:v.target.value
            })}} value={allRoomNotice.THAI}/>
            <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSetAllRoomNotice()}>{t("전송")}</button>
          </div>
        </div>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">

                <div className="card-body table-responsive p-0" style={{
                  height: 700
                }}>
                  <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                      <th>{t("그룹아이디")}</th>
                      <th>{t("룸아이디")}</th>
                      <th>{t("블라인드")}</th>
                      <th style={{width:"200px"}}>{t("플레이어 수")}</th>
                      <th>{t("핸드수")}</th>
                      <th>{t("생성일자")}</th>
                      <th>{t("방 정지하기")}</th>
                      <th>{t("관전")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      roomList.map((item, index) => {
                        return <>
                          <tr key={index}>
                            <td>{item.groupId}</td>
                            <td>{item.roomId}</td>
                            <td>{item.groupData.blind ? (`${Number(item.groupData.blind[0]).toLocaleString()} - ${Number(item.groupData.blind[1]).toLocaleString()}`) : "토너먼트"}</td>
                            <td>
                              <div>{item.playerCount} (+{item.playerCountInputed ? item.playerCountInputed : `${t("직접 입력 없음")}`})</div>
                              <div style={{display:"flex"}}>
                                <input id={'player-count-input-'+item.roomId} type="text" style={{width: "100px"}} className='form-control' placeholder={t("직접 입력")}/>
                                <button className='btn btn-primary' onClick={()=>onClickChangePlayerCount(item.roomId)}>{t("적용")}</button>
                              </div>
                            </td>
                            <td>{item.data.handNumber}</td>
                            <td>{moment(item.addedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td><button className='btn btn-danger'>{t("다음 판 부터 정지")}</button></td>
                            <td><button className='btn btn-warning' onClick={()=>{
                              getGameLink(TEST_USERID, item.roomId);
                            }}>{t("관전")}</button></td>
                          </tr>
                          <tr key={"notice"+index} style={{borderBottom:"3px solid #ddd"}}>
                            <td colSpan={7}>
                              <div style={{display:"flex", alignItems:"center"}}>
                                <div style={{marginRight:"10px"}}>{t("공지 중인 내용")} </div>
                                <textarea id={'room-notice'+item.roomId} style={{width: "100%", maxWidth:"400px"}} className='form-control'>{JSON.stringify(item.noticeText,null,4)}</textarea>
                                {/* <button className='btn btn-primary' onClick={()=>onClickRoomNotice(item.roomId)}>{t("전송")}</button> */}
                              </div>
                            </td>
                          </tr>
                        </>
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RunningGames;
