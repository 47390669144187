import {ContentHeader} from "@components";
import React, {memo, useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import { CurrencyMark } from "@app/conversionChip";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";

const CallbackTd = styled.td`
  >div{
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0 2px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
  .success{
    color: white;
    background-color: green;
  }
  .failed{
    background-color: red;
    color: black;
  }
  .timeout{
    background-color: yellow;
    color: black;
  }
`

const TrackJsonContainer = styled.div`
    display: inline-flex;
    align-items: center;
    gap: 18px;

    >div{
        font-size: 12px;
        border-radius: 5px;
        border:1px solid #ffc3c3;
        background-color: rgba(255, 195, 195,0.2);
        padding: 10px;

        div.type{
            font-weight: bold;
        }

        div.extra{
          border-top: 1px solid #ccc;
        }
    }
`

const BackColorTr = styled.tr<{backColor:string}>`
  background-color: ${p=>p.backColor};
`
export enum MONEY_TRACK_STATUS{
  IN_ROOM,
  IN_VAULT,
  OUT_GAME
}

export enum MONEY_TRACK_TYPE{
  CREATED, 
  MOVE_TO_ROOM,
  MOVE_TO_VAULT,
  ADDED_BUYIN,
  SETTLEMENT
}

export interface TrackJson {
    type:MONEY_TRACK_TYPE
    groupId:number,
    roomId:number,
    chipAmount:number,
    fiatAmount:number,
    addedAt:string,
    extra?:any
}

interface DataDetail {
    trackId:number,
    userId:number,
    agencyId:number,
    startGroupId:number,
    startRoomId:number,
    status:MONEY_TRACK_STATUS,
    groupId:number,
    roomId:number,
    currency:string,
    startAmount:number,
    startAmountFiat:number,
    endAmount:number,
    endAmountFiat:number,
    startedAt:string,
    endedAt:string,
    track:TrackJson[],
    nickname:string,
    agencyName:string
    blind:string[]
    groupType:number
}

export function PrintCurrency(currency:string, money:any){
  money = Number(money);
  if(isNaN(money)){
    return CurrencyMark(currency) + "0";
  }

  if(money < 0){
    return <>-{CurrencyMark(currency)}<DigitsColorWrapper number={Math.abs(money)} /></>
  }
  return <>{CurrencyMark(currency)}<DigitsColorWrapper number={money}/></>;
}

function AllCurrency(users:DataDetail[]){
  return Array.from(new Set(users.map(c=>c.currency)))
}

function PrintOnlyCurrency(currency:string, ff:string, users:DataDetail[]):number{
  return users.filter(v=>v.currency === currency).map((v:any)=>isNaN(v[ff])?0:v[ff]).reduce((a,b:any)=>a+Number(b),0);
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const UserMoneyTracker = ({
  isAgency
}:{
  isAgency:boolean
}) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [totUser, setTotUser] = useState(0);
  const [users, setUsers] = useState<DataDetail[]>();
  const user = useSelector((state: any) => state.auth.currentUser);

  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")
  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))
  const [inputGameType, setInputGameType ] = useState(0)

  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))
  const [searchGameType, setSearchGameType ] = useState(inputGameType)

  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const OnLoad = useCallback(function(){
    setUsers(undefined);
    AdminAPI.userMoneyTracker(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchUserId, searchGameType, searchStartDate, searchEndDate ).then(v=>{
      let d:DataDetail[] = v.rows.map((v:any)=>{
        return {
          ...v
        }
      });

      setUsers(d)
      setTotUser(v.totalCnt)
    })
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    searchGameType,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    searchGameType,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
  ])

  const onClickSearch = useCallback(function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserId(inputUserId);
    setSearchUserNickname(inputUserNickname);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
    setSearchGameType(inputGameType);
  },[
    inputAgencyName,
    inputUserId,
    inputUserNickname,
    inputStartDate,
    inputEndDate,
    inputGameType
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputAgencyName("");
    setInputUserId("");
    setInputUserNickname("");
    setInputGameType(0);
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));

    setSearchAgencyName("");
    setSearchUserId("");
    setSearchUserNickname("");
    setSearchStartDate("");
    setSearchEndDate("");
    setSearchGameType(0);
  },[])

  const TR_COLOR = ["#eee", "#fff"];

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}

    <ContentHeader title={t("유저 정산 내역")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
            <div className="card-header">
                <div className='row mb-2'>
                  {!isAgency ? <>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>{setInputAgencyName(v.target.value)}} value={inputAgencyName}/>
                  </div>
                  </>:<></>}

                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserNickname(v.target.value)} value={inputUserNickname}/>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserId(v.target.value)} value={inputUserId}/>
                  </div>
                </div>
                <div className='row'>

                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("블라인드")}</span>
                    </div>
                    <select className="form-control" onChange={(e)=>setInputGameType(parseInt(e.target.value))} value={inputGameType}>
                      <option value="0">{t("전체")}</option>
                      <option value="1">1,000-2,000</option>
                      <option value="2">2,500-5,000</option>
                      <option value="3">5,000-10,000</option>
                      <option value="4">10,000-25,000</option>
                      <option value="5">25,000-50,000</option>
                      <option value="6">50,000-100,000</option>
                    </select>
                  </div>

                  <div className="input-group col-6">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <input type="datetime-local" className="form-control"
                          value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputEndDate(new Date(e.target.value));
                          }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("어제")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("오늘")}</button>
                    </div>
                  </div>

                  <div className='col-3'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th style={{width:"70px",textAlign:"center"}}>#</th>
                    <th style={{width:"100px"}}>{t("닉네임/에이전시")}</th>
                    <th style={{width:"70px",textAlign:"center"}}>{t("상태")}</th>
                    <th style={{width:"70px",textAlign:"center"}}>{t("시작 방정보")}</th>
                    <th style={{width:"120px"}}>{t("바이인 시각")}</th>
                    <th style={{width:"200px"}}>{t("바이인 금액")}</th>
                    <th style={{width:"200px"}}>{t("총 추가 바이인")}</th>
                    <th style={{width:"120px"}}>{t("바이아웃 시각")}</th>
                    <th style={{width:"200px"}}>{t("바이아웃 금액")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {/* <tr>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>
                      {AllCurrency(users||[]).map((item, index) => {
                        const buyin = PrintOnlyCurrency(item, "buyin", users||[]);
                        const buyinChip = PrintOnlyCurrency(item, "buyinChip", users||[]);
                        return <div>{Math.abs(buyinChip).toLocaleString()}HP ({PrintCurrency(item, Math.abs(buyin))})</div>
                      })}
                    </th>
                    <th>-</th>
                    <th>
                      {AllCurrency(users||[]).map((item, index) => {
                        const seatOutPenalty = PrintOnlyCurrency(item, "seatOutPenalty", users||[]);
                        const seatOutPenaltyChip = PrintOnlyCurrency(item, "seatOutPenaltyChip", users||[]);
                        return <div>{Math.abs(seatOutPenaltyChip).toLocaleString()}HP ({PrintCurrency(item, Math.abs(seatOutPenalty))})</div>
                      })}
                    </th>
                    <th>
                      {AllCurrency(users||[]).map((item, index) => {
                        const seatOutMoney = PrintOnlyCurrency(item, "seatOutMoney", users||[]);
                        const seatOutMoneyChip = PrintOnlyCurrency(item, "seatOutMoneyChip", users||[]);
                        return <div>{Math.abs(seatOutMoneyChip).toLocaleString()}HP ({PrintCurrency(item, Math.abs(seatOutMoney))})</div>
                      })}
                    </th>
                    <th>
                    {AllCurrency(users||[]).map((item, index) => {
                        const seatOutMoney = PrintOnlyCurrency(item, "seatOutMoney", users||[]);
                        const buyin = PrintOnlyCurrency(item, "buyin", users||[]);

                        const buyinChip = PrintOnlyCurrency(item, "buyinChip", users||[]);
                        const seatOutMoneyChip = PrintOnlyCurrency(item, "seatOutMoneyChip", users||[]);

                        return <div>{(seatOutMoneyChip+buyinChip).toLocaleString()}HP ({PrintCurrency(item, (buyin+seatOutMoney))})</div>
                    })}
                    </th>
                    <th>-</th>
                    <th>-</th>
                  </tr> */}
                  {
                    users ? users.map((item, _index) => {
                      return <>
                        <BackColorTr key={_index} backColor={TR_COLOR[_index%2]} style={{ borderTop: "2px solid #ccc" }}>
                            <td style={{textAlign:"center"}}>{item.trackId}</td>
                            <td>
                                {item.nickname}<br/>({item.agencyName})
                            </td>
                            <td>
                                {(function(){
                                    switch(item.status){
                                        case MONEY_TRACK_STATUS.IN_ROOM:
                                            return <span className="badge bg-gradient-success">{t("플레이 중")}</span>
                                        case MONEY_TRACK_STATUS.IN_VAULT:
                                            return <span className="badge bg-gradient-lightblue">{t("로비")}</span>
                                        case MONEY_TRACK_STATUS.OUT_GAME:
                                            return <span className="badge bg-gradient-lime">{t("정산 완료")}</span>
                                        default:
                                            return <></>
                                    }
                                })()}
                            </td>
                            <td style={{textAlign:"center"}}>
                                {(item.groupType === 0 ? <>
                                    <span className="badge bg-gradient-lightblue">{t("토너먼트")}</span><br/>
                                </> : <>
                                    <span className="badge bg-gradient-lime">{t("링")}</span><br/>{(item.blind||[]).join("-")}
                                </>)}
                            </td>
                            <td>{moment(item.startedAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                            <td>
                                {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(item.startAmountFiat)} /><br/>
                                ({Number(item.startAmount).toLocaleString()}HP)
                            </td>
                            <td>
                                {(function(){
                                    let r = item.track.filter((v:TrackJson)=>v.type == MONEY_TRACK_TYPE.ADDED_BUYIN).reduce((a,b)=>{
                                        a.fiat += Number(b.fiatAmount)
                                        a.chip += Number(b.chipAmount)
                                        return a
                                    },{fiat:0, chip:0})

                                    return <>
                                        {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(r.fiat)} /><br/>
                                        ({Number(r.chip).toLocaleString()}HP)
                                    </>
                                })()}
                            </td>
                            <td>{item.endedAt ? moment(item.endedAt).local().format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                            <td>{item.endedAt ? <>
                                {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(item.endAmountFiat)} /><br/>
                                ({Number(item.endAmount).toLocaleString()}HP)
                            </> : "-"}</td>
                      </BackColorTr>
                      {item.track.length > 0 ?
                        <BackColorTr key={_index} backColor={TR_COLOR[_index%2]} style={{ borderTop: "2px solid #ccc" }}>
                            <td colSpan={1000}>
                                <TrackJsonContainer>
                                {item.track.map((v:TrackJson, idx:number)=>{
                                    return <><div key={idx}>
                                        <div className="type">
                                        {(function(){
                                            switch(v.type){
                                                case MONEY_TRACK_TYPE.CREATED : 
                                                    return t("바이인")
                                                case MONEY_TRACK_TYPE.MOVE_TO_ROOM : 
                                                    return t("착석")
                                                case MONEY_TRACK_TYPE.MOVE_TO_VAULT : 
                                                    return t("로비 이동")
                                                case MONEY_TRACK_TYPE.ADDED_BUYIN : 
                                                    return t("추가 바이인")
                                                case MONEY_TRACK_TYPE.SETTLEMENT : 
                                                    return t("정산 완료")
                                                default : 
                                                    return t("?")
                                            }
                                        })()}
                                        </div>
                                        <div className="date">
                                            {moment(v.addedAt).add(9, "hours").format("YYYY-MM-DD HH:mm:ss")}
                                        </div>
                                        <div className="amount">
                                            {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(v.fiatAmount)} /><br/>
                                            ({Number(v.chipAmount).toLocaleString()}HP)
                                        </div>
                                        {/* {(v.extra && v.type == MONEY_TRACK_TYPE.MOVE_TO_VAULT) && <div className="extra">
                                          <div>핸즈 : <b>{Number(v.extra.hands).toLocaleString()}</b></div>
                                          <div>총 배팅 : <b>{Number(v.extra.totBet).toLocaleString()}HP</b></div>
                                          <div>총 당첨 : <b>{Number(v.extra.totWin).toLocaleString()}HP</b></div>
                                        </div>} */}
                                        {(v.extra && v.type == MONEY_TRACK_TYPE.SETTLEMENT) && <div className="extra">
                                          <div>{t("핸즈")} : <b>{Number(v.extra.hands).toLocaleString()}</b></div>
                                          <div>{t("패널티")} : <b>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(v.extra.penaltyFiat)}/>({Number(v.extra.penalty).toLocaleString()}HP)</b></div>
                                          <div>{t("손익금")} : <b>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(v.extra.winFiat)}/>({Number(v.extra.win).toLocaleString()}HP)</b></div>
                                          <div>{t("배팅총액")} : <b>{Number(v.extra.result.betAmount).toLocaleString()}HP</b></div>
                                        </div>}
                                    </div>
                                    {idx != item.track.length-1 && <span key={`a${idx}`}>▶</span>}
                                    </>
                                })}
                                </TrackJsonContainer>
                            </td>
                        </BackColorTr> :
                        <></>}
                      </>
                      }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  {users ? users.length === 0 ? <tr><td colSpan={9}>{t("검색 결과가 없습니다")}.</td></tr> : <></> : <></>}
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                  </li>
                  {
                    Array(Math.ceil( totUser / pageNum )).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <a style={{backgroundColor:page==index?"#ddd":"#fff"}} className="page-link" href="#user-table" onClick={()=>setPage(index)}>{index + 1}</a>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
}

export default UserMoneyTracker;
