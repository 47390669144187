import React, {useCallback, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';

import {useDispatch, useSelector} from 'react-redux';
import {toggleControlSidebar, toggleSidebarMenu} from '@app/store/reducers/ui';
import UserDropdown from '@app/modules/main/header/user-dropdown/UserDropdown';
import styled from "styled-components";
import AdminAPI from '@app/api/admin';
import { useAppSelector } from '@app/store/hooks';
import {useTranslation} from "react-i18next";

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const HeaderItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: 1em;
  color: black;
`

const Header = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const {token} = useAppSelector(state => state.auth);
  const [curRoom, setCurRoom] = useState(0);
  const [curTournament, setCurTournament] = useState(0);
  const [curAccessUser, setCurAccessUser] = useState(0);
  const [todayEggRake, setTodayEggRake] = useState(0);
  const [allRake, setAllRake] = useState(0);
  const navbarVariant = useSelector((state: any) => state.ui.navbarVariant);
  const headerBorder = useSelector((state: any) => state.ui.headerBorder);

  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  const handleToggleControlSidebar = () => {
    dispatch(toggleControlSidebar());
  };

  // useEffect(function(){
  //   AdminAPI.headInfo(token).then((v:{
  //     curRoom:number,
  //     curTournament:number,
  //     todayEggRake:number,
  //     curAccessUser:number,
  //     allRake:number,
  //   })=>{
  //     setCurRoom(v.curRoom || 0);
  //     setCurTournament(v.curTournament || 0);
  //     setCurAccessUser(v.curAccessUser || 0);
  //     setTodayEggRake(v.todayEggRake || 0)
  //     setAllRake(v.allRake || 0)
  //   })
  // },[])

  const getContainerClasses = useCallback(() => {
    let classes = `main-header navbar navbar-expand ${navbarVariant}`;
    if (headerBorder) {
      classes = `${classes} border-bottom-0`;
    }
    return classes;
  }, [navbarVariant, headerBorder]);

  return (
    <nav className={getContainerClasses()}>
      <ul className="navbar-nav" style={{
        width: '100%'
      }}>
        <li className="nav-item">
          <button
            onClick={handleToggleMenuSidebar}
            type="button"
            className="nav-link"
          >
            <i className="fas fa-bars"/>
          </button>
        </li>
        {/* <HeaderRow>
          <HeaderItem>
            <div className=''>{t("방")}</div>
            <div className=''>{Number(curRoom).toLocaleString()}</div>
          </HeaderItem>
          <HeaderItem>
            <div className=''>{t("금일 레이크")}</div>
            <div className=''>{(Math.floor(Number(todayEggRake)*10)/10).toLocaleString()}{t("알")}</div>
          </HeaderItem>
          {allRake > 0 ? <>
            <HeaderItem>
              <div className=''>{t("총 레이크")}</div>
              <div className=''>{(Math.floor(Number(allRake)*10)/10).toLocaleString()}{t("알")}</div>
            </HeaderItem>
          </> : null }
          <HeaderItem>
            <div className=''>{t("접속자")}</div>
            <div className=''>{Number(curAccessUser).toLocaleString()}</div>
          </HeaderItem>
          <HeaderItem>
            <div className=''>{t("토너먼트")}</div>
            <div className=''>{Number(curTournament).toLocaleString()}</div>
          </HeaderItem>
        </HeaderRow> */}
      </ul>
      <ul className="navbar-nav ml-auto">
        <UserDropdown/>
      </ul>
    </nav>
  );
};

export default Header;
