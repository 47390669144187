import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import AgencyExchangeRequestPopup from "@app/components/popup/AgencyExchangeRequestPopup";
import i18n from "@app/utils/i18n";
import { useTranslation } from "react-i18next";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";

export enum ExchangeStatusType {
    Pending = 0,
    Accepted = 1,
    Rejected = 2
}

export function getExchangeStatusString(status: ExchangeStatusType) {
    switch (status) {
        case ExchangeStatusType.Pending:
            return i18n.t("대기중");
        case ExchangeStatusType.Accepted:
            return i18n.t("승인됨");
        case ExchangeStatusType.Rejected:
            return i18n.t("거절");
    }
}

const Agency = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const { token } = useAppSelector(state => state.auth);
    const user = useSelector((state: any) => state.auth.currentUser);
    const [requestModal, setRequestModal] = useState(false);
    const [pageNum, setPageNum] = useState(50);
    const [page, setPage] = useState(0);
    const [totCnt, setTotCnt] = useState(0);
    const [update, setUpdate] = useState(0);

    const [list, setList] = React.useState<{
        exId: number,
        agencyId: number,
        agencyName: string,
        requestAmount: number,
        hasAmount: number,
        afterAmount: number,
        statusCode: number,
        statusAt: string,
        statusMemo: string,
        addedAt: string,
        account_name: string,
        account_address: string,
        account_bankName: string,
    }[] | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await AdminAPI.agencyExchangeRequestList(token, page, pageNum);
            if (response.success) {
                setList(response.rows);
                setTotCnt(response.totalCnt)
            }
        }
        fetchData();
    }, [page, pageNum, update]);

    return <div>
        {requestModal ? <AgencyExchangeRequestPopup onClose={ ()=>{
            setRequestModal(false);
            setUpdate(Date.now())
        } } /> : null}
        <ContentHeader title={t("환전 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row mb-2'>
                                    <div className="offset-8 input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("환전 가능 보유 포인트")} : <DigitsColorWrapper number={Number(user.egg)} /></span>
                                        </div>
                                        <button className="btn btn-primary" onClick={() => setRequestModal(true)}>{t("환전 요청")}</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body table-responsive p-0" style={{ height: 790 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>{t("신청일")}</th>
                                            <th>{t("요청액")}</th>
                                            <th>{t("환전 후 보유 포인트")}</th>
                                            <th>{t("상태")}</th>
                                            <th>{t("상태 변경일")}</th>
                                            <th>{t("상태 메모")}</th>
                                            <th>{t("신청 계좌 정보")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list?.map(v => {
                                            return <tr key={v.exId}>
                                                <td>{v.agencyName}</td>
                                                <td>{moment(v.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                                                <td>{Number(v.requestAmount).toLocaleString()}</td>
                                                <td>{Number(v.afterAmount).toLocaleString()}</td>
                                                <td>{getExchangeStatusString(v.statusCode)}</td>
                                                <td>{v.statusAt ? moment(v.statusAt).local().format("YYYY-MM-DD HH:mm:ss") : ""}</td>
                                                <td>{v.statusMemo}</td>
                                                <td>
                                                    {t("은행명")} : {v.account_bankName}<br />
                                                    {t("계좌주")} : {v.account_name}<br />
                                                    {t("계좌번호")} : {v.account_address}
                                                </td>
                                            </tr>
                                        })}
                                        {list?.length === 0 && <tr>
                                            <td colSpan={8} className="text-center">{t("환전 내역이 없습니다.")}</td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-left">
                                    <li className="page-item">
                                        <div className="page-link">
                                            {t("표시")}
                                        </div>
                                    </li>
                                    {
                                        [50, 100, 200].map((item, index) => {
                                            return <li key={index} className="page-item">
                                                <div className="page-link" style={
                                                    item === pageNum ? {
                                                        backgroundColor: 'lightgrey',
                                                        color: 'black'
                                                    } : {}
                                                } onClick={() => setPageNum(item)}>{item}</div>
                                            </li>
                                        })
                                    }
                                </ul>
                                <ul className="pagination pagination-sm m-0 float-right">
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            <i className="fas fa-chevron-left"></i>
                                        </a>
                                    </li>
                                    {
                                        Array(Math.ceil(totCnt / pageNum)).fill(0).map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <a style={{ backgroundColor: page == index ? "#ddd" : "#fff" }} className="page-link" href="#user-table" onClick={() => setPage(index)}>{index + 1}</a>
                                            </li>
                                        })
                                    }
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            <i className="fas fa-chevron-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default Agency;
