import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup, {  } from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location, Link } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyRakeLogPopup from "@app/components/popup/AgencyRakeLogPopup";
import {useTranslation} from "react-i18next";


export function AgencyTable({
  agencyList,
  setShowCreateModal,
  setShowUpdateAgency,
  setAgencyRakeLogPopup
}:{
  agencyList:any[],
  setShowCreateModal?: (v:boolean) => void,
  setShowUpdateAgency: (v:number) => void,
  setAgencyRakeLogPopup: (v:number) => void
}) {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {token} = useAppSelector(state => state.auth);

  const [subHQList, setSubHQList] = useState<{
    agencyId: number,
    name: string,
  }[]>([]);

  useEffect(function () {
    AdminAPI.getSubHQList(token).then(v => {
      setSubHQList(v.payload)
    })
  }, [])

  return <div className="card-body table-responsive p-0">
    <table className="table table-head-fixed text-nowrap">
      <thead>
        <tr>
          <th>ID</th>
          <th>{t("이름")}</th>
          <th>{t("소속 에이전트")}</th>
          <th>{t("이번달 요율 수익")}</th>
          <th>{t("현재 보유 포인트")}</th>
          <th>{t("레이크 요율")}</th>
          <th>{t("통계")}</th>
          <th>
            {setShowCreateModal ?
              <button type="button" className="btn btn-block btn-primary btn-sm" onClick={() => {
                document.getElementById('modal-default')?.classList.add('show');
                setShowCreateModal(true);
              }}>{t("부본사/에이전시 생성")}</button>
            : null}
          </th>
        </tr>
      </thead>
      <tbody style={{backgroundColor:"white"}}>
        {
          agencyList.map((item: any, index: number) => {
            return <tr key={index}>
              <td>@{item.uid}</td>
              <td>{item.name}</td>
              <td>{item.agentName}</td>
              <td>
                {Math.floor((Number(item.currentMonthRake) * 10)/10).toLocaleString()}
                <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => setAgencyRakeLogPopup(item.agencyId)}>▲</span>
              </td>
              <td>{item.isSubHQ == 1 ? t("부본사") : ((Math.floor(Number(item.egg)*10))/10).toLocaleString()}</td>
              <td style={{ textAlign: 'center' }}>
                {/* <SimpleRakePrint head={true} subHQList={subHQList} values={item.rakePercentage} /> */}
                <div style={{textAlign:"left", fontSize:"12px"}}>
                  <div><b>{t("베팅 요율")}</b> : {item.rakePercentage.BET_RAKE.my||0}</div>
                  <div><b>{t("핸드 패널티")}</b> : {item.rakePercentage.HANDS_PENALTY.my||0}%</div>
                  <div><b>{t("토너먼트 바이인")}</b> : {item.rakePercentage.TOURNAMENT_BUYIN.my||0}%</div>
                </div>
              </td>
              <td style={{ textAlign: 'center' }}>
                <i style={{ cursor: 'pointer' }} className='fas fa-chart-area' onClick={() => { navigate("/agency/status?agencyId=" + item.agencyId) }}></i>
              </td>
              <td style={{ textAlign: 'center' }}>
                <i style={{ cursor: 'pointer' }} className='fas fa-edit' onClick={() => { setShowUpdateAgency(item.agencyId) }}></i>
              </td>
            </tr>
          })
        }
      </tbody>
    </table>
  </div>
}

const Agency = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { token } = useAppSelector(state => state.auth);
  const [agencyList, setAgencyList] = useState([])
  const [agencyTotalCount, setAgencyTotalCount] = useState(0)
  const [inputSearchAgencyName, setInputSearchAgencyName] = useState("");
  const [searchAgencyName, setSearchAgencyName] = useState("");
  const [showUpdateAgency, setShowUpdateAgency] = useState<number>()
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    maxPage: 0,
    paginationMin: 0,
    paginationMax: 0,
  })
  const [rowCount, setRowCount] = useState(15)
  const [agencyRakeLogPopup, setAgencyRakeLogPopup] = useState<number>()
  const getAgencyList = async (innerPageId: number, innerRowCount: number) => {

    const result = await AdminAPI.getAgencyList({
      session: token,
      pageId: innerPageId,
      rowCount: innerRowCount,
      searchAgencyName: searchAgencyName,
    })
    if (result.success) {
      setAgencyList(result.payload.list)
      setAgencyTotalCount(result.payload.totalCount)

      const maxPage = Math.ceil(result.payload.totalCount / innerRowCount)
      const paginationMin = Math.floor(innerPageId / 10) * 10
      const paginationMax = Math.min(paginationMin + 10, maxPage)
      setPaginationData({
        currentPage: innerPageId,
        maxPage: maxPage,
        paginationMin: paginationMin,
        paginationMax: paginationMax,
      })
    }
  }

  useEffect(() => {
    getAgencyList(paginationData.currentPage, rowCount)
  }, [paginationData.currentPage, rowCount, searchAgencyName])

  const onClickSearch = useCallback(function () {
    setSearchAgencyName(inputSearchAgencyName)
  }, [inputSearchAgencyName])

  const onClickCancelSearch = useCallback(function () {
    setInputSearchAgencyName("");
    setSearchAgencyName("");
  }, [])

  return <div>
    <ContentHeader title={t("에이전시 목록")} />
    {
      (showCreateModal || showUpdateAgency !== undefined) &&
      <CreateAgencyPopup
        updateAgencyId={showUpdateAgency}
        onSuccess={() => {
          getAgencyList(paginationData.currentPage, rowCount)
        }}
        onClose={() => { setShowCreateModal(false); setShowUpdateAgency(undefined) }} />
    }
    {agencyRakeLogPopup ? <AgencyRakeLogPopup agencyId={agencyRakeLogPopup} onClose={() => setAgencyRakeLogPopup(0)} /> : null}
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row'>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("에이전트 이름")}</span>
                    </div>
                    <input type="text" value={inputSearchAgencyName} onChange={(v) => setInputSearchAgencyName(v.target.value)} className="form-control" placeholder="Username" />
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div style={{ height: 790, overflow:"scroll" }}>
                <AgencyTable
                  agencyList={agencyList}
                  setShowCreateModal={setShowCreateModal}
                  setShowUpdateAgency={setShowUpdateAgency}
                  setAgencyRakeLogPopup={setAgencyRakeLogPopup}
                />
              </div>

              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link no-hover">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [15, 30, 50].map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                          cursor: 'pointer'
                        }} onClick={() => setRowCount(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                      onClick={() => {
                        if (paginationData.paginationMin < 10) {
                          return;
                        } else {
                          setPaginationData({
                            ...paginationData,
                            currentPage: paginationData.paginationMin - 1
                          })
                        }
                      }}>
                      <i className="fas fa-chevron-left"></i>
                    </div>
                  </li>
                  {
                    Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div
                          className={"page-link " + (index + paginationData.paginationMin === paginationData.currentPage ? 'selected' : '')}
                          style={{ cursor: 'pointer' }}
                          onClick={() => setPaginationData({
                            ...paginationData,
                            currentPage: index + paginationData.paginationMin
                          })}>{index + paginationData.paginationMin + 1}</div>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <div
                      className={"page-link " + (paginationData.paginationMax * rowCount > agencyTotalCount ? 'disabled' : '')}
                      onClick={() => {
                        setPaginationData({
                          ...paginationData,
                          currentPage: paginationData.paginationMax
                        })
                      }}>
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default Agency;
