import {ContentHeader} from "@components";
import React, {memo, useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import styled from "styled-components";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import DiffDigitColorTxt from "@app/components/DiffDigitColorTxt";
import { AgencyEggHistoryType } from "@app/dataset";

const BackColorTr = styled.tr<{backColor:string}>`
  background-color: ${p=>p.backColor};
`

const Table = styled.table`
  width: 100%;
  td{
    text-align: center;
  }
  th{
    text-align: center;
  }
`

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const HISTORY_TYPE_ORDER = [
  AgencyEggHistoryType.NONE,
  AgencyEggHistoryType.RAKE,
  AgencyEggHistoryType.BUYIN,
  AgencyEggHistoryType.REBUYIN,
  AgencyEggHistoryType.BUYOUT,
  AgencyEggHistoryType.CHARGE,
  AgencyEggHistoryType.UNCHARGE,
  AgencyEggHistoryType.WITHDRAW,
  AgencyEggHistoryType.WITHDRAW_REJECT,
  AgencyEggHistoryType.ADMINDIRECT,
  AgencyEggHistoryType.ADMINDIRECTMINUS,
  AgencyEggHistoryType.ERRORRECOVERY,
  AgencyEggHistoryType.MOVETOWALLET,
  AgencyEggHistoryType.TOURNAMENT_JOIN,
  AgencyEggHistoryType.TOURNAMENT_WIN,
  AgencyEggHistoryType.TOURNAMENT_JOIN_CANCEL,
  AgencyEggHistoryType.TOURNAMENT_CANCELED,
  AgencyEggHistoryType.RAKEBACK_WITHDRAWAL,
]
const MEMO_DATA: any = {
  [AgencyEggHistoryType.NONE]: "",
  [AgencyEggHistoryType.RAKE]: "레이크",
  [AgencyEggHistoryType.BUYIN]: "바이인",
  [AgencyEggHistoryType.BUYOUT]: "바이아웃",
  [AgencyEggHistoryType.REBUYIN]: "리바이인",
  [AgencyEggHistoryType.CHARGE]: "충전포인트",
  [AgencyEggHistoryType.WITHDRAW]: "환전포인트",
  [AgencyEggHistoryType.WITHDRAW_REJECT]: "환전 거절",
  [AgencyEggHistoryType.TOURNAMENT_JOIN]: "토너먼트 바이인",
  [AgencyEggHistoryType.TOURNAMENT_WIN]: "토너먼트 상금지급",
  [AgencyEggHistoryType.TOURNAMENT_JOIN_CANCEL]: "토너먼트 바이인 취소",
  [AgencyEggHistoryType.TOURNAMENT_CANCELED]: "토너먼트 취소",
  [AgencyEggHistoryType.ADMINDIRECT]: "관리자 수동 지급",
  [AgencyEggHistoryType.ADMINDIRECTMINUS]: "관리자 수동 차감",
  [AgencyEggHistoryType.UNCHARGE]: "충전포인트 거절",
  [AgencyEggHistoryType.ERRORRECOVERY]: "에러복구",
  [AgencyEggHistoryType.MOVETOWALLET]: "요율지갑 포인트 전환",
  [AgencyEggHistoryType.RAKEBACK_WITHDRAWAL]: "레이크백 포인트 전환",
}
const PointHistory = ({
  isAgency
}:{
  isAgency:boolean
}) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState(0);

  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))
  const [inputType, setInputType ] = useState("0")

  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))
  const [searchType, setSearchType ] = useState(inputType)
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    maxPage: 0,
    min: 0,
    max: 0,
  })

  const OnLoad = useCallback(async function(){
    let v:any;
    if(isAgency){
      v = await AdminAPI.getAgencyPointHistory(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchStartDate, searchEndDate, Number(searchType))
      
    }else{
      v = await AdminAPI.getPointHistory(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchStartDate, searchEndDate, Number(searchType))
    }
    if(v){
      setData(v.rows)
      setTotalCount(v.totalCount)
      const paginationMin = Math.floor(page / 10) * 10
      console.log(paginationMin)
      const paginationMax = Math.min(paginationMin + 10, Math.ceil(v.totalCount / pageNum))
      console.log(paginationMin, paginationMax)
      setPaginationData({
        currentPage: page,
        maxPage: Math.ceil(v.totalCount / pageNum),
        min: paginationMin,
        max: paginationMax,
      })
    }
  },[
    searchAgencyName,
    searchUserNickname,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
    searchType,
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchAgencyName,
    searchUserNickname,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
    searchType,
  ])

  const onClickSearch = useCallback(function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserNickname(inputUserNickname);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
    setSearchType(inputType);
  },[
    inputAgencyName,
    inputUserNickname,
    inputStartDate,
    inputEndDate,
    inputType,
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputAgencyName("");
    setInputUserNickname("");
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));

    setSearchAgencyName("");
    setSearchUserNickname("");
    setSearchStartDate("");
    setSearchEndDate("");
    setSearchType("0");
  },[])

  return <div>
    <ContentHeader title={t("홀덤 포인트 상세내역")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
            <div className="card-header">
                <div className='row mb-2'>
                  {!isAgency ? <>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>{setInputAgencyName(v.target.value)}} value={inputAgencyName}/>
                  </div>
                  </>:<></>}

                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserNickname(v.target.value)} value={inputUserNickname}/>
                  </div>
                </div>
                <div className='row'>
                  
                  {!isAgency ? <>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("상세 유형")}</span>
                    </div>
                    <select onChange={e => setInputType(e.target.value)} value={inputType}>
                        {HISTORY_TYPE_ORDER.map((item, index) => {
                            if(item === AgencyEggHistoryType.NONE) return <option key={index} value={item}>{t("전체")}</option>
                          return <option key={index} value={item}>{t(MEMO_DATA[item])}</option>
                        })}
                    </select>
                  </div>
                  </> : <></>}
                  <div className="input-group col-6">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <input type="datetime-local" className="form-control"
                          value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputEndDate(new Date(e.target.value));
                          }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("어제")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("오늘")}</button>
                    </div>
                  </div>

                  <div className='col-3'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <Table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th style={{width:"50px",textAlign:"center"}}>{t("일자")}</th>
                    <th style={{width:"80px",textAlign:"center" }}>{t("에이전시명")}</th>
                    <th style={{width:"80px",textAlign:"center"}}>{t("닉네임")}</th>
                    <th style={{width:"200px",textAlign:"center"}}>{t("포인트 변동 전")}</th>
                    <th style={{width:"100px", textAlign:"center"}}>{t("상세")}</th>
                    <th style={{width:"200px",textAlign:"center"}}>{t("포인트 변동 후")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    data ? data.map((item, _index) => {
                      return <>
                        <BackColorTr key={_index} backColor="#fff">
                            <td>{moment(item.addedAt).format(DATE_FORMAT)}</td>
                            <td>{item.name}</td>
                            <td>{item.nickname}</td>
                            <td>{Number(item.prevBalance).toLocaleString()}</td>
                            <td>
                              <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
                                <div style={{fontWeight:"600", fontSize:"15px"}}>{t(MEMO_DATA[item.memo])}</div>
                                <div style={{color:item.amount > 0 ? "blue" : "red"}}>{item.amount > 0 ? "+" + Number(item.amount).toLocaleString() : Number(item.amount).toLocaleString()}</div>
                              </div>
                            </td>
                            <td><DiffDigitColorTxt prev={Number(item.prevBalance).toLocaleString()} after={Number(item.afterBalance).toLocaleString()} color={item.amount > 0 ? "blue" : "red"} /></td>
                        </BackColorTr>
                      </>
                    }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  {data ? data.length === 0 ? <tr><td colSpan={9}>{t("검색 결과가 없습니다")}.</td></tr> : <></> : <></>}
                  </tbody>
                </Table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <div className={"page-link " + (paginationData.min < 10 ? 'disabled' : '')}
                        onClick={() => {
                            if (paginationData.min < 10) {
                                return;
                            } else {
                                setPage(paginationData.min - 1)
                            }
                        }}>
                      <i className="fas fa-chevron-left"></i>
                    </div>
                  </li>
                  {
                    Array(paginationData.max - paginationData.min).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <a style={{backgroundColor:page==index+paginationData.min?"#ddd":"#fff"}} className="page-link" href="#user-table" onClick={()=>setPage(index+paginationData.min)}>{index + paginationData.min + 1}</a>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <div className={"page-link " + (paginationData.max * pageNum > totalCount ? 'disabled' : '')}
                        onClick={() => {
                            if (paginationData.max * pageNum > totalCount) {
                                return;
                            } else {
                                setPage(paginationData.min + 10)
                            }
                        }}>
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
}

export default PointHistory;
