import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import {useTranslation} from "react-i18next";
import api from "../../api/admin"

interface AdminLogPopupProps {
  onClose: () => void;
  adminId:number;
}

const AdminLogPopup = ({onClose, adminId}: AdminLogPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [logs , setLogs] = useState<{
    endpoint:string,
    addedAt:string,
  }[]>();

  useEffect(function(){
    api.adminLogs(token, adminId, 0, 50).then(v=>{
        if(v.logs){
            setLogs(v.logs)
        }
    })
  },[])

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("접속 기록")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        
        {logs == null ? <>Loading..</>:<>
        <div className="modal-body" style={{maxHeight:"500px", overflow:"scroll"}}>
          {logs.map((v,k)=>
            <div key={k} className="form-group row">
                <div style={{flex:1}}>{v.endpoint}</div><div>{v.addedAt.split(".")[0].replace("T", " ")}</div>
            </div>
          )}
          {logs.length == 0 ? <div>{t("접속 기록이 없습니다.")}</div> : <></>}
        </div>
        </>}

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AdminLogPopup;
