import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import {CreateTournamentRoomInterface, RAKE_TYPE, ROOM_TYPE} from "@app/dataset";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";

export function nFormatter(num: number, digits: number) {
  const lookup = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" },
  ];
  const regexp = /\.0+$|(?<=\.[0-9]*[1-9])0+$/;
  const item = lookup.find((item: any) => num >= item.value);
  return item ? (num / item.value).toFixed(digits).replace(regexp, "").concat(item.symbol) : "0";
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TournamentList = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [games, setGames] = React.useState<{
    roomId:number,
    groupId:number,
    roomName:string,
    playerCount:number,
    viewerCount:number,
    addedAt:string,
    groupData:any
  }[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);

  useEffect(() => {
    AdminAPI.getRingList({session: token}).then((res) => {
      setGames(res.data)
    })
  }, [refresh])

  return (
    <div>
      <ContentHeader title={t("링 목록")}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">{t("링 목록")}</h3>
                  <div className="card-tools">
                    <div className="input-group input-group-sm" style={{
                      width: 150
                    }}>
                      <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-default">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body table-responsive p-0" style={{
                  height: 700
                }}>
                  <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                      <th>{t("그룹아이디")}</th>
                      <th>{t("방아이디")}</th>
                      <th>{t("이름")}</th>
                      <th>{t("인원")}</th>
                      <th>{t("생성일자")}</th>
                      <th><button type="button" className="btn btn-block btn-primary btn-sm" onClick={()=>{
                        window.location.href = '/create/room/ring';
                      }}>{t("추가")}</button></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      games.map((item, index) => {
                        return <tr key={index}>
                          <td>{item.groupId}</td>
                          <td>{item.roomId}</td>
                          <td style={{"textTransform": "uppercase"}}>
                            {nFormatter(Number(item.groupData.blind[0]), 1)} - {nFormatter(Number(item.groupData.blind[1]), 1)}
                            ({item.roomName})
                          </td>
                          <td>{item.playerCount}{t("명")}</td>
                          <td>{moment(item.addedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>
                            <button type="button" className="btn btn-block btn-danger btn-sm" onClick={async ()=>{
                              let password = prompt(t("비밀번호를 입력해주세요"));
                              if(password !== "2024") {
                                return alert(t("비밀번호가 틀렸습니다"));
                              }
                              
                              const result = await AdminAPI.deleteRingGame( token, item.roomId);
                              if(result.success) {
                                alert(t("방이 삭제되었습니다"))
                              }else {
                                alert(t("방이 삭제에 실패하였습니다 : ")+result.error)
                              }
                              setRefresh(!refresh)
                            }}>{t("삭제")}</button>
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TournamentList;
