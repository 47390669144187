import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import AgencyEggProfit from "@app/pages/AgencyEggProfit";
import { useTranslation } from "react-i18next";

interface AgencyDetailPopupProps {
  onClose: () => void;
  agencyId:number;
}

const AgencyRakeLogPopup = ({
    agencyId,
    onClose
}: AgencyDetailPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);

  return <PopupContainer onClickBackdrop={onClose} maxWidth="800px">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("에이전시 요율 수익")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        
        <div className="modal-body" style={{
            maxHeight:"70vh",
            overflow:"scroll"
        }}>
            <AgencyEggProfit user={{agencyId:agencyId, uid:""}} />
        </div>

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AgencyRakeLogPopup;
