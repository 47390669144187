import React, { useEffect } from "react";
import moment from 'moment';
import { useSelector } from "react-redux";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@app/store/hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { CurrencyMark } from "@app/conversionChip";

const months: string[] = [];
for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
  months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
}

function AllCurrency(items:any[]){
  return Array.from(new Set(items.map((c:any)=>c.currency).filter(e=>!!e)))
}

function PrintOnlyCurrency(currency:string, ff:string, users:any[]):number{
  return users.filter(v=>v.currency === currency).map((v:any)=>isNaN(v[ff])?0:v[ff]).reduce((a,b:any)=>a+Number(b),0);
}

function PrintCurrency(currency:string, money:any){
  money = Number(money);
  if(isNaN(money)){
    return CurrencyMark(currency) + "0";
  }

  if(money < 0){
    return <>-{CurrencyMark(currency)}<DigitsColorWrapper number={Math.abs(money)} /></>
  }
  return <>{CurrencyMark(currency)}<DigitsColorWrapper number={money}/></>;
}


interface Params {
  user: { uid: string, agencyId: number };
}
const TotalTr = styled.tr`
  >td{
    font-weight: bold;
  }
`;

const AgencyProfit = ({
  user
}: Params) => {
  const { t } = useTranslation()
  const { token } = useAppSelector(state => state.auth);
  const [currency, setCurrency] = React.useState<string>('');
  const [currentMonth, setCurrentMonth] = React.useState(moment().utc().format('YYYY-MM'));

  const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentMonth(e.target.value);
  }

  const [list, setList] = React.useState<{
    day:string;
    rake:number;
    penalty:number;
    tournamentBuyin:number;
    fiatRake:number;
    fiatPenalty:number;
    fiatTournamentBuyin:number;
    currency:string;
  }[] | null>([]);

  useEffect(function () {
    setList(null);
    AdminAPI.agencyStatistics_1(token, currentMonth, user.agencyId).then((v) => {
      setList(v.rows)

      if (v.rows.length > 0) {
        setCurrency(v.rows[0].currency)
      }
    })
  }, [currentMonth]);

  return <>
    <h3 className='mt-4'>{user.uid} (@{user.agencyId}) {t("에이전시의 수익 통계")}</h3>
    <h4>
      {currentMonth} {t("수익 현황")}
      {AllCurrency(list||[]).map((item, index) => {
        const fiatRake = PrintOnlyCurrency(item, "fiatRake", list||[]);
        const fiatPenalty = PrintOnlyCurrency(item, "fiatPenalty", list||[]);
        const fiatTournamentBuyin = PrintOnlyCurrency(item, "fiatTournamentBuyin", list||[]);

        return <div>{PrintCurrency(item, Math.abs(fiatRake) + Math.abs(fiatPenalty) + Math.abs(fiatTournamentBuyin))}</div>
      })}
    </h4>
    <h6>
      {t("매일 0시 기준으로 업데이트되며, 소수점 첫째 자리에서 반올림됩니다")}.
    </h6>
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className='row mb-2'>
              <div className="offset-8 input-group col-4">
                <div className="input-group-prepend">
                  <span className="input-group-text">{t("월별 조회")}</span>
                </div>
                <select className="form-control" onChange={onChangeMonth}>
                  {
                    months.map((item, index) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="card-body table-responsive p-0" style={{ height: 785 }}>
            {list ?
              <table className="table table-head-fixed text-nowrap">
                <thead>
                  <tr>
                    <th>{t("일자")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("레이크")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("핸드 패널티")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("토너먼트 바이인")}</th>
                    <th style={{ backgroundColor: "#f1f1f1", borderLeft: "2px solid #aaa" }}>{t("총 수익")}</th>
                  </tr>
                </thead>
                <tbody>
                  <TotalTr>
                    <td>{t("합계")}</td>
                    <td style={{ backgroundColor: "#fff" }}>
                      {AllCurrency(list||[]).map((item, index) => {
                        const fiatRake = PrintOnlyCurrency(item, "fiatRake", list||[]);

                        return <div>{PrintCurrency(item, Math.abs(fiatRake))}</div>
                      })}
                    </td>
                    <td style={{ backgroundColor: "#fff" }}>
                      {AllCurrency(list||[]).map((item, index) => {
                        const fiatRake = PrintOnlyCurrency(item, "fiatPenalty", list||[]);

                        return <div>{PrintCurrency(item, Math.abs(fiatRake))}</div>
                      })}
                    </td>
                    <td style={{ backgroundColor: "#fff" }}>
                      {AllCurrency(list||[]).map((item, index) => {
                        const fiatRake = PrintOnlyCurrency(item, "fiatTournamentBuyin", list||[]);

                        return <div>{PrintCurrency(item, Math.abs(fiatRake))}</div>
                      })}
                    </td>
                    <td style={{ backgroundColor: "#f1f1f1", borderLeft: "2px solid #aaa" }}>
                      {AllCurrency(list||[]).map((item, index) => {
                        const fiatRake = PrintOnlyCurrency(item, "fiatRake", list||[]);
                        const fiatPenalty = PrintOnlyCurrency(item, "fiatPenalty", list||[]);
                        const fiatTournamentBuyin = PrintOnlyCurrency(item, "fiatTournamentBuyin", list||[]);

                        return <div>{PrintCurrency(item, Math.abs(fiatRake) + Math.abs(fiatPenalty) + Math.abs(fiatTournamentBuyin))}</div>
                      })}
                    </td>
                  </TotalTr>
                  {
                    list.map((item, index) => {
                      return <tr key={index}>
                        <td>{item.day}</td>
                        <td style={{ backgroundColor: "#fff" }}>
                          {item.fiatRake > 0 ? <>
                          {CurrencyMark(item.currency)}<DigitsColorWrapper fontSize={13} number={Number(item.fiatRake)} />
                          </>:<>0</>}
                        </td>
                        <td style={{ backgroundColor: "#fff" }}>
                          {item.fiatPenalty > 0 ? <>
                            {CurrencyMark(item.currency)}<DigitsColorWrapper fontSize={13} number={Number(item.fiatPenalty)} />
                          </>:<>0</>}
                          </td>
                        <td style={{ backgroundColor: "#fff" }}>
                          {item.fiatTournamentBuyin > 0 ? <>
                            {CurrencyMark(item.currency)}<DigitsColorWrapper fontSize={13} number={Number(item.fiatTournamentBuyin)} />
                          </>:<>0</>}
                          </td>
                        <td style={{ backgroundColor: "#f1f1f1", borderLeft: "2px solid #aaa" }}>
                          {(item.fiatRake + item.fiatPenalty + item.fiatTournamentBuyin) > 0 ? <>
                            {CurrencyMark(item.currency)}<DigitsColorWrapper fontSize={13} number={Number((item.fiatRake + item.fiatPenalty + item.fiatTournamentBuyin))} />
                          </>:<>0</>}
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </table> : "loading.."}
          </div>
        </div>
      </div >
    </div >
  </>
}
export default AgencyProfit;
