import styled from "styled-components";

const Wrapper = styled.div<{
  maxWidth?:string
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1050;
  .modal-dialog {
    width: 100%;
    ${p=>p.maxWidth && `max-width: ${p.maxWidth};`}
  }
`;

const Backdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
`;

export interface PopupOptions {}

export interface PopupProps {
  children: any;
  options?: PopupOptions;
  onClickBackdrop?: () => void;
  onClick?: (event: any) => void;
  maxWidth?: string;
}

const PopupContainer = ({
  children,
  options,
  onClickBackdrop,
  onClick,
  maxWidth
}: PopupProps) => {
  return (
    <Wrapper
      maxWidth={maxWidth}
      onClick={(event) => {
        if (onClick) {
          onClick(event);
        }
      }}
    >
      <Backdrop onClick={onClickBackdrop} />
      {children}
    </Wrapper>
  );
};

export default PopupContainer;
