import React, { useCallback, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, useNavigate} from 'react-router-dom';
import {MenuItem} from '@components';
import {PfImage} from '@profabric/react-components';
import styled from 'styled-components';
import {SidebarSearch} from '@app/components/sidebar-search/SidebarSearch';
import {loadUser, logoutUser} from '@store/reducers/auth';
import {useAppSelector} from '@store/hooks';
import i18n from '@app/utils/i18n';
import {useTranslation} from "react-i18next";
import AdminAPI from '@app/api/admin';

export interface IMenuItem {
  name: string;
  icon?: string;
  path?: string;
  priviliege?:string;
  agencyOnly?:boolean;
  subHQOnly?:boolean;
  showAll?:boolean;
  standalone?:boolean;
  children?: Array<IMenuItem>;
}

const Logout = styled.div`
  color: white;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const EggInfo = styled.div`
  color: white;
  font-size: 16px;
  padding-left: .8rem;

  img {
    cursor: pointer;
    margin-left: 10px;
  }
  img:hover {
    opacity: 0.7;
  }
  img:active {
    opacity: 0.5;
  }
`;

export function MENUS(): IMenuItem[]{
  return [
    {
      name: i18n.t("대시보드"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t("대시보드"),
          icon: 'fas fa-tachometer-alt nav-icon" />',
          path: '/'
        },
        {
          name: i18n.t('최근 1주일 최대 획득 회원'),
          priviliege: 'Users',
          icon: 'fas fa-tachometer-alt nav-icon" />',
          path: '/dashboard/recent/earn'
        },
        {
          name: i18n.t('최근 1주일 최대 차감 회원'),
          priviliege: 'Users',
          icon: 'fas fa-tachometer-alt nav-icon" />',
          path: '/dashboard/recent/deduction'
        },
      ]
    },
    {
      name: i18n.t("Inquiry"),
      icon: 'fas fa-search nav-icon" />',
      path: '/inquiry'
    },
    
    {
      name: i18n.t("방 만들기"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t('방 만들기 (링)'),
          priviliege: 'GameRingRoom',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/create/room/ring'
        },
  
        {
          name: i18n.t('방 만들기 (토너먼트)'),
          priviliege: 'GameTournamentRoom',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/create/room/tournament'
        }
      ]
    },
    {
      name: i18n.t("방 목록"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t("토너먼트 목록"),
          priviliege: 'GameTournamentRoom',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/list/tournament'
        },
        {
          name: i18n.t("링 목록"),
          priviliege: 'GameRingRoom',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/list/ring'
        },
      ]
    },
    {
      name: i18n.t("진행 중인 게임"),
      priviliege: 'Super',
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/runningGames'
    },
    {
      name: i18n.t("에이전시 관리"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t("에이전시 목록"),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/agency'
        },
        {
          name: i18n.t("부본사 목록"),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/subHQs'
        },
        {
          name: i18n.t("에이전시 요율 지갑"),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/agencyWalletList'
        },
        {
          name: i18n.t("홀덤포인트 관리"),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/egg/management'
        },
        {
          name: i18n.t("홀덤포인트 수동 지급"),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/egg/send'
        },
        {
          name: i18n.t('홀덤포인트 환전'),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/egg/exchange'
        },
        {
          name: i18n.t('에이전시 수익'),
          priviliege: 'Agency',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/agencyProfitList'
        },
        {
          name: i18n.t('홀덤 포인트 상세내역'),
          priviliege: 'Super',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/pointHistory'
        }
      ]
    },
    {
      name: i18n.t("링게임 관리"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        { 
          name: i18n.t("게임 기록"),
          priviliege: 'Chart',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/game/record'
        },
        {
          name: i18n.t("배팅 내역"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/betting/history'
        },
      ]
    },
    {
      name: i18n.t("토너먼트 관리"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t("게임 기록"),
          priviliege: 'Chart',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/tournament/game/record'
        },
        {
          name: i18n.t("배팅 내역"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/tournament/betting/history'
        },
        {
          name: i18n.t("통합 내역"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/tournament/member'
        },
      ]
    },
    {
      name: i18n.t('회원/게임 관리'),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t("회원 정보"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/user/list'
        },
        {
          name: i18n.t("회원 접속 기록"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/user/access'
        },
        {
          name: i18n.t("관리자 관리"),
          priviliege: 'CreateAdmin',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/signup'
        },
        {
          name: i18n.t("공지사항 관리"),
          icon: 'fas fa-solid fa-dice nav-icon',
          priviliege: 'Notice',
          path: '/notice'
        },
        {
          name: i18n.t("유저 사이트머니 복구"),
          icon: 'fas fa-solid fa-dice nav-icon',
          priviliege: 'Notice',
          path: '/sendsitemoney'
        },
        {
          name: i18n.t("강제 정산"),
          icon: 'fas fa-solid fa-dice nav-icon',
          priviliege: 'Notice',
          path: '/forceAdjustment'
        },
        {
          name: i18n.t('중복 IP 접속자'),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/user/duplicate/ip'
        },
        {
          name: i18n.t('유저 요율 상세내역'),
          agencyOnly: true,
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/rakeList'
        },
        {
          name: i18n.t('레이크백 내역'),
          agencyOnly: true,
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/rakebackList'
        },
      ]
    },
    {
      name: i18n.t("정산"),
      icon: 'far fa-caret-square-down nav-icon',
      children: [
        {
          name: i18n.t("유저 정산 내역"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/userMoneyTracker'
        },
        {
          name: i18n.t("유저 통신 내역"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/userAdjustment'
        },
        {
          name: i18n.t('바이인/아웃 통신 로그'),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/BuyInOutLog'
        },
        {
          name: i18n.t("통신 에러 내역"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/BuyinoutErrorLogs'
        },
        {
          name: i18n.t("요율 수익표"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/gameProfit'
        },
        {
          name: i18n.t("토너 수익표"),
          priviliege: 'Users',
          icon: 'fas fa-solid fa-dice nav-icon',
          path: '/tournament/gameProfit'
        },
      ]
    },

    {
      name: i18n.t("USDT 내역"),
      priviliege: 'Super',
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/usdtHistory'
    },

    {
      name: i18n.t("포인트 요청"),
      icon: 'fas fa-solid fa-dice nav-icon',
      agencyOnly: true,
      path: '/egg/request'
    },
    {
      name: i18n.t('매출 현황 (통계)'),
      icon: 'fas fa-solid fa-dice nav-icon',
      agencyOnly: true,
      path: '/sales/status'
    },
    {
      name: i18n.t('게임 기록(에이전시)'),
      agencyOnly:true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/game/record'
    },
  
    {
      name: i18n.t("유저 정산 내역"),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/userMoneyTracker'
    },
    {
      name: i18n.t('유저 통신 내역'),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/userAdjustment'
    },
    {
      name: i18n.t('회원 리스트'),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/user/list'
    },
    {
      name: i18n.t('회원 접속 기록(에이전시)'),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/user/access'
    },
    {
      name: i18n.t("토너먼트 내역"),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/tournament/member'
    },
    {
      name: i18n.t('환전 요청 및 내역'),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/requestExchange'
    },
    {
      name: i18n.t('요율 내역'),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/rakeList'
    },
    {
      name: i18n.t('레이크백 내역'),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/rakebackList'
    },
  
  
    {
      name: i18n.t("수익 통계"),
      subHQOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/subhq/profits'
    },
    {
      name: i18n.t("하부 리스트"),
      subHQOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/subhq/hierarchy'
    },
    {
      name: i18n.t('환전 요청 및 내역'),
      subHQOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/subhq/requestExchange'
    },
    {
      name: i18n.t('요율 내역'),
      subHQOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/subhq/rakeList'
    },
    {
      name: i18n.t("게임 기록"),
      subHQOnly:true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/subhq/game/record'
    },
    {
      name: i18n.t("유저 정산 내역"),
      subHQOnly:true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/subhq/userAdjustment'
    },

    {
      name: i18n.t("환율 정보"),
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/exchangeInfo'
    },
    {
      name: i18n.t("환율 정보"),
      subHQOnly:true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/exchangeInfo'
    },
    {
      name: i18n.t("환율 정보"),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/exchangeInfo'
    },


    {
      name: i18n.t("충환 요청"),
      agencyOnly: true,
      standalone: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/standaloneExchange'
    },
    {
      name: i18n.t("유저 보유금 수동관리"),
      subHQOnly: true,
      standalone: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/standaloneAdjustMoney'
    },
    {
      name: i18n.t("요율 지갑"),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/wallet'
    },
    {
      name: i18n.t("요율 지갑"),
      subHQOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/wallet'
    },
    {
      name: i18n.t("홀덤 포인트 상세내역"),
      agencyOnly: true,
      icon: 'fas fa-solid fa-dice nav-icon',
      path: '/agency/pointHistory'
    },
    {
      name: i18n.t("API 문서"),
      icon: 'far fa-caret-square-down nav-icon',
      showAll: true,
      children: [
        {
          name: i18n.t("한국어"),
          icon: 'fas fa-solid fa-dice nav-icon',
          // agencyOnly: true,
          path: '/apidocument/kr'
        },
        {
          name: i18n.t("영어"),
          icon: 'fas fa-solid fa-dice nav-icon',
          // agencyOnly: true,
          path: '/apidocument/en'
        }
      ]
    }
  ]
}

// export const MENU: IMenuItem[] = ;

const StyledBrandImage = styled(PfImage)`
  float: left;
  line-height: 0.8;
  margin: -1px 8px 0 6px;
  opacity: 0.8;
  --pf-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
  0 6px 6px rgba(0, 0, 0, 0.23) !important;
`;

const StyledUserImage = styled(PfImage)`
  --pf-box-shadow: 0 3px 6px #00000029, 0 3px 6px #0000003b !important;
`;
const MenuSidebar = () => {
  const {t} = useTranslation()
  const user = useSelector((state: any) => state.auth.currentUser);
  const {token} = useAppSelector(state => state.auth);
  const sidebarSkin = useSelector((state: any) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: any) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: any) => state.ui.menuChildIndent);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const MENU = MENUS();

  const logOut = (event: any) => {
    event.preventDefault();
    dispatch(logoutUser());
    navigate('/login');
  };

  const onClickLoadEgg = useCallback(async () =>{
    const response = await AdminAPI.getInfo({session:token});
    dispatch(loadUser(response.payload));
  }, [token, dispatch]);

  useEffect(() => {
    onClickLoadEgg();
  }, [window.location.pathname]);

  return (
    <aside className={`main-sidebar elevation-4 ${sidebarSkin}`}>
      <Link to="/" className="brand-link" style={{
        height: 65,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start'
      }}>
        <StyledBrandImage
          src="/img/logo.png"
          alt="AdminLTE Logo"
          width={33}
          height={33}
          rounded
        />
        <span className="brand-text font-weight-light">{t("홀덤 운영툴")}</span>
      </Link>
      <div className="sidebar">
        { user.agencyId ? <>
        <EggInfo className="egg-info mt-3 d-flex justify-content-start align-items-center">
          <div className="mr-2">
            {t("보유 포인트")} : 
          </div>
          <div>
              {((Math.floor(Number(user.egg)*10))/10).toLocaleString()}
            </div>
            <img src="/img/ic_refresh_list.svg" alt="refresh" width={16} height={16} onClick={onClickLoadEgg}/>
          </EggInfo>
        </> : <></>}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="profile-image" style={{paddingLeft: ".8rem"}}>
            <StyledUserImage
              src={user.picture}
              fallbackSrc="/img/default-profile.png"
              alt="User"
              width={34}
              height={34}
              rounded
            />
          </div>
          <div className="info" style={{color:"white"}} onClick={logOut}>
            {/* <Link to="/profile" className="d-block"> */}
              <Logout>{t("로그아웃")}</Logout>
            {/* </Link> */}
          </div>
        </div>

        <div className="form-inline">
          <SidebarSearch/>
        </div>

        <nav className="mt-2" style={{overflowY: 'hidden'}}>
          <ul
            className={`nav nav-pills nav-sidebar flex-column${
              menuItemFlat ? ' nav-flat' : ''
            }${menuChildIndent ? ' nav-child-indent' : ''}`}
            role="menu"
          >
            {MENU.map((menuItem: IMenuItem) => (
              <MenuItem
                key={menuItem.name + menuItem.path}
                menuItem={menuItem}
              />
            ))}
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
