import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';

import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { chipToCurrency, CurrencyMark } from "@app/conversionChip";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import AgencyWalletToPointPopup from "@app/components/popup/AgencyWalletToPointPopup";

let FromNow = styled.div`
  font-size:12px;
  font-weight: bold;
`

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const AgencyWallet = () => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [pageNum, setPageNum] = useState(999999);
    const [page, setPage] = useState(0);
    const [totUser, setTotUser] = useState(0);
    const [logs, setLogs] = useState<{
        type:number;
        amount: number;
        afterAmount:number;
        fiatAmount: number;
        currency: string;
        addedAt: string;
    }[]>();
    const [sumData, setSumData] = useState({amount:0, fiatAmount: 0});

    const [inputViewType, setInputViewType] = useState(0); // 0: 전체, 1: 적립, 2: 환전
    const [searchViewType, setSearchViewType] = useState(0);

    const [myWalletAmount, setMyWalletAmount] = useState(0);
    const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000 * 3)))
    const [inputEndDate, setInputEndDate] = useState(new Date((Date.now() - 0)))
    const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT))
    const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT))
    const [walletToPointPopup, setWalletToPointPopup] = useState(false);
    const [agencyCurrency, setAgencyCurrency] = useState(null);

    const OnLoad = useCallback(function () {
        setLogs(undefined);
        AdminAPI.walletLogs(token, page, pageNum, searchStartDate, searchEndDate, searchViewType).then(v => {
            setLogs(v.rows)
            setTotUser(v.totalCnt)
            setMyWalletAmount(v.amount);
            setAgencyCurrency(v.currency);
            let _sum = {amount:0, fiatAmount:0};
            v.rows.forEach((r:any)=>{
                _sum.amount += Number(r.amount);
                _sum.fiatAmount += Number(r.fiatAmount);
            })
            setSumData(_sum);
        })
    }, [
        searchStartDate,
        searchEndDate,
        searchViewType
    ])

    useEffect(function () {
        OnLoad();
    }, [
        searchStartDate,
        searchEndDate,
        searchViewType,
    ])

    const onClickSearch = useCallback(function () {
        setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
        setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
        setSearchViewType(inputViewType);
    }, [
        inputStartDate,
        inputEndDate,
        inputViewType,
    ])

    const onClickCancelSearch = useCallback(function () {
        setSearchStartDate("");
        setSearchEndDate("");
        setSearchViewType(0);

        setInputStartDate(new Date('2021-01-01 00:00:00'));
        setInputEndDate(new Date('2099-01-01 23:59:59'));
    }, [])

    return <div>
        {walletToPointPopup && <AgencyWalletToPointPopup onClose={() => {
            OnLoad();
            setWalletToPointPopup(false);
        }} />}
        <ContentHeader title={t("요율 지갑 기록")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="input-group col-1">
                                        <select className="form-control" value={inputViewType} onChange={(e) => setInputViewType(Number(e.target.value))}>
                                            <option value={0}>{t("전체")}</option>
                                            <option value={1}>{t("획득")}</option>
                                            <option value={2}>{t("전환")}</option>
                                        </select>
                                    </div>
                                    <div className="input-group col-8">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("기간")}</span>
                                        </div>
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                                            onChange={(e) => {
                                                setInputStartDate(new Date(e.target.value));
                                            }}
                                        />
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                                            onChange={(e) => {
                                                setInputEndDate(new Date(e.target.value));
                                            }}
                                        />
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().startOf('month').format(DATE_FORMAT)
                                                let r2 = moment().endOf('month').format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("이번달")}</button>
                                        </div>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().startOf('isoWeek').format(DATE_FORMAT)
                                                let r2 = moment().endOf('isoWeek').format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("이번주")}</button>
                                        </div>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                                let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("어제")}</button>
                                        </div>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-info" onClick={() => {
                                                let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                                let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                                setInputStartDate(new Date(r1));
                                                setInputEndDate(new Date(r2));
                                            }}>{t("오늘")}</button>
                                        </div>
                                    </div>
                                    <div className='col-2'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ marginTop: "20px" }}>
                                    <div className="input-group col-12">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                {t("요율 지갑 보유액")} : {Number(myWalletAmount).toLocaleString()}
                                                {agencyCurrency ? <div style={{fontSize:"13px", marginLeft:"5px"}}>({CurrencyMark(agencyCurrency)}<DigitsColorWrapper number={chipToCurrency(agencyCurrency, Number(myWalletAmount))} />)</div> : <></>}
                                            </span>
                                            <button type="button" className="btn btn-primary" onClick={() => setWalletToPointPopup(true)}>{t("요율 지갑")}{" -> "}{t("포인트 변환")}</button>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-2' style={{ marginTop: "20px" }}>
                                    <div className="input-group col-12">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">
                                                {t("요율 지갑 기록은 10초~60초마다 발생한 수익을 한번에 묶어서 지갑으로 입금됩니다")}.<br/>
                                                {t("수익 발생에 대한 개별건은 수익 관련 페이지에서 확인 가능합니다")}.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap" id="user-table">
                                    <thead>
                                        <tr>
                                            <th>{t("타입")}</th>
                                            <th>{t("지급 포인트")}</th>
                                            <th>{t("지급 후 보유")}</th>
                                            <th>{t("시각")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{t("합계")}</td>
                                            <td>
                                                <b>{Number(sumData.amount).toLocaleString()}P</b><br />
                                                <span style={{fontSize:"13px"}}>({CurrencyMark(agencyCurrency || "")}<DigitsColorWrapper number={Number(sumData.fiatAmount)} />)</span>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                        {
                                            logs ? logs.map((item, index) => {
                                                // let prevAddedAt = index == 0 ? item["addedAt"] : logs[index - 1]["addedAt"];
                                                return <tr key={index}>
                                                    <td>{item.type == 1 ? "획득" : "포인트 전환"}</td>
                                                    <td>
                                                        <b>{Number(item.amount).toLocaleString()}P</b><br />
                                                        <span style={{fontSize:"13px"}}>({CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(item.fiatAmount)} />)</span>
                                                    </td>
                                                    <td>
                                                        <b>{Number(item.afterAmount).toLocaleString()}P</b>
                                                    </td>
                                                    <td>
                                                        {/* {moment(prevAddedAt).local().format("YYYY-MM-DD HH:mm:ss")}{" ~ "} */}
                                                        {moment(item.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}
                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                                        }
                                    </tbody>
                                </table>
                            </div>

                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-left">
                                    <li className="page-item">
                                        <div className="page-link">
                                            {t("표시")}
                                        </div>
                                    </li>
                                    {
                                        [50, 100, 200].map((item, index) => {
                                            return <li key={index} className="page-item">
                                                <div className="page-link" style={
                                                    item === pageNum ? {
                                                        backgroundColor: 'lightgrey',
                                                        color: 'black'
                                                    } : {}
                                                } onClick={() => setPageNum(item)}>{item}</div>
                                            </li>
                                        })
                                    }
                                </ul>
                                <ul className="pagination pagination-sm m-0 float-right">
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            <i className="fas fa-chevron-left"></i>
                                        </a>
                                    </li>
                                    {
                                        Array(Math.ceil(totUser / pageNum)).fill(0).map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <a style={{ backgroundColor: page == index ? "#ddd" : "#fff" }} className="page-link" href="#user-table" onClick={() => setPage(index)}>{index + 1}</a>
                                            </li>
                                        })
                                    }
                                    <li className="page-item">
                                        <a className="page-link" href="#">
                                            <i className="fas fa-chevron-right"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </section>
    </div>
}

export default AgencyWallet;
