import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import { ROOM_TYPE } from "@app/dataset";
import { useNavigate } from "react-router-dom";
import GameDetailPopup from "@app/components/popup/GameDetailPopup";
import {useTranslation} from "react-i18next";
import { CurrencyMark, currencyToChip } from "@app/conversionChip";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";

let ProfitPerUser = styled.span`
  font-size:13px;
  font-weight: bold;
  color: #00405a;
`

let lastUpate = 0;
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TournamentMember = ({
  isAgency
}:{
  isAgency?:boolean
}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [tot, setTot] = useState(0);
  const [games, setGames] = useState<{
    nickname:string,
    ranking:number,
    stackSize:number,
    userId:number,
    agencyName:string,
    addedAt:string,
    curBalance:number,
    buyinCount:number,
    totalBuyin:number,
    prizeAmount:number,
    gtd:number
    addBenefit:number,
    groupData:any,
    fiatAmount:number,
    buyinChipAmount:number,
    currency:string,
    suppliedPrize:number
  }[]>();

  const [detailPopup, setDetailPopup] = useState<{roomId:number, gameNumber:number, communityCards: number[]} | null>(null);
  const [totalBuyin, setTotalBuyin] = useState<number>(0);
  const [totalPrize, setTotalPrize] = useState<number>(0);
  const [totalPrizeKRW, setTotalPrizeKRW] = useState<number>(0);
  const [totalAgencyBuyin, setTotalAgencyBuyin] = useState<number>(0);
  const [gtd, setGtd] = useState<number>(0);
  const [isGuarantee, setIsGuarantee] = useState<boolean>(false);
  const [guaranteeBuyinSplitRatio, setGuaranteeBuyinSplitRatio] = useState<number>(0);
  const [guaranteeMinAmount, setGuaranteeMinAmount] = useState<number>(0);

  const [inputGroupId, setInputGroupId ] = useState("")
  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")

  const [searchGroupId, setSearchGroupId ] = useState("")
  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")

  const [tournamentList, setTournamentList] = useState<{
    groupId:number,
    name:string
  }[]>([]);

  const OnLoad = function(){
    setGames(undefined);

    AdminAPI.tournamentMember(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchUserId, Number(searchGroupId) ).then(v=>{
      v.payload.sort((a:any,b:any)=>{
        if(a.groupId == b.groupId){
          if(a.isEnd){
            return a.ranking - b.ranking;
          }
          return b.stackSize - a.stackSize;
        }
        return b.groupId - a.groupId;
      })
      setGames(v.payload)
      setTot(v.total)
    })

    if(searchGroupId){
      AdminAPI.tournamentBuyinInfo(token, Number(searchGroupId)).then((v:any)=>{
        setTotalBuyin(v.buyin);
        setTotalPrize(v.totalPrize);
        setTotalPrizeKRW(v.totalPrizeKRW);
        setIsGuarantee(v.isGuarantee);
        setTotalAgencyBuyin(v.totalBuyinAgency)

        if(v.isGuarantee){
          // const gtd = (v.buyin - v.guaranteeMinAmount) / 100 * v.guaranteeBuyinSplitRatio;
          const gtd = (v.buyin / 100 * v.guaranteeBuyinSplitRatio) - v.guaranteeMinAmount;
          setGtd(Math.max(gtd, 0));
          setGuaranteeBuyinSplitRatio(v.guaranteeBuyinSplitRatio);
          setGuaranteeMinAmount(v.guaranteeMinAmount);
        }else{
          setGtd(0);
          setGuaranteeBuyinSplitRatio(0);
          setGuaranteeMinAmount(0);
        }
      });
    }
  }

  useEffect(function(){
    AdminAPI.tournamentNameList(token).then(v=>{
      setTournamentList(v.list)
    });
  },[])

  useEffect(function(){
    OnLoad();
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    pageNum,
    page,
    searchGroupId
  ])

  const onClickSearch = useCallback(function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserId(inputUserId);
    setSearchUserNickname(inputUserNickname);
    setSearchGroupId(inputGroupId);
  },[
    inputAgencyName,
    inputUserId,
    inputUserNickname,
    inputGroupId
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputAgencyName("");
    setInputUserId("");
    setInputUserNickname("");
    setInputGroupId("");

    setSearchAgencyName("");
    setSearchUserId("");
    setSearchUserNickname("");
    setSearchGroupId("");
  },[])

  return <div>
    {detailPopup && <GameDetailPopup
      roomId={detailPopup.roomId}
      gameNumber={detailPopup.gameNumber}
      communityCards={detailPopup.communityCards}
      onClose={()=>setDetailPopup(null)}
    />}

    <ContentHeader title={t("토너먼트 > 통합 내역")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row mb-2'>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputAgencyName} onChange={v=>setInputAgencyName(v.target.value)}/>
                  </div>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("참여 유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputUserNickname} onChange={v=>setInputUserNickname(v.target.value)}/>
                  </div>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("참여 유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputUserId} onChange={v=>setInputUserId(v.target.value)}/>
                  </div>
                </div>

                <div className='row mb-2'>
                  <div className="input-group col-6">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("토너먼트")}</span>
                    </div>
                    <select onChange={e=>setInputGroupId(e.target.value)} value={inputGroupId}>
                        <option value="">{t("회차 선택")}</option>
                        {tournamentList.map((v,i)=>{
                          return <option key={i} value={v.groupId}>{v.name}</option>
                        })}
                    </select>
                  </div>

                  <div className='col-3'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <div style={{padding:"10px 20px",fontSize:"15px",fontWeight:"bold"}}>
                  {searchGroupId ? <>
                    <span style={{marginRight:"20px"}}>{t("토너먼트 총 바이인")} : {Number(totalBuyin).toLocaleString()}{t("원")}</span>
                    <span style={{marginRight:"20px"}}>({t("소속 유저 총 바이인")} : {Number(totalAgencyBuyin).toLocaleString()}{t("원")})</span>
                    <span style={{marginRight:"5px"}}>{t("상금")} : {Number(totalPrize).toLocaleString()}$ ({Number(totalPrizeKRW).toLocaleString()} {t("원")})</span>
                    {gtd > 0 ? <span style={{marginRight:"5px"}}> + {Number(gtd).toLocaleString()}{t("원")}</span> : <></>}
                    {isGuarantee ? `[GTD {t("토너먼트")}] (${Number(guaranteeMinAmount).toLocaleString()} / ${guaranteeBuyinSplitRatio}%)` : ""}
                  </> : <>
                    {t("토너먼트 회차를 선택해야 요약 집계가 표시됩니다.")}
                  </>}
                </div>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr style={{textAlign:"center"}}>
                    <th>{t("방정보")}</th>
                    <th>{t("닉네임/에이전시")}</th>
                    <th>{t("시각")}</th>
                    <th>{t("바이인 금액(횟수)")}</th>
                    <th>{t("배네핏여부")}</th>
                    {!isAgency ? <th>{t("사이트 보유금")}</th> : null}
                    <th>{t("등수")}</th>
                    <th>{t("현재 스택")}</th>
                    <th>{t("상금")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {/* {searchGroupId ? <></> : <tr><td colSpan={8}>{t("토너먼트 회차를 선택하여 검색해주세요")}.</td></tr>} */}
                  {games && games.length == 0 ? <tr><td colSpan={8}>{isAgency ? `${t("회원 중 해당 토너먼트에 참가자가 없습니다")}.` :`${t("검색 결과가 없습니다")}.`}</td></tr> : <></>}
                  {
                    games ? games.map((item, index) => {
                      return <tr style={{textAlign:"center"}} key={index}>
                        <td style={{fontSize:"12px"}}>
                            {t("토너먼트")}<br />
                            <>{item.groupData.name}</>
                        </td>
                        <td>{item.nickname} ({item.agencyName})</td>
                        <td>{moment(item.addedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>
                            {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(item.fiatAmount)}/>
                            ({item.buyinCount}{t("회")})<br/>
                            <DigitsColorWrapper number={Number(item.buyinChipAmount)}/>HP
                        </td>
                        <td>{item.addBenefit == 1 ? "✅" : "✖️"}</td>
                        {!isAgency ? <td>{Number(item.curBalance).toLocaleString()}</td> : null }
                        <td>{Number(item.ranking).toLocaleString()}</td>
                        <td>{Number(item.stackSize).toLocaleString()}</td>
                        <td>
                          {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(item.suppliedPrize || 0)} />
                          <span style={{fontSize:"13px"}}>
                          (${Number(item.prizeAmount)}{item.gtd > 0 ? ` +$${Number(item.gtd).toLocaleString()}` : ""})<br/>
                          ({currencyToChip("USD", Number(item.prizeAmount) + Number(item.gtd)).toLocaleString()}HP)
                          </span>
                        </td>
                      </tr>
                    }) : (searchGroupId ? <tr><td colSpan={8}>{t("로딩 중")}...</td></tr>  : <></>)
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left" onClick={()=>setPage(page < 10 ? 0 : page-10)}></i>
                    </a>
                  </li>
                  {
                    (function (){
                      let r = Array(Math.ceil( tot / pageNum )).fill(0).map((item, index)=>{
                        return index;
                      })

                      if(page <= 5){
                        r = r.slice(0, 10)
                      }else{
                        r = r.slice(page - 5, page + 5)
                      }

                      return r.map((index) => {
                        return <li className="page-item" key={index}>
                          <a style={{backgroundColor:page==index?"#ddd":"#fff"}}
                             className="page-link"
                             href="#user-table"
                             onClick={()=>setPage(index)}>
                            {index + 1}
                          </a>
                        </li>
                      })
                    })()
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right" onClick={()=>setPage(page+10)}></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default TournamentMember;
