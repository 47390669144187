import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup, { } from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location, Link } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyRakeLogPopup from "@app/components/popup/AgencyRakeLogPopup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PrintCurrency } from "./UserAdjustMoney";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { chipToCurrency, CurrencyMark } from "@app/conversionChip";
import InquiryAnswerPopup from "@app/components/popup/InquiryAnswerPopup";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const InquiryList = () => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [inquiryPopup, setInquiryPopup] = useState<{
        inquiryId:number,
        userId:number,
        subject:string,
        content:string,
        addedAt:string,
        answer:string
    } | null>(null);
    const [inquiryList, setInquiryList] = useState<{
        inquiryId:number,
        userId:number,
        subject:string,
        content:string,
        addedAt:string,
        answer:string
    }[]>([])

    const [inputStatus, setInputStatus] = useState(0); // 0: all, 1:pending, 2: answered
    const [searchStatus, setSearchStatus] = useState(0);

    const getAgencyList = async (searchStatus:number) => {
        const response = await AdminAPI.getInquiryList(token, searchStatus);
        setInquiryList(response.rows);
    }

    useEffect(() => {
      getAgencyList(searchStatus)
    }, [searchStatus])

    const onClickCancelSearch = useCallback(function () {
        setInputStatus(0);
        setSearchStatus(0);
    }, [])

    const onClickSearch = useCallback(function () {
        setSearchStatus(inputStatus)
    }, [inputStatus])

    return <div>
        {inquiryPopup && <InquiryAnswerPopup onRefresh={()=>getAgencyList(searchStatus)} onClose={() => setInquiryPopup(null)} inquiryId={inquiryPopup.inquiryId} userId={inquiryPopup.userId} subject={inquiryPopup.subject} content={inquiryPopup.content} addedAt={inquiryPopup.addedAt} answer={inquiryPopup.answer} />}
        <ContentHeader title={t("Inquiry List")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("Filter")}</span>
                                        </div>
                                        <select className="form-control" value={inputStatus} onChange={(v) => setInputStatus(Number(v.target.value))}>
                                            <option value={0}>{t("Pending")}</option>
                                            <option value={1}>{t("Answered")}</option>
                                        </select>
                                    </div>
                                    <div className='col-4'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap" id="user-table">
                                    <thead>
                                        <tr>
                                            <th>{t("#")}</th>
                                            <th>{t("status")}</th>
                                            <th>{t("user id")}</th>
                                            <th>{t("name")}</th>
                                            <th>{t("subject")}</th>
                                            <th>{t("more")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {inquiryList.map((inquiry: any, index) => {

                                            return <tr key={index}>
                                                <td>{inquiry.inquiryId}</td>
                                                <td>{inquiry.answer == null ? "⚪️" :"🟢"}</td>
                                                <td>{inquiry.uid}</td>
                                                <td>{inquiry.name}</td>
                                                <td>{inquiry.subject}</td>
                                                <td>
                                                    <button type="button" className="btn btn-primary" onClick={() => {
                                                        setInquiryPopup({
                                                            inquiryId: inquiry.inquiryId,
                                                            userId: inquiry.userId,
                                                            subject: inquiry.subject,
                                                            content: inquiry.content,
                                                            addedAt: inquiry.addedAt,
                                                            answer: inquiry.answer
                                                        })
                                                    }}>{t("detail")}</button>
                                                </td>
                                            </tr>
                                        })}

                                        {inquiryList.length == 0 && <tr><td colSpan={5} style={{ textAlign: "center" }}>{t("검색 결과가 없습니다.")}</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </div>
}

export default InquiryList;
