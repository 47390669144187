import {removeWindowClass} from '@app/utils/helpers';

export const loginByAuth = async (email: string, password: string) => {
  //Todo: User Login
  //const token = await Gatekeeper.loginByAuth(email, password);
  const token = "1234"
  localStorage.setItem('token', token);
  removeWindowClass('login-page');
  removeWindowClass('hold-transition');
  return token;
};

export const registerByAuth = async (email: string, password: string) => {
  //Todo: User Register
  //const token = await Gatekeeper.registerByAuth(email, password);
  const token = "1234";
  localStorage.setItem('token', token);
  removeWindowClass('register-page');
  removeWindowClass('hold-transition');
  return token;
};
