import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup, { } from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location, Link } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyRakeLogPopup from "@app/components/popup/AgencyRakeLogPopup";
import { AgencyTable } from "./Agency";
import {useTranslation} from "react-i18next";
import { RakePercentage } from "@app/dataset";

const TrRenderWithChildren = ({
    viewAgencyList,
    setAgencyRakeLogPopup,
    setViewAgencyList,
    setShowUpdateAgency,
    children,
    userCount,
    parentRakes,
    depth,
    parentName
}:{
    parentRakes:RakePercentage,
    viewAgencyList: number|undefined,
    setAgencyRakeLogPopup: (a:number)=>void,
    setViewAgencyList: (a:number)=>void
    setShowUpdateAgency: (a:number)=>void,
    children:any[],
    depth?:number,
    userCount:any,
    parentName:string,
})=>{
    const [open, setOpen] = useState<{[key:string]:boolean}>({});
    
    const toggleOpen = (agencyId:number)=>{
        setOpen({
            ...open,
            [agencyId]:!open[agencyId]
        })
    };

    return <>
        {Object.keys(children).length > 0 ? Object.entries(children).map(([agencyId, item])=><>
            <TrRender
                key={agencyId}
                agencyId={Number(agencyId)}
                uid={item.info.uid}
                name={item.info.name}
                agentName={item.info.agentName}
                currentMonthRake={item.info.currentMonthRake}
                rakes={item.info.rakePercentage}
                egg={item.info.egg}
                parentRakes={parentRakes}
                userCount={(item.info.userCount || userCount[item.info.agencyId] || 0) + (item.info.childrenUserCnt || 0)}
                childrenCount={item.info.childrenCount || 0}
                viewAgencyList={viewAgencyList}
                setAgencyRakeLogPopup={setAgencyRakeLogPopup}
                setViewAgencyList={setViewAgencyList}
                setShowUpdateAgency={setShowUpdateAgency}
                depth={Number(depth||0)}
                isSubHQ={item.info.isSubHQ == 1}
                toggleOpen={toggleOpen}
                openMark={open[Number(agencyId)]}
                parentName={parentName}
            />
            {open[Number(agencyId)] && <TrRenderWithChildren 
                key={agencyId+"-children"}
                viewAgencyList={viewAgencyList}
                setAgencyRakeLogPopup={setAgencyRakeLogPopup}
                setViewAgencyList={setViewAgencyList}
                setShowUpdateAgency={setShowUpdateAgency}
                children={item.children}
                userCount={userCount}
                parentRakes={item.info.rakePercentage}
                depth={Number(depth||0)+1}
                parentName={item.info.name}
                />}
        </>) : null}
    </>
}

const CellColor = [
    "#FFDD04",
    "rgba(140,255,172, 1)",
    "rgba(140,255,172, 0.7)",
    "rgba(140,255,172, 0.4)",
    "rgba(140,255,172, 0.1)",
    "rgba(140,255,172, 0)",
]

const TrRender = ({
    isSubHQ,
    uid,
    name,
    agentName,
    parentName,
    agencyId,
    currentMonthRake,
    egg,
    childrenCount,
    userCount,
    viewAgencyList,
    depth,
    rakes,
    parentRakes,
    setAgencyRakeLogPopup,
    setViewAgencyList,
    setShowUpdateAgency,
    toggleOpen,
    openMark
}:{
    isSubHQ?:boolean,
    uid:number,
    name:string,
    agentName:string,
    agencyId:number,
    currentMonthRake:number,
    egg:number,
    childrenCount:number,
    depth?:number,
    userCount: number,
    rakes:RakePercentage,
    parentRakes:RakePercentage|null,
    parentName:string,
    viewAgencyList: number|undefined,
    setAgencyRakeLogPopup: (a:number)=>void,
    setViewAgencyList: (a:number)=>void
    setShowUpdateAgency: (a:number)=>void,
    toggleOpen?: (a:number)=>void,
    openMark?:boolean
})=>{
    const {t} = useTranslation()
    const navigate = useNavigate();

    const color = CellColor[depth||0] || "#FFFFFF";

    return <tr style={{backgroundColor:color}}>
    <td>
        <div style={{display:"inline-block", paddingLeft:((depth||0)*5)+"px"}}>{depth == 0 ? "" : "↳"}</div>
        {depth == 0 ? t("에이전트") : (isSubHQ ? t("부본사") : t("사이트"))}
    </td>
    <td>@{uid}</td>
    <td>{name}</td>
    <td>{t("회원수")} : {userCount || 0}{" "}{childrenCount ? `(${t("하부")} : ${childrenCount})` : "" }</td>
    <td>
        {(Math.floor((Number(currentMonthRake) * 10))/10).toLocaleString()}
        <span style={{ marginLeft: "10px", cursor: "pointer" }} onClick={() => setAgencyRakeLogPopup(agencyId)}>▲</span>
    </td>
    <td>{Number(egg).toLocaleString()}</td>
    <td>
        <div style={{fontSize:"12px"}}>
            <div>{t("레이크 요율")} : <b>{(rakes.BET_RAKE.my||0)}</b> {parentRakes ? <span>{"<<<"}<b>{parentRakes.BET_RAKE.my||0}</b> ({parentName})</span> : ""} </div>
            <div>{t("핸드 패널티")} : <b>{(rakes.HANDS_PENALTY.my||0)}%</b> {parentRakes ? <span>{"<<<"}<b>{parentRakes.HANDS_PENALTY.my||0}%</b> ({parentName})</span> : ""} </div>
            <div>{t("토너먼트 바이인")} : <b>{(rakes.TOURNAMENT_BUYIN.my||0)}%</b> {parentRakes ? <span>{"<<<"}<b>{parentRakes.TOURNAMENT_BUYIN.my||0}%</b> ({parentName})</span> : ""} </div>
        </div>
    </td>
    <td style={{ textAlign: 'center' }}>
        <i style={{ cursor: 'pointer' }} className='fas fa-chart-area' onClick={() => { navigate("/agency/status?agencyId=" + agencyId) }}></i>
    </td>
    <td style={{ textAlign: 'center' }}>
        <i style={{ cursor: 'pointer' }} className='fas fa-edit' onClick={() => { setShowUpdateAgency(agencyId) }}></i>
    </td>
    {childrenCount > 0 || depth == 0 ? 
        <td style={{
            cursor: "pointer",
        }} onClick={() => {
            if(toggleOpen){
                return toggleOpen(agencyId);
            }
            if(agencyId == viewAgencyList){
                setViewAgencyList(0);
            }else{
                setViewAgencyList(agencyId);
            }
        }}>
            {openMark === undefined ? (agencyId == viewAgencyList ? "▲" : "▼") : "" }
            {openMark !== undefined ? (openMark ?  "▲" : "▼") : ""}
        </td>
    : <td></td>}
</tr>
}

const SubHQs = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const [showCreateModal, setShowCreateModal] = useState(false);
    const { token } = useAppSelector(state => state.auth);
    const [agencyList, setAgencyList] = useState([])
    const [agencyTotalCount, setAgencyTotalCount] = useState(0)
    const [inputSearchAgencyName, setInputSearchAgencyName] = useState("");
    const [searchAgencyName, setSearchAgencyName] = useState("");
    const [showUpdateAgency, setShowUpdateAgency] = useState<number>()
    const [paginationData, setPaginationData] = useState({
        currentPage: 0,
        maxPage: 0,
        paginationMin: 0,
        paginationMax: 0,
    })
    const [rowCount, setRowCount] = useState(15)
    const [agencyRakeLogPopup, setAgencyRakeLogPopup] = useState<number>()
    const [viewAgencyList, setViewAgencyList] = useState<number>()
    const [agencies, setAgencies] = useState<any>()
    const [userCount, setUserCount] = useState<any>({});
    const [childrenCount, setChildrenCount] = useState<any>({});

    useEffect(function () {
        setAgencies({children:null})
        async function _() {
            if(viewAgencyList){
                const result = await AdminAPI.getSubLevelAgency(token, viewAgencyList)
                if (result.success) {
                    setAgencies(result.payload[viewAgencyList])
                }
            }
        }
        if(viewAgencyList && viewAgencyList > 0)
            _();
    }, [viewAgencyList])

    const getAgencyList = async (innerPageId: number, innerRowCount: number) => {
        const result = await AdminAPI.getTopLevelSubHQ(
            token,
            innerPageId,
            innerRowCount,
            searchAgencyName
        )
        if (result.success) {
            setAgencyList(result.payload.list)
            setAgencyTotalCount(result.payload.totalCount)
            setUserCount(result.userCount)
            setChildrenCount(result.childrenCount)

            const maxPage = Math.ceil(result.payload.totalCount / innerRowCount)
            const paginationMin = Math.floor(innerPageId / 10) * 10
            const paginationMax = Math.min(paginationMin + 10, maxPage)
            setPaginationData({
                currentPage: innerPageId,
                maxPage: maxPage,
                paginationMin: paginationMin,
                paginationMax: paginationMax,
            })
        }
    }

    useEffect(() => {
        getAgencyList(paginationData.currentPage, rowCount)
    }, [paginationData.currentPage, rowCount, searchAgencyName])

    const onClickSearch = useCallback(function () {
        setSearchAgencyName(inputSearchAgencyName)
    }, [inputSearchAgencyName])

    const onClickCancelSearch = useCallback(function () {
        setInputSearchAgencyName("");
        setSearchAgencyName("");
    }, [])

    return <div>
        <ContentHeader title={t("부본사 목록")} />
        {
            (showCreateModal || showUpdateAgency !== undefined) &&
            <CreateAgencyPopup
                updateAgencyId={showUpdateAgency}
                onSuccess={() => {
                    getAgencyList(paginationData.currentPage, rowCount)
                    if(viewAgencyList){
                        AdminAPI.getSubLevelAgency(token, viewAgencyList).then(result=>{
                            if (result.success) {
                                setAgencies(result.payload[viewAgencyList])
                            }
                        })
                    }
                }}
                onClose={() => { setShowCreateModal(false); setShowUpdateAgency(undefined) }} />
        }
        {agencyRakeLogPopup ? <AgencyRakeLogPopup agencyId={agencyRakeLogPopup} onClose={() => setAgencyRakeLogPopup(0)} /> : null}
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("부본사 이름")}</span>
                                        </div>
                                        <input type="text" value={inputSearchAgencyName} onChange={(v) => setInputSearchAgencyName(v.target.value)} className="form-control" placeholder="Username" />
                                    </div>
                                    <div className='offset-6 col-2'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 790 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>{t("단계")}</th>
                                            <th>ID</th>
                                            <th>{t("이름")}</th>
                                            <th>{t("회원수")}</th>
                                            <th>{t("이번달 수익")}</th>
                                            <th>{t("현재 보유 포인트")}</th>
                                            <th>{t("요율")}</th>
                                            <th>{t("통계")}</th>
                                            <th>
                                                <button type="button" className="btn btn-block btn-primary btn-sm" onClick={() => {
                                                    document.getElementById('modal-default')?.classList.add('show');
                                                    setShowCreateModal(true);
                                                }}>{t("부본사/에이전시 생성")}</button>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            agencyList.map((item: any, index: number) => {
                                                
                                                let childrenRender = null;
                                                if(item.agencyId == viewAgencyList){
                                                    if(agencies.children == null){
                                                        childrenRender =  <tr key={item.agencyId+"-children"}>
                                                            <td colSpan={9} style={{ textAlign: 'center' }}>
                                                                {t("로딩 중...")}
                                                            </td>
                                                        </tr>
                                                    }else if(Object.keys(agencies.children).length == 0){
                                                        childrenRender = <tr key={item.agencyId+"-children"}>
                                                            <td colSpan={9} style={{ textAlign: 'center' }}>
                                                                {t("하위 에이전시가 없습니다.")}
                                                            </td>
                                                        </tr>
                                                    }else {
                                                        childrenRender = <TrRenderWithChildren
                                                            key={item.agencyId+"-children"}
                                                            viewAgencyList={viewAgencyList}
                                                            parentRakes={item.rakePercentage}
                                                            setAgencyRakeLogPopup={setAgencyRakeLogPopup}
                                                            setViewAgencyList={setViewAgencyList}
                                                            setShowUpdateAgency={setShowUpdateAgency}
                                                            children={agencies.children}
                                                            userCount={userCount}
                                                            parentName={item.name}
                                                            depth={1}
                                                        />
                                                    }
                                                }

                                                return <>
                                                    <TrRender
                                                        key={item.agencyId}
                                                        uid={item.uid}
                                                        name={item.name}
                                                        agentName={item.agentName}
                                                        agencyId={item.agencyId}
                                                        currentMonthRake={item.currentMonthRake}
                                                        egg={item.egg}
                                                        rakes={item.rakePercentage}
                                                        parentName={""}
                                                        parentRakes={null}
                                                        childrenCount={(childrenCount[item.agencyId] || 0)-1}
                                                        userCount={userCount[item.agencyId] || 0}
                                                        viewAgencyList={viewAgencyList}
                                                        setAgencyRakeLogPopup={setAgencyRakeLogPopup}
                                                        setViewAgencyList={setViewAgencyList}
                                                        setShowUpdateAgency={setShowUpdateAgency}
                                                        depth={0}
                                                        isSubHQ={item.isSubHQ}
                                                    />
                                                    {childrenRender}
                                                </>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-left">
                                    <li className="page-item">
                                        <div className="page-link no-hover">
                                            {t("표시")}
                                        </div>
                                    </li>
                                    {
                                        [15, 30, 50].map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                                                    cursor: 'pointer'
                                                }} onClick={() => setRowCount(item)}>{item}</div>
                                            </li>
                                        })
                                    }
                                </ul>
                                <ul className="pagination pagination-sm m-0 float-right">
                                    <li className="page-item">
                                        <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                                            onClick={() => {
                                                if (paginationData.paginationMin < 10) {
                                                    return;
                                                } else {
                                                    setPaginationData({
                                                        ...paginationData,
                                                        currentPage: paginationData.paginationMin - 1
                                                    })
                                                }
                                            }}>
                                            <i className="fas fa-chevron-left"></i>
                                        </div>
                                    </li>
                                    {
                                        Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                                            return <li className="page-item" key={index}>
                                                <div
                                                    className={"page-link " + (index + paginationData.paginationMin === paginationData.currentPage ? 'selected' : '')}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => setPaginationData({
                                                        ...paginationData,
                                                        currentPage: index + paginationData.paginationMin
                                                    })}>{index + paginationData.paginationMin + 1}</div>
                                            </li>
                                        })
                                    }
                                    <li className="page-item">
                                        <div
                                            className={"page-link " + (paginationData.paginationMax * rowCount > agencyTotalCount ? 'disabled' : '')}
                                            onClick={() => {
                                                setPaginationData({
                                                    ...paginationData,
                                                    currentPage: paginationData.paginationMax
                                                })
                                            }}>
                                            <i className="fas fa-chevron-right"></i>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default SubHQs;
