import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BET_TYPE } from "@app/dataset";
import { ROOM_TYPE } from "@app/dataset";
import {useTranslation} from "react-i18next";

function act(ss:BET_TYPE){
  switch(ss){
      case BET_TYPE.FOLD:
          return 'FOLD';
      case BET_TYPE.CALL:
          return 'CALL';
      case BET_TYPE.CHECK:
          return 'CHECK';
      case BET_TYPE.RAISE:
          return 'RAISE';
      case BET_TYPE.ALLIN:
          return 'ALL IN';
      case BET_TYPE.BET:
          return 'BET';
      case BET_TYPE.SB:
          return 'SB';
      case BET_TYPE.BB:
          return 'BB';
      case BET_TYPE.STR:
          return 'STR';
      case BET_TYPE.ANTE:
          return 'ANTE';
  }
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const BetHistory = ({roomType} : {roomType:ROOM_TYPE}) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [dataList, setDataList] = useState([])
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);
  const [dataTotalCount, setDataTotalCount] = useState(0)
  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);

  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")
  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))

  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))

  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    maxPage: 0,
    paginationMin: 0,
    paginationMax: 0,
  })
  const [rowCount, setRowCount] = useState(15)
  const getBettingHistory = async (innerPageId: number, innerRowCount: number) => {
    console.log(searchAgencyName)
    const result = await AdminAPI.getBettingHistory({
      session: token,
      pageId: innerPageId,
      rowCount: innerRowCount,
      searchAgencyName,
      searchUserNickname,
      searchUserId,
      searchStartDate,
      searchEndDate,
      roomType:roomType
    });
    if (result.success) {
      setDataList(result.payload.list)
      setDataTotalCount(result.payload.totalCount)

      const maxPage = Math.ceil(result.payload.totalCount / innerRowCount)
      const paginationMin = Math.floor(innerPageId / 10) * 10
      const paginationMax = Math.min(paginationMin + 10, maxPage)
      setPaginationData({
        currentPage: innerPageId,
        maxPage: maxPage,
        paginationMin: paginationMin,
        paginationMax: paginationMax,
      })
    }
  }

  useEffect(() => {
    getBettingHistory(paginationData.currentPage, rowCount)
  }, [paginationData.currentPage, rowCount, searchAgencyName, searchUserNickname, searchUserId, searchStartDate, searchEndDate, roomType])

  const onClickSearch = function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserNickname(inputUserNickname);
    setSearchUserId(inputUserId);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  }

  const onClickCancelSearch = useCallback(function(){
    setSearchAgencyName("");
    setSearchUserNickname("");
    setSearchUserId("");
    setSearchStartDate("");
    setSearchEndDate("");

    setInputAgencyName("");
    setInputUserNickname("");
    setInputUserId("");
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));
  },[])

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}
    <ContentHeader title={ (roomType == ROOM_TYPE.RING ? t("링") : t("토너먼트")) + (" > "+t("배팅 내역"))}/>
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row mb-2'>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>{setInputAgencyName(v.target.value)}} value={inputAgencyName}/>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserNickname(v.target.value)} value={inputUserNickname}/>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserId(v.target.value)} value={inputUserId}/>
                  </div>
                </div>
                <div className='row'>

                  <div className="input-group col-8">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <input type="datetime-local" className="form-control"
                          value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputEndDate(new Date(e.target.value));
                          }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("어제")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("오늘")}</button>
                    </div>
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap">
                  <thead>
                  <tr>
                    <th>{t("베팅")} ID</th>
                    <th style={{
                      textAlign: 'center'
                    }}>{t("방 정보")}
                    </th>
                    <th>{t("게임 넘버")}</th>
                    <th>ID ({t("닉네임")})</th>
                    <th>{t("소속 에이전시")}</th>
                    <th>{t("베팅 시각")}</th>
                    <th>{t("베팅 금액")}</th>
                    <th>{t("보유금")}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {dataList.length == 0 ? <tr>
                    <td colSpan={9}> {t("검색 결과가 없습니다")}.</td>
                  </tr> : null}
                  {
                    dataList.map((item: any, index) => {
                      return <tr key={index}>
                        <td>@{item.betId}</td>
                        <td style={{
                          textAlign: 'center'
                        }}>{
                          item.groupData &&
                         (item.groupType === 0 ? <>
                            <span className="badge bg-gradient-lightblue">{t("토너먼트")}</span><br/>{item.groupData.name}
                          </> : <>
                            <span className="badge bg-gradient-lime">{t("링")}</span><br/>{item.groupData.blind.join('/')}
                          </>)
                        }
                        </td>
                        <td>{item.game}</td>
                        <td>{item.uid} ({item.nickname}) <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setUserDetailPopup(item.userId) }}/></td>
                        <td>{item.agencyName}<i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setAgencyDetailPopup(item.agencyId) }}/></td>
                        <td>{moment.utc(item.bettedAt).local().format(DATE_FORMAT)}</td>
                        <td>{item.bet === 0 ? act(item.type) : item.bet.toLocaleString() + "("+act(item.type)+")"}</td>
                        <td>{Number(item.stackSizeAtBet).toLocaleString()}{t("알")}</td>

                        <td style={{
                          textAlign: 'center'
                        }}>
                          {/* <i style={{
                            cursor: 'pointer'
                          }} className='fas fa-edit'></i> */}
                        </td>
                      </tr>

                    })
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link no-hover">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [15, 30, 50].map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                          cursor: 'pointer'
                        }} onClick={() => setRowCount(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                         onClick={() => {
                           if (paginationData.paginationMin < 10) {
                             return;
                           } else {
                             setPaginationData({
                               ...paginationData,
                               currentPage: paginationData.paginationMin - 1
                             })
                           }
                         }}>
                      <i className="fas fa-chevron-left"></i>
                    </div>
                  </li>
                  {
                    Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div
                          className={"page-link " + (index + paginationData.paginationMin === paginationData.currentPage ? 'selected' : '')}
                          style={{cursor: 'pointer'}}
                          onClick={() => setPaginationData({
                            ...paginationData,
                            currentPage: index + paginationData.paginationMin
                          })}>{index + paginationData.paginationMin + 1}</div>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <div
                      className={"page-link " + (paginationData.paginationMax * rowCount > dataTotalCount ? 'disabled' : '')}
                      onClick={() => {
                        setPaginationData({
                          ...paginationData,
                          currentPage: paginationData.paginationMax
                        })
                      }}>
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default BetHistory;
