import {ContentHeader} from "@components";
import React, {memo, useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import { useSelector } from "react-redux";
import {useTranslation} from "react-i18next";
import { CurrencyMark } from "@app/conversionChip";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";

const CallbackTd = styled.td`
  >div{
    display: inline-block;
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    margin: 0 2px;
    border-radius: 50%;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
  .success{
    color: white;
    background-color: green;
  }
  .failed{
    background-color: red;
    color: black;
  }
  .timeout{
    background-color: yellow;
    color: black;
  }
`

const BackColorTr = styled.tr<{backColor:string}>`
  background-color: ${p=>p.backColor};
`

interface DataDetail {
  enterId:number;
  userId:number;
  currency:string;
  agencyId:number;
  params:{
    "memo": string,
    "type": number,
    "userId": number,
    "groupId": number,
    "extraData": any,
    "chipAmount": number,
    "fiatAmount": number
  },

  searchNextBuyout:{
    enterId:number,
    userId:number,
    params:{
      "memo": string,
      "type": number,
      "userId": number,
      "groupId": number,
      "extraData": any,
      "chipAmount": number,
      "fiatAmount": number
    },
    addedAt:string,
    result:number,
    bet:{
      winAmount:number;
      realBetAmount:number;
      cnt:number;
    }
  } | 2 | 1 | undefined;

  addedAt:string;
  result:number;
  prevBalance:{egg:number, balance:number};
  afterBalance:{egg:number, balance:number};
  memo:string;
  nickname:string;
  agencyName:string;
  blind:string[],
  groupType:number;
}

export function PrintCurrency(currency:string, money:any){
  money = Number(money);
  if(isNaN(money)){
    return CurrencyMark(currency) + "0";
  }

  if(money < 0){
    return <>-{CurrencyMark(currency)}<DigitsColorWrapper number={Math.abs(money)} /></>
  }
  return <>{CurrencyMark(currency)}<DigitsColorWrapper number={money}/></>;
}

function AllCurrency(users:DataDetail[]){
  return Array.from(new Set(users.map(c=>c.currency)))
}

function PrintOnlyCurrency(currency:string, ff:string, users:DataDetail[]):number{
  return users.filter(v=>v.currency === currency).map((v:any)=>isNaN(v[ff])?0:v[ff]).reduce((a,b:any)=>a+Number(b),0);
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const UserAdjustMoney = ({
}:{
}) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    paginationMin: 0,
    paginationMax: 0,
  });
  const [users, setUsers] = useState<DataDetail[]>();
  const user = useSelector((state: any) => state.auth.currentUser);

  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")
  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))
  const [inputGameType, setInputGameType ] = useState(0)

  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))
  const [searchGameType, setSearchGameType ] = useState(inputGameType)

  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const OnLoad = useCallback(function(){
    setUsers(undefined);
    AdminAPI.userBuyinoutLogs(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchUserId, searchGameType, searchStartDate, searchEndDate ).then(v=>{
      let d:DataDetail[] = v.rows.map((v:any)=>{
        return {
          ...v,
          memo: v.memo || "",
        }
      });

      setUsers(d)
      const paginationMin = Math.floor(page / 10) * 10;
      const maxPage = Math.ceil(v.totalCnt / pageNum);
      setPaginationData({
        currentPage: page,
        paginationMin: paginationMin,
        paginationMax: Math.min(paginationMin + 10, maxPage),
      })
    })
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    searchGameType,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    searchGameType,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
  ])

  const onClickSearch = useCallback(function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserId(inputUserId);
    setSearchUserNickname(inputUserNickname);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
    setSearchGameType(inputGameType);
  },[
    inputAgencyName,
    inputUserId,
    inputUserNickname,
    inputStartDate,
    inputEndDate,
    inputGameType
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputAgencyName("");
    setInputUserId("");
    setInputUserNickname("");
    setInputGameType(0);
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));

    setSearchAgencyName("");
    setSearchUserId("");
    setSearchUserNickname("");
    setSearchStartDate("");
    setSearchEndDate("");
    setSearchGameType(0);
  },[])

  const handleSearchNextBuyout = useCallback( async function(enterId:number, userId:number){
    
    setUsers(users?.map(v=>{
      if(v.enterId === enterId && v.userId === userId){
        return {...v, searchNextBuyout:1}
      }
      return v;
    }));

    let result = await AdminAPI.userNextBuyoutLog(token, enterId, userId) as {
      success:boolean,
      row:{
        enterId:number,
        userId:number,
        params:{
          "memo": string,
          "type": number,
          "userId": number,
          "groupId": number,
          "extraData": any,
          "chipAmount": number,
          "fiatAmount": number
        },
        addedAt:string,
        result:number,
        bet:{
          winAmount:number;
          realBetAmount:number;
          cnt:number;
        }
      }
    };

    if(result.success == false){
      setUsers(users?.map(v=>{
        if(v.enterId === enterId && v.userId === userId){
          return {...v, searchNextBuyout:2}
        }
        return v;
      }));
    }else{
      setUsers(users?.map(v=>{
        if(v.enterId === enterId && v.userId === userId){
          return {...v, searchNextBuyout:result.row}
        }
        return v;
      }));
    }

  },[users, setUsers])

  const TR_COLOR = ["#eee", "#fff"];

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}

    <ContentHeader title={t("유저 정산 내역")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
            <div className="card-header">
                <div className='row mb-2'>
                  {user.agencyId == null ? <>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>{setInputAgencyName(v.target.value)}} value={inputAgencyName}/>
                  </div>
                  </>:<></>}

                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserNickname(v.target.value)} value={inputUserNickname}/>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserId(v.target.value)} value={inputUserId}/>
                  </div>
                </div>
                <div className='row'>

                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("블라인드")}</span>
                    </div>
                    <select className="form-control" onChange={(e)=>setInputGameType(parseInt(e.target.value))} value={inputGameType}>
                      <option value="0">{t("전체")}</option>
                      <option value="1">1,000-2,000</option>
                      <option value="2">2,500-5,000</option>
                      <option value="3">5,000-10,000</option>
                      <option value="4">10,000-25,000</option>
                      <option value="5">25,000-50,000</option>
                      <option value="6">50,000-100,000</option>
                    </select>
                  </div>

                  <div className="input-group col-6">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <input type="datetime-local" className="form-control"
                          value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputEndDate(new Date(e.target.value));
                          }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("어제")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("오늘")}</button>
                    </div>
                  </div>

                  <div className='col-3'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th style={{width:"70px",textAlign:"center"}}>#</th>
                    <th style={{width:"70px",textAlign:"center"}}>{t("처리여부")}</th>
                    <th style={{width:"70px",textAlign:"center"}}>{t("방정보")}</th>
                    <th style={{width:"100px"}}>{t("닉네임/에이전시")}</th>
                    <th style={{width:"200px"}}>{t("당시 사이트 보유금")}</th>
                    <th style={{width:"120px"}}>{t("시각")}</th>
                    <th style={{width:"200px"}}>{t("바이인 금액")}</th>
                    <th style={{width:"200px"}}>{t("바이아웃 금액")}</th>
                    <th style={{width:"200px"}}>{t("핸드패널티/핸드수")}</th>
                    <th style={{width:"200px"}}>{t("정산 후 사이트 보유금")}</th>
                    <th style={{width:"200px"}}>{t("메모")}</th>
                    <th style={{width:"200px"}}>{t("바이아웃 서치")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {/* <tr>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>-</th>
                    <th>
                      {AllCurrency(users||[]).map((item, index) => {
                        const buyin = PrintOnlyCurrency(item, "buyin", users||[]);
                        const buyinChip = PrintOnlyCurrency(item, "buyinChip", users||[]);
                        return <div>{Math.abs(buyinChip).toLocaleString()}HP ({PrintCurrency(item, Math.abs(buyin))})</div>
                      })}
                    </th>
                    <th>-</th>
                    <th>
                      {AllCurrency(users||[]).map((item, index) => {
                        const seatOutPenalty = PrintOnlyCurrency(item, "seatOutPenalty", users||[]);
                        const seatOutPenaltyChip = PrintOnlyCurrency(item, "seatOutPenaltyChip", users||[]);
                        return <div>{Math.abs(seatOutPenaltyChip).toLocaleString()}HP ({PrintCurrency(item, Math.abs(seatOutPenalty))})</div>
                      })}
                    </th>
                    <th>
                      {AllCurrency(users||[]).map((item, index) => {
                        const seatOutMoney = PrintOnlyCurrency(item, "seatOutMoney", users||[]);
                        const seatOutMoneyChip = PrintOnlyCurrency(item, "seatOutMoneyChip", users||[]);
                        return <div>{Math.abs(seatOutMoneyChip).toLocaleString()}HP ({PrintCurrency(item, Math.abs(seatOutMoney))})</div>
                      })}
                    </th>
                    <th>
                    {AllCurrency(users||[]).map((item, index) => {
                        const seatOutMoney = PrintOnlyCurrency(item, "seatOutMoney", users||[]);
                        const buyin = PrintOnlyCurrency(item, "buyin", users||[]);

                        const buyinChip = PrintOnlyCurrency(item, "buyinChip", users||[]);
                        const seatOutMoneyChip = PrintOnlyCurrency(item, "seatOutMoneyChip", users||[]);

                        return <div>{(seatOutMoneyChip+buyinChip).toLocaleString()}HP ({PrintCurrency(item, (buyin+seatOutMoney))})</div>
                    })}
                    </th>
                    <th>-</th>
                    <th>-</th>
                  </tr> */}
                  {
                    users ? users.map((item, _index) => {
                      return <BackColorTr key={_index} backColor={TR_COLOR[_index%2]} style={{ borderTop: "2px solid #ccc" }}>
                        <td>#{item.enterId}</td>
                        <td style={{textAlign:"center"}}>{item.result == 1 ? "🟢":"🔴"}</td>
                        <td style={{textAlign:"center"}}>
                          {(item.groupType === 0 ? <>
                            <span className="badge bg-gradient-lightblue">{t("토너먼트")}</span><br/>
                          </> : <>
                            <span className="badge bg-gradient-lime">{t("링")}</span><br/>{(item.blind||[]).join("-")}
                          </>)}
                        </td>

                        <td>
                          <span onClick={(e)=>{
                            e.preventDefault();
                            setUserDetailPopup(item.userId);
                          }}>{item.nickname}</span>

                          #<a href="#" onClick={(e)=>{
                            e.preventDefault();
                            setAgencyDetailPopup(item.agencyId);
                          }}>{item.agencyName}</a>
                        </td>
                        <td>
                          {PrintCurrency(item.currency, item.prevBalance.balance)}
                        </td>
                        <td>{moment(item.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                        <td>{ (item.params.memo == "room buyin" || item.params.memo == "room rebuyin") ? <>
                          {Math.abs(item.params.chipAmount).toLocaleString()}HP<br/>
                          ({PrintCurrency(item.currency, item.params.fiatAmount)})
                        </> : <>-</>}</td>
                        <td>{ (item.params.memo != "room buyin" && item.params.memo != "room rebuyin") ?<>
                          {Math.abs(item.params.chipAmount).toLocaleString()}HP<br/>
                          ({PrintCurrency(item.currency, item.params.fiatAmount)})
                        </> : <>-</>}
                        </td>
                        <td>{ (item.params.memo != "room buyin" && item.params.memo != "room rebuyin") ?<div style={{fontSize:"12px"}}>
                            {t("핸즈")}: <b>{item.params.extraData?.hands || 0}</b><br/>
                            {t("패널티")}: <b>{PrintCurrency(item.currency, item.params.extraData?.penalty)}</b>
                        </div>:<></>}</td>
                        <td>{PrintCurrency(item.currency, item.afterBalance.balance)}</td>
                        <td>{item.memo}</td>
                        <td>
                          {/* 기능상 더이상 사용되기 어려워 삭제.
                          {item.searchNextBuyout ? <>
                            {item.searchNextBuyout === 1 ? <div className="btn-group">
                              {t("로딩 중...")}
                            </div> : (
                              item.searchNextBuyout === 2 ? <div className="btn-group">
                                {t("검색되지 않음")}
                              </div> : <div style={{fontSize:"12px"}}>
                                {item.searchNextBuyout.params == null ? <>
                                  {t("게임 중 혹은 로비정산")}<br/>
                                  {t("배팅")} : <b>{item.searchNextBuyout.bet.realBetAmount.toLocaleString()}Hp</b><br/>
                                  {t("당첨")} : <b>{item.searchNextBuyout.bet.winAmount.toLocaleString()}Hp</b><br/>
                                  {t("토탈 플레이")} : <b>{item.searchNextBuyout.bet.cnt.toLocaleString()}{t("회")}</b><br/>
                                </> : <>
                                  {item.searchNextBuyout.params.memo == "room buyin" ? <>
                                    #{item.searchNextBuyout.enterId}{item.searchNextBuyout.result == 1 ? "🟢":"🔴"}<br/>

                                    {t("바이인 시각")} : <b>{moment(item.searchNextBuyout.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}</b><br/>
                                    {t("바이인 금액")} : <b>{PrintCurrency(item.currency, item.searchNextBuyout.params.fiatAmount)}</b><br/>
                                    {t("토탈 플레이")} : <b>{item.searchNextBuyout.bet.cnt.toLocaleString() || 0}{t("회")}</b><br/>
                                    {t("배팅")} : <b>{item.searchNextBuyout.bet.realBetAmount.toLocaleString()}Hp</b><br/>
                                    {t("당첨")} : <b>{item.searchNextBuyout.bet.winAmount.toLocaleString()}Hp</b><br/>
                                  </>:<>
                                    #{item.searchNextBuyout.enterId}{item.searchNextBuyout.result == 1 ? "🟢":"🔴"}<br/>

                                    {t("바이아웃 시각")} : <b>{moment(item.searchNextBuyout.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}</b><br/>
                                    {t("바이아웃 금액")} : <b>{PrintCurrency(item.currency, item.searchNextBuyout.params.fiatAmount)}</b><br/>
                                    {t("바이아웃 금액 패널티")} : <b>{PrintCurrency(item.currency, item.searchNextBuyout.params.extraData?.penalty)}</b><br/>
                                    {t("핸즈")} : <b>{item.searchNextBuyout.params.extraData?.hands || 0}</b><br/>
                                    {t("배팅")} : <b>{item.searchNextBuyout.bet.realBetAmount.toLocaleString()}Hp</b><br/>
                                    {t("당첨")} : <b>{item.searchNextBuyout.bet.winAmount.toLocaleString()}Hp</b><br/>
                                  </>}
                                </>}
                              </div> 
                            )}
                          </> : <> 
                          { item.params.memo == "room buyin" ? <>
                              <div className="btn-group">
                                <button className="btn btn-primary btn-xs" onClick={()=>handleSearchNextBuyout(item.enterId, item.userId)}>{t("확인하기")}</button>
                              </div>
                          </> : <>
                            <div className="btn-group">
                              <button className="btn btn-warning btn-xs" onClick={()=>handleSearchNextBuyout(item.enterId, item.userId)}>{t("확인하기")}</button>
                            </div>
                          </>}
                        </>} */}
                      </td> 
                      </BackColorTr>
                      }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  {users ? users.length === 0 ? <tr><td colSpan={9}>{t("검색 결과가 없습니다")}.</td></tr> : <></> : <></>}
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                  </li>
                  {
                    Array(paginationData.paginationMax).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <a style={{backgroundColor:paginationData.currentPage==index?"#ddd":"#fff"}} className="page-link" href="#user-table" onClick={()=>setPage(index)}>{index + 1}</a>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
}

export default UserAdjustMoney;
