import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";
import StandaloneAdjustMoneyPopup from "@app/components/popup/StandaloneAdjustPopup";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
var end = new Date();
end.setHours(23,59,59,999);

const StandaloneAdjustmentMoney = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [dataList, setDataList] = useState([])
  const [dataTotalCount, setDataTotalCount] = useState(0)
  const [standaloneAdjustMoneyPopup, setStandaloneAdjustMoneyPopup] = useState<number | null>(null);
  const [inputAgencyName, setInputAgencyName] = useState("")
  const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate] = useState(new Date((end.getTime())))

  const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT))

  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    maxPage: 0,
    paginationMin: 0,
    paginationMax: 0,
  })
  const [rowCount, setRowCount] = useState(15)

  const getAdjustmentHistory = async (innerPageId: number, innerRowCount: number) => {
    const result = await AdminAPI.getStandaloneAdjustmentHistory(token, innerPageId, innerRowCount, searchStartDate, searchEndDate)

    if (result.success) {
      setDataList(result.rows)
      setDataTotalCount(result.totalCnt)

      const maxPage = Math.ceil(result.totalCnt / innerRowCount)
      const paginationMin = Math.floor(innerPageId / 10) * 10
      const paginationMax = Math.min(paginationMin + 10, maxPage)
      setPaginationData({
        currentPage: innerPageId,
        maxPage: maxPage,
        paginationMin: paginationMin,
        paginationMax: paginationMax,
      })
    }
  }

  useEffect(() => {
    getAdjustmentHistory(paginationData.currentPage, rowCount)
  }, [paginationData.currentPage, rowCount, searchStartDate, searchEndDate])

  const onClickSearch = function(){
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  }

  const onClickCancelSearch = useCallback(function(){
    setSearchStartDate("");
    setSearchEndDate("");

    setInputAgencyName("");
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));
  },[])

  const onCloseStandaloneAdjustMoneyPopup = useCallback(function(){
    setStandaloneAdjustMoneyPopup(null);
    getAdjustmentHistory(paginationData.currentPage, rowCount)
  },[])

  return <div>
    {standaloneAdjustMoneyPopup !== null ? <StandaloneAdjustMoneyPopup onClose={onCloseStandaloneAdjustMoneyPopup} /> : <></>}
    <section className="content">
      <div className="container-fluid">
        <h3>{t("유저 보유금 수동관리")}</h3>
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row'>
                  <div className="input-group col-8">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <input type="datetime-local" className="form-control"
                          value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputEndDate(new Date(e.target.value));
                          }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("어제")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("오늘")}</button>
                    </div>
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap">
                  <thead>
                  <tr>
                    <th>{t("로그")} ID</th>
                    <th>{t("유저정보")}</th>
                    <th>{t("지급액")}</th>
                    <th>{t("지급 시각")}</th>
                    <th>{t("반영전 잔액")}</th>
                    <th>{t("반영후 잔액")}</th>
                    <th>{t("메모")}</th>
                    <th>
                      <button type="button" className="btn btn-block btn-primary btn-sm" onClick={() => {
                        document.getElementById('modal-default')?.classList.add('show');
                        setStandaloneAdjustMoneyPopup(1);
                      }}>{t("유저 보유금 변경")}</button>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {dataList.length == 0 ? <tr>
                    <td colSpan={9}> {t("검색 결과가 없습니다")}.</td>
                  </tr> : null}
                  {
                    dataList.map((item: any, index) => {
                      return <tr key={index}>
                        <td>{item.id}</td>
                        <td>
                            {t("아이디")} : {item.uid}<br />
                            {t("닉네임")} : {item.userNickName}<br />
                            {t("에이전시")} : {item.agencyName}
                        </td>
                        <td>{Number(item.amount).toLocaleString()}{t("원")}</td>
                        <td>{moment.utc(item.addedAt).local().format(DATE_FORMAT)}</td>
                        <td>{Number(item.beforeBalance).toLocaleString()}{t("원")}</td>
                        <td>{Number(item.afterBalance).toLocaleString()}{t("원")}</td>
                        <td>{item.memo}</td>
                        <td></td>
                      </tr>
                    })
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link no-hover">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [15, 30, 50].map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                          cursor: 'pointer'
                        }} onClick={() => setRowCount(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                         onClick={() => {
                           if (paginationData.paginationMin < 10) {
                             return;
                           } else {
                             setPaginationData({
                               ...paginationData,
                               currentPage: paginationData.paginationMin - 1
                             })
                           }
                         }}>
                      <i className="fas fa-chevron-left"></i>
                    </div>
                  </li>
                  {
                    Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div
                          className={"page-link " + (index + paginationData.paginationMin === paginationData.currentPage ? 'selected' : '')}
                          style={{cursor: 'pointer'}}
                          onClick={() => setPaginationData({
                            ...paginationData,
                            currentPage: index + paginationData.paginationMin
                          })}>{index + paginationData.paginationMin + 1}</div>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <div
                      className={"page-link " + (paginationData.paginationMax * rowCount > dataTotalCount ? 'disabled' : '')}
                      onClick={() => {
                        setPaginationData({
                          ...paginationData,
                          currentPage: paginationData.paginationMax
                        })
                      }}>
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
}

export default StandaloneAdjustmentMoney;

