import PopupContainer from "@app/components/popup/PopupContainer";
import {useTranslation} from "react-i18next";
import { API_VERSION } from "@app/pages/apidocument/ApiViewer";
interface AlertNewStandaloneRequestPopupProps {
  onClose: () => void;
}

const AlertNewStandaloneRequestPopup = ({onClose}: AlertNewStandaloneRequestPopupProps) => {
  const {t} = useTranslation();

  return <PopupContainer onClickBackdrop={onClose} maxWidth="720px">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("알림")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-10 col-form-label white-space-pre-line">{t("신규 충환 요청이 있습니다. 확인해주세요.")}</label>
          </div>
          <div className="float-right">
            <button onClick={onClose} className="btn btn-sm btn-secondary">{t("확인했습니다")}</button>
          </div>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AlertNewStandaloneRequestPopup;
