// import React, { useEffect } from "react";
// import moment from 'moment';
// import { useAppSelector } from "@app/store/hooks";
// import { useSelector } from "react-redux";
// import AdminAPI from "@app/api/admin";
// import { useParams } from "react-router-dom";
// import styled from "styled-components";
// import {useTranslation} from "react-i18next";
// import DigitsColorWrapper from "@app/components/DigitsColorWrapper";


// const SmallDesc = styled.div`
//   font-size: 0.8em;
//   color: #666;
// `;

// const months:string[] = [];
// for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
//   months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
// }

// interface Params {
//   user:{uid:string, agencyId:number};
// }
// const TotalTr = styled.tr`
//   >td{
//     font-weight: bold;
//   }
// `;

// const EggDistribution = ( {
//   user
// } : Params ) => {
//   const {t} = useTranslation()
//   const {token} = useAppSelector(state => state.auth);
//   const [currentMonth, setCurrentMonth] = React.useState(moment().utc().format('YYYY-MM'));
//   const [list, setList] = React.useState<{
//     day:string,
//     request:number,
//     buyin:number,
//     cashout:number,
//     // rake:number
//   }[] | null>(null);

//   const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
//     setCurrentMonth(e.target.value);
//   }

//   useEffect(function () {
//     setList(null);
//     AdminAPI.agencyStatistics_2(token, currentMonth, user.agencyId).then((v)=>{
//       setList(v.rows.map((item:any)=>{
//         return {
//           day: item.day,
//           request: Math.round(item.request),
//           buyin: Math.round(item.buyin),
//           cashout: Math.round(item.cashout),
//           // rake: Math.round(item.rake)
//         }
//       }))
//     })
//   },[currentMonth]);

//   const totRequest = Math.abs(list ? list.reduce( (a,b)=>a+b.request, 0 ): 0);
//   const totBuyin = Math.abs(list ? list.reduce( (a,b)=>a+b.buyin, 0 ): 0);
//   const totCashout = Math.abs(list ? list.reduce( (a,b)=>a+b.cashout, 0 ): 0);
//   // const totRake = Math.abs(list ? list.reduce( (a,b)=>a+b.rake, 0 ): 0);

//   return <>
//     <h3 className='mt-4'>{user.uid} (@{user.agencyId}) {t("에이전시의 유통 통계")}</h3>
//     <h4>
//       {currentMonth} {t("유통 총액")}: <DigitsColorWrapper number={((totCashout+totRequest) - totBuyin)}/>{t("HP")}
//     </h4>
//     <h6>
//       {t("매일 0시 기준으로 업데이트되며, 소수점 첫째 자리에서 반올림됩니다.")}
//     </h6>
//     <div className="row">
//       <div className="col-12">
//         <div className="card">
//           <div className="card-header">
//             <div className='row mb-2'>
//               <div className="offset-8 input-group col-4">
//                 <div className="input-group-prepend">
//                   <span className="input-group-text">월별 유통량</span>
//                 </div>
//                 <select className="form-control" onChange={onChangeMonth}>
//                   {
//                     months.map((item, index) => {
//                       return <option value={item}>{item}</option>
//                     })
//                   }
//                 </select>
//               </div>
//             </div>
//           </div>
//           <div className="card-body table-responsive p-0" style={{height: 785}}>
//             {list ? <table className="table table-head-fixed text-nowrap">
//               <thead>
//               <tr>
//                 <th>일자</th>
//                 <th>포인트 충전</th>
//                 <th>포인트 사용</th>
//                 <th>포인트 회수</th>
//                 {/* <th>알 획득(rake)</th> */}
//                 <th>증감</th>
//               </tr>
//               </thead>
//               <tbody>
//                 <TotalTr>
//                   <td>합계</td>
//                   <td>{totRequest.toLocaleString()}</td>
//                   <td>{(-totBuyin).toLocaleString()}</td>
//                   <td>{totCashout.toLocaleString()}</td>
//                   {/* <td>{totRake.toLocaleString()}</td> */}
//                   <td>
//                     사용 + 회수 = {Number((totCashout) - totBuyin).toLocaleString()}
//                     <SmallDesc>
//                       (충전 + 사용 + 회수) = {Number((totCashout+totRequest) - totBuyin).toLocaleString()}
//                     </SmallDesc>
//                   </td>
//                 </TotalTr>
//               {
//                 list.map((item, index) => {
//                   return <tr key={index}>
//                     <td>{item.day}</td>
//                     <td>{Number(item.request).toLocaleString()}</td>
//                     <td>{Number(item.buyin).toLocaleString()}</td>
//                     <td>{Number(item.cashout).toLocaleString()}</td>
//                     {/* <td>{Number(item.rake).toLocaleString()}</td> */}
//                     <td>
//                       {Number(item.buyin + (item.request + item.cashout)).toLocaleString()}
//                     </td>
//                   </tr>
//                 })
//               }
//               </tbody>
//             </table> : "loading.."}
//           </div>
//         </div>
//       </div>
//     </div>
//   </>
// }
// export default EggDistribution;


import React, { useEffect } from "react";
import moment from 'moment';
import { useSelector } from "react-redux";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@app/store/hooks";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const months: string[] = [];
for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
  months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
}

interface Params {
  user: { uid: string, agencyId: number };
}
const TotalTr = styled.tr`
  >td{
    font-weight: bold;
  }
`;

const EggDistribution = ({
  user
}: Params) => {
  const { t } = useTranslation()
  const { token } = useAppSelector(state => state.auth);
  const [currentMonth, setCurrentMonth] = React.useState(moment().utc().format('YYYY-MM'));

  const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentMonth(e.target.value);
  }

  const [list, setList] = React.useState<{
    day: string,
    buyin: number,
    buyout: number,
    tournamentBuyin: number,
    tournamentPrize: number,
    rake: number,
    request: number,
    rakeback: number,
  }[] | null>([]);

  useEffect(function () {
    setList(null);
    AdminAPI.agencyStatistics_2(token, currentMonth, user.agencyId).then((v) => {
      setList(v.rows.map((item: any) => {
        return {
          day: item.day,
          request: Math.round(item.request),
          buyin: Math.round(item.buyin),
          buyout: Math.round(item.buyout),
          tournamentBuyin: Math.round(item.tournamentBuyin),
          tournamentPrize: Math.round(item.tournamentPrize),
          rake: Math.round(item.rake),
          rakeback: Math.round(item.rakeback),
        }
      }))
    })
  }, [currentMonth]);

  return <>
    <h3 className='mt-4'>{user.uid} (@{user.agencyId}) {t("홀덤 포인트 통계")}</h3>
    <h6>
      {t("매일 0시 기준으로 업데이트되며, 소수점 첫째 자리에서 반올림됩니다")}.
    </h6>
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className='row mb-2'>
              <div className="offset-8 input-group col-4">
                <div className="input-group-prepend">
                  <span className="input-group-text">{t("월별 조회")}</span>
                </div>
                <select className="form-control" onChange={onChangeMonth}>
                  {
                    months.map((item, index) => {
                      return <option value={item}>{item}</option>
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className="card-body table-responsive p-0" style={{ height: 785 }}>
            {list ?
              <table className="table table-head-fixed text-nowrap">
                <thead>
                  <tr>
                    <th>{t("일자")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("포인트 충전")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("바이인")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("바이아웃")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("토너먼트 바이인")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("상금 수령액")}</th>
                    <th style={{ backgroundColor: "#fff" }}>{t("레이크백")}</th>
                  </tr>
                </thead>
                <tbody>
                  <TotalTr>
                    <td>{t("합계")}</td>
                    <td style={{ backgroundColor: "#fff" }}>{list.reduce((a, b) => a + b.request, 0).toLocaleString()}</td>
                    <td style={{ backgroundColor: "#fff" }}>{list.reduce((a, b) => a + b.buyin, 0).toLocaleString()}</td>
                    <td style={{ backgroundColor: "#fff" }}>{list.reduce((a, b) => a + b.buyout, 0).toLocaleString()}</td>
                    <td style={{ backgroundColor: "#fff" }}>{list.reduce((a, b) => a + b.tournamentBuyin, 0).toLocaleString()}</td>
                    <td style={{ backgroundColor: "#fff" }}>{list.reduce((a, b) => a + b.tournamentPrize, 0).toLocaleString()}</td>
                    <td style={{ backgroundColor: "#fff" }}>{list.reduce((a, b) => a + b.rakeback, 0).toLocaleString()}</td>
                  </TotalTr>
                  {
                    list.map((item, index) => {
                      return <tr key={index}>
                        <td>{item.day}</td>
                        <td style={{ backgroundColor: "#fff" }}>{Number(item.request).toLocaleString()}</td>
                        <td style={{ backgroundColor: "#fff" }}>{Number(item.buyin).toLocaleString()}</td>
                        <td style={{ backgroundColor: "#fff" }}>{Number(item.buyout).toLocaleString()}</td>
                        <td style={{ backgroundColor: "#fff" }}>{Number(item.tournamentBuyin).toLocaleString()}</td>
                        <td style={{ backgroundColor: "#fff" }}>{Number(item.tournamentPrize).toLocaleString()}</td>
                        <td style={{ backgroundColor: "#fff" }}>{Number(item.rakeback).toLocaleString()}</td>
                      </tr>
                    })
                  }
                </tbody>
              </table> : <>{t("로딩 중...")}</> }
          </div>
        </div>
      </div>
    </div>
  </>
}
export default EggDistribution;
