import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useEffect, useRef, useState } from "react";
import { sha256 } from "js-sha256";
import api from "../../api/admin"
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface AgencyDetailPopupProps {
    userId?: number;
    onClose: () => void;
}

const GroupName = styled.span`
    font-size: 13px;
`
const Amount = styled.span`
    font-size: 13px;
    font-weight: bold;
`

const SumTr = styled.tr`
    font-weight: bold;
    text-align: center;
    background-color: beige;

    td{
        padding: 2px;
    }
`

const AgencyInGameMoneyPopup = ({
    onClose,
    userId
}: AgencyDetailPopupProps) => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [agencys, setAgencys] = useState<{
        agencyId: string,
        name: string,
        egg: number
    }[]>();
    const [selectedAgency, setSelectedAgency] = useState(0);

    const [groups, setGroups] = useState<{
        groupId:number,
        blind:number[],
        type:number,
        name:string,
    }[]>();
    const [viewInfoList, setViewInfoList] = useState<{
        "userId": number,
        "nickname": string,
        "agencyName": string,
        "uid": string,
        "vaults": {
            "userId":number,
            "groupId":number,
            "amount":number,
            "hands":number,
            "buyin":number,
        }[],
        "rooms": {
            roomId:number,
            groupId:number,
            stackSize:number,
        }[]
    }[]>();

    useEffect(function () {
        api.agencyNameList(token).then(v => {
            setAgencys(v.rows);
        })
    }, []);

    const onClickSearch = async function () {
        api.agencyUserListForViewMoney(token, selectedAgency).then(v => {
            if(v.success){
                setViewInfoList(v.rows);
                setGroups(v.groups);
            }else{
                alert("조회된 유저가 없습니다.")
            }
        })
    }

    return <PopupContainer onClickBackdrop={onClose} maxWidth={"550px"}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{t("에이전시 유저 게임 내 현재 보유액 조회")}</h4>
                    <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="form-group row">
                        <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("에이전시")}</label>
                        <div className="col-sm-9">
                            <select className="form-control" onChange={v => setSelectedAgency(Number(v.target.value))} >
                                <option value={0}>{t("선택하세요")}</option>
                                {agencys?.map(v => <option key={v.agencyId} value={v.agencyId}>{v.name}</option>)}
                            </select>
                            <button type="button" className="btn btn-primary" onClick={onClickSearch}>{t("검색")}</button>
                        </div>
                    </div>

                    {/* {viewInfo && <>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("닉네임")}</label>
                            <div className="col-sm-9">
                                <input type="text" className="form-control" value={viewInfo.nickname} readOnly />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("게임 내 보유액")}</label>
                            <div className="col-sm-9">
                                {viewInfo.currentRooms.length == 0 ? `${t("게임 내 보유액이 없습니다")}.` : <table className="table table-head-fixed text-nowrap">
                                    <tr>
                                        <th>{t("그룹아이디")}</th>
                                        <th>{t("이름")}</th>
                                        <th>{t("블라인드")}</th>
                                        <th>{t("보유액")}</th>
                                    </tr>
                                    {viewInfo.currentRooms.map(v => <tr>
                                        <td>{v.groupId}</td>
                                        <td>{v.name || t("링게임")}</td>
                                        <td>{v.blind.join("-")}</td>
                                        <td>{v.userMoney.toLocaleString()}{t("알")}</td>
                                    </tr>)}
                                </table>}
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("로비 정산 대기금")}</label>
                            <div className="col-sm-9">
                                {viewInfo.currentLobbys.length == 0 ? `${t("로비 정산 금액이 없습니다")}.` : <table className="table table-head-fixed text-nowrap">
                                    <tr>
                                        <th>{t("그룹아이디")}</th>
                                        <th>{t("블라인드")}</th>
                                        <th>{t("보유액")}</th>
                                    </tr>
                                    {viewInfo.currentLobbys.map(v => <tr>
                                        <td>{v.groupId}</td>
                                        <td>{v.blind.join("-")}</td>
                                        <td>{v.userMoney.toLocaleString()}{t("알")}</td>
                                    </tr>)}
                                </table>}
                            </div>
                        </div>
                    </>} */}

                    {viewInfoList && viewInfoList.length > 0 && <>
                        <div className="card-body table-responsive p-0" style={{ height: 550 }}>
                            <table className="table table-head-fixed text-nowrap">
                                <tr style={{textAlign:"center"}}>
                                    <th>{t("닉네임")}</th>
                                    <th>{t("로비 정산금")}</th>
                                    <th>{t("게임 스텍")}</th>
                                </tr>
                                <SumTr>
                                    <td>합계</td>
                                    <td>
                                        {viewInfoList.reduce((acc, v) => {
                                            return acc + v.vaults.reduce((acc, v) => acc + v.amount, 0);
                                        }, 0).toLocaleString()} 포인트
                                    </td>
                                    <td>
                                        {viewInfoList.reduce((acc, v) => {
                                            return acc + v.rooms.reduce((acc, v) => acc + v.stackSize, 0);
                                        }, 0).toLocaleString()} 포인트
                                    </td>
                                </SumTr>
                                {viewInfoList.map(v => {
                                    return <tr>
                                    <td>{v.nickname}({v.uid})</td>
                                    <td>{v.vaults.map((v)=>{
                                        let group = groups?.find(g=>g.groupId == v.groupId);
                                        return <>
                                            <div>
                                                <GroupName>{group?.name || group?.blind.join("-")}</GroupName> : 
                                                <Amount>{v.amount.toLocaleString()} {t("포인트")}</Amount>
                                            </div>
                                        </>
                                    })}</td>
                                    <td>{v.rooms.map(v=>{
                                        let group = groups?.find(g=>g.groupId == v.groupId);
                                        return <>
                                            <div>
                                                <GroupName>{group?.name || group?.blind.join("-")}</GroupName> : 
                                                <Amount>{v.stackSize.toLocaleString()} {t("포인트")}</Amount>
                                            </div>
                                        </>
                                    })}</td>
                                </tr>})}
                            </table>
                        </div>
                    </>}
                </div>

                <div className="modal-footer justify-content-between">
                    <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>
    </PopupContainer>
}

export default AgencyInGameMoneyPopup;
