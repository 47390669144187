import React, {useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import AdminAPI from "@app/api/admin";
import {logoutUser} from "@store/reducers/auth";
import { useTranslation } from 'react-i18next';

const PrivateRoute = () => {
  const {t} = useTranslation();
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);
  const token = useSelector((state: any) => state.auth.token);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(token){
      AdminAPI.getInfo({session: token}).then(res => {
        if(res.success){
          console.log('res.payload', res.payload)
        }else{
          dispatch(logoutUser());
          alert(t("로그인이 만료되었습니다"))
        }
      })
    }
  }, [token])

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoute;
