import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup, { } from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location, Link } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyRakeLogPopup from "@app/components/popup/AgencyRakeLogPopup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PrintCurrency } from "./UserAdjustMoney";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import SortBox from "@app/modules/main/sort-box/SortBox";

export enum PROFIT_TYPE {
  NONE,
  POT_RAKE,
  HAND_RAKE,
  LEAVEROOM_HAND_LOSS,
  TOURNAMENT_BUYIN
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const AgencyProfitList = () => {
  const { t } = useTranslation();
  const { token } = useAppSelector(state => state.auth);
  const [agencyList, setAgencyList] = useState<{
    agencyId:number;
    agencyName:string;

    [PROFIT_TYPE.POT_RAKE]:{
      currency:string;
      agencyAmount:number;
      fiatAmountAgency:number;
      krwAmountAgency:number;
    },

    [PROFIT_TYPE.LEAVEROOM_HAND_LOSS]:{
      currency:string;
      agencyAmount:number;
      fiatAmountAgency:number;
      krwAmountAgency:number;
    }

    [PROFIT_TYPE.TOURNAMENT_BUYIN]:{
      currency:string;
      agencyAmount:number;
      fiatAmountAgency:number;
      krwAmountAgency:number;
    }
  }[]>([])

  const [inputStartDate, setInputStartDate] = React.useState(new Date(Date.now() - 1000 * 60 * 60 * 24 * 3));
  const [inputEndDate, setInputEndDate] = React.useState(new Date());
  const [searchStartDate, setSearchStartDate] = React.useState(moment(inputStartDate).format(DATE_FORMAT));
  const [searchEndDate, setSearchEndDate] = React.useState(moment(inputEndDate).format(DATE_FORMAT));
  const [sortKey, setSortKey] = React.useState(PROFIT_TYPE.POT_RAKE);
  const [sortType, setSortType] = React.useState("");
  const [totalData, setTotalData] = React.useState<{[key in number]:{agencyAmount:number, krwAmountAgency:number}}>({
    [PROFIT_TYPE.POT_RAKE]:{agencyAmount:0, krwAmountAgency:0},
    [PROFIT_TYPE.LEAVEROOM_HAND_LOSS]:{agencyAmount:0, krwAmountAgency:0},
    [PROFIT_TYPE.TOURNAMENT_BUYIN]:{agencyAmount:0, krwAmountAgency:0},
  });

  const getAgencyList = async (searchStartDate: string, searchEndDate: string) => {
    const response = await AdminAPI.getAgencyProfitList(token, searchStartDate, searchEndDate);
    let r = response.rows.reduce((a:any,b:any) => {
      a[b.agencyId] = a[b.agencyId] || {agencyId:b.agencyId, agencyName:b.agencyName};
      a[b.agencyId][b.profitType] = b;
      return a;
    },{});
    setSortKey(PROFIT_TYPE.POT_RAKE);
    setSortType("");
    setAgencyList(Object.values(r));
    response.rows.map((data:any) => {
      totalData[data.profitType].agencyAmount += Number(data.agencyAmount);
      totalData[data.profitType].krwAmountAgency += Number(data.krwAmountAgency);
    });
    setTotalData(totalData);
  }

  useEffect(() => {
    getAgencyList(searchStartDate, searchEndDate)
  }, [searchStartDate, searchEndDate])

  const onClickSearch = useCallback(function () {
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  }, [
    inputStartDate,
    inputEndDate,
  ])

  const onClickCancelSearch = useCallback(function () {
    setSearchStartDate(moment().add(-1, "days").format(DATE_FORMAT))
    setSearchEndDate(moment().add(0, "months").format(DATE_FORMAT))
  }, [])

  const onSort = useCallback(function (sortKey: number, sortType: string) {
    setSortKey(sortKey)
    setSortType(sortType)

    agencyList.sort((a:any, b:any) => {
      if(a[sortKey] == null || b[sortKey] == null) return 0;

      if(sortType == "asc") {
        return a[sortKey].agencyAmount - b[sortKey].agencyAmount;
      }
      return b[sortKey].agencyAmount - a[sortKey].agencyAmount;
    })
    setAgencyList([...agencyList])
  }, [agencyList])

  return <div>
    <ContentHeader title={t("에이전시 수익 목록")} />
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row'>

                  <div className="input-group col-6">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                      value={moment(inputStartDate).format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => {
                        setInputStartDate(new Date(e.target.value))
                      }}
                    />
                    <input type="datetime-local" className="form-control"
                      value={moment(inputEndDate).format("YYYY-MM-DDTHH:mm")}
                      onChange={(e) => {
                        setInputEndDate(new Date(e.target.value))
                      }}
                    />
                  </div>

                  <div className='col-3'>
                    <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th>{t("에이전시 ID")}</th>
                      <th>{t("에이전시명")}</th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center position-relative">
                          {t("레이크")}
                          <SortBox sortKey={PROFIT_TYPE.POT_RAKE} sortType={sortType} nowSortKey={sortKey} onSort={onSort} />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center position-relative">
                          {t("핸드 패널티")}
                          <SortBox sortKey={PROFIT_TYPE.LEAVEROOM_HAND_LOSS} sortType={sortType} nowSortKey={sortKey} onSort={onSort} />
                        </div>
                      </th>
                      <th>
                        <div className="d-flex align-items-center justify-content-center position-relative">
                          {t("토너먼트 바이인")}
                          <SortBox sortKey={PROFIT_TYPE.TOURNAMENT_BUYIN} sortType={sortType} nowSortKey={sortKey}  onSort={onSort} />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <td></td>
                    <td></td>
                    <td style={{textAlign:"center"}}><b>{t("합계")}</b> : 
                      <DigitsColorWrapper fontSize={13} number={totalData[PROFIT_TYPE.POT_RAKE].agencyAmount}/>HP
                      <span style={{fontSize:"13px", fontWeight:"bold"}}>({PrintCurrency("KRW", totalData[PROFIT_TYPE.POT_RAKE].krwAmountAgency)})</span>
                    </td>
                    <td style={{textAlign:"center"}}><b>{t("합계")}</b> : 
                      <DigitsColorWrapper fontSize={13} number={totalData[PROFIT_TYPE.LEAVEROOM_HAND_LOSS].agencyAmount}/>HP
                      <span style={{fontSize:"13px", fontWeight:"bold"}}>({PrintCurrency("KRW", totalData[PROFIT_TYPE.LEAVEROOM_HAND_LOSS].krwAmountAgency)})</span>
                    </td>
                    <td style={{textAlign:"center"}}><b>{t("합계")}</b> : 
                      <DigitsColorWrapper fontSize={13} number={totalData[PROFIT_TYPE.TOURNAMENT_BUYIN].agencyAmount}/>HP
                      <span style={{fontSize:"13px", fontWeight:"bold"}}>({PrintCurrency("KRW", totalData[PROFIT_TYPE.TOURNAMENT_BUYIN].krwAmountAgency)})</span>
                    </td>

                      {agencyList.map((agency:any, index) => {
                        let types = [PROFIT_TYPE.POT_RAKE, PROFIT_TYPE.LEAVEROOM_HAND_LOSS, PROFIT_TYPE.TOURNAMENT_BUYIN];

                        return <tr key={index} style={{textAlign:"center"}}>
                          <td>{agency.agencyId}</td>
                          <td>{agency.agencyName}</td>
                          {types.map((type:PROFIT_TYPE) => {
                            if(agency[type] == null) return <td > - </td>;
                            
                            return <td key={type}>
                              <DigitsColorWrapper fontSize={13} number={agency[type] ? Number(agency[type].agencyAmount) : 0}/>HP
                              <span style={{fontSize:"13px", fontWeight:"bold"}}>({PrintCurrency("KRW", agency[type].krwAmountAgency)})</span>
                            </td>
                          })}
                        </tr>
                      })}

                      {agencyList.length == 0 && <tr><td colSpan={5} style={{ textAlign: "center" }}>{t("검색 결과가 없습니다")}.</td></tr>}
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default AgencyProfitList;
