import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import AgencyExchangeRequestPopup from "@app/components/popup/AgencyExchangeRequestPopup";
import { ExchangeStatusType, getExchangeStatusString } from "./AgencyExchangeList";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { CurrencyMark } from "@app/conversionChip";

const months: string[] = [];
for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
    months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
}

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const USDTHistory = () => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);

    const [list, setList] = React.useState<{
        id: number,
        txHash: string,
        network: "TRX" | "ETH",
        walletAddress: string,
        fromWallet: string,
        toWallet: string,
        quantity: number,
        addedAt: string
    }[]>([]);

    const [inputStartDate, setInputStartDate] = React.useState(new Date(Date.now() - 1000 * 60 * 60 * 24 * 3));
    const [inputEndDate, setInputEndDate] = React.useState(new Date());
    const [searchStartDate, setSearchStartDate] = React.useState(moment(inputStartDate).format(DATE_FORMAT));
    const [searchEndDate, setSearchEndDate] = React.useState(moment(inputEndDate).format(DATE_FORMAT));
    const [inputEth, setInputEth] = React.useState("");
    const [inputTrx, setInputTrx] = React.useState("");
    
    const onSearch = async (startDate: string, endDate: string) => {
        const response = await AdminAPI.getUsdtHistory(token, startDate, endDate);
        setList(response.rows)
    }

    const onInfo = async () => {
        const response = await AdminAPI.getUsdtInfo(token);
        setInputEth(response.ethAddress);
        setInputTrx(response.trxAddress);
    }

    useEffect(() => {
        onInfo();
    }, []);

    useEffect(() => {
        onSearch(searchStartDate, searchEndDate)
    }, [searchStartDate, searchEndDate]);

    const onClickSearch = useCallback(function () {
        setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
        setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
    }, [
        inputStartDate,
        inputEndDate,
    ])

    const onClickCancelSearch = useCallback(function () {
        setSearchStartDate(moment().add(-1, "days").format(DATE_FORMAT))
        setSearchEndDate(moment().add(0, "months").format(DATE_FORMAT))
    }, [])

    const onClickSetInfo = useCallback(async () => {
        let pass = prompt(`${t("비밀번호를 입력하세요")}.`);
        if(pass !== "2024"){
            alert(`${t("비밀번호가 틀렸습니다")}.`);
            return;
        }

        let res = await AdminAPI.setUsdtInfo(token, inputEth, inputTrx);
        if(res.success) {
            onInfo();
            alert(t("데이터가 수정되었습니다"));
        }
    }, [inputEth, inputTrx]);

    return <div>
        <ContentHeader title={t("USDT 입출금 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="row">
                                    <div className="input-group col-9">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("TRX")}</span>
                                        </div>
                                        <input type="text" className="form-control" value={inputTrx} onChange={(e) => setInputTrx(e.target.value)} />
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("ETH")}</span>
                                        </div>
                                        <input type="text" className="form-control" value={inputEth} onChange={(e) => setInputEth(e.target.value)} />
                                        <div className='col-3'>
                                            <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={onInfo}>{t("초기화")}</button>
                                            <button type="button" className="btn btn-primary float-right" onClick={onClickSetInfo}>{t("수정")}</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="input-group col-6">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("기간")}</span>
                                        </div>
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputStartDate).format("YYYY-MM-DDTHH:mm")}
                                            onChange={(e) => {
                                                setInputStartDate(new Date(e.target.value))
                                            }}
                                        />
                                        <input type="datetime-local" className="form-control"
                                            value={moment(inputEndDate).format("YYYY-MM-DDTHH:mm")}
                                            onChange={(e) => {
                                                setInputEndDate(new Date(e.target.value))
                                            }}
                                        />
                                    </div>

                                    <div className='col-3'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
                                    </div>
                                </div>
                            </div>


                            <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap" id="user-table">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>{t("입출금")}</th>
                                            <th>{t("네트워크")}</th>
                                            <th>{t("TxHash")}</th>
                                            <th>{t("지갑주소")}</th>
                                            <th>{t("출금주소")}</th>
                                            <th>{t("입금주소")}</th>
                                            <th>{t("수량")}</th>
                                            <th>{t("일시")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            list.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{item.id}</td>
                                                    <td>{item.walletAddress.toLowerCase() === item.fromWallet.toLowerCase() ? 
                                                        <div className="text-danger">{t("출금")}</div> : 
                                                        <div className="text-success">{t("입금")}</div>
                                                    }
                                                    </td>
                                                    <td>{item.network}</td>
                                                    <td><a href={`${item.network === "TRX" ? "https://tronscan.org/#/transaction/" : "https://etherscan.io/tx/"}${item.txHash}`} target="_blank">{item.txHash.slice(0, 10)}...</a></td>
                                                    <td>{item.walletAddress.slice(0, 10)}...</td>
                                                    <td>{item.fromWallet.slice(0, 10)}...</td>
                                                    <td>{item.toWallet.slice(0, 10)}...</td>
                                                    <td>{Number(item.quantity).toLocaleString()}</td>
                                                    <td>{moment(item.addedAt).format("YYYY-MM-DD HH:mm:ss")}</td>
                                                </tr>
                                            })
                                        }
                                        {list.length === 0 && <tr><td colSpan={8} className="text-center">{t("검색 결과가 없습니다")}.</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default USDTHistory;
