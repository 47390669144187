import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import {useTranslation} from "react-i18next";

interface AgencyDetailPopupProps {
  userId?: number;
  onClose: () => void;
}

const UserInGameMoneyPopup = ({
    onClose,
    userId
}: AgencyDetailPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [searchName, setSearchName] = useState("")
  const [searchedUsers , setSearchedUsers] = useState<{
    userId:string,
    nickname:string,
    agencyName:string,
    uid:string,
  }[] | null>(null);
  const [viewInfo, setViewInfo] = useState<{
    userId:string,
    nickname:string,

    currentRooms:{
        roomId:number,
        groupId:number,
        name:string,
        blind:number[]
        userMoney:number;
    }[]

    currentLobbys:{
        groupId:number,
        blind:number[]
        userMoney:number;
    }[]
  }>();
  const [inputUserId, setInputUserId] = useState(0);

  useEffect(()=>{
    if(userId){
        api.getUserInfo(userId, token).then(v=>{
            setSearchedUsers([v])
            setInputUserId(v.userId)
        })
    }
  },[userId])

  const onClickSearch = async function(){
    api.userListForViewMoney(token, searchName).then(v=>{
        setSearchedUsers(v.rows)
    })
  }

  const onClickView = async function(){
    if(inputUserId == 0)  return alert(`${t("유저를 선택하세요")}`);

    api.viewUserMoney(token, inputUserId).then(v=>{
      if(v.success == false) return alert(t("조회에 대한 권한이 없습니다."));
        setViewInfo(v.payload)
    })
  }

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("게임 내 현재 보유액 조회")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("유저 검색")}</label>
            <div className="col-sm-9">
                <input type="text" className="form-control" value={searchName} onChange={v=>setSearchName(v.target.value)}/>
                <button type="button" className="btn btn-primary" onClick={onClickSearch}>{t("검색")}</button>
            </div>
          </div>

        {searchedUsers == null ? <>{t("유저를 검색해주세요")}</>:<>
          {searchedUsers.length == 0 ? t("검색된 유저가 없습니다") : <>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("유저 선택")}</label>
                <div className="col-sm-9">
                <select className="form-control" onChange={v=>setInputUserId(Number(v.target.value))} value={inputUserId} >
                    <option value={0}>{t("선택하세요")}</option>
                    {searchedUsers.map(v=><option value={v.userId}>{t("아이디")} : {v.uid} / {t("닉네임")} : {v.nickname} / {t("에이전시")} : {v.agencyName}</option>)}
                </select>
                <button type="button" className="btn btn-primary" onClick={onClickView}>{t("조회하기")}</button>
                </div>
            </div>
          </>}
        </>}
        {viewInfo && <>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("닉네임")}</label>
                <div className="col-sm-9">
                    <input type="text" className="form-control" value={viewInfo.nickname} readOnly/>
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("게임 내 보유액")}</label>
                <div className="col-sm-9">
                    {viewInfo.currentRooms.length == 0 ? `${t("게임 내 보유액이 없습니다")}.` : <table className="table table-head-fixed text-nowrap">
                        <tr>
                            <th>{t("그룹아이디")}</th>
                            <th>{t("이름")}</th>
                            <th>{t("블라인드")}</th>
                            <th>{t("보유액")}</th>
                        </tr>
                        {viewInfo.currentRooms.map(v=><tr>
                            <td>{v.groupId}</td>
                            <td style={{whiteSpace:"break-spaces"}}>{v.name||t("링게임")}</td>
                            <td>{v.blind ? v.blind.join("-") : t("없음")}</td>
                            <td>{v.userMoney.toLocaleString()}{t("알")}</td>
                        </tr>)}
                    </table> }
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("로비 정산 대기금")}</label>
                <div className="col-sm-9">
                {viewInfo.currentLobbys.length == 0 ? `${t("로비 정산 금액이 없습니다")}.` : <table className="table table-head-fixed text-nowrap">
                        <tr>
                            <th>{t("그룹아이디")}</th>
                            <th>{t("블라인드")}</th>
                            <th>{t("보유액")}</th>
                        </tr>
                        {viewInfo.currentLobbys.map(v=><tr>
                            <td>{v.groupId}</td>
                            <td>{v.blind.join("-")}</td>
                            <td>{v.userMoney.toLocaleString()}{t("알")}</td>
                        </tr>)}
                    </table> }
                </div>
            </div>
        </>}
        </div>

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  </PopupContainer>
}

export default UserInGameMoneyPopup;
