import React, { useEffect } from "react";
import moment from 'moment';
import { useAppSelector } from "@app/store/hooks";
import { useSelector } from "react-redux";
import AdminAPI from "@app/api/admin";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { CurrencyMark } from "@app/conversionChip";
import { PrintCurrency } from "../UserAdjustMoney";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const OrderTh = styled.th`
  cursor: pointer;
  color: #0050a5;
  &:hover{
  }
  >span{
    margin-left: 5px;
  }
`;

const TotalTr = styled.tr`
  >td{
    font-weight: bold;
  }
`;

interface Params {
  user:{uid:string, agencyId:number};
}
const UserProfitByUser = ( {
  user
} : Params ) =>{
  const {t} = useTranslation()
  const {token} = useAppSelector(state => state.auth);
  const [inputUserNickname, setInputUserNickname] = React.useState('');
  const [inputUserId, setInputUserId] = React.useState('');
  const [inputAgentCode, setInputAgentCode] = React.useState('');
  const [inputStartDate, setInputStartDate] = React.useState(new Date(moment().format("YYYY-MM-DD 00:00:00")));
  const [inputEndDate, setInputEndDate] = React.useState(new Date());

  const [searchUserNickname, setSearchUserNickname] = React.useState('');
  const [searchUserId, setSearchUserId] = React.useState('');
  const [searchAgentCode, setSearchAgentCode] = React.useState('');
  const [searchStartDate, setSearchStartDate] = React.useState(moment(inputStartDate).format(DATE_FORMAT));
  const [searchEndDate, setSearchEndDate] = React.useState(moment(inputEndDate).format(DATE_FORMAT));

  const [orderBy, setOrderBy] = React.useState<'total' | 'userId' | 'uid' | 'betAmount' | 'winAmount' | 'betCount' | 'winCount' | 'lossAmount' | 'seatOutAmount' | 'buyinAmount' | 'tournamentBetting' | 'tournamentBettingCount' | null>('userId');
  const [orderASC, setOrderASC] = React.useState<boolean>(false);

  const [list, setList] = React.useState<{
    userId:number,
    nickname:string,
    uid:string,
    betting:number,
    bettingFiat:number,
    tournamentBetting:number,
    bettingCount:number,
    tournamentBettingCount:number,
    winning:number,
    winningFiat:number,
    winningCount:number,
    seatOutAmount:number;
    buyinAmount:number;
    lossAmount:number;

    tournamentBuyinAmount:number;
    tournamentSeatOutAmount:number;

    currency:string;
    lossAmountFiat:number;
    seatOutAmountFiat:number;
    buyinAmountFiat:number;
    tournamentSeatOutAmountFiat:number;
    tournamentBuyinAmountFiat:number;

  }[] | null>(null);

  useEffect(function () {
    setList(null)
    AdminAPI.agencyStatistics_3(token, searchUserNickname, searchUserId, searchStartDate, searchEndDate, searchAgentCode, orderBy || "", orderASC, user.agencyId).then((v)=>{
      setList(v.rows);
    })
  },[searchUserNickname, searchUserId, searchStartDate, searchEndDate, searchAgentCode, orderBy, orderASC]);

  const onCickSearch = () => {
    setSearchUserNickname(inputUserNickname);
    setSearchUserId(inputUserId);
    setSearchAgentCode(inputAgentCode);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  }

  const onClickOrderBy = (_orderBy: 'total' | 'userId' | 'uid' | 'betAmount' | 'winAmount' | 'betCount' | 'winCount' | 'lossAmount' | 'seatOutAmount' | 'buyinAmount' | 'tournamentBetting' | 'tournamentBettingCount') => () => {
    if(orderBy == _orderBy){
      if(!orderASC){
        setOrderASC(!orderASC);
      }else{
        setOrderASC(true);
        setOrderBy("userId");
      }
    }else{
      setOrderBy(_orderBy);
      setOrderASC(false);
    }
  }

  const tot_betting = Math.abs(list ? list.reduce( (a,b)=>a+b.betting, 0 ) : 0);
  const tot_betting_fiat = Math.abs(list ? list.reduce( (a,b)=>a+b.bettingFiat, 0 ) : 0);
  const tot_tournamentBetting = Math.abs(list ? list.reduce( (a,b)=>a+b.tournamentBetting, 0 ) : 0);
  const tot_winning = Math.abs(list ? list.reduce( (a,b)=>a+(b.winning), 0 ) : 0);
  const tot_winning_fiat = Math.abs(list ? list.reduce( (a,b)=>a+(b.winningFiat), 0 ) : 0);
  const tot_lossAmount = Math.abs(list ? list.reduce( (a,b)=>a+(b.lossAmount), 0 ) : 0);
  const tot_buyinAmount = Math.abs(list ? list.reduce( (a,b)=>a+(b.buyinAmount), 0 ) : 0);
  const tot_seatOutAmount = Math.abs(list ? list.reduce( (a,b)=>a+(b.seatOutAmount), 0 ) : 0);
  const tot_profit = tot_buyinAmount-tot_seatOutAmount;
  const tot_bettingCount = Math.abs(list ? list.reduce( (a,b)=>a+b.bettingCount, 0 ) : 0);
  const tot_tournamentBettingCount = Math.abs(list ? list.reduce( (a,b)=>a+b.tournamentBettingCount, 0 ) : 0);
  const tot_winningCount = Math.abs(list ? list.reduce( (a,b)=>a+b.winningCount, 0 ) : 0);

  const tot_tournamentSeatOut = Math.abs(list ? list.reduce( (a,b)=>a+(b.tournamentSeatOutAmount), 0 ) : 0);
  const tot_tournamentBuyin = Math.abs(list ? list.reduce( (a,b)=>a+(b.tournamentBuyinAmount), 0 ) : 0);
  const currency = list && list.length > 0 ? list[0].currency : "KRW";

  return <>
    <h3 className='mt-4 mb-3'>{user.uid} (@{user.agencyId}) {t("에이전시 유저 수익 통계")}</h3>

    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <div className='row mb-2'>
              <div className="input-group col-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">{"총판 코드"}</span>
                </div>
                <input type="text" className="form-control" placeholder="Code" onChange={(v)=>setInputAgentCode(v.target.value)}/>
              </div>
              <div className="input-group col-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">{t("유저 닉네임")}</span>
                </div>
                <input type="text" className="form-control" placeholder="Username" onChange={(v)=>setInputUserNickname(v.target.value)}/>
              </div>
              <div className="input-group col-3">
                <div className="input-group-prepend">
                  <span className="input-group-text">{t("유저 아이디")}</span>
                </div>
                <input type="text" className="form-control" placeholder="Username" onChange={(v)=>setInputUserId(v.target.value)} />
              </div>
            </div>
            <div className="row">
              <div className="input-group col-5">
                <div className="input-group-prepend">
                  <span className="input-group-text">{t("기간")}</span>
                </div>
                <input type="datetime-local" className="form-control"
                       value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                       onChange={(e) => {
                        setInputStartDate( new Date(e.target.value) )
                       }}
                />
                <input type="datetime-local" className="form-control"
                       value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                       onChange={(e) => {
                        setInputEndDate( new Date(e.target.value) )
                       }}
                />
              </div>
              <div className='col-1'>
                <button type="button" className="btn btn-primary float-right" onClick={onCickSearch}>{t("적용")}</button>
              </div>
            </div>
          </div>
          <div className="card-body table-responsive p-0" style={{height: 785}}>
            {list ? <table className="table table-head-fixed text-nowrap">
              <thead>
              <tr>
                <OrderTh onClick={onClickOrderBy("userId")}>
                  {t("유저번호")}
                  {orderBy == "userId" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("uid")}>
                  {t("유저 이름")}
                  {orderBy == "uid" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("betAmount")}>
                  {t("베팅 금액(캐시)")}
                  {orderBy == "betAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("tournamentBetting")}>
                  {t("베팅 금액(토너먼트)")}
                  {orderBy == "tournamentBetting" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("winAmount")}>
                  {t("당첨 포인트")}
                  {orderBy == "winAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("lossAmount")}>
                  {t("로스난 포인트")}
                  {orderBy == "lossAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("buyinAmount")}>
                  {t("바이인 포인트")}
                  {orderBy == "buyinAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("buyinAmount")}>
                  {t("바이인(토너)")}
                  {orderBy == "buyinAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("seatOutAmount")}>
                  {t("싯아웃(캐시)")}
                  {orderBy == "seatOutAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("seatOutAmount")}>
                  {t("상금(토너)")}
                  {orderBy == "seatOutAmount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("total")}>
                  {t("손익 포인트 (총합)")}
                  {orderBy == "total" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("betCount")}>
                  {t("베팅 횟수 (캐시)")}
                  {orderBy == "betCount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("tournamentBettingCount")}>
                  {t("베팅 횟수 (토너먼트)")}
                  {orderBy == "betCount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
                <OrderTh onClick={onClickOrderBy("winCount")}>
                  {t("당첨 횟수")}
                  {orderBy == "winCount" ? <span>{orderASC ? "▲" : "▼"}</span> : <span>↕</span> }
                </OrderTh>
              </tr>
              </thead>
              <tbody>
                <TotalTr style={{borderBottom:"2px dashed gray"}}>
                  <td>{t("합계")}</td>
                  <td>-</td>
                  <td>
                    {tot_betting.toLocaleString()}<br/>
                    ({PrintCurrency(currency, tot_betting_fiat)})
                  </td>
                  <td>{tot_tournamentBetting.toLocaleString()}</td>
                  <td>
                    {tot_winning.toLocaleString()}<br/>
                    ({PrintCurrency(currency, tot_winning_fiat)})
                  </td>
                  <td>{tot_lossAmount.toLocaleString()}</td>
                  <td>{tot_buyinAmount.toLocaleString()}</td>
                  <td>{tot_tournamentBuyin.toLocaleString()}</td>
                  <td>{tot_seatOutAmount.toLocaleString()}</td>
                  <td>{tot_tournamentSeatOut.toLocaleString()}</td>
                  <td>{tot_profit.toLocaleString()}</td>
                  <td>{tot_bettingCount.toLocaleString()}</td>
                  <td>{tot_tournamentBettingCount.toLocaleString()}</td>
                  <td>{tot_winningCount.toLocaleString()}</td>
                </TotalTr>
              {
                list.map((item, index) => {
                  return <><tr key={index}>
                    <td>@{item.userId}</td>
                    <td>{item.nickname} ({item.uid})</td>
                    <td>{Number(item.betting).toLocaleString()}</td>
                    <td>{Number(item.tournamentBetting).toLocaleString()}</td>
                    <td>{Number(item.winning).toLocaleString()}</td>
                    <td>{Number(item.lossAmount).toLocaleString()}</td>
                    <td>{Math.abs(item.buyinAmount).toLocaleString()}</td>
                    <td>{Math.abs(item.tournamentBuyinAmount).toLocaleString()}</td>
                    <td>{Math.abs(item.seatOutAmount).toLocaleString()}</td>
                    <td>{Math.abs(item.tournamentSeatOutAmount).toLocaleString()}</td>
                    <td>{Number(Math.abs(item.buyinAmount) - Math.abs(item.seatOutAmount)).toLocaleString()}</td>
                    <td>{Number(item.bettingCount).toLocaleString()}</td>
                    <td>{Number(item.tournamentBettingCount).toLocaleString()}</td>
                    <td>{Number(item.winningCount).toLocaleString()}</td>
                  </tr>
                  <tr style={{borderBottom:"2px dashed gray"}}>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(item.lossAmountFiat)}/></td>
                    <td>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(item.buyinAmountFiat)}/></td>
                    <td>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(item.tournamentBuyinAmountFiat)}/></td>
                    <td>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(item.seatOutAmountFiat)}/></td>
                    <td>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(item.tournamentSeatOutAmountFiat)}/></td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  </>
                })
              }
              </tbody>
            </table>: "loading.."}
          </div>
          {/* <div className="card-footer clearfix">
            <ul className="pagination pagination-sm m-0 float-left">
              <li className="page-item">
                <div className="page-link">
                  표시
                </div>
              </li>
              {
                [15, 30, 50].map((item, index) => {
                  return <li className="page-item">
                    <div className="page-link" style={
                      item === 15 ? {
                        backgroundColor: 'lightgrey',
                        color: 'black'
                      } : {}
                    }>{item}</div>
                  </li>
                })
              }
            </ul>
            <ul className="pagination pagination-sm m-0 float-right">
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="fas fa-chevron-left"></i>
                </a>
              </li>
              {
                Array(10).fill(0).map((item, index) => {
                  return <li className="page-item"><a className="page-link" href="#">{index + 1}</a></li>
                })
              }
              <li className="page-item">
                <a className="page-link" href="#">
                  <i className="fas fa-chevron-right"></i>
                </a>
              </li>
            </ul>
          </div> */}
        </div>

      </div>
    </div>
  </>
}
export default UserProfitByUser;
