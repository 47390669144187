import React from "react";
import { KR_DOC, EN_DOC } from "./documentExporter";
import styled from "styled-components";

const ApiWrapper = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    padding: 20px;
`;

export default function ApiViewer({ lang }: { lang: string }) {
    return <ApiWrapper dangerouslySetInnerHTML={{ __html: lang === 'kr' ? KR_DOC : EN_DOC }} />;
}

export const API_VERSION = "v2.9";