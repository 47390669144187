import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const OrderBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
`;
const SortArrow = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    &:hover {
        color: #007bff;
    }
    &.active {
        color: #007bff;
    }
`;

const SortArrowIcon = styled.i`
    font-size: 1.1rem;
`;

enum ORDER_TYPE {
    UP = 1,
    DOWN = 2,
}

export interface IOrderBox {
    targetId: number;
    onClick: (order: ORDER_TYPE, targetId: number) => void;
}

const OrderBox = ({ targetId, onClick }: IOrderBox) => {
    return (
        <OrderBoxContainer>
            <SortArrow
                onClick={() => onClick(ORDER_TYPE.UP, targetId)}
                style={{ marginBottom: "-2px" }}
            >
                <SortArrowIcon className="fas fa-sort-up" />
            </SortArrow>
            <SortArrow
                onClick={() => onClick(ORDER_TYPE.DOWN, targetId)}
                style={{ marginTop: "-2px" }}
            >
                <SortArrowIcon className="fas fa-sort-down" />
            </SortArrow>
        </OrderBoxContainer>
    );
};

export default OrderBox;
