import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useEffect, useRef, useState } from "react";
import { sha256 } from "js-sha256";
import api from "../../api/admin"
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
    padding: 10px;
    .subject {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        .head {
            font-weight: bold;
        }
    }
    .content {
        margin-top: 10px;
        .head {
            font-weight: bold;
        }
        .content{
            white-space: pre-line;
        }
    }
    .answer {
        margin-top: 10px;
        .head {
            font-weight: bold;
        }
        .content{
            white-space: pre-line;
        }
    }
`

interface AdminLogPopupProps {
    onRefresh: () => void;
    onClose: () => void;
    inquiryId: number;
    userId: number;
    subject: string;
    content: string;
    addedAt: string;
    answer: string;
}

const InquiryAnswerPopup = ({
    inquiryId,
    userId,
    subject,
    content,
    addedAt,
    answer,
    onClose,
    onRefresh
}: AdminLogPopupProps) => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const answerRef = useRef<HTMLTextAreaElement>(null);

    const [uploading, setUploading] = useState(false);

    const handleCreateNotice = async () => {

        let answer = answerRef.current!.value || "";
        if (answer.length == 0) {
            alert("답변을 입력해주세요.")
            return;
        }

        setUploading(true);
        let v = await api.submitInquiryAnswer(token, inquiryId, answer);
        if (v.success) {
            alert("Submit success");
            onClose();
            onRefresh();
        }
    };

    useEffect(function () {
    }, [])

    return <PopupContainer onClickBackdrop={onClose}>
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">{t("inquiry answer")}</h4>
                    <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Container>
                        <div className="subject">
                            <div className="head">Subject</div>
                            <div className="content">{subject}</div>
                        </div>

                        <div className="content">
                            <div className="head">Content</div>
                            <div className="content">{content}</div>
                        </div>

                        <div className="answer">
                            <div className="head">{t("answer")}</div>
                            <div className="content">
                                { answer ? <div>{answer}</div> : <textarea ref={answerRef} className="form-control"  />}
                            </div>
                        </div>
                    </Container>
                </div>

                {uploading ? <div>{t("업로드 중")}...</div> :
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
                        {answer == null && <button type="button" className="btn btn-primary" onClick={handleCreateNotice}>{t("Submit")}</button> }
                    </div>}

            </div>
        </div>
    </PopupContainer>
}
export default InquiryAnswerPopup;
