import { EXCHANGE_RATE } from "./dataset/common";

let exchangeData:EXCHANGE_RATE|null = null;
let chipPriceUSD:number|null = null;

const fixed:{[key:string]:number} = {
    USD:1000,
    SGD:1000,
    KRW:1,
    JPY:1,
    PHP:1,
    THB:1,
    HKD:1,
    TWD:1,
    VND:1,
    MYR:1,
    IDR:1,
    RMB:1,
    BRL:1000,
}

export function isInit(){
    return exchangeData != null;
}

export function setExchangeRate(chipAmountOneUSD:number, rates:EXCHANGE_RATE){
    exchangeData = rates;
    chipPriceUSD = 1/chipAmountOneUSD;
}

export function currencyToChip(symbol:string, currencyAmount:number){
    if(!exchangeData) throw "currencyToChip 1 error "+symbol;
    if(!exchangeData[symbol]) throw "currencyToChip 2 error "+symbol;
    if(!chipPriceUSD) throw "currencyToChip 3 error "+symbol;

    const negative = currencyAmount < 0 ? -1 : 1;
    currencyAmount = Math.abs(currencyAmount);

    const perOneUSD = Math.ceil((1000000 / exchangeData[symbol]) * 100000)/100000 * (currencyAmount/1000000);
    return negative * Math.floor(perOneUSD / chipPriceUSD);
}

export function chipToCurrency(symbol:string, chipAmount:number, fix?:number){
    if(!exchangeData) throw "chipToCurrency 1 error "+symbol;
    if(!exchangeData[symbol]) throw "chipToCurrency 2 error "+symbol;
    if(!chipPriceUSD) throw "currencyToChip 3 error "+symbol;
    
    const negative = chipAmount < 0 ? -1 : 1;
    chipAmount = Math.abs(chipAmount);

    const fixedMulti = fix ? fix : fixed[symbol]||1;
    const result = chipAmount * chipPriceUSD * exchangeData[symbol];
    
    return negative * Number(Math.floor(result * fixedMulti) / fixedMulti);
}

export function fiatToFiat(from:string, to:string, amount:number){
    if(!exchangeData) throw "fliatToFiat 1 error "+from;
    if(!exchangeData[from]) throw "fliatToFiat 2 error "+from;
    if(!exchangeData[to]) throw "fliatToFiat 3 error "+to;
    
    const usd = amount / exchangeData[from];
    const fixedMulti = fixed[to]||1;
    return Number(Math.floor((usd * exchangeData[to]) * (fixedMulti)) / fixedMulti);
}

let userCurrency = "KRW";
export function setUserCurrency(symbol:string){
    userCurrency = symbol || "KRW";
}

export function UserCurrency(){
    return userCurrency;
}

export function UserToChip(currencyAmount:number){
    return currencyToChip(userCurrency, currencyAmount);
}

export function UserToCurrency(chipAmount:number){
    return chipToCurrency(userCurrency, chipAmount);
}

export function UserCurrencyMark(){
    return CurrencyMark(userCurrency);
}

export function CurrencyMark(currency:string){
    switch(currency){
        case "KRW": return "₩";
        case "USD": return "$";
        case "JPY": return "¥";
        case "PHP": return "₱";
        case "THB": return "฿";
        case "SGD": return "S$";
        case "CNY": return "¥";
        case "BRL": return "R$";
        case "TWD": return "NT$";
        case "VND": return "₫";
        case "MXN": return "MX$";
        case "TRY": return "₺";
        default: return "";
    }
}