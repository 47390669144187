import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import moment from "moment";
import { useSelector } from "react-redux";
import AgencyExchangeRequestPopup from "@app/components/popup/AgencyExchangeRequestPopup";
import { ExchangeStatusType, getExchangeStatusString } from "./AgencyExchangeList";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Container = styled.div`
    display: flex;
    height: 600px;
    max-height: 700px;
    gap: 10px;
    padding: .75rem 1.25rem;
`

const LeftContainer = styled.div`
    flex:1;
    height: 100%;
    overflow-y: scroll;
    border: 1px solid #999;
    max-width: 500px;
`

const RightContainer = styled.div`
    flex:1;
    height: 100%;
    overflow-y: scroll;
    border: 1px solid #999;
    background-color: #f2f2f2;
    display: flex;

    >div{
        flex:1;
    }

    >.card-body{
        flex:1;
    }

    table{
        background-color: white;
    }
`

const HoverTr = styled.tr`
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #f2f2f2;
    }

    &:active {
        background-color: #e2e2e2;
    }
`

const Head = styled.div`
    display: flex;
    font-weight: bold;
    gap: 30px;
    padding: 10px 20px;
`

const EggExchange = () => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const { token } = useAppSelector(state => state.auth);
    const user = useSelector((state: any) => state.auth.currentUser);
    const [inputAgencyName, setInputAgencyName] = useState("");
    const [searchAgencyName, setSearchAgencyName] = useState("");
    const [selectAgencyId, setSelectAgencyId] = useState(0);
    const [forceUpdate, setForceUpdate] = useState(0);

    const [agencyList, setAgencyList] = useState<{
        agencyId: number,
        name: string,
        egg: number,
        requested: boolean,
    }[]>([]);

    const [agencyDetail, setAgencyDetail] = useState<{
        rows: {
            exId: number,
            agencyId: number,
            requestAmount: number,
            hasAmount: number,
            afterAmount: number,
            statusCode: ExchangeStatusType,
            statusAt: string,
            statusMemo: string,
            addedAt: string,
            account_name: string,
            account_address: string,
            account_bankName: string,
        }[]
        totalCnt: number,
        agency: {
            agencyId: number
            name: string
            egg: number
        }
    }>();

    useEffect(function () {
        AdminAPI.getAgencyListForExchange(token, searchAgencyName).then((res: any) => {
            res.rows.sort((a: any, b: any) => {
                if (a.requested)
                    return -1;
                else if (b.requested)
                    return 1;
                else if(a.requestedAt != null && b.requestedAt == null)
                    return -1;
                else if(a.requestedAt == null && b.requestedAt != null)
                    return 1;
                else if(a.requestedAt > b.requestedAt)
                    return -1;
                else if(a.requestedAt < b.requestedAt)
                    return 1;
                return 0;
            })

            setAgencyList(res.rows);
        });
    }, [searchAgencyName, forceUpdate]);

    useEffect(function () {
        if(selectAgencyId == 0){
            setAgencyDetail(undefined);
            return;
        }
        AdminAPI.getAgencyRequestExchangeList(token, selectAgencyId).then((res: any) => {
            setAgencyDetail(res);
        });
    }, [selectAgencyId, forceUpdate])

    const onClickSearch = () => {
        setSearchAgencyName(inputAgencyName);
    }

    const onClickCancelSearch = () => {
        setInputAgencyName("");
        setSearchAgencyName("");
    }

    const onClickExchangeRequest = (exId: number, accepted:boolean) => {
        if(accepted){
            if(confirm(t("정말로 승인하시겠습니까?"))){
                let password = prompt(t("환전 승인 비밀번호를 입력해주세요.")) || "";
                if(password.length == 0){
                    alert(t("비밀번호를 입력해주세요."));
                    return;
                }
                AdminAPI.agencyExchangeAccept(token, exId, ExchangeStatusType.Accepted, "", password).then((res:any)=>{
                    setForceUpdate(Date.now())
                    if(!res.success){
                        if(res.memo) return alert(res.memo);
                        alert(t("처리에 오류가 발생하였습니다. 개발자에게 문의해주세요."))
                    }
                })
            }
        }else{
            let memo = prompt(t("환전 거절 사유를 입력해주세요. 5자 이상 작성하십시오. 사유는 에이전트에게도 노출됩니다."));
            if( memo && memo.length >= 5){
                let password = prompt(t("환전 거절 비밀번호를 입력해주세요.")) || "";
                if(password.length == 0){
                    alert(t("비밀번호를 입력해주세요."));
                    return;
                }

                AdminAPI.agencyExchangeAccept(token, exId, ExchangeStatusType.Rejected, memo, password).then((res:any)=>{
                    setForceUpdate(Date.now())
                    if(!res.success){
                        if(res.memo) return alert(res.memo);
                        alert(t("처리에 오류가 발생하였습니다. 개발자에게 문의해주세요."))
                    }
                })
            }else{
                alert(t("거절 사유를 5자 이상 작성해주세요."))
            }
        }
    }

    return <div>
        <ContentHeader title={t("환전 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="input-group col-6">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("에이전트 이름")}</span>
                                        </div>
                                        <input type="input" className="form-control"
                                            value={inputAgencyName}
                                            onChange={(e) => setInputAgencyName(e.target.value)}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <button type="button" className="btn btn-primary float-left" onClick={() => onClickSearch()}>{t("검색")}</button>
                                        <button type="button" className="btn btn-secondary float-left" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                    </div>
                                </div>
                            </div>

                            <Container>
                                <LeftContainer>
                                    <div className="card-body table-responsive p-0">
                                        <table className="table table-head-fixed text-nowrap" id="user-table">
                                            <thead>
                                                <tr style={{ textAlign: "center" }}>
                                                    <th>{t("환전 신청")}</th>
                                                    <th>{t("에이전트 이름")}</th>
                                                    <th>{t("보유 포인트")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {agencyList.map((agency, index) => {
                                                    return <HoverTr key={index} onClick={() => setSelectAgencyId(agency.agencyId)}>
                                                        <td>
                                                            {agency.requested ? "🟢" : "-"}
                                                            {/* <AgencyExchangeRequestPopup agencyId={agency.agencyId} /> */}
                                                        </td>
                                                        <td>{agency.name}</td>
                                                        <td>{Number(agency.egg).toLocaleString()}</td>
                                                    </HoverTr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </LeftContainer>
                                <RightContainer>
                                    {agencyDetail ? <div>
                                        <Head>
                                            <div>{t("에이전트 이름")}: {agencyDetail.agency.name}</div>
                                            <div>{t("보유 포인트")}: {Number(agencyDetail.agency.egg).toLocaleString()}</div>
                                        </Head>

                                        <div className="card-body table-responsive p-0" >
                                            <table className="table table-head-fixed text-nowrap" style={{width:"100%"}} id="user-table">
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th>{t("신청일")}</th>
                                                        <th>{t("환전 신청 포인트")}</th>
                                                        <th>{t("계좌주")}</th>
                                                        <th>{t("계좌번호")}</th>
                                                        <th>{t("은행명")}</th>
                                                        <th>{t("환전 처리일")}</th>
                                                        <th>{t("환전 처리 메모")}</th>
                                                        <th>{t("처리")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {agencyDetail.rows.map((exchange, index) => {
                                                        return <tr key={index} style={{ textAlign: "center" }}>
                                                            <td>{moment(exchange.addedAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                                                            <td><b>{Number(exchange.requestAmount).toLocaleString()}</b></td>
                                                            <td>{exchange.account_name}</td>
                                                            <td>{exchange.account_address}</td>
                                                            <td>{exchange.account_bankName}</td>
                                                            {/* <td>{getExchangeStatusString(exchange.statusCode)}</td> */}
                                                            <td>{exchange.statusAt ? moment(exchange.statusAt).format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                                                            <td>{exchange.statusMemo? exchange.statusMemo : "-"}</td>
                                                            <td>
                                                                {exchange.statusCode == ExchangeStatusType.Pending ? <>
                                                                    <button type="button" style={{marginRight:"5px"}} className="btn btn-primary" onClick={() => onClickExchangeRequest(exchange.exId, true)}>{t("승인")}</button>
                                                                    <button type="button" className="btn btn-danger" onClick={() => onClickExchangeRequest(exchange.exId, false)}>{t("거절")}</button>
                                                                </> : getExchangeStatusString(exchange.statusCode)}
                                                            </td>
                                                        </tr>
                                                    })}
                                                    {agencyDetail.rows.length == 0 ? <tr><td colSpan={8}>{t("신청 기록이 없습니다.")}</td></tr> : null}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div> : null}
                                </RightContainer>
                            </Container>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default EggExchange;
