import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { CurrencyMark } from "@app/conversionChip";

const months: string[] = [];
for (let i = 0; i < moment().utc().diff(moment().utc().subtract('year', 1), 'month'); i++) {
    months.push(moment().utc().subtract('month', i).format('YYYY-MM'));
}

const Agency = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentMonth, setCurrentMonth] = React.useState(moment().utc().format('YYYY-MM'));
    const { token } = useAppSelector(state => state.auth);
    const [list, setList] = React.useState<any>(null);
    const [totalDatas, setTotalDatas] = React.useState<any>({});
    const [accum, setAccum] = React.useState<any>({});

    const onChangeMonth = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentMonth(e.target.value);
    }

    useEffect(function () {
        AdminAPI.subhqProfits(token, moment(currentMonth).endOf("month").format("YYYY-MM-DD HH:mm:ss")).then((v) => {
            let totalDatas: any = {1:{HP:{amount:0, fiat:0}}, 3:{HP:{amount:0, fiat:0}}, 4:{HP:{amount:0, fiat:0}}, accum:{HP:{amount:0, fiat:0}}};
            let _accum = 0;
            // 오로지 맨위 합계만 적용해둠. 근데 나중에 각 로우별로 해달라고 할까봐 데이터만 준비해둠.
            const result:any = new Array(moment(currentMonth).daysInMonth()).fill(0).map((_,i)=>{
                const date = moment(currentMonth).date(i+1).format('YYYY-MM-DD');
                const item = v.datas[date];
                let datas: any = {1:{HP:{amount:0, fiat:0}}, 3:{HP:{amount:0, fiat:0}}, 4:{HP:{amount:0, fiat:0}}}; // profit 1,3,4
                accum[date] = _accum;
                if(item){
                    Object.keys(item).map((curr, ci)=>{
                        Object.keys(item[curr]).map((profitType, si)=>{
                            let amount = Number(item[curr][profitType].amount);
                            let fiat = Number(item[curr][profitType].fiat);
                            // 각 로우 데이터에 화폐별 수익 데이터 추가
                            datas[profitType][curr] = {amount, fiat};
                            // 각 로우 데이터에 모든 화폐별 수익 데이터 추가
                            datas[profitType].HP = datas[profitType].HP || {amount:0, fiat:0};
                            datas[profitType].HP.amount += amount;
                            datas[profitType].HP.fiat += fiat;
                            // 합계 구하는곳
                            // 합계 데이터에, 모든 화폐 합산
                            totalDatas[profitType].HP = totalDatas[profitType].HP || {amount:0, fiat:0};
                            totalDatas[profitType].HP.amount += amount;
                            totalDatas[profitType].HP.fiat += fiat;
                            // 합계 데이터에, 각 화폐 합산
                            totalDatas[profitType][curr] = totalDatas[profitType][curr] || {amount:0, fiat:0};
                            totalDatas[profitType][curr].amount += amount;
                            totalDatas[profitType][curr].fiat += fiat;
                            // 합계데이터에, 전체 데이터 합산
                            totalDatas.accum.HP = totalDatas.accum.HP || {amount:0, fiat:0};
                            totalDatas.accum.HP.amount += amount;
                            totalDatas.accum.HP.fiat += fiat;
                            // 합계 데이터에, 각 화폐 합산
                            totalDatas.accum[curr] = totalDatas.accum[curr] || {amount:0, fiat:0};
                            totalDatas.accum[curr].amount += amount;
                            totalDatas.accum[curr].fiat += fiat;
                            // 각 날짜별 누적 합계 구하는곳
                            _accum += amount;
                            accum[date] = _accum;
                        });
                    });
                }
                return datas;
            });
            setList(result)
            setAccum(accum)
            setTotalDatas(totalDatas)
        })
    }, [currentMonth]);


    return <div>
        <ContentHeader title={t("레이크 수익 내역")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row mb-2'>
                                    <div className="offset-8 input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("월별 레이크 수익")}</span>
                                        </div>
                                        <select className="form-control" onChange={onChangeMonth}>
                                            {
                                                months.map((item, index) => {
                                                    return <option key={index} value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body table-responsive p-0" style={{ height: 790 }}>
                                <table className="table table-head-fixed text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>{t("날짜")}</th>
                                            <th>{t("레이크 수익")}</th>
                                            <th>{t("정산 수수료 수익")}</th>
                                            <th>{t("토너먼트 바이인 수익")}</th>
                                            <th>{t("월 누적")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{fontWeight:"bold"}}>
                                            <td>{t("합계")}</td>
                                            {Object.keys(totalDatas).map((key, index)=>{
                                                return <td>
                                                    {Object.keys(totalDatas[key]).map((k, i)=>{
                                                        if(k != "HP"){
                                                            return <div key={i} style={{fontSize:"15px",lineHeight:"1.2"}}>{Math.floor(totalDatas[key][k].fiat)}{CurrencyMark(k)} ({k})</div>
                                                        }
                                                        return <div key={i} style={{marginBottom:"5px"}}>{Math.floor(totalDatas[key][k].amount*10)/10}{k}</div>
                                                    })}
                                                </td>
                                            })}
                                        </tr>
                                    { list ? list.map((item:any, i:number) => {
                                        const date = moment(currentMonth).date(i+1).format('YYYY-MM-DD');
                                        if(item){
                                            return <tr key={date}>
                                                <td>{date}</td>
                                                {Object.keys(item).map((k, i)=>{
                                                    return <td>{Math.floor(item[k].HP.amount*10)/10}HP</td>
                                                })}
                                                <td>{Math.floor(accum[date]*10)/10}HP</td>
                                            </tr>
                                        }
                                    }): <>
                                        <tr><td colSpan={6} style={{textAlign:"center"}}>{t("검색 결과가 없습니다")}.</td></tr>
                                    </>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
}

export default Agency;
