import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup, { } from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location, Link } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyRakeLogPopup from "@app/components/popup/AgencyRakeLogPopup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PrintCurrency } from "./UserAdjustMoney";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { chipToCurrency, CurrencyMark } from "@app/conversionChip";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
function PrintStatus(status: number) {
    switch (status) {
        case 0:
            return "Pending";
        case 1:
            return "Accepted";
        case 2:
            return "Reject";
        default:
            return "Unknown";
    }
}
const UserWallet = () => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [deposits, setDeposits] = useState<{
        historyId:number,
        type:number,
        amount:number,
        userId:number,
        name:string,
        uid:string,
        status:number,
        addedAt:string
    }[]>([])
    const [withdrawals, setWithdrawals] = useState<{
        historyId:number,
        type:number,
        amount:number,
        userId:number,
        name:string,
        uid:string,
        status:number,
        addedAt:string
    }[]>([])

    const getAgencyList = async () => {
        const response1 = await AdminAPI.userWalletList(token, 1);
        setDeposits(response1.rows);

        const response2 = await AdminAPI.userWalletList(token, 2);
        setWithdrawals(response2.rows);
    }

    const onClickAccept = useCallback(function (historyId: number) {
        if(window.confirm("Are you sure accept this request?")){
            AdminAPI.userWalletAccept(token, historyId).then(() => {
                alert("Success accept");
                getAgencyList();
            })
        }
        
    }, []);

    const onClickReject = useCallback(function (historyId: number) {
        if(window.confirm("Are you sure reject this request?")){
            AdminAPI.userWalletReject(token, historyId).then(() => {
                alert("Success reject");
                getAgencyList();
            })
        }
    }, []);

    useEffect(() => {
      getAgencyList()
    }, [])

    return <div>
        <ContentHeader title={t("User Wallet")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <h4> Deposit Request </h4>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 400 }}>
                                <table className="table table-head-fixed text-nowrap" id="user-table">
                                    <thead>
                                        <tr>
                                            <th>{t("#")}</th>
                                            <th>{t("type")}</th>
                                            <th>{t("user id")}</th>
                                            <th>{t("name")}</th>
                                            <th>{t("amount")}</th>
                                            <th>{t("status")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {deposits.map((agency: any, index) => {
                                            return <tr key={index}>
                                                <td>{agency.historyId}</td>
                                                <td>{agency.type == 1 ? "Deposit" : "Withdrawal"}</td>
                                                <td>{agency.uid}</td>
                                                <td>{agency.name}</td>
                                                <td>{Number(agency.amount).toLocaleString()}</td>
                                                <td>{PrintStatus(agency.status)}</td>
                                                {agency.status == 0 ? <td>
                                                    
                                                    <button type="button" className="btn btn-primary" onClick={() => {
                                                        onClickAccept(agency.historyId);
                                                    }}>{t("Accept")}</button>

                                                    <button type="button" style={{marginLeft:"10px"}} className="btn btn-danger" onClick={() => {
                                                        onClickReject(agency.historyId);
                                                    }}>{t("Reject")}</button>
                                                </td> : <td></td>}
                                            </tr>
                                        })}

                                        {deposits.length == 0 && <tr><td colSpan={5} style={{ textAlign: "center" }}>{t("검색 결과가 없습니다.")}</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>


                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <h4> Withdrawal Request </h4>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 400 }}>
                                <table className="table table-head-fixed text-nowrap" id="user-table">
                                    <thead>
                                        <tr>
                                            <th>{t("#")}</th>
                                            <th>{t("type")}</th>
                                            <th>{t("user id")}</th>
                                            <th>{t("name")}</th>
                                            <th>{t("amount")}</th>
                                            <th>{t("status")}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {withdrawals.map((agency: any, index) => {
                                            return <tr key={index}>
                                                <td>{agency.historyId}</td>
                                                <td>{agency.type == 1 ? "Deposit" : "Withdrawal"}</td>
                                                <td>{agency.uid}</td>
                                                <td>{agency.name}</td>
                                                <td>{Number(agency.amount).toLocaleString()}</td>
                                                <td>{PrintStatus(agency.status)}</td>
                                                {agency.status == 0 ? <td>
                                                    <button type="button" className="btn btn-primary" onClick={() => {
                                                        onClickAccept(agency.historyId);
                                                    }}>{t("Accept")}</button>

                                                    <button type="button" style={{marginLeft:"10px"}} className="btn btn-danger" onClick={() => {
                                                        onClickReject(agency.historyId);
                                                    }}>{t("Reject")}</button>
                                                </td> : <td></td>}
                                            </tr>
                                        })}

                                        {deposits.length == 0 && <tr><td colSpan={5} style={{ textAlign: "center" }}>{t("검색 결과가 없습니다.")}</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </div>
}

export default UserWallet;
