import React, {useCallback, useEffect} from 'react';
import {ContentHeader} from '@components';
import { CreateTournamentRoomInterface, RAKE_TYPE, ROOM_TYPE} from "@app/dataset";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";
export type EXCHANGE_RATE = {[SYMBOL:string]:number};
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const CreateRoom = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [ rebuyinNoLimit, setRebuyinNoLimit ] = React.useState<boolean>(false)
  const [presets, setPresets] = React.useState<any[]>([]);
  const [refresh, setRefresh] = React.useState(new Date().getTime());

  const [fiatRate, setFiatRate] = React.useState<EXCHANGE_RATE>({});

  useEffect(() => {
    handleGetRingPreset()
  }, [refresh]);

  useEffect(()=>{
    AdminAPI.getFiatExchangeRate(token).then((result)=>{
      setFiatRate(result.rate)
    });
  },[])

  const [tournamentRoomData, setTournamentRoomData] = React.useState<CreateTournamentRoomInterface>({
    type: ROOM_TYPE.TOURNAMENT,
    buyIn: 0,
    smallBlind: 0,
    bigBlind: 0,
    blindStructure: [
      [100, 200, 0, 60 * 5],
      [200, 400, 0, 60 * 5],
      [300, 600, 0, 60 * 5],
      [400, 800, 0, 60 * 5],
      [500, 1000, 0, 60 * 5],
      [600, 1200, 0, 60 * 5],
      [800, 1600, 0, 60 * 5],
      [1000, 2000, 1000, 60 * 5],
      [1500, 3000, 0, 60 * 5],
      [2000, 4000, 0, 60 * 5],
      [3000, 6000, 0, 60 * 5],
      [4000, 8000, 0, 60 * 5],
      [6000, 12000, 0, 60 * 5],
      [10000, 20000, 0, 60 * 5],
      [15000, 30000, 0, 60 * 5],
      [20000, 40000, 0, 60 * 5],
      [30000, 60000, 0, 60 * 5],
      [40000, 80000, 0, 60 * 5],
      [50000, 100000, 0, 60 * 5],
      [60000, 120000, 0, 60 * 5],
      [100000, 200000, 0, 60 * 5]
    ],
    name: "WSOP 토너먼트",
    startedAt: moment().add(20, "minutes").format(DATE_FORMAT),
    buyinPrice: 50000,
    addonPrice: 70000,
    beginChip: 50000,
    rebuyinChip: 50000,
    addOnChip: 100000,
    preRegisterBenefitTimeMinutes: 10,
    preRegisterBenefitChip: 10000,
    prize: [
      10000000,
      5000000,
      2000000,
      1000000,
      500000,
      400000,
      300000,
      200000,
      100000,
      50000,
    ],
    normalPrize: [
      {max:10, grade:[100,0,0,0,0,0,0,0,0,0,0]},
      {max:100, grade:[70,30,0,0,0,0,0,0,0,0,0]},
      {max:500, grade:[50,25,15,5,3,2,0,0,0,0,0]},
      {max:1000, grade:[30,25,20,15,10,0,0,0,0,0,0]},
      {max:2000, grade:[30,25,20,15,10,0,0,0,0,0,0]},
    ],
    normalPrizeBuyinRatio: 50,
    isNormalPrize:true,
    availableRegisterLevel: 10,
    minTotalMember: 2,
    maxTotalMember: 2000,
    maxTableMember: 8,
    finalTableMember: 9,
    rebuyinLimit: 2, // -1 {t("이면 무제한")}
    timeStructure: {
      playTimeSeconds: 60 * 10,
      restTimeSeconds: 60
    }, // {t("플레이 시간 구조")}
    isAnte: true,
    isStraddle: false,
    rakeType: RAKE_TYPE.NONE,
    isGuarantee: false,
    guaranteeMinAmount:3000000,
    guaranteeBuyinSplitRatio:80,
    minStartMember:20,
  })

  const handleGetRingPreset = () => {
    AdminAPI.getTournamentPresets(token).then((result) => {
      setPresets(result.list)
    })
  }
  const handleRemovePreset = (presetName: string) => {
    AdminAPI.deleteTournamentPreset(token, presetName).then((result) => {
      if (result.success) {
        alert(t("프리셋이 삭제되었습니다"))
        setRefresh(new Date().getTime())
      }
    })
  }
  const handleCreatePreset = () => {
    const presetName = document.getElementById('presetName') as HTMLInputElement;
    if(presetName.value === ''){
      alert(t("프리셋명을 입력해주세요"))
      return;
    }
    AdminAPI.upsertTournamentPreset(token, presetName.value, {
      ...tournamentRoomData,
      rebuyinLimit : rebuyinNoLimit ? -1 : tournamentRoomData.rebuyinLimit
    }).then((result) => {
      if (result.success) {
        alert(t("프리셋이 생성되었습니다"))
        setRefresh(new Date().getTime())
      }
    })
  }
  const handleApplyPreset = (preset: any) => {
    console.log(preset)
    let data = JSON.parse(JSON.stringify(preset));
    data.isNormalPrize = data.normalPrize === undefined ? false : data.isNormalPrize;
    data.normalPrize = data.normalPrize === undefined ? [{max:10, grade:[100,0,0,0,0,0,0,0,0,0,0]}] : data.normalPrize;
    data.normalPrizeBuyinRatio = data.normalPrizeBuyinRatio === undefined ? 0 : data.normalPrizeBuyinRatio;
    setTournamentRoomData(data)

    alert(t("프리셋이 적용되었습니다"))
  }

  useEffect(function(){
    if(tournamentRoomData.rebuyinLimit < 0){
      setRebuyinNoLimit(true);
    }
  },[ tournamentRoomData.rebuyinLimit ])

  const handleCreateTournamentRoom = async () => {
    try{
      tournamentRoomData.smallBlind = tournamentRoomData.blindStructure[0][0];
      tournamentRoomData.bigBlind = tournamentRoomData.blindStructure[0][1];
      tournamentRoomData.startedAt = moment(tournamentRoomData.startedAt).utc().format(DATE_FORMAT);
      const result = await AdminAPI.createRoom(token, {
        ...tournamentRoomData,
        rebuyinLimit : rebuyinNoLimit ? -1 : tournamentRoomData.rebuyinLimit
      });
      if(result.success) {
        alert(t("방이 생성되었습니다"))
      }
    }catch(e){
      alert(t("방 만들기에 실패하였습니다"))
    }
  }

  const onChangePrizeType = useCallback((w:boolean)=>{
    if(w == true){
      setTournamentRoomData({
        ...tournamentRoomData,
        isGuarantee:false
      })
    }

    setTournamentRoomData({
      ...tournamentRoomData,
      isNormalPrize:w
    })
  },[tournamentRoomData])

  const totalPrize = tournamentRoomData.prize.reduce((acc, cur) => acc + cur, 0);


  return (
    <div>
      <ContentHeader title={t("방 만들기")}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row" >
            <div className='col-8'>
              <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">{t("토너먼트 만들기")}</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("블라인드 구조")}</label>
                    <table className="table table-bordered">
                      <thead>
                      <tr>
                        <th style={{
                          width: 10
                        }}>#</th>
                        <th>SB</th>
                        <th>BB</th>
                        <th>{t("앤티")}</th>
                        <th>{t("블라인드시간")}</th>
                        <th style={{width:70}}><button type="button" className="btn btn-block btn-primary btn-sm" onClick={()=>{
                          const _blindStructure = [...tournamentRoomData.blindStructure];
                          _blindStructure.push([0, 0, 0, 0]);
                          setTournamentRoomData({
                            ...tournamentRoomData,
                            blindStructure: _blindStructure
                          })
                        }}>{t("추가")}</button></th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        tournamentRoomData.blindStructure.map((blind, index) => {
                          return <tr key={index}>
                            <td>{index+1}</td>
                            {
                              Array.from(Array(4)).map((_, _index) => {
                                return <td key={_index}><input style={{width: '100%'}} type='number' value={blind[_index]} onChange={(event)=>{
                                  const _blindStructure = [...tournamentRoomData.blindStructure];
                                  _blindStructure[index][_index] = Number(event.target.value);
                                  setTournamentRoomData({
                                    ...tournamentRoomData,
                                    blindStructure: _blindStructure
                                  })
                                }}/></td>
                              })
                            }
                            <td><button type="button" className="btn btn-block btn-danger btn-sm" onClick={()=>{
                              const _blindStructure = [...tournamentRoomData.blindStructure];
                              _blindStructure.splice(index, 1);
                              setTournamentRoomData({
                                ...tournamentRoomData,
                                blindStructure: _blindStructure
                              })
                            }}>{t("삭제")}</button></td>
                          </tr>
                        })
                      }
                      </tbody>
                    </table>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("방 이름")}</label>
                    <input type="text" className="form-control" value={tournamentRoomData.name}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               name: e.target.value
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("시작 날짜 (한국시간)")}</label>
                    <input type="datetime-local" className="form-control" value={tournamentRoomData.startedAt}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               startedAt: e.target.value
                             })
                           }}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("바이인 금액(달러 기준)")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.buyinPrice}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                                buyinPrice: Number(e.target.value)
                             })
                           }}
                    />
                    <div>{fiatRate["KRW"] && `${t("원화")} : `+Math.floor(fiatRate["KRW"] * tournamentRoomData.buyinPrice).toLocaleString()}{t("원")}</div>
                    <div>{fiatRate["KRW"] && `${t("엔화")} : `+Math.floor(fiatRate["JPY"] * tournamentRoomData.buyinPrice).toLocaleString()}{t("엔")}</div>
                    <div>{fiatRate["KRW"] && `${t("바트")} : `+Math.floor(fiatRate["THB"] * tournamentRoomData.buyinPrice).toLocaleString()}{t("바트")}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("애드온 금액")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.addonPrice}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               addonPrice: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("시작 칩")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.beginChip}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               beginChip: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("리바인 칩")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.rebuyinChip}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               rebuyinChip: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("애드온 칩")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.addOnChip}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               addOnChip: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("사전예약 허용 시간(분) (예, 30 입력시 30분 전까지 등록 가능)")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.preRegisterBenefitTimeMinutes}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               preRegisterBenefitTimeMinutes: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("사전예약 등록 칩")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.preRegisterBenefitChip}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               preRegisterBenefitChip: Number(e.target.value)
                             })
                           }}
                    />
                  </div>


                  <div className="form-group" style={{display:"flex",marginTop:"50px"}}>
                    <div>
                      <input type="radio" id="normalPrizeType1" name="normalPrize" onChange={()=>onChangePrizeType(true)} value="1" checked={tournamentRoomData.isNormalPrize ? true: false}/>
                      <label htmlFor="normalPrizeType1">{t("일반 토너먼트")}</label>
                    </div>
                    <div style={{marginLeft:"10px"}}>
                      <input type="radio" id="normalPrizeType2" name="normalPrize" onChange={()=>onChangePrizeType(false)} value="0" checked={tournamentRoomData.isNormalPrize ? false: true}/>
                      <label htmlFor="normalPrizeType2">GTD {t("토너먼트")}</label>
                    </div>
                  </div>

                  {tournamentRoomData.isNormalPrize ?
                  <>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("총 바이인 상금 비율")}</label>
                      <input type="number" className="form-control" value={tournamentRoomData.normalPrizeBuyinRatio}
                            onChange={(e) => {
                              setTournamentRoomData({
                                ...tournamentRoomData,
                                normalPrizeBuyinRatio: Number(e.target.value)
                              })
                            }}
                      />
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{t("일반 상금")}</label>
                        <table className="table table-bordered" style={{
                        }}>
                          <tr>
                            <th style={{width:90}}><button type="button" className="btn btn-block btn-primary btn-sm" onClick={()=>{
                              const _normalPrize = [...tournamentRoomData.normalPrize];
                              const lastGrade = tournamentRoomData.normalPrize[tournamentRoomData.normalPrize.length-1];
                              _normalPrize.push({max:lastGrade.max+1, grade:lastGrade.grade.map(()=>0)});
                              _normalPrize[_normalPrize.length-1].grade[0] = 100;
                              setTournamentRoomData({
                                ...tournamentRoomData,
                                normalPrize: _normalPrize
                              })
                            }}>{t("열 +")}</button></th>
                            <th style={{width:"100px"}} >{t("참가인원")}</th>
                            {tournamentRoomData.normalPrize[0].grade.map((_, index) => {
                              return <th key={index}>#{index+1}</th>
                            })}
                            <th rowSpan={tournamentRoomData.normalPrize.length + 1} style={{verticalAlign:"middle"}}>
                              <div style={{}}>
                                <button type="button" style={{width:"25px"}} className="btn btn-primary btn-sm" onClick={()=>{
                                  const _normalPrize = [...tournamentRoomData.normalPrize];
                                  for(let o of tournamentRoomData.normalPrize){
                                    o.grade.push(0);
                                  }
                                  setTournamentRoomData({
                                    ...tournamentRoomData,
                                    normalPrize: _normalPrize
                                  })
                                }}>+</button>
                                <button type="button" style={{width:"25px", marginTop:"10px"}} className="btn btn-danger btn-sm" onClick={()=>{
                                  const _normalPrize = [...tournamentRoomData.normalPrize];
                                  if(tournamentRoomData.normalPrize.length == 1){
                                    return alert(t('최소 1개 이상의 열이 있어야 합니다.'));
                                  }
                                  for(let o of tournamentRoomData.normalPrize){
                                    o.grade.pop();
                                  }
                                  setTournamentRoomData({
                                    ...tournamentRoomData,
                                    normalPrize: _normalPrize
                                  })
                                }}>-</button>
                              </div>

                            </th>
                          </tr>
                          {
                            tournamentRoomData.normalPrize.map((prize, index) => {
                              const totalRatio = prize.grade.reduce((acc, cur, i) => {
                                return acc + cur;
                              }, 0);
                              console.log(totalRatio, prize.grade)

                              return <tr key={index}>
                                <td><button type="button" className="btn btn-block btn-danger btn-sm" onClick={()=>{
                                  const _normalPrize = [...tournamentRoomData.normalPrize];
                                  _normalPrize.splice(index, 1);
                                  setTournamentRoomData({
                                    ...tournamentRoomData,
                                    normalPrize: _normalPrize
                                  })
                                }}>{t("열 삭제")}</button></td>

                                <td>
                                  <div style={{display:"flex", gap:"5px", alignItems:"center"}}>
                                    <input style={{width: '100%'}} type='number' value={prize.max} onChange={(event)=>{
                                      const _normalPrize = [...tournamentRoomData.normalPrize];
                                      const prevMax = _normalPrize[index-1] ? _normalPrize[index-1].max : 0;
                                      const nextMax = _normalPrize[index+1] ? _normalPrize[index+1].max : Number.MAX_SAFE_INTEGER;
                                      let value = Number(event.target.value);

                                      if(prevMax >= value){
                                        alert(t('이전 참가인원보다 적게 작성할 수 없습니다.'));
                                        value = prevMax+1;
                                      }
                                      if(nextMax <= value){
                                        alert(t('다음 참가인원보다 많게 작성할 수 없습니다.'));
                                        value = nextMax-1;
                                      }

                                      _normalPrize[index].max = value;
                                      setTournamentRoomData({
                                        ...tournamentRoomData,
                                        normalPrize: _normalPrize
                                      })
                                    }}/>
                                  </div>
                                </td>
                                {prize.grade.map((grade, _index) => {
                                  return <td style={{backgroundColor:totalRatio != 100 ? "red":"inherit", padding:0}}>
                                      <input style={{width: '100%', border:"0"}} type='number' value={grade} onChange={(event)=>{
                                        const _normalPrize = [...tournamentRoomData.normalPrize];
                                        let n = Number(event.target.value);
                                        _normalPrize[index].grade[_index] = n > 0 ? n : 0;
                                        setTournamentRoomData({
                                          ...tournamentRoomData,
                                          normalPrize: _normalPrize
                                        })
                                      }}/>
                                  </td>
                                })}
                              </tr>
                            })
                          }
                        </table>
                    </div>
                  </>:
                  <>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">{t("상금")}</label>
                      <div><b>{t("상금 총액")} : {totalPrize.toLocaleString()}{t("원")}</b></div>
                      <table className="table table-bordered" style={{
                        maxWidth: 400
                      }}>
                        <thead>
                        <tr>
                          <th style={{
                            width: 10
                          }}>#</th>
                          <th>{t("상금")}</th>
                          <th>{t("비율")}</th>
                          <th style={{width:70}}><button type="button" className="btn btn-block btn-primary btn-sm" onClick={()=>{
                            const _prize = [...tournamentRoomData.prize];
                            _prize.push(0);
                            setTournamentRoomData({
                              ...tournamentRoomData,
                              prize: _prize
                            })
                          }}>{t("추가")}</button></th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          tournamentRoomData.prize.map((prize, index) => {
                            return <tr key={index}>
                              <td>{index+1}</td>
                              <td key={index}><input style={{width: '100%'}} type='number' value={prize} onChange={(event)=>{
                                const _prize = [...tournamentRoomData.prize];
                                _prize[index] = Number(event.target.value);
                                setTournamentRoomData({
                                  ...tournamentRoomData,
                                  prize: _prize
                                })
                              }}/></td>
                              <td>{Math.floor(prize/totalPrize * 100 * 100)/100}%</td>
                              <td><button type="button" className="btn btn-block btn-danger btn-sm" onClick={()=>{
                                const _prize = [...tournamentRoomData.prize];
                                _prize.splice(index, 1);
                                setTournamentRoomData({
                                  ...tournamentRoomData,
                                  prize: _prize
                                })
                              }}>{t("삭제")}</button></td>
                            </tr>
                          })
                        }
                        </tbody>
                      </table>
                    </div>

                    <div className="form-group" style={{margin:"20px 0", padding:"20px 0 10px 0", borderTop:"1px solid red", borderBottom:"1px solid red"}}>
                      <h4>{t("개런티 토너먼트 설정")}</h4>
                      <span> <input type='checkbox' onChange={e=>{
                        setTournamentRoomData({
                          ...tournamentRoomData,
                          isGuarantee: e.target.checked
                        })
                      }}
                      checked={tournamentRoomData.isGuarantee}
                      /> {t("개런티 활성화")}</span>
                      <br/><br/>

                      { tournamentRoomData.isGuarantee ? <>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{t("개런티 활성 최소 금액")}</label>
                        <input type="number" className="form-control" value={tournamentRoomData.guaranteeMinAmount}
                              onChange={(e) => {
                                setTournamentRoomData({
                                  ...tournamentRoomData,
                                  guaranteeMinAmount: Number(e.target.value)
                                })
                              }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputEmail1">{t("초과 금액 상금 전환 비율")}(%)</label>
                        <input type="number" className="form-control" value={tournamentRoomData.guaranteeBuyinSplitRatio}
                              onChange={(e) => {
                                let value = Number(e.target.value);
                                if(value > 100){
                                  value = 100;
                                }
                                setTournamentRoomData({
                                  ...tournamentRoomData,
                                  guaranteeBuyinSplitRatio: value
                                })
                              }}
                        />
                      </div>
                      </> : <></> }
                    </div>
                  </>}

                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("등록 가능 레벨")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.availableRegisterLevel}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               availableRegisterLevel: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최소 테이블 인원")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.minTotalMember}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               minTotalMember: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최소 참가 인원 (미달 시 토너먼트 진행 안함)")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.minStartMember}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               minStartMember: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최대 참가 인원")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.maxTotalMember}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               maxTotalMember: Number(e.target.value)
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최대 테이블 인원")}</label>
                    {
                      [5,6,8,9].map((item, index) => {
                        return <div className="form-check">
                          <input className="form-check-input" type="radio" name="maxTableMember" checked={tournamentRoomData.maxTableMember === item}
                                 onClick={() => {
                                   setTournamentRoomData({
                                     ...tournamentRoomData,
                                     maxTableMember: item
                                   })
                                 }}/>
                          <label className="form-check-label">{item}</label>
                        </div>
                      })
                    }
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("최종 테이블 인원")}</label>
                    {
                      [5,6,8,9].map((item, index) => {
                        return <div className="form-check">
                          <input className="form-check-input" type="radio" name="finalTableMember" checked={tournamentRoomData.finalTableMember === item}
                                 onClick={() => {
                                   setTournamentRoomData({
                                     ...tournamentRoomData,
                                     finalTableMember: item
                                   })
                                 }}/>
                          <label className="form-check-label">{item}</label>
                        </div>
                      })
                    }
                  </div>
                  <div className="form-group">
                    <div>
                      <label htmlFor="exampleInputEmail1">{t("리바인 제한")}</label>
                      <span style={{marginLeft:"10px"}}><input type="checkbox" onChange={v=>setRebuyinNoLimit(v.target.checked)} checked={rebuyinNoLimit} /> {t("제한 없음")}</span>
                    </div>
                    {rebuyinNoLimit ?
                      <input className="form-control" value={t("제한 없음")} disabled={true} /> :
                      <input type="number" className="form-control" value={tournamentRoomData.rebuyinLimit}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               rebuyinLimit: Number(e.target.value)
                             })
                           }}
                    />}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("플레이 시간 (초)")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.timeStructure.playTimeSeconds}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               timeStructure: {
                                 ...tournamentRoomData.timeStructure,
                                 playTimeSeconds: Number(e.target.value)
                               }
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("휴식 시간 (초)")}</label>
                    <input type="number" className="form-control" value={tournamentRoomData.timeStructure.restTimeSeconds}
                           onChange={(e) => {
                             setTournamentRoomData({
                               ...tournamentRoomData,
                               timeStructure: {
                                 ...tournamentRoomData.timeStructure,
                                 restTimeSeconds: Number(e.target.value)
                               }
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("스트래들")}</label>
                    <div className="form-group">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="isStraddle"
                               checked={tournamentRoomData.isStraddle} onClick={() => {
                          setTournamentRoomData({
                            ...tournamentRoomData,
                            isStraddle: true
                          })
                        }}/>
                        <label className="form-check-label">{t("있음")}</label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="isStraddle"
                               checked={!tournamentRoomData.isStraddle} onClick={() => {
                          setTournamentRoomData({
                            ...tournamentRoomData,
                            isStraddle: false
                          })
                        }}/>
                        <label className="form-check-label">{t("없음")}</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="card-footer"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12
                  }}>
                  <button className="btn btn-warning"
                          onClick={() => handleCreateTournamentRoom()}>{t("토너먼트 만들기")}
                  </button>

                </div>
              </div>
            </div>

            <div className='col-4'>
              <div className="card card-dark">
                <div className="card-header">
                  <h3 className="card-title">{t("프리셋")}</h3>
                </div>
                <div className="card-body" style={{
                  maxHeight: 700,
                  overflowY: 'auto'
                }}>
                  <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                      <th>{t("프리셋명")}</th>
                      <th></th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      presets.map((item, index: number) => {
                        return <tr key={index}>
                          <td>{item.name.replace('PRESET_TOURNAMENT_','')}</td>
                          <td>
                            <div className='btn btn-info' onClick={()=>{
                              handleApplyPreset(JSON.parse(item.value))
                            }}>{t("불러오기")}</div>
                          </td>
                          <td>
                            <div className='btn btn-danger' onClick={()=>{
                              handleRemovePreset(item.name.replace('PRESET_TOURNAMENT_',''))
                            }}>{t("삭제")}</div>
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </table>
                </div>
                <div
                  className="card-footer"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12
                  }}>
                  <div className='row' style={{
                    flexGrow: 1
                  }}>
                    <div className="input-group col-12">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t("프리셋명")}</span>
                      </div>
                      <input id='presetName' type="text" className="form-control"/>
                    </div>
                  </div>

                  <button className="btn btn-primary" style={{
                    flexShrink: 0
                  }}
                          onClick={() => handleCreatePreset()}>{t("프리셋저장")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateRoom;
