import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import {useTranslation} from "react-i18next";

const AgencyServerStatus = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);

  const [min20List, setMin20LList] = useState<{
    name:string,
    agencyId:number,
    unixtimestamp:number,
    timeoutCount:number,
    codeErrorCount:number,
    addedAt:string,
  }[]>();
  const [hour12List, setHour12LList] = useState<{
    name:string,
    agencyId:number,
    unixtimestamp:number,
    timeoutCount:number,
    codeErrorCount:number,
    addedAt:string,
  }[]>();

  const OnLoad = useCallback(function(){
    setHour12LList(undefined);
    AdminAPI.agencyServerConnectionErrorLast12Hour(token).then(v=>{
      console.log(JSON.stringify(v))
      setHour12LList(v.rows.filter((v:any)=> v.timeoutCount + v.codeErrorCount>10))
    })

    setMin20LList(undefined);
    AdminAPI.agencyServerConnectionErrorLast20Min(token).then(v=>{
      console.log(JSON.stringify(v))
      setMin20LList(v.rows)
    })
  },[
  ])

  useEffect(function(){
    OnLoad();
  },[])

  return <div>
    <h3>{t("통신 에러 내역")}</h3>
    <section className="content">
      <div className="container-fluid">
        <div className="card-body">

          <h4>20{t("분 이내 통신 에러")}</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{t("에이전시 이름")}</th>
                <th>{t("타임아웃 횟수")}</th>
                <th>{t("에러 횟수")}</th>
                <th>{t("시각")}</th>
              </tr>
            </thead>
            <tbody>
              {min20List?.map((v,i)=>{
                return <tr key={i}>
                  <td>{v.name}</td>
                  <td>{v.timeoutCount}</td>
                  <td>{v.codeErrorCount}</td>
                  <td>{v.addedAt}</td>
                </tr>
              })}
              {!min20List || min20List?.length === 0 && <tr>
                <td colSpan={5}>20{t("분 이내에 통신 에러건이 없습니다")}.</td>
               </tr>}
            </tbody>
          </table>

          <br />
          <h4>{t("12시간 이내 통신 에러 내역 (10회 이상만 표기)")}</h4>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>{t("에이전시 이름")}</th>
                <th>{t("타임아웃 횟수")}</th>
                <th>{t("에러 횟수")}</th>
                <th>{t("시각")}</th>
              </tr>
            </thead>
            <tbody>
              {hour12List?.map((v,i)=>{
                return <tr key={i}>
                  <td>{v.name}</td>
                  <td>{v.timeoutCount}</td>
                  <td>{v.codeErrorCount}</td>
                  <td>{v.addedAt}</td>
                </tr>
              })}
              {!hour12List || hour12List?.length === 0 && <tr>
                <td colSpan={5}>{t("12시간 이내에 10건 이상 누적 통신 에러건이 없습니다.")}</td>
               </tr>}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  </div>
}

export default AgencyServerStatus;
