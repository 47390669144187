import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import {CreateTournamentRoomInterface, RAKE_TYPE, ROOM_TYPE} from "@app/dataset";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useTranslation} from "react-i18next";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const TournamentList = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [tournamentList, setTournamentList] = React.useState<any[]>([]);
  const [refresh, setRefresh] = useState<boolean>(false);
  useEffect(() => {
    AdminAPI.getTournamentList({session: token}).then((res) => {
      setTournamentList(res.data)
    })
  }, [refresh])

  return (
    <div>
      <ContentHeader title={t("토너먼트 목록")}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">{t("토너먼트 목록")}</h3>
                  <div className="card-tools">
                    <div className="input-group input-group-sm" style={{
                      width: 150
                    }}>
                      <input type="text" name="table_search" className="form-control float-right" placeholder="Search"/>
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-default">
                          <i className="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body table-responsive p-0" style={{
                  height: 700
                }}>
                  <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>AddedAt</th>
                      <th><button type="button" className="btn btn-block btn-primary btn-sm" onClick={()=>{
                        window.location.href = '/create/room/tournament';
                      }}>{t("추가")}</button></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      tournamentList.map((item, index) => {
                        return <tr key={index}>
                          <td>{item.groupId}</td>
                          <td>{item.data.name}</td>
                          <td>{item.isEnd ? t("종료") : (item.isStart ? t("게임중") : (item.isOpen ? t("등록중") : t("등록대기")))}</td>
                          <td>{moment(item.addedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>
                            {item.isEnd ?
                              <button type="button" className="btn btn-block btn-warning btn-sm" onClick={async () => {
                                const result = await AdminAPI.setInvisibleTournament({
                                  session: token,
                                  groupId: item.groupId
                                });
                                if (result.success) {
                                  alert(t("토너먼트가 게임 목록에서 숨겨졌습니다"))
                                } else {
                                  alert(t("토너먼트 숨김처리에 실패하였습니다"))
                                }
                                setRefresh(!refresh)
                              }}>{t("목록에서 숨기기")}</button>
                              : null}
                          </td>
                          <td>
                            <button type="button" className="btn btn-block btn-danger btn-sm" onClick={async () => {
                              const result = await AdminAPI.deleteTournament({session: token, groupId: item.groupId});
                              if (result.success) {
                                alert(`${t("토너먼트가 삭제되었습니다")}.`)
                              } else {
                                alert(`${t("토너먼트 삭제에 실패하였습니다")}.`)
                              }
                              setRefresh(!refresh)
                            }}>{t("영구 삭제")}</button>
                            <div style={{fontSize: "12px"}}>{t("복구 불가/게임 데이터 완전삭제")}</div>
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TournamentList;
