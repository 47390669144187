import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BET_TYPE } from "@app/dataset";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";


const GrayTh = styled.th`
  background-color: #eee !important;
`

const WhiteGrayTh = styled.th`
  background-color: #ddd !important;
`

const GrayTd = styled.td`
  background-color: #eee;
  font-size: 15px;

  span{
    font-size: 13px;
    &.zero {
        display: none;
    }
    &.green{
        color: green;
    }
    &.red{
        color: red;
    }
  }
`

const WhiteGrayTd = styled.td`
  background-color: #ddd;
  font-size: 15px;
  
  span{
    font-size: 13px;
    &.zero {
        display: none;
    }
    &.green{
        color: green;
    }
    &.red{
        color: red;
    }
  }
`

function nNumber(n:any){
  if(isNaN(n)){
    return 0;
  }
  return Math.floor(Number(n) * 10) / 10;
}


function act(ss: BET_TYPE) {
  switch (ss) {
    case BET_TYPE.FOLD:
      return 'FOLD';
    case BET_TYPE.CALL:
      return 'CALL';
    case BET_TYPE.CHECK:
      return 'CHECK';
    case BET_TYPE.RAISE:
      return 'RAISE';
    case BET_TYPE.ALLIN:
      return 'ALL IN';
    case BET_TYPE.BET:
      return 'BET';
    case BET_TYPE.SB:
      return 'SB';
    case BET_TYPE.BB:
      return 'BB';
    case BET_TYPE.STR:
      return 'STR';
    case BET_TYPE.ANTE:
      return 'ANTE';
  }
}
function toNum(v: any) {
  if (isNaN(v))
    return 0;
  if (v == Infinity)
    return 0;
  return v;
}
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const GameProfit = () => {
  const { t } = useTranslation();
  const { token } = useAppSelector(state => state.auth);
  const [rawData, setRawData] = useState<any>();
  const [dataList, setDataList] = useState([])
  const [total, setTotal] = useState<{
    totalLoss: number,
    totalRake: number,
    totalTournament: number,
  }>({
    totalLoss: 0,
    totalRake: 0,
    totalTournament: 0,
  });
  const [timezone, setTimezone] = useState(9);
  const [selectMenu, setSelectMenu] = useState(0);
  const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000)))
  const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT))

  const getBettingHistory = async () => {
    const result = await AdminAPI.totalProfit(token, searchStartDate, timezone);
    if (result.success) {
      setRawData(result.data)
    }
  }

  useEffect(() => {
    getBettingHistory()
  }, [searchStartDate, timezone])

  useEffect(()=>{
    if(rawData){
      rawData.sort((a: any, b: any) => {
        return new Date(a.day).getTime() - new Date(b.day).getTime()
      });

      let data = JSON.parse(JSON.stringify(rawData));

      let totalLoss = 0;
      let totalRake = 0;
      let totalTournament = 0;

      let rows:any = []
      for (let i in data) {
        let idx = Number(i);
        let v = selectMenu == 0 ? data[i].hq : selectMenu == 1 ? data[i].agency : data[i].subhq;
        if(moment(data[i].day).month() != moment(searchStartDate).month()){
          data[i].day = moment(searchStartDate).startOf('month').format("YYYY-MM-DD 09:00:00");
        };

        v.loss = Number(v.loss);
        v.rake = Number(v.rake);
        v.tournament = Number(v.tournament);

        totalLoss += v.loss;
        totalRake += v.rake;
        totalTournament += v.tournament;

        v.accumLoss = totalLoss;
        v.accumRake = totalRake;
        v.accumTournament = totalTournament;

        v.accumLossRatio = 0;
        v.accumRakeRatio = 0;
        v.accumTournamentRatio = 0;
        v.accumTotalRatio = 0;

        v.lossRatio = 0;
        v.rakeRatio = 0;
        v.tournamentRatio = 0;
        v.totalRatio = 0;

        if (idx > 0) {
          let prev = data[idx - 1];
          v.accumLossRatio = toNum(((v.accumLoss - prev.accumLoss) / prev.accumLoss) * 100);
          v.accumRakeRatio = toNum(((v.accumRake - prev.accumRake) / prev.accumRake) * 100);
          v.accumTournamentRatio = toNum(((v.accumTournament - prev.accumTournament) / prev.accumTournament) * 100);
          v.accumTotalRatio = toNum(((v.accumLoss + v.accumRake + v.accumTournament - prev.accumLoss - prev.accumRake - prev.accumTournament) / (prev.accumLoss + prev.accumRake + prev.accumTournament)) * 100);
          v.lossRatio = toNum(((v.loss - prev.loss) / prev.loss) * 100);
          v.rakeRatio = toNum(((v.rake - prev.rake) / prev.rake) * 100);
          v.tournamentRatio = toNum(((v.tournament - prev.tournament) / prev.tournament) * 100);
          v.totalRatio = toNum(((v.loss + v.rake + v.tournament - prev.loss - prev.rake- prev.tournament) / (prev.loss + prev.rake+prev.tournament)) * 100);
        }

        rows.push({
          ...v,
          day: data[i].day
        })
      }
      console.log(rows)
      setDataList(rows)

      setTotal({
        totalLoss: totalLoss,
        totalRake: totalRake,
        totalTournament: totalTournament,
      })
    }

  },[rawData, selectMenu])

  const onClickSearch = function () {
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
  }

  const onClickCancelSearch = useCallback(function () {
    let r1 = moment().startOf('month').format(DATE_FORMAT)
    setInputStartDate(new Date(r1));

    setSearchStartDate(r1);
  }, [])

  return <div>
    <ContentHeader title={t("수익표")} />
    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row">
                  <div className="input-group col-8">
                    <div className="input-group-prepend">
                      <button type="button" className={"btn " + (timezone == 9 ? "btn-primary" : "btn-secondary")} onClick={()=>setTimezone(9)}>
                        {t("한국시간")}(0~24)
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className={"btn " + (timezone == 0 ? "btn-primary" : "btn-secondary")} onClick={()=>setTimezone(0)}>
                        {t("세계기준시")}(9~9)
                      </button>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className="input-group col-8">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                      value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                      onChange={(e) => {
                        setInputStartDate(new Date(e.target.value));
                      }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={() => {
                        let r1 = moment().subtract(3, 'months').startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("세달전")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={() => {
                        let r1 = moment().subtract(2, 'months').startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("두달전")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={() => {
                        let r1 = moment().subtract(1, 'months').startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("지난달")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={() => {
                        let r1 = moment().startOf('month').format(DATE_FORMAT)
                        setInputStartDate(new Date(r1));
                      }}>{t("이번달")}</button>
                    </div>
                  </div>
                  <div className='col-4'>
                    <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="input-group col-8">
                    <div className="input-group-prepend">
                      <button type="button" className={"btn " + (selectMenu == 0 ? "btn-primary" : "btn-secondary")} onClick={()=>setSelectMenu(0)}>
                        {t("본사")}
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className={"btn " + (selectMenu == 1 ? "btn-primary" : "btn-secondary")} onClick={()=>setSelectMenu(1)}>
                        {t("에이전시")}
                      </button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className={"btn " + (selectMenu == 2 ? "btn-primary" : "btn-secondary")} onClick={()=>setSelectMenu(2)}>
                        {t("부본사")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-3">
                    <div className="info-box">
                      <span className="info-box-icon bg-info"><i className="fas fa-sign-out-alt" /></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{t("총 정산 수수료")}</span>
                        <span className="info-box-number"><DigitsColorWrapper number={nNumber(total.totalLoss)}/>{t("포인트")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="info-box">
                      <span className="info-box-icon bg-warning"><i className="fas fa-cookie-bite" /></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{t("총 레이크")}</span>
                        <span className="info-box-number"><DigitsColorWrapper number={nNumber(total.totalRake)}/>{t("포인트")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="info-box">
                      <span className="info-box-icon bg-primary"><i className="fas fa-chair" /></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{t("총 토너먼트 바이인")}</span>
                        <span className="info-box-number"><DigitsColorWrapper number={nNumber(total.totalTournament)}/>{t("포인트")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="info-box">
                      <span className="info-box-icon bg-success"><i className="fas fa-hand-holding-usd" /></span>
                      <div className="info-box-content">
                        <span className="info-box-text">{t("총 수익")}</span>
                        <span className="info-box-number"><DigitsColorWrapper number={nNumber(total.totalLoss + total.totalRake)}/>{t("포인트")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                <table className="table table-head-fixed text-nowrap">
                  <thead>
                    <tr>
                      <th>{t("일자")}</th>
                      <GrayTh>{t("정산 수수료")}</GrayTh>
                      <GrayTh>{t("레이크")}</GrayTh>
                      <GrayTh>{t("토너먼트 바이인")}</GrayTh>
                      <GrayTh>{t("수익총합")}</GrayTh>
                      <WhiteGrayTh>{t("정산 수수료(누적)")}</WhiteGrayTh>
                      <WhiteGrayTh>{t("레이크(누적)")}</WhiteGrayTh>
                      <WhiteGrayTh>{t("토너먼트 바이인(누적)")}</WhiteGrayTh>
                      <WhiteGrayTh>{t("수익총합(누적)")}</WhiteGrayTh>
                    </tr>
                  </thead>
                  <tbody>
                    {dataList.length == 0 ? <tr>
                      <td colSpan={9}> {t("검색 결과가 없습니다")}.</td>
                    </tr> : null}
                    {
                      dataList.map((item: any, index) => {
                        return <tr key={index}>
                          <td>{item.day}</td>

                          <GrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.loss)}/></b>{t("포인트")}
                            <span className={item.lossRatio == 0 ? "zero" : item.lossRatio > 0 ? "green" : "red"}>({item.lossRatio.toFixed(2)}%)</span>
                          </GrayTd>
                          <GrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.rake)}/></b>{t("포인트")}
                            <span className={item.rakeRatio == 0 ? "zero" : item.rakeRatio > 0 ? "green" : "red"}>({item.rakeRatio.toFixed(2)}%)</span>
                          </GrayTd>
                          <GrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.tournament)}/></b>{t("포인트")}
                            <span className={item.tournamentRatio == 0 ? "zero" : item.tournamentRatio > 0 ? "green" : "red"}>({item.tournamentRatio.toFixed(2)}%)</span>
                          </GrayTd>
                          <GrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.loss + item.rake + item.tournament)}/></b>{t("포인트")}
                            <span className={item.totalRatio == 0 ? "zero" : item.totalRatio > 0 ? "green" : "red"}>({item.totalRatio.toFixed(2)}%)</span>
                          </GrayTd>
                          <WhiteGrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.accumLoss)}/></b>{t("포인트")}
                            <span className={item.accumLossRatio == 0 ? "zero" : item.accumLossRatio > 0 ? "green" : "red"}>({item.accumLossRatio.toFixed(2)}%)</span>
                          </WhiteGrayTd>
                          <WhiteGrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.accumRake)}/></b>{t("포인트")}
                            <span className={item.accumRakeRatio == 0 ? "zero" : item.accumRakeRatio > 0 ? "green" : "red"}>({item.accumRakeRatio.toFixed(2)}%)</span>
                          </WhiteGrayTd>
                          <WhiteGrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.accumTournament)}/></b>{t("포인트")}
                            <span className={item.accumTournamentRatio == 0 ? "zero" : item.accumTournamentRatio > 0 ? "green" : "red"}>({item.accumTournamentRatio.toFixed(2)}%)</span>
                          </WhiteGrayTd>
                          <WhiteGrayTd>
                            <b><DigitsColorWrapper number={nNumber(item.accumLoss + item.accumRake + item.accumTournament)}/></b>{t("포인트")}
                            <span className={item.accumTotalRatio == 0 ? "zero" : item.accumTotalRatio > 0 ? "green" : "red"}>({item.accumTotalRatio.toFixed(2)}%)</span>
                          </WhiteGrayTd>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
}

export default GameProfit;
