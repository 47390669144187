import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import { ROOM_TYPE } from "@app/dataset";
import { useNavigate } from "react-router-dom";
import GameDetailPopup from "@app/components/popup/GameDetailPopup";
import {useTranslation} from "react-i18next";
import { UserCurrencyMark } from "@app/conversionChip";

let BanMemo = styled.div`
  font-size:12px;
  text-align: center;
`

let ProfitPerUser = styled.span`
  font-size:13px;
  font-weight: bold;
  color: #00405a;
`

let lastUpate = 0;
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const GameRecord = ({isAgency, roomType}:{isAgency:boolean, roomType?:ROOM_TYPE}) => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [tot, setTot] = useState(0);
  const [games, setGames] = useState<{
    logId:number,
    roomType:"ring"|"tournament",
    sbbb:string,
    roomId:number,
    hands:number,
    boardCards:string[],
    agencyIds:number[],
    winnerNames:string[],
    winnerAgencyNames:string[],
    winnerUserIds:number[],
    winCards:Array<string>[],
    pot:number,
    rake:number,
    profit:number,
    agencyId:number,
    createdAt:string,
    name:string,
    betId:number,

    winners:{[key:string]:any}
  }[]>();

  const [detailPopup, setDetailPopup] = useState<{roomId:number, gameNumber:number, communityCards: number[]} | null>(null);

  const [inputGroupId, setInputGroupId ] = useState("")
  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")
  const [inputStartDate, setInputStartDate] = React.useState(new Date(Date.now() - 1000 * 60 * 60 * 24 * 1));
  const [inputEndDate, setInputEndDate] = React.useState(new Date());

  const [searchGroupId, setSearchGroupId ] = useState("")
  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")
  const [searchStartDate, setSearchStartDate] = React.useState(moment(inputStartDate).format(DATE_FORMAT));
  const [searchEndDate, setSearchEndDate] = React.useState(moment(inputEndDate).format(DATE_FORMAT));

  const [tournamentList, setTournamentList] = useState<{
    groupId:number,
    name:string
  }[]>([]);

  const OnLoad = function(){
    const t1 = new Date(searchStartDate)
    const t2 = new Date(searchEndDate);
    if(Math.abs(t1.getTime() - t2.getTime()) > 1000 * 60 * 60 * 24 * 7){
        alert(t("검색 기간은 {{day}}일 이내로 설정해주세요", {day : "7"}));
        return;
    }

    setGames(undefined);
    const requested = Date.now();
    AdminAPI.gameRecords(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchUserId, searchStartDate, searchEndDate, roomType, searchGroupId ).then(v=>{
        if(requested < lastUpate) return;
        lastUpate = requested;

        for(let res of v.payload.list){
            let winnerUsers:any = {}
            for(let i in res.winnerUserIds){
                winnerUsers[res.winnerUserIds[i]] = i;
            }
            let obj = Object.values(winnerUsers);
            res.winnerNames = res.winnerNames.filter((v:string,i:number)=>obj.includes(i.toString()))
            res.agencyNames = res.agencyNames.filter((v:string,i:number)=>obj.includes(i.toString()))
            res.winnerUserIds = res.winnerUserIds.filter((v:string,i:number)=>obj.includes(i.toString()))
            res.agencyIds = res.agencyIds.filter((v:string,i:number)=>obj.includes(i.toString()))
        }
        setGames(v.payload.list.map((v:any)=>{
            let pot = v.lastGameData.pots.reduce((a:any,b:any)=>a+b.amount,0);
            let rake = v.lastGameData.pots.reduce((a:any,b:any)=>a+b.potRake,0);

            const playerGameData = (v.lastGameData?.changeStackSizeList ?? []).reduce((a:any,b:any)=>{
              a[b.userId] = {amount: b.stackSize - b.prevStackSize, fiat: ((b.stackSize - b.prevStackSize) * Number(v.exchangeRatio)).toFixed(2)};
              return a;
            },{});

            return {
                logId:v.logId,
                roomType:v.roomType == ROOM_TYPE.RING ? "ring" : "tournament",
                sbbb:v.roomType == ROOM_TYPE.RING ? v.groupData.blind.join("-") : "",
                roomId:v.roomId,
                hands:v.game,
                name:v.groupData.name,
                boardCards:v.lastGameData.communityCards,
                agencyIds:v.agencyIds,
                winnerNames:v.winnerNames,
                winnerAgencyNames:v.agencyNames,
                winnerUserIds:v.winnerUserIds,
                winCards:v.winnerUserIds.map((userId:number)=>{
                    const lastGameDataCards = v.lastGameData.cards
                    const cards = lastGameDataCards.find((v:any)=>v.userId == userId)
                    if(!cards) return []

                    return v.lastGameData.cards.find((v:any)=>v.userId == userId).cards
                }),

                winners: playerGameData,
                pot:pot,
                rake:rake,
                profit:v.profit,
                agencyId:v.agencyId,
                createdAt:v.createdAt,
                betId:v.betId,
            }
        }))
        setTot(v.payload.totalCount)
    })
  }

  useEffect(function(){
    if(roomType == ROOM_TYPE.TOURNAMENT){
      AdminAPI.tournamentNameList(token).then(v=>{
        setTournamentList(v.list)
      });
    }
  },[roomType])

  useEffect(function(){
    OnLoad();
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    pageNum,
    page,
    searchStartDate,
    searchEndDate,
    searchGroupId,
    roomType
  ])

  const onClickSearch = useCallback(function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserId(inputUserId);
    setSearchUserNickname(inputUserNickname);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
    setSearchGroupId(inputGroupId);
  },[
    inputAgencyName,
    inputUserId,
    inputUserNickname,
    inputStartDate,
    inputEndDate,
    inputGroupId,
    roomType
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputAgencyName("");
    setInputUserId("");
    setInputUserNickname("");

    setSearchAgencyName("");
    setSearchUserId("");
    setSearchUserNickname("");

    setSearchStartDate(moment().add(-1, "days").format(DATE_FORMAT))
    setSearchEndDate(moment().add(0, "months").format(DATE_FORMAT))
  },[])
  
  const onClickReloadRecords = async function(){
    if(games && games.length > 1){
      let r = await AdminAPI.reloadGameRecords(token, games[0].betId, games[games.length-1].betId);
      if(r.success == true){
        OnLoad();
        alert(t("데이터를 갱신했습니다. {{count}}개의 데이터를 추가했습니다.", { count:r.count }));
      }else{
        alert(t("데이터를 갱신하는데 실패했습니다."));
      }
    }
  }

  return <div>
    {detailPopup && <GameDetailPopup
      roomId={detailPopup.roomId}
      gameNumber={detailPopup.gameNumber}
      communityCards={detailPopup.communityCards}
      onClose={()=>setDetailPopup(null)}
    />}

    <ContentHeader title={ (roomType == ROOM_TYPE.RING ? t("링") : t("토너먼트")) + ` > ${t("게임 기록")}`}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className='row mb-2'>
                  <div className="input-group col-3" style={isAgency ? {display:"none"} : {}}>
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputAgencyName} onChange={v=>setInputAgencyName(v.target.value)}/>
                  </div>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("참여 유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputUserNickname} onChange={v=>setInputUserNickname(v.target.value)}/>
                  </div>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("참여 유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputUserId} onChange={v=>setInputUserId(v.target.value)}/>
                  </div>
                </div>

                <div className='row mb-2'>

                  {roomType != ROOM_TYPE.TOURNAMENT ? <>
                    <div className="input-group col-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t("방 정보")}</span>
                      </div>
                      <select onChange={e=>setInputGroupId(e.target.value)} value={inputGroupId}>
                          <option value="">{t("전체")}</option>
                          <option value="1">1,000-2,000</option>
                          <option value="2">2,500-5,000</option>
                          <option value="3">5,000-10,000</option>
                          <option value="4">10,000-25,000</option>
                          <option value="5">25,000-50,000</option>
                          <option value="6">50,000/100,000</option>
                      </select>
                    </div>
                  </> : <></>}

                  {roomType == ROOM_TYPE.TOURNAMENT ? <>
                    <div className="input-group col-3">
                      <div className="input-group-prepend">
                        <span className="input-group-text">{t("토너먼트")}</span>
                      </div>
                      <select onChange={e=>setInputGroupId(e.target.value)} value={inputGroupId}>
                          <option value="">{t("전체")}</option>
                          {tournamentList.map((v,i)=>{
                            return <option key={i} value={v.groupId}>{v.name}</option>
                          })}
                      </select>
                    </div>
                  </> : <></>}

                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                    <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                        value={moment(inputStartDate).format("YYYY-MM-DDTHH:mm")}
                        onChange={(e) => {
                            setInputStartDate( new Date(e.target.value) )
                        }}
                    />
                    <input type="datetime-local" className="form-control"
                        value={moment(inputEndDate).format("YYYY-MM-DDTHH:mm")}
                        onChange={(e) => {
                            setInputEndDate( new Date(e.target.value) )
                        }}
                    />
                  </div>

                  <div className='col-3'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
                  </div>
                </div>
              </div>
              
              <div className="card-header">
                <div className='row mb-2'>
                    <button type="button" className="btn btn-primary float-right" onClick={onClickReloadRecords}>{t("재정렬하기")}</button>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr style={{textAlign:"center"}}>
                    <th>{t("정보")}</th>
                    <th>{t("방번호")}</th>
                    <th>{t("핸드")}</th>
                    <th>{t("시각")}</th>
                    <th>{t("보드카드")}</th>
                    <th>{t("승리/유저(에이전시)/이익금")}</th>
                    <th>{t("팟 금액")}</th>
                    <th>{t("레이크")}</th>
                    <th>{t("상세보기")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {games && games.length == 0 ? <tr><td colSpan={9}>{t("검색 결과가 없습니다")}.</td></tr> : <></>}
                  {
                    games ? games.map((item, index) => {
                      return <tr style={{textAlign:"center"}} key={index}>
                        <td style={{fontSize:"12px"}}>
                            {item.roomType == "ring"? t("링") : t("토너먼트")}<br />
                            {item.sbbb}
                            {item.roomType != "ring" ? <>{item.name}</> : <></>}
                        </td>
                        <td>{item.roomId}</td>
                        <td>{item.hands}</td>
                        <td>
                            {moment.utc(item.createdAt).local().format("YYYY-MM-DD")}<br/>
                            {moment.utc(item.createdAt).local().format("HH:mm:ss")}
                        </td>
                        <td>
                            {item.boardCards.map((v:string, i:number)=>{
                                return <img key={i} src={"/img/cards/"+v+".svg"} style={{width:"30px", marginRight:"3px"}}/>
                            })}
                        </td>
                        <td>
                            {item.winnerAgencyNames.map((v:string,i:number)=>{
                                const _userId = item.winnerUserIds[i].toString();
                                const amount = item.winners[_userId] && item.winners[_userId].amount;
                                const fiat = item.winners[_userId] && item.winners[_userId].fiat;
                                return <span key={i}>
                                    {item.winCards[i].map((v:string)=>{
                                        return <img src={"/img/cards/"+v+".svg"} style={{width:"30px", marginRight:"3px"}}/>
                                    })}
                                    {item.winnerNames[i]}
                                    {"  "}({v} <i style={{ cursor: 'pointer',marginLeft:"3px" }} className='fas fa-chart-area' onClick={()=>{ navigate("/agency/status?agencyId="+item.agencyIds[i]) }}></i>)
                                    <ProfitPerUser>+{amount ? amount.toLocaleString() : 0} ({UserCurrencyMark()}{fiat ? fiat.toLocaleString() : 0})</ProfitPerUser><br/>
                                    <br/>
                                </span>
                            })}
                        </td>
                        <td>{item.pot.toLocaleString()}</td>
                        <td>{item.rake.toLocaleString()}</td>
                        <td>
                            <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' ,marginRight:"10px" }} onClick={() => { setDetailPopup({ communityCards:item.boardCards.map(x => Number(x)), roomId:item.roomId, gameNumber:item.hands }) }}/>
                            {isAgency ? <></> : <i style={{ cursor: 'pointer' }} className='fas fa-chart-area' onClick={()=>{ navigate("/agency/status?agencyId="+item.agencyId) }}></i>}
                        </td>
                      </tr>
                    }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left" onClick={()=>setPage(page < 10 ? 0 : page-10)}></i>
                    </a>
                  </li>
                  {
                    (function (){
                      let r = Array(Math.ceil( tot / pageNum )).fill(0).map((item, index)=>{
                        return index;
                      })

                      if(page <= 5){
                        r = r.slice(0, 10)
                      }else{
                        r = r.slice(page - 5, page + 5)
                      }

                      return r.map((index) => {
                        return <li className="page-item" key={index}>
                          <a style={{backgroundColor:page==index?"#ddd":"#fff"}}
                             className="page-link"
                             href="#user-table"
                             onClick={()=>setPage(index)}>
                            {index + 1}
                          </a>
                        </li>
                      })
                    })()
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right" onClick={()=>setPage(page+10)}></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default GameRecord;
