import {ContentHeader} from "@components";
import React, {useEffect, useState} from "react";
import AgencyProfit from "@pages/SalesStatus/AgencyProfit";
import EggDistribution from "@pages/SalesStatus/EggDistribution";
import Game from "@pages/SalesStatus/Game";
import UserProfitByPeriod from "@pages/SalesStatus/UserProfitByPeriod";
import UserProfitByUser from "@pages/SalesStatus/UserProfitByUser";
import { useSelector } from "react-redux";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@app/store/hooks";
import useQueryParams from "@app/utils/useQueryParms";
import AgencyEggProfit from '@app/pages/AgencyEggProfit';
import {useTranslation} from "react-i18next";

const SalesStatus = () => {
  const {t} = useTranslation()
  const [salesCategory, setSalesCategory] = useState<
    'agencyProfit' | 'eggDistribution' | 'game' | 'userProfitByPeriod' | 'userProfitByUser' | 'rakeProfit'
  >('agencyProfit');

  const {token} = useAppSelector(state => state.auth);
  const params = useQueryParams();
  const user = useSelector((state: any) => state.auth.currentUser);
  const [agencyInfo, setAgencyInfo] = useState<{
    agencyId: number,
    uid:string,
  }>();

  const agencyId = params.get("agencyId");
  console.log("agencyId", agencyId)
  useEffect(function () {
    if(agencyId){
      console.log("=======================")
      AdminAPI.getAgencyInfo(token, Number(agencyId)).then((v)=>{
        console.log("=======================",v)
        setAgencyInfo({
          agencyId: v.agency.agencyId,
          uid: v.agency.uid,
        })
      });
    }else{
      setAgencyInfo({
        agencyId: user.agencyId,
        uid: user.uid,
      })
    }
  },[agencyId])

  return <div>
    <ContentHeader title={t("매출 현황 (통계)")}/>
    {agencyInfo ? <>
    <section className="content">
      <div className="container-fluid">

        <div className="row btn-group-toggle mb-2" data-toggle="buttons" style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '0 7.5px'
        }}>
          <label style={{flex: 1}} className={"btn bg-olive " + (salesCategory === 'agencyProfit' ? 'active' : '')}
                 onClick={() => setSalesCategory('agencyProfit')}>
            <input type="radio" name="options" id="option_b2" autoComplete="off"
                   checked={salesCategory === 'agencyProfit'}/> {t("에이전시 수익 통계")}
          </label>
          <label style={{flex: 1}} className={"btn bg-olive " + (salesCategory === 'eggDistribution' ? 'active' : '')}
                 onClick={() => setSalesCategory('eggDistribution')}>
            <input type="radio" name="options" id="option_b2" autoComplete="off"
                   checked={salesCategory === 'eggDistribution'}/> {t("홀덤 포인트 통계")}
          </label>
          {/* <label style={{flex: 1}} className={"btn bg-olive " + (salesCategory === 'game' ? 'active' : '')}
                 onClick={() => setSalesCategory('game')}>
            <input type="radio" name="options" id="option_b2" autoComplete="off"
                   checked={salesCategory === 'game'}/> 게임별 통계
          </label>
          <label style={{flex: 1}}
                 className={"btn bg-olive " + (salesCategory === 'userProfitByPeriod' ? 'active' : '')}
                 onClick={() => setSalesCategory('userProfitByPeriod')}>
            <input type="radio" name="options" id="option_b2" autoComplete="off"
                   checked={salesCategory === 'userProfitByPeriod'}/> 기간 별 유저 수익 통계
          </label> */}
          <label style={{flex: 1}} className={"btn bg-olive " + (salesCategory === 'userProfitByUser' ? 'active' : '')}
                 onClick={() => setSalesCategory('userProfitByUser')}>
            <input type="radio" name="options" id="option_b2" autoComplete="off"
                   checked={salesCategory === 'userProfitByUser'}/> {t("유저 수익 통계")}
          </label>

          <label style={{flex: 1}} className={"btn bg-olive " + (salesCategory === 'rakeProfit' ? 'active' : '')}
                 onClick={() => setSalesCategory('rakeProfit')}>
            <input type="radio" name="options" id="option_b2" autoComplete="off"
                   checked={salesCategory === 'rakeProfit'}/> {t("요율 수익 통계")}
          </label>
        </div>
        {
          salesCategory === 'agencyProfit' && <AgencyProfit user={agencyInfo}/>
        }
        {
          salesCategory === 'eggDistribution' && <EggDistribution user={agencyInfo}/>
        }
        {/* {
          salesCategory === 'game' && <Game/>
        }
        {
          salesCategory === 'userProfitByPeriod' && <UserProfitByPeriod/>
        } */}
        {
          salesCategory === 'userProfitByUser' && <UserProfitByUser user={agencyInfo}/>
        }
        {
          salesCategory === 'rakeProfit' && <AgencyEggProfit user={agencyInfo} />
        }


      </div>
    </section>
    </>:<>Loading...</>}
  </div>
}

export default SalesStatus;
