import PopupContainer from "@app/components/popup/PopupContainer";
import {useTranslation} from "react-i18next";
import moment from "moment";
interface AlertFewEggPopupProps {
  onClose: () => void;
  amount?: number;
}

const AlertFewEggPopup = ({onClose, amount}: AlertFewEggPopupProps) => {
  const {t} = useTranslation();

  const onClickNotToday = () => {
    onClose();
    localStorage.setItem("FEW_EGG_ALERT_TODAY", moment().format("YYYY-MM-DD"));
  }

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("알림")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        <div className="modal-body">
          <div className="form-group row">
            <label className="col-sm-10 col-form-label">{t("홀덤포인트 잔액이 {{amount}}포인트 미만입니다.", {amount: amount})}</label>
          </div>
          <div className="float-right">
            <button onClick={onClickNotToday} className="btn btn-sm btn-secondary">{t("오늘하루 보지 않기")}</button>
          </div>
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default AlertFewEggPopup;
