import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import { CurrencyMark } from "@app/conversionChip";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";

const CallbackTd = styled.td`
  >div{
    display: inline-block;
    /* width: 20px;
    height: 20px; */
    text-align: center;
    line-height: 20px;
    margin: 0 2px;
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-weight: bold;
    font-size: 12px;
  }
  .success{
    color: white;
    background-color: green;
  }
  .failed{
    background-color: red;
    color: black;
  }
  .timeout{
    background-color: yellow;
    color: black;
  }
`

const BackColorTr = styled.tr<{backColor:string}>`
  background-color: ${p=>p.backColor};
`

const GrayTh = styled.th`
  background-color: #eee !important;
`

const WhiteGrayTh = styled.th`
  background-color: #ddd !important;
`

const GrayTd = styled.td`
  background-color: #eee;
`

const WhiteGrayTd = styled.td`
  background-color: #ddd;
`

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const UserAdjustMoney = () => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [totUser, setTotUser] = useState(0);
  const [users, setUsers] = useState<{
    logId:number,
    userId:number,
    groupId:number,
    roomId:number,
    curMoney:number,
    buyin:number,
    seatOutPenalty:number,
    hands:number,
    seatOutMoney:number,
    endedMoney:number,
    buyinAt:string,
    leaveAt:string,
    buyinCallbackUid:string,
    leaveCallbackUid:string,
    buyinFailed:number,
    leaveFailed:number,
    nickname:string,
    agencyName:string,
    groupType:number,
    agencyId:number,
    blind:number[],
    buyinTTL:number,
    leaveTTL:number,
    currency: string,

    subs:{
      currency: string,
      curMoney:number,
      buyin:number,
      seatOutPenalty:number,
      hands:number,
      seatOutMoney:number,
      endedMoney:number,
      buyinAt:string,
      leaveAt:string,
      buyinCallbackUid:string,
      leaveCallbackUid:string,
      buyinFailed:number,
      leaveFailed:number,
      buyinTTL:number,
      leaveTTL:number,
    }[]
  }[]>();

  const [inputAgencyName, setInputAgencyName ] = useState("")
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")
  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))
  const [inputGameType, setInputGameType ] = useState(0)

  const [searchAgencyName, setSearchAgencyName ] = useState("")
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))
  const [searchGameType, setSearchGameType ] = useState(inputGameType)

  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const OnLoad = useCallback(function(){
    setUsers(undefined);
    AdminAPI.userAdjustMoneyList(token, page*pageNum, pageNum, searchAgencyName, searchUserNickname, searchUserId, searchGameType, searchStartDate, searchEndDate ).then(v=>{
      setUsers(v.rows)
      setTotUser(v.totalCnt)
    })
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    searchGameType,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchAgencyName,
    searchUserNickname,
    searchUserId,
    searchGameType,
    searchStartDate,
    searchEndDate,
    pageNum,
    page,
  ])

  const onClickSearch = useCallback(function(){
    setSearchAgencyName(inputAgencyName);
    setSearchUserId(inputUserId);
    setSearchUserNickname(inputUserNickname);
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
    setSearchGameType(inputGameType);
  },[
    inputAgencyName,
    inputUserId,
    inputUserNickname,
    inputStartDate,
    inputEndDate,
    inputGameType
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputAgencyName("");
    setInputUserId("");
    setInputUserNickname("");
    setInputGameType(0);
    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));

    setSearchAgencyName("");
    setSearchUserId("");
    setSearchUserNickname("");
    setSearchStartDate("");
    setSearchEndDate("");
    setSearchGameType(0);
  },[])

  const TR_COLOR = ["#eee", "#fff"];

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}

    <ContentHeader title={t("바이인/아웃 통신 로그")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
            <div className="card-header">
                <div className='row mb-2'>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("소속 에이전트")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>{setInputAgencyName(v.target.value)}} value={inputAgencyName}/>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserNickname(v.target.value)} value={inputUserNickname}/>
                  </div>
                  <div className="input-group col-4">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" onChange={(v)=>setInputUserId(v.target.value)} value={inputUserId}/>
                  </div>
                </div>
                <div className='row'>

                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("블라인드")}</span>
                    </div>
                    <select className="form-control" onChange={(e)=>setInputGameType(parseInt(e.target.value))} value={inputGameType}>
                      <option value="0">{t("전체")}</option>
                      <option value="1">1,000-2,000</option>
                      <option value="2">2,500-5,000</option>
                      <option value="3">5,000-10,000</option>
                      <option value="4">10,000-25,000</option>
                      <option value="5">25,000-50,000</option>
                      <option value="6">50,000-100,000</option>
                    </select>
                  </div>

                  <div className="input-group col-6">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("기간")}</span>
                    </div>
                    <input type="datetime-local" className="form-control"
                          value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputStartDate(new Date(e.target.value));
                           }}
                    />
                    <input type="datetime-local" className="form-control"
                          value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                          onChange={(e) => {
                            setInputEndDate(new Date(e.target.value));
                          }}
                    />
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("어제")}</button>
                    </div>
                    <div className="input-group-append">
                      <button type="button" className="btn btn-info" onClick={()=>{
                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                        setInputStartDate(new Date(r1));
                        setInputEndDate(new Date(r2));
                      }}>{t("오늘")}</button>
                    </div>
                  </div>

                  <div className='col-2'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th>{t("방정보")}</th>
                    <th>{t("닉네임/에이전시")}</th>

                    <GrayTh>{t("바이인 금액")}</GrayTh>
                    <GrayTh>{t("시각")}</GrayTh>
                    <GrayTh>TTL</GrayTh>
                    <GrayTh>CALLBACK</GrayTh>

                    <WhiteGrayTh>{t("바이아웃 금액")}</WhiteGrayTh>
                    <WhiteGrayTh>{t("시각")}</WhiteGrayTh>
                    <WhiteGrayTh>TTL</WhiteGrayTh>
                    <WhiteGrayTh>CALLBACK</WhiteGrayTh>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    users ? users.map((item, _index) => {
                      return <>
                      <tr style={{borderTop:"2px dashed #aaa"}}>
                        <td style={{textAlign:"center"}} rowSpan={1+item.subs.length}>
                          {(item.groupType === 0 ? <>
                            <span className="badge bg-gradient-lightblue">{t("토너먼트")}</span><br/>
                          </> : <>
                            <span className="badge bg-gradient-lime">{t("링")}</span><br/>{(item.blind||[]).join("-")}
                          </>)}
                        </td>
                        <td rowSpan={1+item.subs.length}>
                          <span onClick={(e)=>{
                            e.preventDefault();
                            setUserDetailPopup(item.userId);
                          }}>{item.nickname}</span>

                          #<a href="#" onClick={(e)=>{
                            e.preventDefault();
                            setAgencyDetailPopup(item.agencyId);
                          }}>{item.agencyName}</a>
                        </td>

                        <GrayTd>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(Number(item.buyin))}/></GrayTd>
                        <GrayTd>{moment.utc(item.buyinAt).local().format(DATE_FORMAT)}</GrayTd>
                        <GrayTd>{Number(item.buyinTTL).toLocaleString()}ms</GrayTd>
                        <CallbackTd>
                          {item.buyinFailed.toString() != ""  ? (item.buyinFailed == 0 ? <div className='success'>SUCCESS</div> : (item.buyinFailed == 1 ? <div className='failed'>FAILED</div> : <div className='timeout'>TIMEOUT</div> )) : <></>}
                        </CallbackTd>

                        {item.leaveFailed == null ? <>
                          <WhiteGrayTd></WhiteGrayTd>
                          <WhiteGrayTd></WhiteGrayTd>
                          <WhiteGrayTd></WhiteGrayTd>
                          <CallbackTd></CallbackTd>
                        </> : <>
                        <WhiteGrayTd>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(Number(item.seatOutMoney))}/></WhiteGrayTd>
                        <WhiteGrayTd>{moment.utc(item.leaveAt).local().format(DATE_FORMAT)}</WhiteGrayTd>
                        <WhiteGrayTd>{Number(item.leaveTTL).toLocaleString()}ms</WhiteGrayTd>
                        <CallbackTd>
                          {item.leaveFailed.toString() != ""  ? (item.leaveFailed == 0 ? <div className='success'>SUCCESS</div> : (item.leaveFailed == 1 ? <div className='failed'>FAILED</div> : <div className='timeout'>TIMEOUT</div> )) : <></>}
                        </CallbackTd>
                        </>}
                      </tr>
                      {
                        item.subs.map((sub, index) => {
                          return <tr>
                            <GrayTd>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(Number(sub.buyin))}/></GrayTd>
                            <GrayTd>{moment.utc(sub.buyinAt).local().format(DATE_FORMAT)}</GrayTd>
                            <GrayTd>{Number(sub.buyinTTL).toLocaleString()}ms</GrayTd>
                            <CallbackTd>
                              {sub.buyinFailed.toString() != ""  ? (sub.buyinFailed == 0 ? <div className='success'>SUCCESS</div> : (sub.buyinFailed == 1 ? <div className='failed'>FAILED</div> : <div className='timeout'>TIMEOUT</div> )) : <></>}
                            </CallbackTd>

                            {sub.leaveFailed == null ? <>
                              <WhiteGrayTd></WhiteGrayTd>
                              <WhiteGrayTd></WhiteGrayTd>
                              <WhiteGrayTd></WhiteGrayTd>
                              <CallbackTd></CallbackTd>
                            </> : <>
                            <WhiteGrayTd>{CurrencyMark(item.currency)}<DigitsColorWrapper number={Math.abs(Number(sub.seatOutMoney))}/></WhiteGrayTd>
                            <WhiteGrayTd>{moment.utc(sub.leaveAt).local().format(DATE_FORMAT)}</WhiteGrayTd>
                            <WhiteGrayTd>{Number(sub.leaveTTL).toLocaleString()}ms</WhiteGrayTd>
                            <CallbackTd>
                              {sub.leaveFailed.toString() != ""  ? (sub.leaveFailed == 0 ? <div className='success'>SUCCESS</div> : (sub.leaveFailed == 1 ? <div className='failed'>FAILED</div> : <div className='timeout'>TIMEOUT</div> )) : <></>}
                            </CallbackTd>
                          </>}
                          </tr>
                        })
                      }
                      </>
                      }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  {users ? users.length === 0 ? <tr><td colSpan={9}>{t("검색 결과가 없습니다")}.</td></tr> : <></> : <></>}
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                  </li>
                  {
                    Array(Math.ceil( totUser / pageNum )).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <a style={{backgroundColor:page==index?"#ddd":"#fff"}} className="page-link" href="#user-table" onClick={()=>setPage(index)}>{index + 1}</a>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>
}

export default UserAdjustMoney;
