import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import { useAppSelector, useAppDispatch } from "@store/hooks";
import { useTranslation } from "react-i18next";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import styled from 'styled-components';
import { CurrencyMark } from "@app/conversionChip";
import { Modal, Button, Form } from 'react-bootstrap';
import StandaloneBankInfoPopup from "@app/components/popup/StandaloneBankInfoPopup";
import { loadUser } from "@app/store/reducers/auth";
import AlertFewEggPopup from "@app/components/popup/AlertFewEggPopup";
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";

const StatusBadge = styled.span<{ status: string }>`
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  background-color: ${props => {
        switch (props.status) {
            case 'PENDING': return '#ffc107';
            case 'APPROVED': return '#28a745';
            case 'REJECTED': return '#dc3545';
            case 'CANCELLED': return '#6c757d';
            default: return '#6c757d';
        }
    }};
  color: white;
`;

const ProcessModal = ({ show, onHide, request, onProcess }: { show: boolean, onHide: () => void, request: any, onProcess: (requestId: number, status: string, memo: string) => void }) => {
    const { t } = useTranslation();
    const [memo, setMemo] = useState('');
    const [status, setStatus] = useState('');

    const handleHide = () => {
        setMemo('');
        setStatus('');
        onHide();
    }

    const handleSubmit = () => {
        if (!status) {
            alert(t('처리 상태를 선택해주세요.'));
            return;
        }
        if (!memo) {
            alert(t('메모를 입력해주세요.'));
            return;
        }
        setMemo('');
        setStatus('');
        onProcess(request.requestId, status, memo);
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('요청 처리')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <div className="col-6">
                        <h5>{t('유저 정보')}</h5>
                        <p>{t('닉네임')}: {request?.userNickname}</p>
                        <p>{t('에이전시')}: {request?.agencyName}</p>
                    </div>
                    <div className="col-6">
                        <h5>{t('요청 정보')}</h5>
                        <p>{t('요청 타입')}: {request?.type === 'CHARGE' ? t('충전') : t('환전')}</p>
                        <p>{t('금액')}: {CurrencyMark(request?.currency)}{Number(request?.fiatAmount).toLocaleString()} ({Number(request?.chipAmount).toLocaleString()}P)</p>
                        <p>{t('요청일시')}: {moment(request?.requestDate).format('YYYY-MM-DD HH:mm:ss')}</p>
                    </div>
                </div>

                {request?.method === "OFFLINE" ? <></> : <>
                    <div className="row mb-3">
                        <div className="col-12">

                            <h5>{t('계좌 정보')}</h5>
                            {request?.type === 'CHARGE' ? (
                                <p>{t('입금자명')}: {request?.accountHolder}</p>
                            ) : (
                                <>
                                    <p>{t('계좌주')}: {request?.accountHolder}</p>
                                    <p>{t('입금계좌')}: {request?.account}</p>
                                </>
                            )}
                        </div>
                    </div>
                </>}

                <Form.Group className="mb-3">
                    <Form.Label>{t('처리 상태')}</Form.Label>
                    <select value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">{t('선택')}</option>
                        <option value="APPROVED">{t('승인')}</option>
                        <option value="REJECTED">{t('거절')}</option>
                        {/* <option value="CANCELLED">{t('지급 철회')}</option> */}
                    </select>
                </Form.Group>
                <Form.Group>
                    <Form.Label>{t('메모')}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={3}
                        value={memo}
                        onChange={(e) => setMemo(e.target.value)}
                        placeholder={t('메모를 입력해주세요.')}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleHide}>{t('취소')}</Button>
                <Button variant="primary" onClick={handleSubmit}>{t('처리')}</Button>
            </Modal.Footer>
        </Modal>
    );
};

const MemoModal = ({ show, onHide, memo }: { show: boolean, onHide: () => void, memo: string }) => {
    const { t } = useTranslation();
    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('메모')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{memo}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>{t('Close')}</Button>
            </Modal.Footer>
        </Modal>
    );
}

const StandaloneExchange = () => {
    const { t } = useTranslation();
    const { token, currentUser } = useAppSelector(state => state.auth);
    const dispatch = useAppDispatch();
    const [requests, setRequests] = useState<any[]>([]);
    const [totData, setTotData] = useState(0);
    const [showProcessModal, setShowProcessModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [showBankInfoModal, setShowBankInfoModal] = useState(false);
    const [showMemoModal, setShowMemoModal] = useState<string|null>(null);
    // 검색 조건 state
    const [inputStartDate, setInputStartDate] = useState(new Date((Date.now() - 60 * 60 * 24 * 1000)));
    const [inputEndDate, setInputEndDate] = useState(new Date());
    const [inputStatus, setInputStatus] = useState("");
    const [inputAgencyName, setInputAgencyName] = useState("");
    const [inputUserNickname, setInputUserNickname] = useState("");
    const [inputType, setInputType] = useState("");
    // 실제 검색에 사용되는 state
    const [searchStartDate, setSearchStartDate] = useState(moment(inputStartDate).format(DATE_FORMAT));
    const [searchEndDate, setSearchEndDate] = useState(moment(inputEndDate).format(DATE_FORMAT));
    const [searchStatus, setSearchStatus] = useState("");
    const [searchAgencyName, setSearchAgencyName] = useState("");
    const [searchUserNickname, setSearchUserNickname] = useState("");
    const [searchType, setSearchType] = useState("");
    const [sums, setSums] = useState<{[currency:string]:{[type:string]:{fiatAmount:number, chipAmount:number}}}>({});
    const [showAlertFewEggPopup, setShowAlertFewEggPopup] = useState(false);
    const [page, setPage] = useState(0);
    const [pageNum, setPageNum] = useState(50);

    const fetchData = useCallback(async () => {
        const res = await AdminAPI.getStandaloneRequests(token, searchStartDate, searchEndDate, searchStatus, searchAgencyName, searchUserNickname, searchType, page, pageNum);
        if (res.success) {
            setRequests(res.rows);
            setTotData(res.totalCount);
            setSums(res.sums);
        }
    }, [token, searchStartDate, searchEndDate, searchStatus, searchAgencyName, searchUserNickname, searchType, page, pageNum]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleProcess = async (requestId: number, status: string, memo: string) => {
        const res = await AdminAPI.processStandaloneRequest(token, requestId, status, memo);
        fetchData();
        if (res.success) {
            alert(t('처리가 완료되었습니다.'));
        } else {
            alert(t('처리에 실패하였습니다.') + "\n[" + res.message + "]");
            setShowAlertFewEggPopup(true);
        }
    }

    const onClickSearch = useCallback(() => {
        setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
        setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
        setSearchStatus(inputStatus);
        setSearchAgencyName(inputAgencyName);
        setSearchUserNickname(inputUserNickname);
        setSearchType(inputType);
        setPage(0);
    }, [inputStartDate, inputEndDate, inputStatus, inputAgencyName, inputUserNickname, inputType]);

    const onClickCancelSearch = useCallback(() => {
        setInputStartDate(new Date((Date.now() - 60 * 60 * 24 * 1000)));
        setInputEndDate(new Date());
        setInputStatus("");
        setInputAgencyName("");
        setInputUserNickname("");
        setInputType("");
        setSearchStartDate(moment(new Date((Date.now() - 60 * 60 * 24 * 1000))).format(DATE_FORMAT));
        setSearchEndDate(moment(new Date()).format(DATE_FORMAT));
        setSearchStatus("");
        setSearchAgencyName("");
        setSearchUserNickname("");
        setSearchType("");
        setPage(0);
    }, []);

    const openProcessModal = useCallback((request: any) => {
        setSelectedRequest(request);
        setShowProcessModal(true);
    }, []);

    const onClickCancelPayment = useCallback(async (request: any, userMoney: number, fiatAmount: number) => {
        if (!window.confirm(t('지급 철회하시겠습니까? 해당 처리는 번복될 수 없습니다.'))) {
            return;
        }

        if( userMoney < fiatAmount ){
            if(!window.confirm(t("해당 유저의 보유금이 철회금보다 작은 상황으로 철회를 지속하면 해당 유저의 보유금이 마이너스가 됩니다. 그래도 정말 진행하시겠습니까?")))
                return;
        }

        const res = await AdminAPI.cancelStandalonePayment(token, request.requestId);
        fetchData();
        if (res.success) {
            alert(t('지급이 철회되었습니다.'));
        } else {
            alert(t('지급 철회에 실패하였습니다.') + "\n[" + res.message + "]");
        }

    }, [token]);

    const onClickBankInfo = useCallback(() => {
        setShowBankInfoModal(true);
    }, []);

    const onClickSaveBankInfo = useCallback(async (bank: string, bankAccount: string, bankAccountHolder: string, usdtAddress: string, usdtNetwork: string) => {
        const res = await AdminAPI.editStandaloneBankInfo(token, bank, bankAccount, bankAccountHolder, usdtAddress, usdtNetwork);
        if (res.success) {
            alert(t('계좌 정보가 수정되었습니다.'));
            const response = await AdminAPI.getInfo({ session: token });
            dispatch(loadUser(response.payload));
        }
    }, [token, dispatch]);

    return (
        <div>
            {showAlertFewEggPopup && <AlertFewEggPopup onClose={() => { setShowAlertFewEggPopup(false); }} amount={currentUser.egg + 1}/> }
            {showMemoModal && <MemoModal show={!!showMemoModal} onHide={() => setShowMemoModal(null)} memo={showMemoModal} />}
            {showBankInfoModal && <StandaloneBankInfoPopup show={showBankInfoModal} onHide={() => setShowBankInfoModal(false)} bankInfo={currentUser.standaloneBankInfo} onClickSave={onClickSaveBankInfo} />}
            <ContentHeader title={t("충전/환전 요청 관리")} />
            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-2">
                                    <div className="form-group">
                                        <label>{t("시작일")}</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={moment(inputStartDate).format("YYYY-MM-DD")}
                                            onChange={(e) => setInputStartDate(new Date(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label>{t("종료일")}</label>
                                        <input
                                            type="date"
                                            className="form-control"
                                            value={moment(inputEndDate).format("YYYY-MM-DD")}
                                            onChange={(e) => setInputEndDate(new Date(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label>{t("처리상태")}</label>
                                        <select
                                            className="form-control"
                                            value={inputStatus}
                                            onChange={(e) => setInputStatus(e.target.value)}
                                        >
                                            <option value="">{t("전체")}</option>
                                            <option value="PENDING">{t("대기")}</option>
                                            <option value="APPROVED">{t("승인")}</option>
                                            <option value="REJECTED">{t("거절")}</option>
                                            <option value="CANCELLED">{t("지급 철회")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label>{t("요청타입")}</label>
                                        <select
                                            className="form-control"
                                            value={inputType}
                                            onChange={(e) => setInputType(e.target.value)}
                                        >
                                            <option value="">{t("전체")}</option>
                                            <option value="CHARGE">{t("충전")}</option>
                                            <option value="EXCHANGE">{t("환전")}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-2">
                                    <div className="form-group">
                                        <label>{t("유저 닉네임")}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            value={inputUserNickname}
                                            onChange={(e) => setInputUserNickname(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-2 d-flex justify-content-start">
                                    <button
                                        className="btn btn-primary float-right ml-2"
                                        onClick={onClickBankInfo}
                                    >
                                        {t("계좌 정보 수정")}
                                    </button>
                                </div>
                                <div className="col-10">
                                    <button
                                        className="btn btn-primary float-right ml-2"
                                        onClick={onClickSearch}
                                    >
                                        {t("검색")}
                                    </button>
                                    <button
                                        className="btn btn-secondary float-right"
                                        onClick={onClickCancelSearch}
                                    >
                                        {t("초기화")}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>{t("요청ID")}</th>
                                        <th>{t("유저")}</th>
                                        <th>{t("에이전시")}</th>
                                        <th>{t("요청타입")}</th>
                                        <th>{t("금액")}</th>
                                        <th>{t("결제방식")}</th>
                                        <th>{t("계좌정보")}</th>
                                        <th>{t("상태")}</th>
                                        <th>{t("요청일시")}</th>
                                        <th>{t("처리일시")}</th>
                                        <th>{t("메모")}</th>
                                        <th>{t("관리")}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests.length > 0 && (
                                        <>
                                            <tr>
                                                <td colSpan={4} style={{textAlign:"right"}}>{t("총합")}</td>
                                                <td colSpan={8}>
                                                    {Object.keys(sums).map((currency) => (
                                                        Object.keys(sums[currency]).map((type) => (
                                                            <div key={currency}>
                                                                [{type === "CHARGE" ? t("충전") : t("환전")}]{" : "}
                                                                {CurrencyMark(currency)}
                                                                {Number(sums[currency][type].fiatAmount).toLocaleString()}
                                                                <span style={{marginLeft:"2px", fontSize:"12px"}}>({Number(sums[currency][type].chipAmount).toLocaleString()}P)</span>
                                                            </div>
                                                        ))
                                                    ))}
                                                </td>
                                            </tr>
                                        </>
                                    )}
                                    {requests.length === 0 && (
                                        <tr>
                                            <td colSpan={12} className="text-center">{t("검색 결과가 없습니다")}</td>
                                        </tr>
                                    )}
                                    {requests.map((request) => (
                                        <tr key={request.requestId}>
                                            <td>{request.requestId}</td>
                                            <td>{request.userNickname}</td>
                                            <td>{request.agencyName}</td>
                                            <td>{request.type === 'CHARGE' ? t('충전') : t('환전')}</td>
                                            <td>
                                                {CurrencyMark(request.currency)}{Number(request.fiatAmount).toLocaleString()}
                                                ({Number(request.chipAmount).toLocaleString()}P)
                                            </td>
                                            <td>{request.method}</td>
                                            <td>
                                                {request.type == "CHARGE" ? <>
                                                    {t("입금자명")} : {request.accountHolder}<br />
                                                </> : <>
                                                    {t("계좌주")} : {request.accountHolder}<br />
                                                    {t("입금계좌")} : {request.account}
                                                </>}
                                            </td>
                                            <td>
                                                <StatusBadge status={request.status}>
                                                    {request.status === 'PENDING' && t('대기')}
                                                    {request.status === 'APPROVED' && t('승인')}
                                                    {request.status === 'REJECTED' && t('거절')}
                                                    {request.status === 'CANCELLED' && t('지급 철회')}
                                                </StatusBadge>
                                            </td>
                                            <td>{moment(request.requestDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>{request.processDate && moment(request.processDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                                            <td>
                                                {request.memo && request.memo.length > 20 ? <div style={{cursor: "pointer", textDecoration: "underline"}} onClick={() => setShowMemoModal(request.memo)}>{request.memo.slice(0, 20)}...</div> : request.memo}
                                            </td>
                                            <td>
                                                {request.status === 'PENDING' && (
                                                    <button
                                                        className="btn btn-sm btn-primary"
                                                        onClick={() => openProcessModal(request)}
                                                    >
                                                        {t("처리")}
                                                    </button>
                                                )}
                                            </td>
                                            <td>
                                                {(request.status === 'APPROVED' && request.type === 'CHARGE') && (
                                                    <button className="btn btn-sm btn-danger" onClick={() => onClickCancelPayment(request, request.userMoney, request.fiatAmount)}>
                                                        {t("지급 철회")}
                                                        <div style={{fontSize:"12px"}}>({t("유저 보유금")} : {Number(request.userMoney).toLocaleString()})</div>
                                                        <div style={{fontSize:"12px"}}>({t("철회금")} : {Number(request.fiatAmount).toLocaleString()})</div>
                                                    </button>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="card-footer clearfix">
                            <ul className="pagination pagination-sm m-0 float-left">
                                <li className="page-item">
                                    <div className="page-link">
                                        {t("표시")}
                                    </div>
                                </li>
                                {
                                    [50, 100, 200].map((item, index) => {
                                        return <li key={index} className="page-item">
                                            <div className="page-link" style={
                                                item === pageNum ? {
                                                    backgroundColor: 'lightgrey',
                                                    color: 'black'
                                                } : {}
                                            } onClick={() => setPageNum(item)}>{item}</div>
                                        </li>
                                    })
                                }
                            </ul>
                            <ul className="pagination pagination-sm m-0 float-right">
                                <li className="page-item">
                                    <a className="page-link" href="#">
                                        <i className="fas fa-chevron-left"></i>
                                    </a>
                                </li>
                                {
                                    Array(Math.ceil(totData / pageNum)).fill(0).map((item, index) => {
                                        return <li className="page-item" key={index}>
                                            <a style={{ backgroundColor: page == index ? "#ddd" : "#fff" }} className="page-link" href="#user-table" onClick={() => setPage(index)}>{index + 1}</a>
                                        </li>
                                    })
                                }
                                <li className="page-item">
                                    <a className="page-link" href="#">
                                        <i className="fas fa-chevron-right"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <ProcessModal
                show={showProcessModal}
                onHide={() => {
                    setShowProcessModal(false);
                    setSelectedRequest(null);
                }}
                request={selectedRequest}
                onProcess={handleProcess}
            />
        </div>
    );
}

export default StandaloneExchange;