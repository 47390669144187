import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import { RakePercentage } from "@app/dataset";
import {  } from "@app/components/popup/CreateAgencyPopup";
import {useTranslation} from "react-i18next";
import { PasswordModalPopup } from "./UserList";

let BanMemo = styled.div`
  font-size:12px;
  text-align: center;
`

const UserListForAgency = () => {
  const {t} = useTranslation();
  const {token, currentUser} = useAppSelector(state => state.auth);
  const [inputUserNickname, setInputUserNickname ] = useState("")
  const [inputUserId, setInputUserId ] = useState("")
  const [pageNum,setPageNum] = useState(50);
  const [page,setPage] = useState(0);
  const [totUser, setTotUser] = useState(0);
  const [totBanUser, setTotBanUser] = useState(0);
  const [totAllUser, setTotAllUser] = useState(0);
  const [topMenu, setTopMenu] = useState(0);
  const [users, setUsers] = useState<{
    userId:number,
    agencyId:number,
    uid:string,
    nickname:string,
    money:number,
    banAt:string,
    lastAccess:string,
    totalReport:number,
    banMemo:string,
    banAdminId:string,
    banAdminUid:string,
    agentCode:string
    rakebackAmount:number,
    email:string,
    telegram:string,
  }[]>();
  const [orderBy, setOrderBy] = useState<"rakebackAmount"|"money"|null>(null);
  const [orderType, setOrderType] = useState<"asc"|"desc">("desc");

  const [showPasswordPopup, setShowPasswordPopup] = useState<{userId:number, uid:string, nickname:string}|null>(null);
  const [searchUserNickname, setSearchUserNickname ] = useState("")
  const [searchUserId, setSearchUserId ] = useState("")
  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const OnLoad = useCallback(function(){
    setUsers(undefined);
    AdminAPI.userListForAgency(token, page*pageNum, pageNum, searchUserNickname, searchUserId, topMenu == 1, orderBy, orderType ).then(v=>{
      setUsers(v.rows)
      setTotUser(v.totalCnt)
      setTotBanUser(v.banUserCnt)
      setTotAllUser(v.userCnt)
    })
  },[
    searchUserNickname,
    searchUserId,
    pageNum,
    page,
    topMenu,
    orderBy,
    orderType
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchUserNickname,
    searchUserId,
    pageNum,
    page,
    topMenu,
    orderBy,
    orderType
  ])

  const onClickSearch = useCallback(function(){
    setSearchUserId(inputUserId);
    setSearchUserNickname(inputUserNickname);
  },[
    inputUserId,
    inputUserNickname,
  ])

  const onClickCancelSearch = useCallback(function(){
    setInputUserId("");
    setInputUserNickname("");

    setSearchUserId("");
    setSearchUserNickname("");
  },[])

  const onClickReleaseBan = useCallback(async function(userId:number){
    if(window.confirm(`${t("정말로 접속을 제한을 해제하시겠습니까")}?`)){
      let r = await AdminAPI.userReleaseBanForAgency(token, userId)
      if(r.success == -1){
        alert(t("이미 운영팀에서 제한이 진행된 유저입니다."))
      }
      OnLoad()
    }
  },[OnLoad])
  const onClickBan = useCallback(async function(userId:number){
    if(window.confirm(`${t("정말로 접속을 제한하겠습니까")}?`)){
      let memo = prompt(`${t("사유를 작성해주세요")}.`) || ""
      let r = await AdminAPI.userBanForAgency(token, userId, memo)
      if(r.success == -1){
        alert(t("운영팀에서 접속 제한 된 유저는 풀 수 없습니다."))
      }
      OnLoad()
    }
  },[OnLoad])

  const onClickOrderBy = useCallback(function(_orderBy: "rakebackAmount"|"money"|null){
    if(orderBy === _orderBy){
      if(orderType === "asc"){
        setOrderBy(null);
        setOrderType("desc");
      }else{
        setOrderType("asc");
      }
    }else{
      setOrderBy(_orderBy);
      setOrderType("desc");
    }
  },[orderBy, orderType])

  const isEmailOrTelegram = useMemo(() => {
    return users?.some(user => user.email || user.telegram);
  }, [users])

  const onClickPasswordChange = useCallback(function(item: any){
    if(item != undefined){
      setShowPasswordPopup(item);
    }
  },[])

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}
    {showPasswordPopup !== null ? <PasswordModalPopup userId={showPasswordPopup!.userId} userInfo={showPasswordPopup!} onClose={()=>setShowPasswordPopup(null)} /> : <></>}

    <ContentHeader title={t("유저 목록")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div className="row btn-group-toggle mb-2" data-toggle="buttons" style={{
                  paddingLeft: 7.5,
                  paddingRight: 7.5
                }}>
                  <label className={"col-6 btn bg-olive " + (topMenu == 0 ? "active" :"")}>
                    <input type="radio" name="options" id="option_b1" autoComplete="off" checked={topMenu == 0} onClick={()=>setTopMenu(0)}/> {t("전체 유저")} ({totAllUser.toLocaleString()})
                  </label>
                  <label className={"col-6 btn bg-olive " + (topMenu == 1 ? "active" :"")}>
                    <input type="radio" name="options" id="option_b2" autoComplete="off" checked={topMenu == 1} onClick={()=>setTopMenu(1)}/> {t("접속 제한 유저")} ({totBanUser.toLocaleString()})
                  </label>
                </div>
                <div className='row mb-2'>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 닉네임")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputUserNickname} onChange={v=>setInputUserNickname(v.target.value)}/>
                  </div>
                  <div className="input-group col-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text">{t("유저 아이디")}</span>
                    </div>
                    <input type="text" className="form-control" value={inputUserId} onChange={v=>setInputUserId(v.target.value)}/>
                  </div>
                  <div className='offset-1 col-2'>
                    <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                    <button type="button" className="btn btn-primary float-right" onClick={onClickSearch}>{t("검색")}</button>
                  </div>
                </div>
              </div>

              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th>{t("유저")} ID</th>
                    <th>{t("유저 이름")}</th>
                    <th>{t("총판 코드")}</th>
                    <th>{t("마지막 접속 시간")}</th>
                    <th onClick={()=>onClickOrderBy("rakebackAmount")} style={{cursor: "pointer", backgroundColor: "#ddd"}}>
                      {t("레이크백 포인트")} 
                      <button type="button" className="btn btn-sm">{orderBy === "rakebackAmount" ? (orderType === "asc" ? "▲" : "▼") : "-"}</button>
                    </th>
                    {currentUser.standalone && <th onClick={()=>onClickOrderBy("money")} style={{cursor: "pointer", backgroundColor: "#ddd"}}>
                      {t("보유금")}
                      <button type="button" className="btn btn-sm">{orderBy === "money" ? (orderType === "asc" ? "▲" : "▼") : "-"}</button>
                    </th>}

                    {isEmailOrTelegram ? <>
                      <th>{t("이메일")}</th>
                      <th>{t("텔레그램")}</th>
                    </> : <></>}
                    
                    <th>{t("상태")}</th>
                    <th>{t("제한시각")}</th>
                    <th>{t("제한자")}</th>
                    <th></th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    users ? users.map((item, index) => {
                      const isRestricted = item.banAt != null
                      return <tr key={index}>
                        <td>@{item.uid}</td>
                        <td>{item.nickname} <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setUserDetailPopup(item.userId) }}/></td>
                        <td>{item.agentCode || "" }</td>
                        <td>{moment(item.lastAccess).local().format("YYYY-MM-DD HH:mm:ss") }</td>
                        <td>{Number(item.rakebackAmount || 0).toLocaleString()}</td>
                        {currentUser.standalone && <td>{Number(item.money || 0).toLocaleString()}</td>}

                        {isEmailOrTelegram ? <>
                          <td>{item.email}</td>
                          <td>{item.telegram}</td>
                        </> : <></>}

                        <td style={{textAlign:"center"}}>
                          {
                            isRestricted ? <>
                              <span className="badge bg-danger">{t("접속 제한")}</span>
                              <BanMemo>{item.banMemo}</BanMemo>
                            </>:
                              <span className="badge bg-success">{t("정상")}</span>
                          }
                        </td>
                        {isRestricted ? <>
                          <td>{moment(item.banAt).local().format("YYYY-MM-DD HH:mm:ss")}</td>
                          <td>{item.banAdminUid}</td>
                        </>:<>
                          <td>-</td>
                          <td>-</td>
                        </>}
                        <td>
                          {
                            isRestricted ?
                              <button type="button" className="btn btn-success btn-sm" onClick={()=>onClickReleaseBan(item.userId)}>{t("제한 취소하기")}</button> :
                              <button type="button" className="btn btn-danger btn-sm" onClick={()=>onClickBan(item.userId)}>{t("접속 제한하기")}</button>
                          }
                        </td>
                        <td>
                          <button type="button" className="btn btn-primary btn-sm" onClick={()=>{onClickPasswordChange(item)}}>{t("비밀번호 수정")}</button>
                        </td>
                      </tr>
                    }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [50, 100, 200].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                  </li>
                  {
                    Array(Math.ceil( totUser / pageNum )).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <a style={{backgroundColor:page==index?"#ddd":"#fff"}} className="page-link" href="#user-table" onClick={()=>setPage(index)}>{index + 1}</a>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default UserListForAgency;