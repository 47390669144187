import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import i18n from '../utils/i18n';
import { CurrencyMark } from "@app/conversionChip";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";

export enum RESULT_CODE{
  NONE = 0,
  SUCCESS = 1,
  FAILED = -1,

  GETBALANCE_TIMEOUT = -2,
  FAILED_USER_MONEY_NOT_ENOUGH = -3,
  FAILED_AGENCY_MONEY_NOT_ENOUGH = -4,
  FAILED_FIAT_AMOUNT_NOT_MATCH = -5,

  AGENCY_REJECT = 2,
  FAILED_BUT_REVERTED = 3,
}

const LoadingModal = styled.div`
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: none;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 30px;
`

function typeToString(type:number){
  switch(type){
    case 0:
      return "BUYIN";
    case 1:
      return "REBUYIN";
    case 2:
      return "CASH OUT";
    case 3:
      return "TOURNAMENT WIN";
    case 4:
      return "CANCEL BUYIN";
    case 5:
      return "CANCEL TOURNAMENT";
    default:
      return "UNKNOWN: "+type;
  }
}

function errorCodeToString(result:RESULT_CODE){
  switch(result){
    case RESULT_CODE.NONE:
      return i18n.t("실행 중");
    case RESULT_CODE.SUCCESS:
      return i18n.t("정상 성공");
    case RESULT_CODE.FAILED:
      return i18n.t("완전 실패");
    case RESULT_CODE.GETBALANCE_TIMEOUT:
      return i18n.t("GETBALANCE_TIMEOUT ");
    case RESULT_CODE.FAILED_USER_MONEY_NOT_ENOUGH:
      return i18n.t("사이트 내 유저머니 부족 실패");
    case RESULT_CODE.FAILED_AGENCY_MONEY_NOT_ENOUGH:
      return i18n.t("에이전시 알 보유금 부족");
    case RESULT_CODE.FAILED_FIAT_AMOUNT_NOT_MATCH:
      return i18n.t("요청 금액이 틀림");
    case RESULT_CODE.AGENCY_REJECT:
      return i18n.t("에이전시 서버 거절");
    case RESULT_CODE.FAILED_BUT_REVERTED:
      return i18n.t("통신 실패, 알 복구 완료");
    default:
      return "UNKNOWN: ";
  }
}

function errorCodeToDealWithDesc(moneyAmount:number, result:RESULT_CODE){
  if(moneyAmount > 0){ // leave , win
    switch(result){
      case RESULT_CODE.NONE:
        return i18n.t("진행 중")+"..";
      case RESULT_CODE.SUCCESS:
        return i18n.t("절차 완료");
      case RESULT_CODE.FAILED:
        return i18n.t("알 / 머니 지급 필요");
      case RESULT_CODE.GETBALANCE_TIMEOUT:
        return i18n.t("알 / 머니 지급 필요");
      case RESULT_CODE.FAILED_USER_MONEY_NOT_ENOUGH:
        return i18n.t("처리 불가");
      case RESULT_CODE.FAILED_AGENCY_MONEY_NOT_ENOUGH:
        return i18n.t("처리 불가");
      case RESULT_CODE.AGENCY_REJECT:
        return i18n.t("에이전시 서버에서 거절. 에이전시에게 수동으로 복구 요청 필요. 알/머니 모두 복구필요");
      case RESULT_CODE.FAILED_BUT_REVERTED:
        return i18n.t("[타임아웃] 머니 유실 가능성 있음. 에이전시의 데이터 확인 필요. 확인 후 수동으로 알/머니 모두");
      default:
        return "UNKNOWN: "+result;
    }
  }else{ // buyin / rebuyin, join tournament
    switch(result){
      case RESULT_CODE.NONE:
        return i18n.t("진행 중")+"..";
      case RESULT_CODE.SUCCESS:
        return i18n.t("처리 필요 없음");
      case RESULT_CODE.FAILED:
        return i18n.t("처리 필요 없음");
      case RESULT_CODE.GETBALANCE_TIMEOUT:
        return i18n.t("처리 필요 없음");
      case RESULT_CODE.FAILED_USER_MONEY_NOT_ENOUGH:
        return i18n.t("처리 필요 없음");
      case RESULT_CODE.FAILED_AGENCY_MONEY_NOT_ENOUGH:
        return i18n.t("처리 필요 없음");
      case RESULT_CODE.AGENCY_REJECT:
        return i18n.t("처리 필요 없음");
      case RESULT_CODE.FAILED_BUT_REVERTED:
        return i18n.t("[타임아웃] 머니 유실 가능성 있음. 에이전시의 데이터 확인 필요. 머니만 진행");
      default:
        return "UNKNOWN: "+result;
    }
  }
}


function retryable(review:number, moneyAmount:number, result:RESULT_CODE){
  if(review == 1) return false;

  if(moneyAmount > 0){ // leave , win
    switch(result){
      case RESULT_CODE.NONE:
        return false
      case RESULT_CODE.SUCCESS:
        return false
      case RESULT_CODE.FAILED:
        return true;
      case RESULT_CODE.GETBALANCE_TIMEOUT:
        return true;
      case RESULT_CODE.FAILED_USER_MONEY_NOT_ENOUGH:
        return false;
      case RESULT_CODE.FAILED_AGENCY_MONEY_NOT_ENOUGH:
        return false;
      case RESULT_CODE.AGENCY_REJECT:
        return true;
      case RESULT_CODE.FAILED_BUT_REVERTED:
        return false;
      case RESULT_CODE.FAILED_FIAT_AMOUNT_NOT_MATCH:
        return true;
    }
  }else{ // buyin / rebuyin, join tournament
    switch(result){
      case RESULT_CODE.NONE:
        return false;
      case RESULT_CODE.SUCCESS:
        return false;
      case RESULT_CODE.FAILED:
        return false;
      case RESULT_CODE.GETBALANCE_TIMEOUT:
        return false;
      case RESULT_CODE.FAILED_USER_MONEY_NOT_ENOUGH:
        return false;
      case RESULT_CODE.FAILED_AGENCY_MONEY_NOT_ENOUGH:
        return false;
      case RESULT_CODE.AGENCY_REJECT:
        return false;
      case RESULT_CODE.FAILED_BUT_REVERTED:
        return true;
      case RESULT_CODE.FAILED_FIAT_AMOUNT_NOT_MATCH:
        return false;
    }
  }

  return false;
}

const BuyinoutErrorLogs = () => {
  const {t} = useTranslation();
  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const {token} = useAppSelector(state => state.auth);
  const [list, setList] = useState<{
    addedAt:string,
    agencyName:string,
    cb_uid:string,
    egg:number,
    enterId:number,
    errorStr:string,
    finishedAt:string,
    logId:number,
    nickname:string,
    agencyId:number,
    type:number,
    review:number,
    currency:string,
    params:{
      extraData:any, // hands: 0, roomId: 1372, penalty: 0}
      groupId:number, // 1
      memo:string, // room leave"
      fiatAmount:number, // 200000
      chipAmount:number, // 0
      type:number, // 2
      uniqueKey:string, // 1372,191,7865"
      userId:number, // 7865
    },
    result:RESULT_CODE,
    retry:number,
    uid:string,
    userId:number,
    memo:string[]
  }[]>();

  const OnLoad = useCallback(function(){
    // setList(undefined);
    AdminAPI.buyinoutErrors(token).then(v=>{
      console.log(JSON.stringify(v))
      setList(v.rows)
    })
  },[
  ])

  useEffect(function(){
    OnLoad();
  },[])

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}
    {showLoading ? <LoadingModal>{t("처리중")}...</LoadingModal> : <></>}

    <ContentHeader title={t("통신 에러 내역")}/>
    <section className="content">
      <div className="container-fluid">

        <div className="row">
          <div className="col-12">
            <div className="card">

              <div className="card-body table-responsive p-0" >
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("통신 시간")}</th>
                    <th>{t("유저")}</th>
                    <th>{t("에이전시")}</th>
                    <th>{t("타입")}</th>
                    <th>{t("요청 금액")}</th>
                    <th>{t("에러 메세지")}</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    list ? list.map((item, index) => {
                      return <><tr key={index}>
                        <td>{item.enterId}</td>
                        <td>{moment(item.addedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td><a href="#" onClick={function(e){
                          e.preventDefault();
                          setUserDetailPopup(item.userId);
                        }}>{item.nickname}</a></td>
                        <td><a href="#" onClick={function(e){
                          e.preventDefault();
                          setAgencyDetailPopup(item.agencyId);
                        }}>{item.agencyName}</a></td>
                        <td>{typeToString(item.params.type)}</td>
                        <td>
                          {CurrencyMark(item.currency)}<DigitsColorWrapper number={Number(item.params.fiatAmount)}/><br/>
                          ({Number(item.params.chipAmount).toLocaleString()}HP)
                        </td>
                        <td>
                          { item.review != 1 ? <>
                            [{t(errorCodeToString(item.result))}] {item.errorStr ? item.errorStr : "."}<br/>
                            <><b>{t("필요 대처")}</b> : {t(errorCodeToDealWithDesc(item.params.fiatAmount, item.result))}</> : <></>
                          </> : <>{t("대응 완료")}</>}
                        </td>
                        <td></td>
                        <td>
                          { retryable( item.review, item.params.fiatAmount, item.result ) ? <button className="btn btn-sm btn-primary" onClick={function(){
                            if(confirm(`${t("정말로 재시도 하시겠습니까")}?`)){
                              let pass = prompt(`${t("비밀번호를 입력하세요")}.`);
                              if(pass !== "2024"){
                                alert(`${t("비밀번호가 틀렸습니다")}.`);
                                return;
                              }

                              setShowLoading(true);
                              AdminAPI.buyoutErrorRetry(token, item.enterId).then((v:any)=>{
                                setShowLoading(false);
                                if(v.success){
                                  alert(`${t("재시도 완료")}`)
                                  OnLoad();
                                }else{
                                  alert(`${t("재시도 실패")}`)
                                }
                              })
                            }
                          }}>{t("재시도")}</button> : <></>}

                          { item.review == 0 ? <button className="btn btn-sm btn-warning" onClick={function(){
                            if(confirm(`${t("정말로 문제가 해결되었습니까")}?`)){
                              let pass = prompt(`${t("비밀번호를 입력하세요")}.`);
                              if(pass !== "2024"){
                                alert(`${t("비밀번호가 틀렸습니다")}.`);
                                return;
                              }

                              AdminAPI.buyoutErrorPass(token, item.enterId).then((v:any)=>{
                                if(v.success){
                                  alert(`${t("패스 완료")}`)
                                  OnLoad();
                                }else{
                                  alert(`${t("패스 실패")}`)
                                }
                              })
                            }
                          }}>{t("문제 해결됨")}</button> : <></>}

                        </td>
                      </tr>
                      <tr style={{borderBottom:"2px dotted gray"}}>
                        { item.memo ? <td colSpan={9999}>
                          { Array.isArray(item.memo) && item.memo.map((v, i) =>  <div>{v}</div>) }
                          { Array.isArray(item.memo) == false && JSON.stringify(item.memo) }
                        </td> : <></>}
                      </tr>
                    </>
                    }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  { list && list.length === 0 ? <tr><td colSpan={9}>{t("데이터가 없습니다")}.</td></tr> : <></> }
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}

export default BuyinoutErrorLogs;
