import React, {useCallback, useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import { CreateTournamentRoomInterface, RAKE_TYPE, ROOM_TYPE} from "@app/dataset";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import {sha256} from "js-sha256";
import {useAppSelector} from "@store/hooks";
import styled from 'styled-components';
import api from "../api/admin"
import { useSelector } from 'react-redux';
import AdminLogPopup from '@app/components/popup/AdminLogPopup';
import {useTranslation} from "react-i18next";

const ToggleButton = styled.button`
  cursor: pointer;
  border:0;
  &:hover{
    text-decoration: underline;
  }
`;

const Privilege = styled.div`
  background-color: #eee;
  border-radius: 5px;
  margin: 5px 0;
  text-align: center;
  color: black;
  &:hover{
    opacity: 0.6;
    cursor: pointer;
  }
`

const SignUp = () => {
  const {t} = useTranslation();
  const [logPopup, setLogPopup] = useState<number|null>(null);
  const [makeNew, setMakeNew] = useState(0);
  const [addPrivilege, setAddPrivilege] = useState(0);
  const [addPrivilegeSelected, setAddPrivilegeSelected] = useState("");
  const [admins, setAdmins] = useState<{
    adminId:number,
    uid:string,
    addedAt:string,
    privilege:number,
    privileges:string[]
  }[]>([]);
  const {token} = useAppSelector(state => state.auth);
  const [adminUserData, setAdminUserData] = useState({
    id: '',
    password: '',
  })

  const handleCreateTournamentRoom = async () => {
    const result = await AdminAPI.signup({
      session: token,
      ...adminUserData
    });

    if(result.success){
      alert(t("관리자 계정이 생성되었습니다"))
      setMakeNew(0)
      api.getAdminList(token).then(v=>{
        setAdmins(v.list)
      })
    }else{
      alert(t("관리자 계정 생성에 실패하였습니다"))
    }
  }

  const onToggleMakeNew = useCallback(function(){
    setMakeNew( makeNew == 0 ? 1 : 0 )
  },[makeNew])

  useEffect(function(){
    api.getAdminList(token).then(v=>{
      setAdmins(v.list)
    })
  },[])

  const onClickRemovePriviliege = useCallback(function(adminId:number, privilegeName:string){
    if(privilegeName == "Super")return alert(`${t("슈퍼 권한을 가지고 있는 계정은 권한 변경이 불가능합니다")}.`);

    let adm = admins.find(v=>v.adminId == adminId)
    if(adm){
      if(confirm(`${t("정말로 해당 권한을 삭제하시겠습니까")}?`)){
        api.setAdminPrilvilege(token, adminId, privilegeName, true).then(v=>{
          adm!.privileges = v.privileges;
          setAdmins([...admins])
        });
      }
    }
  },[admins])

  const onClickApplyPrivilege = useCallback(function(){
    const adminId = addPrivilege;
    const privilegeName = addPrivilegeSelected;
    let adm = admins.find(v=>v.adminId == adminId)
    if(adm){
      let check = adm.privileges.find(v=>v == privilegeName)
      if(check){
        return alert(`${t("해당 권한을 이미 보유하고 있습니다")}.`)
      }

      if(adm.privileges.find(v=>v == "Super"))return alert(`${t("슈퍼 권한을 가지고 있는 계정은 권한 변경이 불가능합니다")}.`);

      if(confirm(`${t("정말로 해당 권한을 추가하시겠습니까")}?`)){
        api.setAdminPrilvilege(token, adminId, privilegeName, false).then(v=>{
          adm!.privileges = v.privileges;
          setAdmins([...admins])
        });
      }
    }
  },[admins, addPrivilege, addPrivilegeSelected])

  const onClickAddPrivilege = useCallback(function(adminId:number){
    setAddPrivilege(adminId)
    setAddPrivilegeSelected("");
  },[])

  const onClickDeleteAdmin = useCallback(function(adminId:number){
    if(confirm(`${t("정말로 해당 관리자를 삭제하시겠습니까")}?`)){
      api.adminDel(token, adminId).then(v=>{
        api.getAdminList(token).then(v=>{
          setAdmins(v.list)
        })
      })
    }
  },[])

  return (
    <div>
      {logPopup !== null ? <AdminLogPopup onClose={()=>setLogPopup(null)} adminId={logPopup!} /> : null}
      <ContentHeader title={t("관리자 만들기")}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className='col-md-6'>
              <ToggleButton onClick={onToggleMakeNew}>{ makeNew == 0 ? t(`관리자 생성`) : t(`생성 닫기`)}</ToggleButton>
              {makeNew == 1 ? <div className="card card-warning">
                <div className="card-header">
                  <h3 className="card-title">{t("관리자 계정 생성")}</h3>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("관리자")} ID</label>
                    <input type="text" className="form-control" defaultValue={''}
                           onChange={(e) => {
                             setAdminUserData({
                               ...adminUserData,
                               id: e.target.value
                             })
                           }}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">{t("관리자")} PASSWORD</label>
                    <input type="password" className="form-control" defaultValue={''}
                           onChange={(e) => {
                             setAdminUserData({
                               ...adminUserData,
                               password: sha256(e.target.value)
                             })
                           }}
                    />
                  </div>
                </div>

                <div
                  className="card-footer"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 12
                  }}>
                  <button className="btn btn-warning"
                          onClick={() => handleCreateTournamentRoom()}>{t("관리자 계정 생성")}
                  </button>
                </div>
              </div> : <></>}

            </div>
          </div>
          <div className="card">
            <div className="card-body table-responsive p-0">
              <table className="table table-head-fixed text-nowrap">
                <thead>
                <tr>
                  <th style={{width:"20px"}}>#</th>
                  <th>{t("아이디")}</th>
                  <th>{t("권한")}</th>
                  <th>{t("생성일")}</th>
                  <th>{t("활동 로그")}</th>
                  <th style={{width:"80px"}}>{t("삭제")}</th>
                </tr>
                </thead>
                <tbody>
                  {admins.map(v=><tr key={v.adminId}>
                    <td>{v.adminId}</td>
                    <td>{v.uid}</td>
                    <td>
                      {v.privileges.map(c=><Privilege key={c} onClick={()=>onClickRemovePriviliege(v.adminId, c)}>{c} (x)</Privilege>)}
                      {addPrivilege == v.adminId ? <><select onChange={(v)=>setAddPrivilegeSelected(v.target.value)} style={{marginRight:"5px"}}>
                          <option>-</option>
                          <option>Agency</option>
                          <option>GameRingRoom</option>
                          <option>GameTournamentRoom</option>
                          <option>PointManaging</option>
                          <option>Chart</option>
                          {/* <option>AdminSetting</option> */}
                          <option>Users</option>
                          <option>CreateAdmin</option>
                      </select>
                      <button onClick={()=>onClickApplyPrivilege()}>{t("등록")}</button>
                      <button onClick={()=>setAddPrivilege(0)}>{t("취소")}</button></> :
                      <Privilege onClick={()=>onClickAddPrivilege(v.adminId)}>+</Privilege>}

                    </td>
                    <td>{v.addedAt}</td>
                    <td><button onClick={()=>setLogPopup(v.adminId)}>View</button></td>
                    <td><button onClick={()=>onClickDeleteAdmin(v.adminId)}>Del</button></td>
                  </tr>)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SignUp;
