import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import CreateAgencyPopup from "@app/components/popup/CreateAgencyPopup";
import {NavLink, useNavigate, useLocation, Location} from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import moment from "moment";
import NoticeAddPopup from "@app/components/popup/NoticeAddPopup";
import {useTranslation} from "react-i18next";
import OrderBox from "@app/modules/main/sort-box/OrderBox";

interface NoticeInfo {
    noticeId:number;
    title:string;
    thumb:string;
    image:string;
    text:string;
    addedAt:string;
    order:number;
}

const Notice = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const {token} = useAppSelector(state => state.auth);
  const [notice, setNotice] = useState<NoticeInfo[]>([])
  const [noticeTotalCount, setNoticeTotalCount] = useState(0)
  const [paginationData, setPaginationData] = useState({
    currentPage: 0,
    maxPage: 0,
    paginationMin: 0,
    paginationMax: 0,
  })
  const [rowCount, setRowCount] = useState(15)
  const getNotice = async (innerPageId: number, innerRowCount: number) => {
    const result = await AdminAPI.noticeList(token, innerPageId, innerRowCount);
    if (result.success) {
      setNotice(result.payload.list)
      setNoticeTotalCount(result.payload.totalCount)

      const maxPage = Math.ceil(result.payload.totalCount / innerRowCount)
      const paginationMin = Math.floor(innerPageId / 10) * 10
      const paginationMax = Math.min(paginationMin + 10, maxPage)
      setPaginationData({
        currentPage: innerPageId,
        maxPage: maxPage,
        paginationMin: paginationMin,
        paginationMax: paginationMax,
      })
    }
  }

  const removenotice = async (noticeId: number) => {
    const result = await AdminAPI.noticeDelete(token, noticeId);
    if (result.success) {
      getNotice(paginationData.currentPage, rowCount)
    }
  };

  const onOrderChange = async (noticeId:number, order:number) => {
    let confirm = window.confirm(t(`순서를 ${order == 1 ? "올리시겠습니까?" : "내리시겠습니까?"}`));
    if(!confirm)
      return;
    const result = await AdminAPI.noticeOrderUpdate(token, noticeId, order);
    if (result.success) {
      getNotice(paginationData.currentPage, rowCount)
    }
  }

  useEffect(() => {
    getNotice(paginationData.currentPage, rowCount)
  }, [paginationData.currentPage, rowCount])

  return <div>
    <ContentHeader title={t("공지사항 목록")}/>

    {showCreateModal && <NoticeAddPopup onClose={() => { setShowCreateModal(false); getNotice(paginationData.currentPage, rowCount);} }/>}

    <section className="content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">

              <div className="card-body table-responsive p-0" style={{height: 790}}>
                <table className="table table-head-fixed text-nowrap">
                  <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("제목")}</th>
                    <th>{t("썸네일")}</th>
                    <th>{t("이미지")}</th>
                    <th>{t("텍스트")}</th>
                    <th>{t("등록날짜")}</th>
                    <th>{t("순서")}</th>
                    <th>
                      <button type="button" className="btn btn-block btn-primary btn-sm" onClick={() => {
                        document.getElementById('modal-default')?.classList.add('show');
                        setShowCreateModal(true);
                      }}>{t("공지사항 등록")}</button>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    notice.map((item: NoticeInfo, index: number) => {
                      return <tr key={index}>
                        <td>@{item.noticeId}</td>
                        <td>{item.title}</td>
                        <td><img onClick={()=>window.open(item.thumb, "_blank")} src={item.thumb} style={{width:"150px", height:"50px", cursor:"pointer"}} /></td>
                        <td><img onClick={()=>window.open(item.image, "_blank")} src={item.image} style={{width:"150px", height:"50px", cursor:"pointer"}} /></td>
                        <td>{item.text}</td>
                        <td>{moment(item.addedAt as any).format('YYYY-MM-DD HH:mm:ss')}</td>
                        <td style={{position:"relative"}}>{item.order} <OrderBox targetId={item.noticeId} onClick={(order, targetId) => {
                          onOrderChange(targetId, order);
                        }} /></td>

                        <td style={{ textAlign: 'center' }}>
                          <i style={{ cursor: 'pointer' }} className='fas fa-trash' onClick={()=>{
                            if(window.confirm('{t("삭제하시겠습니까")}?')){
                              removenotice(item.noticeId);
                            }
                          }}></i>
                        </td>
                      </tr>
                    })
                  }
                  </tbody>
                </table>
              </div>
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link no-hover">
                      {t("표시")}
                    </div>
                  </li>
                  {
                    [15, 30, 50].map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div className={"page-link " + (item === rowCount ? 'selected' : '')} style={{
                          cursor: 'pointer'
                        }} onClick={() => setRowCount(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <div className={"page-link " + (paginationData.paginationMin < 10 ? 'disabled' : '')}
                         onClick={() => {
                           if (paginationData.paginationMin < 10) {
                             return;
                           } else {
                             setPaginationData({
                               ...paginationData,
                               currentPage: paginationData.paginationMin - 1
                             })
                           }
                         }}>
                      <i className="fas fa-chevron-left"></i>
                    </div>
                  </li>
                  {
                    Array(paginationData.paginationMax - paginationData.paginationMin).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <div
                          className={"page-link " + (index + paginationData.paginationMin === paginationData.currentPage ? 'selected' : '')}
                          style={{cursor: 'pointer'}}
                          onClick={() => setPaginationData({
                            ...paginationData,
                            currentPage: index + paginationData.paginationMin
                          })}>{index + paginationData.paginationMin + 1}</div>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <div
                      className={"page-link " + (paginationData.paginationMax * rowCount > noticeTotalCount ? 'disabled' : '')}
                      onClick={() => {
                        setPaginationData({
                          ...paginationData,
                          currentPage: paginationData.paginationMax
                        })
                      }}>
                      <i className="fas fa-chevron-right"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

          </div>
        </div>
      </div>

    </section>
  </div>
}
export default Notice;
