import styled from "styled-components";
import React from "react";

const Wrapper = styled.div<{folded: boolean}>`
  position: absolute;
  left: -100%;
  top: -100%;
  width: 64px;
  height: 78px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: #FFF;
  
  ${p => p.folded && `
    opacity: 0.6;
  `};

  > div.top {
    width: 100%;
    position: relative;
    >.changeStackSize{
      position: absolute;
      top: -14px;
      left: 50%;
      transform: translateX(-50%);
      font-family: Pretendard;
      font-size: 9px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      border-radius: 4px;
      border: 0.4px solid white;
      background: rgba(0, 0, 0, 0.80);
      padding: 2px;
    }
    >.changeStackSize.red{
      color: #FF2525;
    }
    >.changeStackSize.green{
     color: #37FF25; 
    }
    > .profileImg {
      width: 48px;
      height: 48px;
      background-size: cover;
      background-position: center center;
      border-radius: 50%;
      margin: 0 auto;
      
      &[data-winner=true] {
        border: 2px solid #FFEDAC;
        filter: drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.60));
      }
    }

    .winner-badge {
      position: absolute;
      top: -4px;
      right: 0;
    }
  }

  > div.bottom {
    width: 100%;
    position: relative;
    margin-top: -6px;
    
    > .profileName {
      border-radius: 5px;
      border-top: 0.4px solid rgba(255, 255, 255, 0.00);
      background: radial-gradient(95.31% 95.31% at 50% 100%, rgba(182, 182, 182, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #181A1D;
      padding: 2px 4px;
      position: relative;
      z-index: 1;
      text-align: center;

      > .nickname {
        font-size: 10px;
        font-weight: 500;
      }

      > .prize {
        color: ${p => p.folded ? '#FFFFFF' : '#2F99FB'};
        text-align: center;
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
`;

const CardDeck = styled.div`
  position: absolute;
  bottom: -8px;
  right: 2px;
  
  > img {
    width: 50%;
    height: auto;
    box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.40);
    
    &:first-child {
      transform: translate(3px, 0) rotate(-5deg);
    }

    &:last-child {
      transform: translate(-3px, 0) rotate(5deg);
    }
  }
`;


function HistoryPlayer(
  {
    userId,
    seat,
    nickname,
    profileImg,
    folded,
    winner,
    prize,
    cards,
    changeStackSize
  }: {
    userId: number;
    seat: number;
    nickname: string;
    profileImg: string;
    folded: boolean;
    winner: boolean;
    prize: number;
    cards: number[];
    changeStackSize: number;
  }
): JSX.Element {

  return <Wrapper className="history-player" data-seat={seat} data-user={userId} folded={folded}>
    <div className="top">
      {
        changeStackSize !== 0 && <div className={'changeStackSize ' + (changeStackSize > 0 ? 'green' : 'red')}>
          {changeStackSize > 0 ? '+' : ''}{(changeStackSize).toLocaleString()}
        </div>
      }

      <div className="profileImg"
           data-winner={winner}
           style={{backgroundImage: `url(/img/profile/${profileImg}.png)`}}
      />
      {
        cards.length === 2 && (
          <CardDeck>
            <img src={`/img/cards/${cards[0]}.svg`}/>
            <img src={`/img/cards/${cards[1]}.svg`}/>
          </CardDeck>
        )
      }
      {
        winner && (
          <img className="winner-badge" src={"/img/winner_badge.svg"}/>
        )
      }
    </div>
    <div className="bottom">
      <div className="profileName">
        <div className="nickname">{nickname}</div>
        {
          folded ? (
            <div className="prize">FOLD</div>
          ) : (
            <div className="prize">{prize.toLocaleString()}</div>
          )
        }
      </div>
    </div>
  </Wrapper>;
}

export default HistoryPlayer;
