import {ContentHeader} from "@components";
import React, {useCallback, useEffect, useState} from "react";
import moment from "moment";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from '@app/store/hooks';
import AgencyDetailPopup from "@app/components/popup/AgencyDetailPopup";
import UserInfoDetailPopup from "@app/components/popup/UserInfoDetailPopup";
import styled from "styled-components";
import UserInGameMoneyPopup from "@app/components/popup/UserInGameMoneyPopup";
import {useTranslation} from "react-i18next";
import AgencyInGameMoneyPopup from "@app/components/popup/AgencyInGameMoneyPopup";

let FromNow = styled.div`
  font-size:12px;
  font-weight: bold;
`

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const UserAccessList = ({isAgency}:{isAgency:boolean}) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const [pageNum,setPageNum] = useState(999999);
  const [page,setPage] = useState(0);
  const [totUser, setTotUser] = useState(0);
  const [users, setUsers] = useState<{
    userId:number,
    agencyId:number,
    uid:string,
    nickname:string,
    agencyName:string,
    buyinAmount:number,
    seatOutAmount:number,
    lastBuyinAt:string,
    lastAccess:string,
  }[]>();

  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);

  const [inputStartDate, setInputStartDate ] = useState(new Date((Date.now() - 60*60*24*1000)))
  const [inputEndDate, setInputEndDate ] = useState(new Date((Date.now() - 0)))
  const [searchStartDate, setSearchStartDate ] = useState(moment(inputStartDate).format(DATE_FORMAT))
  const [searchEndDate, setSearchEndDate ] = useState(moment(inputEndDate).format(DATE_FORMAT))

  const [userInGameMoneyPopup, setUserInGameMoneyPopup] = useState<number | null>(null);
  const [agencyInGameMoneyPopup, setAgencyInGameMoneyPopup] = useState<number | null>(null);

  const OnLoad = useCallback(function(){
    setUsers(undefined);
    AdminAPI.userAccessList(token, 0, 0, searchStartDate, searchEndDate ).then(v=>{
      setUsers(v.rows)
      setTotUser(v.totalCnt)
    })
  },[
    searchStartDate,
    searchEndDate,
  ])

  useEffect(function(){
    OnLoad();
  },[
    searchStartDate,
    searchEndDate,
  ])

  const onClickSearch = useCallback(function(){
    setSearchStartDate(moment(inputStartDate).format(DATE_FORMAT));
    setSearchEndDate(moment(inputEndDate).format(DATE_FORMAT));
  },[
    inputStartDate,
    inputEndDate,
  ])

  const onClickCancelSearch = useCallback(function(){
    setSearchStartDate("");
    setSearchEndDate("");

    setInputStartDate(new Date('2021-01-01 00:00:00'));
    setInputEndDate(new Date('2099-01-01 23:59:59'));
  },[])

  return <div>
    {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
    {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}
    {userInGameMoneyPopup !== null ? <UserInGameMoneyPopup onClose={()=>setUserInGameMoneyPopup(null)} /> : <></>}
    {agencyInGameMoneyPopup !== null ? <AgencyInGameMoneyPopup onClose={()=>setAgencyInGameMoneyPopup(null)} /> : <></>}

    <ContentHeader title={t("접속 기록")}/>
    <section className="content">
      <div className="container-fluid">
        <button type="button" className="btn btn-primary" style={{marginBottom:"15px"}} onClick={()=>setUserInGameMoneyPopup(0)}>{t("현재 게임 내 유저 보유금 조회")}</button>
        {!isAgency ? <button type="button" className="btn btn-primary" style={{marginBottom:"15px", marginLeft:"5px"}} onClick={()=>setAgencyInGameMoneyPopup(0)}>{t("현재 게임 내 에이전시 유저 보유금 조회")}</button> : <></>}
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-header">
                        <div className='row'>
                            <div className="input-group col-8">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{t("기간")}</span>
                                </div>
                                <input type="datetime-local" className="form-control"
                                        value={moment(inputStartDate).format("YYYY-MM-DD HH:mm:ss")}
                                        onChange={(e) => {
                                        setInputStartDate(new Date(e.target.value));
                                        }}
                                />
                                <input type="datetime-local" className="form-control"
                                        value={moment(inputEndDate).format("YYYY-MM-DD HH:mm:ss")}
                                        onChange={(e) => {
                                        setInputEndDate(new Date(e.target.value));
                                        }}
                                />
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().startOf('month').format(DATE_FORMAT)
                                        let r2 = moment().endOf('month').format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("이번달")}</button>
                                </div>
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().startOf('isoWeek').format(DATE_FORMAT)
                                        let r2 = moment().endOf('isoWeek').format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("이번주")}</button>
                                </div>
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().add(-24, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                        let r2 = moment().add(-24, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("어제")}</button>
                                </div>
                                <div className="input-group-append">
                                    <button type="button" className="btn btn-info" onClick={()=>{
                                        let r1 = moment().add(0, "hours").hour(0).minute(0).second(0).format(DATE_FORMAT)
                                        let r2 = moment().add(0, "hours").hour(23).minute(59).second(59).format(DATE_FORMAT)

                                        setInputStartDate(new Date(r1));
                                        setInputEndDate(new Date(r2));
                                    }}>{t("오늘")}</button>
                                </div>
                            </div>
                        <div className='col-4'>
                        <button type="button" className="btn btn-secondary float-right" style={{marginLeft:"5px"}} onClick={()=>onClickCancelSearch()}>{t("검색 취소")}</button>
                        <button type="button" className="btn btn-primary float-right" onClick={()=>onClickSearch()}>{t("검색")}</button>
                    </div>
                </div>

                <div className='row mb-2' style={{marginTop:"20px"}}>
                    <div className="input-group col-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{t("기간 내 접속자")}</span>
                        </div>
                        <input type="text" className="form-control" disabled value={totUser} />
                    </div>
                    <div className="input-group col-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{t("바이인 총합")}</span>
                        </div>
                        <input type="text" className="form-control" disabled value={users ? Math.abs(users.reduce((a,b)=>a+Number(b.buyinAmount), 0)).toLocaleString() : 0} />
                    </div>
                    <div className="input-group col-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{t("바이아웃 총합")}</span>
                        </div>
                        <input type="text" className="form-control" disabled value={users ? users.reduce((a,b)=>a+Number(b.seatOutAmount), 0).toLocaleString() : 0} />
                    </div>
                    <div className="input-group col-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text">{t("유저 수익 총합")}</span>
                        </div>
                        <input type="text" className="form-control" disabled value={users ? (users.reduce((a,b)=>a+Number(b.buyinAmount), 0)+users.reduce((a,b)=>a+Number(b.seatOutAmount), 0)).toLocaleString() : 0} />
                    </div>
                </div>
            </div>



              <div className="card-body table-responsive p-0" style={{height: 785}}>
                <table className="table table-head-fixed text-nowrap" id="user-table">
                  <thead>
                  <tr>
                    <th>{t("유저")} ID</th>
                    <th>{t("유저 이름")}</th>
                    <th>{t("에이전시 이름")}</th>
                    <th>{t("마지막 접속 시각")}</th>
                    <th>{t("기간 내 바이인 총합")}</th>
                    <th>{t("기간 내 바이아웃 총합")}</th>
                    <th>{t("기간 내 유저 수익")}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    users ? users.map((item, index) => {
                      return <tr key={index}>
                        <td>@{item.uid}</td>
                        <td>{item.nickname} <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setUserDetailPopup(item.userId) }}/></td>
                        <td>{item.agencyName} <i className='fas fa-info-circle ml-2' style={{ cursor: 'pointer' }} onClick={() => { setAgencyDetailPopup(item.agencyId) }}/></td>
                        <td>
                            {moment(item.lastAccess).local().format(DATE_FORMAT)}
                            {/* item.lastAccess{t("이 현재와 몇시 몇분 몇초 차이나는지 출력")} */}
                            <FromNow>({moment.utc(item.lastAccess).fromNow()})</FromNow>
                        </td>
                        <td>{Math.abs(Number(item.buyinAmount)).toLocaleString()}{t("알")}</td>
                        <td>{Number(item.seatOutAmount).toLocaleString()}{t("알")}</td>
                        <td>{(Number(item.buyinAmount) + Number(item.seatOutAmount)).toLocaleString()}{t("알")}</td>
                      </tr>
                    }) : <tr><td colSpan={9}>{t("로딩 중")}...</td></tr>
                  }
                  </tbody>
                </table>
              </div>
              {/*
              <div className="card-footer clearfix">
                <ul className="pagination pagination-sm m-0 float-left">
                  <li className="page-item">
                    <div className="page-link">
                      표시
                    </div>
                  </li>
                  {
                    [100, 200, 500].map((item, index) => {
                      return <li key={index} className="page-item">
                        <div className="page-link" style={
                          item === pageNum ? {
                            backgroundColor: 'lightgrey',
                            color: 'black'
                          } : {}
                        } onClick={()=>setPageNum(item)}>{item}</div>
                      </li>
                    })
                  }
                </ul>
                <ul className="pagination pagination-sm m-0 float-right">
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-left"></i>
                    </a>
                  </li>
                  {
                    Array(Math.ceil( totUser / pageNum )).fill(0).map((item, index) => {
                      return <li className="page-item" key={index}>
                        <a style={{backgroundColor:page==index?"#ddd":"#fff"}} className="page-link" href="#user-table" onClick={()=>setPage(index)}>{index + 1}</a>
                      </li>
                    })
                  }
                  <li className="page-item">
                    <a className="page-link" href="#">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
              */}
            </div>
          </div>
        </div>
      </div>

    </section>
  </div>
}

export default UserAccessList;
