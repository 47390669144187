import { ContentHeader } from "@components";
import React, { useCallback, useEffect, useState } from "react";
import CreateAgencyPopup, { } from "@app/components/popup/CreateAgencyPopup";
import { NavLink, useNavigate, useLocation, Location, Link } from 'react-router-dom';
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import AgencyRakeLogPopup from "@app/components/popup/AgencyRakeLogPopup";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { PrintCurrency } from "./UserAdjustMoney";
import DigitsColorWrapper from "@app/components/DigitsColorWrapper";
import { chipToCurrency, CurrencyMark } from "@app/conversionChip";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const AgencyWalletList = () => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [agencyList, setAgencyList] = useState<{
        agencyId:number,
        agencyName:string,
        amount:number,
        egg:number,
    }[]>([])

    const [inputSearchAgencyName, setInputSearchAgencyName] = useState("");
    const [searchAgencyName, setSearchAgencyName] = useState("");

    const getAgencyList = async (searchAgencyName:string) => {
        const response = await AdminAPI.getAgencyWalletList(token, searchAgencyName);
        setAgencyList(response.list);
    }

    useEffect(() => {
      getAgencyList(searchAgencyName)
    }, [searchAgencyName])

    const onClickCancelSearch = useCallback(function () {
        setInputSearchAgencyName("");
        setSearchAgencyName("");
    }, [])

    const onClickSearch = useCallback(function () {
        setSearchAgencyName(inputSearchAgencyName)
    }, [inputSearchAgencyName])

    return <div>
        <ContentHeader title={t("에이전시 요율 지갑")} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='row'>
                                    <div className="input-group col-4">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text">{t("에이전트 이름")}</span>
                                        </div>
                                        <input type="text" value={inputSearchAgencyName} onChange={(v) => setInputSearchAgencyName(v.target.value)} className="form-control" placeholder={t("에이전시 이름")} />
                                    </div>
                                    <div className='col-4'>
                                        <button type="button" className="btn btn-secondary float-right" style={{ marginLeft: "5px" }} onClick={() => onClickCancelSearch()}>{t("검색 취소")}</button>
                                        <button type="button" className="btn btn-primary float-right" onClick={() => onClickSearch()}>{t("검색")}</button>
                                    </div>
                                </div>
                            </div>

                            <div className="card-body table-responsive p-0" style={{ height: 785 }}>
                                <table className="table table-head-fixed text-nowrap" id="user-table">
                                    <thead>
                                        <tr>
                                            <th>{t("#")}</th>
                                            <th>{t("에이전시명")}</th>
                                            <th>{t("보유 포인트")}</th>
                                            <th>{t("요율 지갑 보유액")}</th>
                                            <th>{t("총액")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {agencyList.map((agency: any, index) => {

                                            return <tr key={index}>
                                                <td>{agency.agencyId}</td>
                                                <td>{agency.agencyName}</td>
                                                <td>
                                                    {Number(agency.egg).toLocaleString()}
                                                    <div style={{fontSize:"13px"}}>
                                                    ({CurrencyMark("KRW")}<DigitsColorWrapper number={chipToCurrency("KRW", agency.egg, 1000)} />)
                                                    </div>
                                                </td>
                                                <td>
                                                    {Number(agency.amount).toLocaleString()}
                                                    <div style={{fontSize:"13px"}}>
                                                    ({CurrencyMark("KRW")}<DigitsColorWrapper number={chipToCurrency("KRW", agency.amount, 1000)} />)
                                                    </div>
                                                </td>
                                                <td>
                                                    {(Number(agency.amount)+Number(agency.egg)).toLocaleString()}
                                                    <div style={{fontSize:"13px"}}>
                                                    ({CurrencyMark("KRW")}<DigitsColorWrapper number={chipToCurrency("KRW", (Number(agency.amount)+Number(agency.egg)), 1000)} />)
                                                    </div>
                                                </td>
                                            </tr>
                                        })}

                                        {agencyList.length == 0 && <tr><td colSpan={5} style={{ textAlign: "center" }}>{t("검색 결과가 없습니다")}.</td></tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </div>
}

export default AgencyWalletList;
