import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import {useAppSelector} from "@store/hooks";
import {useEffect, useRef, useState} from "react";
import {sha256} from "js-sha256";
import api from "../../api/admin"
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";

interface UserInfoDetailPopupProps {
  onClose: () => void;
  userId:number;
}

const UserInfoDetailPopup = ({ userId, onClose }: UserInfoDetailPopupProps) => {
  const {t} = useTranslation();
  const {token} = useAppSelector(state => state.auth);
  const user = useSelector((state: any) => state.auth.currentUser);
  const [userInfo , setUserInfo] = useState<{
    userId:number,
    uid:string,
    agencyId:number,
    nickname:string,
    addedAt:string,
    money:number,
    agentCode:string,
    lastAccess:string,
    lastIp:string,
    agencyName:string,
  }>();

  const [accessIP, setAccessIP] = useState<{
    ips:string[]
    others:{
      userId:number,
      ip:string,
      nickname:string,
      agencyName:string,
    }[]
  }>({
    ips:[],
    others:[]
  });

  useEffect(function(){
    if(!user.agencyId){
      api.getUserInfo(userId, token).then(v=>setUserInfo(v.member))
      api.getUserAccessIP(userId, token).then(v=>{
        if(v.success == false) return;
        setAccessIP(v)
      })
    }else{
      api.getUserInfoForAgency(userId, token).then(v=>setUserInfo(v.member))
    }
  },[user])

  return <PopupContainer onClickBackdrop={onClose}>
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">{t("유저 정보")}</h4>
          <button onClick={() => onClose()} type="button" className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>

        {userInfo ?
        <div className="modal-body">

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("소속 에이전트")}</label>
            <div className="col-sm-9">
              <input type="text" disabled className="form-control" placeholder={t("소속 에이전트")} value={userInfo.agencyName}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("아이디")}</label>
            <div className="col-sm-9">
              <input type="text" disabled className="form-control" placeholder={t("아이디")} value={userInfo.uid}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("닉네임")}</label>
            <div className="col-sm-9">
              <input type="text" disabled className="form-control" placeholder={t("닉네임")} value={userInfo.nickname}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("총판 코드")}</label>
            <div className="col-sm-9">
              <input type="text" disabled className="form-control" placeholder={t("-")} value={userInfo.agentCode}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("마지막 접속 시간")}</label>
            <div className="col-sm-9">
              <input type="text" disabled className="form-control" placeholder={t("마지막 접속 시간")} value={moment(userInfo.lastAccess).local().format("YYYY-MM-DD HH:mm:ss")}/>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("마지막 접속 IP")}</label>
            <div className="col-sm-9">
              <input type="text" disabled className="form-control" placeholder={t("마지막 접속 IP")} value={userInfo.lastIp}/>
            </div>
          </div>

          {user.agencyId ? <>
          </> : <>
            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("접속")} IP</label>
              <div className="col-sm-9">
                <textarea disabled className="form-control" placeholder="IP" value={accessIP ? accessIP.ips.join("\n") : ""}/>
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">{t("중복 IP 사용자")}</label>
              <div className="col-sm-9">
                {accessIP.others.map((v,i)=><div
                  key={i}>
                    [{v.nickname}] / {v.agencyName} / {v.ip}
                </div>)}
              </div>
            </div>
          </>}
        </div>
        : <>Loading..</>}

        <div className="modal-footer justify-content-between">
          <button type="button" className="btn btn-default" data-dismiss="modal" onClick={onClose}>Close</button>
          {/* <button type="button" className="btn btn-primary" onClick={handleCreateAgency}>{t("생성하기")}</button> */}
        </div>
      </div>
    </div>
  </PopupContainer>
}
export default UserInfoDetailPopup;
