import React, {useEffect, useState} from 'react';
import {ContentHeader} from '@components';
import moment from "moment";
import Chart, {ChartItem} from 'chart.js/auto';
import styled from "styled-components";
import api from "../../api/admin"
import { useSelector } from 'react-redux';
import UserInfoDetailPopup from '@app/components/popup/UserInfoDetailPopup';
import AgencyDetailPopup from '@app/components/popup/AgencyDetailPopup';
import {useTranslation} from "react-i18next";

const TableItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;

  > .profile {
    width: 1rem;
    height: 1rem;
    object-fit: cover;
  }
`;

const DashboardEarn = () => {
  const {t} = useTranslation()
  const token = useSelector((state: any) => state.auth.token);
  const [userDetailPopup, setUserDetailPopup] = useState<number | null>(null);
  const [agencyDetailPopup, setAgencyDetailPopup] = useState<number | null>(null);
  const [userList, setUserList] = useState<{
    name:string,
    agenyName:string,
    agencyId:number,
    uid:string,
    userId:number,
    point:number}[]
  >([]);

  useEffect(function(){
    api.top10Week(token).then(v=>{
      setUserList(v.users.map((e:any)=>({
        name:e.nickname,
        agenyName:e.agencyName,
        agencyId:e.agencyId,
        userId:e.userId,
        uid:e.uid,
        point:e.pt,
      })))
    })
  },[])

  return (
    <div>
      {userDetailPopup !== null ? <UserInfoDetailPopup userId={userDetailPopup} onClose={()=>setUserDetailPopup(null)} /> : <></>}
      {agencyDetailPopup !== null ? <AgencyDetailPopup agencyId={agencyDetailPopup} onClose={()=>setAgencyDetailPopup(null)} /> : <></>}
      <ContentHeader title={t("최근 1주일 최대 획득 회원")}/>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card-body table-responsive p-0">
                  <table className="table table-head-fixed text-nowrap">
                    <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("유저 이름 (유저 아이디)")}</th>
                      <th>{t("소속 에이전트")}</th>
                      <th>{t("최근 1주일 증감")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      userList.map((item, index) => {
                        return <tr key={index}>
                          <td>{item.userId}</td>
                          <td>
                            <TableItemWrapper>
                              <img src="/img/default-profile.png" className="img-circle elevation-2 profile"
                                   alt="User Image"/><span>{item.name} ({item.uid})</span>
                              <i className='fas fa-info-circle ml-2'
                                 style={{
                                   cursor: 'pointer'
                                 }} onClick={() => {
                                setUserDetailPopup(item.userId)
                              }}/>
                            </TableItemWrapper></td>
                          <td>
                            <TableItemWrapper>
                              <img src="/img/default-profile.png" className="img-circle elevation-2 profile"
                                   alt="User Image"/><span>{item.agenyName}</span>
                              <i className='fas fa-info-circle ml-2'
                                 style={{
                                   cursor: 'pointer'
                                 }} onClick={() => {
                                setAgencyDetailPopup(item.agencyId)
                              }}/>
                            </TableItemWrapper>
                          </td>
                          {item.point < 0 ?
                            <td className='text-danger'>{item.point.toLocaleString()}</td> :
                            <td className='text-success'>+{item.point.toLocaleString()}</td>
                          }
                        </tr>
                      })
                    }
                    {/* <tr>
                      <td colSpan={4} style={{
                        textAlign: 'center',
                        cursor: 'pointer'
                      }}>
                        <span>더보기</span>
                      </td>
                    </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DashboardEarn;
