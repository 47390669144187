import PopupContainer from "@app/components/popup/PopupContainer";
import AdminAPI from "@app/api/admin";
import { useAppSelector } from "@store/hooks";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface StandaloneBankInfoPopupProps {
    show: boolean;
    onHide: () => void;
    bankInfo: any;
    onClickSave: (bank: string, bankAccount: string, bankAccountHolder: string, usdtAddress: string, usdtNetwork: string) => void;
}

const StandaloneBankInfoPopup = ({ show, onHide, bankInfo, onClickSave }: StandaloneBankInfoPopupProps) => {
    const { t } = useTranslation();
    const { token } = useAppSelector(state => state.auth);
    const [bank, setBank] = useState(bankInfo ? bankInfo.bank : "");
    const [bankAccount, setBankAccount] = useState(bankInfo ? bankInfo.bankAccount : "");
    const [bankAccountHolder, setBankAccountHolder] = useState(bankInfo ? bankInfo.bankAccountHolder : "");
    const [usdtAddress, setUsdtAddress] = useState(bankInfo ? bankInfo.usdtAddress : "");
    const [usdtNetwork, setUsdtNetwork] = useState(bankInfo ? bankInfo.usdtNetwork : "");

    const handleSave = useCallback(() => {
        onClickSave(bank, bankAccount, bankAccountHolder, usdtAddress, usdtNetwork);
    }, [onClickSave, bank, bankAccount, bankAccountHolder, usdtAddress, usdtNetwork]);

    return (
        <PopupContainer onClickBackdrop={onHide}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">{t("계좌 정보")}</h4>
                        <button type="button" className="close" onClick={onHide}>
                            <span>×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("은행명")}</label>
                            <div className="col-sm-9">
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    value={bank}
                                    onChange={(e) => setBank(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("계좌번호")}</label>
                            <div className="col-sm-9">
                                <input 
                                    type="text" 
                                    className="form-control"
                                    value={bankAccount}
                                    onChange={(e) => setBankAccount(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("예금주")}</label>
                            <div className="col-sm-9">
                                <input 
                                    type="text" 
                                    className="form-control"
                                    value={bankAccountHolder}
                                    onChange={(e) => setBankAccountHolder(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("USDT 주소")}</label>
                            <div className="col-sm-9">
                                <input 
                                    type="text" 
                                    className="form-control"
                                    value={usdtAddress}
                                    onChange={(e) => setUsdtAddress(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label">{t("USDT 네트워크")}</label>
                            <div className="col-sm-9">
                                <select 
                                    className="form-control"
                                    value={usdtNetwork}
                                    onChange={(e) => setUsdtNetwork(e.target.value)}
                                >
                                    <option value="TRC20">TRC20</option>
                                    <option value="ERC20">ERC20</option>
                                    <option value="BEP20">BEP20</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" className="btn btn-primary" onClick={handleSave}>
                            {t("저장")}
                        </button>
                        <button type="button" className="btn btn-default" onClick={onHide}>
                            {t("닫기")}
                        </button>
                    </div>
                </div>
            </div>
        </PopupContainer>
    );
};

export default StandaloneBankInfoPopup;
